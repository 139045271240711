import { useState, useMemo, useEffect, useRef } from "react";
import { Col, Row, Button, Badge, Collapse } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { createSelector } from "reselect";
import { useDispatch, useSelector } from "react-redux";
import withRouter from "Common/withRouter";
import { capitalizeString } from "helpers/common";
import { openModal } from "slices/thunk";
import { BsChevronDown } from "react-icons/bs";
import { Link } from "react-router-dom";

interface PillWithDropdownProps {
  totalCount: number;
  items: any;
  handleBadegeClick: any;
}

interface DLCProps {
  searchTerm: string;
  activeBrand: string;
  setActiveBrand: (value: string) => void;
  activeCampaign: string;
  setActiveCampaign: (value: string) => void;
  activePhoneNumber: string;
  setActivePhoneNumber: (value: string) => void;
  filterValues: {
    brandId: any[];
    brandName: any[];
    campaignId: any[];
    phoneNumber: any[];
    portingId: string;
  };
  router: any;
}

const styles = {
  dropdownContainer: {
    position: "relative" as const,
    display: "inline-block",
    zIndex: 3,
  },
  dropdownMenu: {
    position: "absolute" as const,
    top: "calc(100% + 5px)",
    left: "0",
    backgroundColor: "#fff",
    borderRadius: "4px",
    boxShadow: "0 2px 8px rgba(0,0,0,0.15)",
    border: "1px solid #dde1ef",
    minWidth: "220px",
    maxHeight: "300px",
    overflowY: "auto" as const,
    zIndex: 1000,
  },
  dropdownItem: {
    padding: "8px 16px",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#f5f5f5",
    },
  },
};

const PillWithDropdown: React.FC<PillWithDropdownProps> = ({
  totalCount,
  items,
  handleBadegeClick,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
      }
    };

    if (isOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen]);

  return (
    <div style={styles.dropdownContainer} ref={dropdownRef}>
      <div className="d-flex align-items-center">
        <Badge
          bg={isOpen ? "secondary" : "primary"}
          pill
          className="me-2 cursor-pointer"
          onClick={handleBadegeClick}
        >
          {totalCount}
        </Badge>
        <span className="me-2">Campaigns</span>
        <BsChevronDown
          size={16}
          style={{
            transform: isOpen ? "rotate(180deg)" : "rotate(0)",
            transition: "transform 0.2s ease-in-out",
            color: "#6c757d",
            cursor: "pointer",
          }}
          onClick={() => setIsOpen(!isOpen)}
        />
      </div>

      {isOpen && (
        <div style={styles.dropdownMenu}>
          {items.map((item: any, index: number) => (
            <Button
              key={index}
              variant="link"
              className="w-100 text-decoration-none px-3 py-2 text-dark d-flex justify-content-between align-items-center"
              onClick={() => {
                item.onClick();
                setIsOpen(false);
              }}
            >
              <span className="me-2">{item.name}</span>
              <Badge bg="secondary" pill>
                {item.count}
              </Badge>
            </Button>
          ))}
        </div>
      )}
    </div>
  );
};

const highlightSearchTerm = (text: string, searchTerm: string) => {
  if (!searchTerm || !text) return text;

  const regex = new RegExp(`(${searchTerm})`, "gi");
  return text.split(regex).map((part, i) =>
    regex.test(part) ? (
      <span key={i} className="text-primary fw-semibold">
        {part}
      </span>
    ) : (
      part
    )
  );
};

const filterData = (data: any, term: string, filters: any) => {
  let filteredData = data;

  // First apply the search term filter
  if (term) {
    filteredData = filteredData.filter((brand: any) => {
      const brandMatch =
        brand.brandId?.toLowerCase().includes(term.toLowerCase()) ||
        brand.brandName?.toLowerCase().includes(term.toLowerCase()) ||
        false;
      const campaignMatch =
        brand.campaigns?.some((campaign: any) =>
          campaign.campaignId?.toLowerCase().includes(term.toLowerCase())
        ) || false;
      const phoneNumberMatch =
        brand.campaigns?.some(
          (campaign: any) =>
            campaign.ownNumbers?.some((number: any) =>
              number.phoneNumber?.includes(term)
            ) ||
            campaign.releasedNumbers?.some((number: any) =>
              number.phoneNumber?.includes(term)
            )
        ) || false;
      const portingIdMatch =
        brand.campaigns?.some((campaign: any) =>
          campaign.portingRequestIds?.some((porting: any) =>
            porting.portingId?.toLowerCase().includes(term.toLowerCase())
          )
        ) || false;

      return brandMatch || campaignMatch || phoneNumberMatch || portingIdMatch;
    });
  }

  // Apply all filters with AND logic
  if (filters) {
    filteredData = filteredData
      .map((brand: any) => {
        // First check if this brand should be included based on brandId filter
        if (
          filters.brandId?.length > 0 &&
          !filters.brandId.some((b: any) => b.brandId === brand.brandId)
        ) {
          return null;
        }

        // Clone the brand object to avoid mutating the original data
        const filteredBrand = { ...brand };

        // Filter campaigns based on campaignId filter
        if (filters.campaignId?.length > 0) {
          filteredBrand.campaigns = brand.campaigns?.filter((campaign: any) =>
            filters.campaignId.some(
              (c: any) => c.campaignId === campaign.campaignId
            )
          );
          if (!filteredBrand.campaigns?.length) return null;
        }

        // Filter campaigns based on phoneNumber filter
        if (filters.phoneNumber?.length > 0) {
          filteredBrand.campaigns = brand.campaigns?.filter(
            (campaign: any) =>
              campaign.ownNumbers?.some((number: any) =>
                filters.phoneNumber.some(
                  (p: any) => p.phoneNumber === number.phoneNumber
                )
              ) ||
              campaign.releasedNumbers?.some((number: any) =>
                filters.phoneNumber.some(
                  (p: any) => p.phoneNumber === number.phoneNumber
                )
              )
          );
          if (!filteredBrand.campaigns?.length) return null;
        }

        // Filter campaigns based on portingId filter
        if (filters.portingId) {
          filteredBrand.campaigns = brand.campaigns?.filter((campaign: any) =>
            campaign.portingRequestIds?.some(
              (porting: any) => porting.portingId === filters.portingId
            )
          );
          if (!filteredBrand.campaigns?.length) return null;
        }

        return filteredBrand;
      })
      .filter(Boolean); // Remove null entries
  }

  // Return filtered data, excluding pending brands
  return filteredData.filter(
    (dt: any) => dt?.brandId?.toLowerCase() !== "pending"
  );
};

interface Brand {
  brandId: string;
  brandName: string;
  campaigns?: Campaign[];
}

interface Campaign {
  campaignId: string;
  brandId: string;
  dcaStatus: string;
  campaignType: string;
  ownNumbers?: PhoneNumber[];
  portingRequestIds?: PortingRequest[];
  releasedNumbers?: PhoneNumber[];
}

interface PhoneNumber {
  phoneNumber: string;
  signalHouseSID: string;
  tenDLCWhiteListStatus: string;
}

interface PortingRequest {
  portingId: string;
  status: string;
}

interface ProcessedData {
  brandCount: number;
  campaignCount: number;
  ownNumbersCount: number;
  portingRequestCount: number;
  releasedNumbersCount: number;
  filteredBrands: Brand[];
}

// Helper function to check if a value is empty or null
const isValidValue = (value: any): boolean => {
  if (value === null || value === undefined) return false;
  if (typeof value === "string" && value.trim() === "") return false;
  if (Array.isArray(value) && value.length === 0) return false;
  return true;
};

// Updated buildUrl helper function
const buildUrl = (baseUrl: string, params: Record<string, any>) => {
  const validParams = Object.entries(params).reduce((acc, [key, value]) => {
    if (isValidValue(value)) {
      acc[key] = value;
    }
    return acc;
  }, {} as Record<string, any>);

  const queryString = new URLSearchParams(validParams).toString();
  return `${baseUrl}${queryString ? "?" + queryString : ""}`;
};

const DLC: React.FC<DLCProps> = ({
  searchTerm,
  activeBrand,
  setActiveBrand,
  activeCampaign,
  setActiveCampaign,
  activePhoneNumber,
  setActivePhoneNumber,
  filterValues,
  router,
}) => {
  const dispatch = useDispatch<any>();
  const { subGroupId, subgroupnames } = useParams();
  const [hoveredId, setHoveredId] = useState<string>("");
  const [lineCoords, setLineCoords] = useState<{
    x1: number;
    y1: number;
    x2: number;
    y2: number;
    xMid: number;
    curveRadius: number;
    isLevelConnector: boolean;
  } | null>(null);
  const [numberLineCoords, setNumberLineCoords] = useState<{
    x1: number;
    y1: number;
    x2: number;
    y2: number;
    xMid: number;
    curveRadius: number;
    isLevelConnector: boolean;
  } | null>(null);

  const containerRef = useRef<HTMLDivElement>(null);

  const selectAccount = createSelector(
    (state: any) => state.Groups,
    (groups) => ({
      groupDetails: groups.AllGroups,
      subgroupDetailsCount: groups.subgroupDetailsCount,
      subgroupDetails: groups.subgroupDetails,
      error: groups.error,
    })
  );

  const { groupDetails, subgroupDetailsCount, subgroupDetails } =
    useSelector(selectAccount);

  // Add this ref to track if this is the first render
  const isFirstRender = useRef(true);

  // Add this ref to track previous filter values
  const prevFiltersRef = useRef({
    searchTerm: "",
    filterValues: {} as typeof filterValues,
  });

  // Combined function to process data and get counts in a single pass
  const processedData = useMemo((): ProcessedData => {
    // Use the existing filterData function to maintain exact filtering logic
    const filteredBrands = filterData(
      subgroupDetails?.data?.brands || [],
      searchTerm,
      filterValues
    ) as Brand[];

    // Calculate counts based on filtered data
    const brandCount = filteredBrands.length;

    const campaigns = filteredBrands
      .filter((dt: Brand) => (activeBrand ? dt.brandId === activeBrand : true))
      .flatMap((dt: Brand) => dt.campaigns || []);

    const relevantCampaigns = campaigns.filter((dt: Campaign) =>
      activeCampaign ? dt.campaignId === activeCampaign : true
    );

    const ownNumbers = relevantCampaigns.flatMap(
      (dt: Campaign) => dt.ownNumbers || []
    );

    const portingRequests = relevantCampaigns.flatMap(
      (dt: Campaign) => dt.portingRequestIds || []
    );

    const releasedNumbers = relevantCampaigns.flatMap(
      (dt: Campaign) => dt.releasedNumbers || []
    );

    return {
      brandCount,
      campaignCount: campaigns.length,
      ownNumbersCount: ownNumbers.length,
      portingRequestCount: portingRequests.length,
      releasedNumbersCount: releasedNumbers.length,
      filteredBrands,
    };
  }, [
    subgroupDetails?.data?.brands,
    searchTerm,
    filterValues,
    activeBrand,
    activeCampaign,
  ]);

  const updateLineCoords = () => {
    if (activeBrand && activeCampaign) {
      const brandElement = document.querySelector(
        `[data-brand-id="${activeBrand}"] .badge`
      );
      const campaignElement = document.querySelector(
        `[data-campaign-id="${activeCampaign}"] .badge`
      );
      const container = containerRef.current;

      if (brandElement && campaignElement && container) {
        const containerRect = container.getBoundingClientRect();
        const brandRect = brandElement.getBoundingClientRect();
        const campaignRect = campaignElement.getBoundingClientRect();

        const width = campaignRect.left - brandRect.right;
        const curveRadius = Math.min(Math.max(width * 0.2, 20), 5);
        const xMid = brandRect.right - containerRect.left + width / 2;

        // Calculate vertical difference
        const verticalDiff = Math.abs(
          campaignRect.top +
            campaignRect.height / 2 -
            (brandRect.top + brandRect.height / 2)
        );

        // Add isLevelConnector flag to indicate if points are roughly at same level
        const isLevelConnector = verticalDiff < 10; // 10px threshold

        setLineCoords({
          x1: brandRect.right - containerRect.left,
          y1: brandRect.top - containerRect.top + brandRect.height / 2,
          x2: campaignRect.left - containerRect.left,
          y2: campaignRect.top - containerRect.top + campaignRect.height / 2,
          xMid,
          curveRadius,
          isLevelConnector,
        });
      } else {
        setLineCoords(null);
      }
    } else {
      setLineCoords(null);
    }

    if (activeCampaign && activePhoneNumber) {
      const campaignElement = document.querySelector(
        `[data-campaign-id="${activeCampaign}"] .badge`
      );
      const numberElement = document.querySelector(
        `[data-phone-number="${activePhoneNumber}"] .badge`
      );
      const container = containerRef.current;

      if (campaignElement && numberElement && container) {
        const containerRect = container.getBoundingClientRect();
        const campaignRect = campaignElement.getBoundingClientRect();
        const numberRect = numberElement.getBoundingClientRect();

        const width = numberRect.left - campaignRect.right;
        const curveRadius = Math.min(Math.max(width * 0.2, 20), 5);
        const xMid = campaignRect.right - containerRect.left + width / 2;

        const verticalDiff = Math.abs(
          numberRect.top +
            numberRect.height / 2 -
            (campaignRect.top + campaignRect.height / 2)
        );

        const isLevelConnector = verticalDiff < 10;

        setNumberLineCoords({
          x1: campaignRect.right - containerRect.left,
          y1: campaignRect.top - containerRect.top + campaignRect.height / 2,
          x2: numberRect.left - containerRect.left,
          y2: numberRect.top - containerRect.top + numberRect.height / 2,
          xMid,
          curveRadius,
          isLevelConnector,
        });
      } else {
        setNumberLineCoords(null);
      }
    } else {
      setNumberLineCoords(null);
    }
  };

  useEffect(() => {
    updateLineCoords();
    window.addEventListener("resize", updateLineCoords);

    const timeoutId = setTimeout(updateLineCoords, 100);

    return () => {
      window.removeEventListener("resize", updateLineCoords);
      clearTimeout(timeoutId);
    };
  }, [activeBrand, activeCampaign, activePhoneNumber, subgroupDetails]);

  // Add useEffect to watch for filter changes
  useEffect(() => {
    // Skip the first mount
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }

    // Check if brandId or campaignId filters have changed
    if (
      filterValues.brandId?.length > 0 ||
      filterValues.campaignId?.length > 0
    ) {
      setActiveBrand("");
      setActiveCampaign("");
    }
  }, [
    filterValues.brandId,
    filterValues.campaignId,
    setActiveBrand,
    setActiveCampaign,
  ]);

  // Add this effect to handle phone number selection
  useEffect(() => {
    if (activePhoneNumber) {
      // Find the associated campaign and brand
      const brandWithNumber = processedData.filteredBrands.find((brand) =>
        brand.campaigns?.some(
          (campaign) =>
            campaign.ownNumbers?.some(
              (number) => number.phoneNumber === activePhoneNumber
            ) ||
            campaign.releasedNumbers?.some(
              (number) => number.phoneNumber === activePhoneNumber
            )
        )
      );

      if (brandWithNumber) {
        setActiveBrand(brandWithNumber.brandId);

        const campaignWithNumber = brandWithNumber.campaigns?.find(
          (campaign) =>
            campaign.ownNumbers?.some(
              (number) => number.phoneNumber === activePhoneNumber
            ) ||
            campaign.releasedNumbers?.some(
              (number) => number.phoneNumber === activePhoneNumber
            )
        );

        if (campaignWithNumber) {
          setActiveCampaign(campaignWithNumber.campaignId);
        }
      }
    }
  }, [activePhoneNumber]);

  const handleNumberClick = (
    phoneNumber: string,
    brandId: string,
    campaignId: string
  ) => {
    if (phoneNumber === activePhoneNumber) {
      setActivePhoneNumber("");
      setActiveCampaign("");
      setActiveBrand("");
    } else {
      setActivePhoneNumber(phoneNumber);
      setActiveCampaign(campaignId);
      setActiveBrand(brandId);
    }
  };

  useEffect(() => {
    return () => {
      setLineCoords(null);
      setNumberLineCoords(null);
    };
  }, []);

  useEffect(() => {
    console.log("Selection changed:", {
      activeBrand,
      activeCampaign,
      activePhoneNumber,
    });
  }, [activeBrand, activeCampaign, activePhoneNumber]);

  // Update navigation calls to use buildUrl
  const getBaseParams = () => {
    const params: Record<string, any> = {};

    const groupId = groupDetails?.records?.[0]?.group_id;
    if (isValidValue(groupId)) params.groupId = groupId;

    if (isValidValue(subGroupId)) params.subGroupId = subGroupId;
    if (isValidValue(subgroupnames)) params.subgroupnames = subgroupnames;

    return params;
  };

  const handleCampaignsNavigation = (dcaComplete?: string) => {
    const params = {
      ...getBaseParams(),
      ...(isValidValue(activeBrand) && { brandId: activeBrand }),
      ...(isValidValue(activeCampaign) && { campaignId: activeCampaign }),
      ...(isValidValue(dcaComplete) && { dcaComplete }),
    };

    router.navigate(buildUrl("/campaigns", params));
  };

  const handleNumbersNavigation = () => {
    const params = {
      ...getBaseParams(),
      ...(isValidValue(activeBrand) && { brandId: activeBrand }),
      ...(isValidValue(activeCampaign) && { campaignId: activeCampaign }),
    };

    router.navigate(buildUrl("/mynumbers", params));
  };

  const handlePortingNavigation = (portingId?: string) => {
    const params = {
      ...getBaseParams(),
      ...(isValidValue(activeBrand) && { brandId: activeBrand }),
      ...(isValidValue(activeCampaign) && { campaignId: activeCampaign }),
      ...(isValidValue(portingId) && { portingId }),
    };

    router.navigate(buildUrl("/port-numbers", params));
  };

  // Update the PillWithDropdown items to use filtered data
  const campaignItems = useMemo(() => {
    // Get all campaigns from filtered brands
    const campaigns = processedData.filteredBrands
      ?.filter((dt: any) =>
        activeBrand ? dt?.brandId === activeBrand : dt?.brandId !== ""
      )
      .flatMap((dt1: any) => dt1?.campaigns || [])
      // Apply campaign filter if active
      .filter((campaign: any) =>
        activeCampaign ? campaign.campaignId === activeCampaign : true
      );

    return [
      {
        name: "Signal House Rejected",
        count: campaigns.filter(
          (campaign) => campaign.dcaStatus?.toLowerCase() === "rejectedreview"
        ).length,
        onClick: () => handleCampaignsNavigation("rejectedReview"),
      },
      {
        name: "Pending DCA Review",
        count: campaigns.filter(
          (campaign) => campaign.dcaStatus?.toLowerCase() === "pending"
        ).length,
        onClick: () => handleCampaignsNavigation("Pending"),
      },
      {
        name: "DCA Rejected",
        count: campaigns.filter(
          (campaign) => campaign.dcaStatus?.toLowerCase() === "rejected"
        ).length,
        onClick: () => handleCampaignsNavigation("Rejected"),
      },
      {
        name: "DCA Approved",
        count: campaigns.filter(
          (campaign) => campaign.dcaStatus?.toLowerCase() === "approved"
        ).length,
        onClick: () => handleCampaignsNavigation("Approved"),
      },
    ];
  }, [
    processedData.filteredBrands,
    activeBrand,
    activeCampaign,
    handleCampaignsNavigation,
  ]);

  // Update campaign click handler
  const handleCampaignClick = (campaignId: string, campaignType: string) => {
    if (!isValidValue(campaignId)) return;

    const baseUrl =
      campaignType === "own"
        ? `/campaigns/${campaignId}`
        : `/campaigns-partner/${campaignId}`;

    router.navigate(buildUrl(baseUrl, getBaseParams()));
  };

  // Update phone number click handler
  const handlePhoneNumberClick = (
    phoneNumber: string,
    brandId: string,
    campaignId: string
  ) => {
    if (!isValidValue(phoneNumber)) return;

    const params = {
      ...getBaseParams(),
      ...(isValidValue(brandId) && { brandId }),
      ...(isValidValue(campaignId) && { campaignId }),
    };

    router.navigate(buildUrl(`/mynumbers/${phoneNumber}`, params));
  };

  // Update porting status click handler
  const handlePortingClick = (portingId: string) => {
    if (!isValidValue(portingId)) return;

    router.navigate(buildUrl(`/porting-status/${portingId}`, getBaseParams()));
  };

  // Update released number click handler
  const handleReleasedNumberClick = (
    phoneNumber: string,
    brandId: string,
    campaignId: string
  ) => {
    if (!isValidValue(phoneNumber)) return;

    const params = {
      ...getBaseParams(),
      ...(isValidValue(brandId) && { brandId }),
      ...(isValidValue(campaignId) && { campaignId }),
    };

    router.navigate(buildUrl(`/mynumbers/${phoneNumber}`, params));
  };

  return (
    <div className="position-relative">
      <div className="subgrpDetail" ref={containerRef}>
        {(lineCoords || numberLineCoords) && (
          <svg
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              pointerEvents: "none",
              zIndex: 1,
            }}
          >
            <defs>
              <marker
                id="arrowhead"
                markerWidth="4"
                markerHeight="3"
                refX="2"
                refY="1.5"
                orient="auto"
              >
                <polygon points="0 0, 4 1.5, 0 3" fill="#e41cfd" />
              </marker>
            </defs>
            {/* Brand to Campaign line */}
            {lineCoords && (
              <path
                d={
                  lineCoords.isLevelConnector
                    ? `
                    M ${lineCoords.x1},${lineCoords.y1}
                    Q ${lineCoords.xMid},${lineCoords.y1} ${
                        lineCoords.x2 - 2
                      },${lineCoords.y2}
                  `
                    : lineCoords.y1 < lineCoords.y2
                    ? `
                      M ${lineCoords.x1},${lineCoords.y1}
                      L ${lineCoords.xMid - lineCoords.curveRadius},${
                        lineCoords.y1
                      }
                      Q ${lineCoords.xMid},${lineCoords.y1} ${
                        lineCoords.xMid
                      },${lineCoords.y1 + lineCoords.curveRadius}
                      L ${lineCoords.xMid},${
                        lineCoords.y2 - lineCoords.curveRadius
                      }
                      Q ${lineCoords.xMid},${lineCoords.y2} ${
                        lineCoords.xMid + lineCoords.curveRadius
                      },${lineCoords.y2}
                      L ${lineCoords.x2 - 2},${lineCoords.y2}
                    `
                    : `
                      M ${lineCoords.x1},${lineCoords.y1}
                      L ${lineCoords.xMid - lineCoords.curveRadius},${
                        lineCoords.y1
                      }
                      Q ${lineCoords.xMid},${lineCoords.y1} ${
                        lineCoords.xMid
                      },${lineCoords.y1 - lineCoords.curveRadius}
                      L ${lineCoords.xMid},${
                        lineCoords.y2 + lineCoords.curveRadius
                      }
                      Q ${lineCoords.xMid},${lineCoords.y2} ${
                        lineCoords.xMid + lineCoords.curveRadius
                      },${lineCoords.y2}
                      L ${lineCoords.x2 - 2},${lineCoords.y2}
                    `
                }
                style={{
                  fill: "none",
                  stroke: "#e41cfd",
                  strokeWidth: 2,
                  markerEnd: "url(#arrowhead)",
                }}
              />
            )}
            {/* Campaign to Number line */}
            {numberLineCoords && (
              <path
                d={
                  numberLineCoords.isLevelConnector
                    ? `
                    M ${numberLineCoords.x1},${numberLineCoords.y1}
                    Q ${numberLineCoords.xMid},${numberLineCoords.y1} ${
                        numberLineCoords.x2 - 2
                      },${numberLineCoords.y2}
                  `
                    : numberLineCoords.y1 < numberLineCoords.y2
                    ? `
                    M ${numberLineCoords.x1},${numberLineCoords.y1}
                    L ${numberLineCoords.xMid - numberLineCoords.curveRadius},${
                        numberLineCoords.y1
                      }
                    Q ${numberLineCoords.xMid},${numberLineCoords.y1} ${
                        numberLineCoords.xMid
                      },${numberLineCoords.y1 + numberLineCoords.curveRadius}
                    L ${numberLineCoords.xMid},${
                        numberLineCoords.y2 - numberLineCoords.curveRadius
                      }
                    Q ${numberLineCoords.xMid},${numberLineCoords.y2} ${
                        numberLineCoords.xMid + numberLineCoords.curveRadius
                      },${numberLineCoords.y2}
                    L ${numberLineCoords.x2 - 2},${numberLineCoords.y2}
                  `
                    : `
                    M ${numberLineCoords.x1},${numberLineCoords.y1}
                    L ${numberLineCoords.xMid - numberLineCoords.curveRadius},${
                        numberLineCoords.y1
                      }
                    Q ${numberLineCoords.xMid},${numberLineCoords.y1} ${
                        numberLineCoords.xMid
                      },${numberLineCoords.y1 - numberLineCoords.curveRadius}
                    L ${numberLineCoords.xMid},${
                        numberLineCoords.y2 + numberLineCoords.curveRadius
                      }
                    Q ${numberLineCoords.xMid},${numberLineCoords.y2} ${
                        numberLineCoords.xMid + numberLineCoords.curveRadius
                      },${numberLineCoords.y2}
                    L ${numberLineCoords.x2 - 2},${numberLineCoords.y2}
                  `
                }
                style={{
                  fill: "none",
                  stroke: "#e41cfd",
                  strokeWidth: 2,
                  markerEnd: "url(#arrowhead)",
                }}
              />
            )}
          </svg>
        )}

        <Row className="head" style={{ position: "relative", zIndex: 2 }}>
          <Col className="custom-col pe-4">
            <Badge
              bg="primary"
              pill
              className="me-2 cursor-pointer"
              onClick={() =>
                router.navigate(
                  `/brands?groupId=${groupDetails?.records?.[0]?.group_id}&subGroupId=${subGroupId}&subgroupnames=${subgroupnames}&brandId=${activeBrand}`
                )
              }
            >
              {processedData.brandCount}
            </Badge>
            <span className="fs-7">Brands</span>
            <Link
              to={`/brands/create?groupId=${groupDetails?.records?.[0]?.group_id}&subGroupId=${subGroupId}&subgroupnames=${subgroupnames}&brandId=${activeBrand}&campaignId=${activeCampaign}`}
            >
              <Button variant="secondary" className="d-flex ms-2 p-1">
                <i className="bx bx-plus" />
              </Button>
            </Link>
          </Col>
          <Col className="custom-col px-4" style={{ flex: "2" }}>
            <PillWithDropdown
              totalCount={processedData.campaignCount}
              items={campaignItems}
              handleBadegeClick={() => handleCampaignsNavigation()}
            />
            <Link
              to={`/campaigns/create?groupId=${groupDetails?.records?.[0]?.group_id}&subGroupId=${subGroupId}&subgroupnames=${subgroupnames}&brandId=${activeBrand}&campaignId=${activeCampaign}`}
            >
              <Button variant="secondary" className="d-flex ms-2 p-1">
                <i className="bx bx-plus" />
              </Button>
            </Link>
          </Col>
          <Col className="custom-col px-5">
            <Badge
              bg="primary"
              pill
              className="me-2 cursor-pointer"
              onClick={() => handleNumbersNavigation()}
            >
              {processedData.ownNumbersCount}
            </Badge>
            <span className="fs-7">Numbers</span>
            <Button
              variant="secondary"
              className="d-flex ms-2 p-1"
              onClick={() => {
                dispatch(
                  openModal({
                    modalbody:
                      "Add existing number to Sub Group or Purchase new number?",
                    data: {
                      title: "Add Number",
                      footer: true,
                      cancelBtn: true,
                      buttonText: {
                        cancel: "Existing",
                        submit: "Purchase",
                      },
                      onCancel: ({ onClose, isButtonClicked }: any) => {
                        onClose();
                        isButtonClicked &&
                          router.navigate(
                            `/mynumbers?groupId=${groupDetails?.records?.[0]?.group_id}&subGroupId=${subGroupId}&subgroupnames=${subgroupnames}&brandId=${activeBrand}&campaignId=${activeCampaign}`
                          );
                      },
                      onSubmit: ({ onClose }: any) => {
                        onClose();
                        router.navigate(
                          `/buynumbers?groupId=${groupDetails?.records?.[0]?.group_id}&subGroupId=${subGroupId}&subgroupnames=${subgroupnames}&brandId=${activeBrand}&campaignId=${activeCampaign}`
                        );
                      },
                    },
                  })
                );
              }}
            >
              <i className="bx bx-plus" />
            </Button>
          </Col>
          <Col className="custom-col px-4">
            <Badge
              bg="primary"
              pill
              className="me-2 cursor-pointer"
              onClick={() => handlePortingNavigation()}
            >
              {processedData.portingRequestCount}
            </Badge>
            <span className="fs-7">Port Requests</span>
            <Link
              to={`/port-numbers/new-porting-requests?groupId=${groupDetails?.records?.[0]?.group_id}&subGroupId=${subGroupId}&subgroupnames=${subgroupnames}&brandId=${activeBrand}&campaignId=${activeCampaign}`}
            >
              <Button variant="secondary" className="d-flex ms-2 p-1">
                <i className="bx bx-plus" />
              </Button>
            </Link>
          </Col>
          <Col className="custom-col ps-4">
            <Badge
              bg="primary"
              pill
              className="me-2 cursor-pointer"
              onClick={() =>
                router.navigate(
                  buildUrl("/release-number", {
                    ...getBaseParams(),
                    ...(isValidValue(activeBrand) && { brandId: activeBrand }),
                    ...(isValidValue(activeCampaign) && {
                      campaignId: activeCampaign,
                    }),
                  })
                )
              }
            >
              {processedData.releasedNumbersCount}
            </Badge>
            <span className="fs-7">Released Numbers</span>
          </Col>
        </Row>
        <Row className="p-4 pb-0" style={{ position: "relative", zIndex: 1 }}>
          <Col className="custom-col pe-4">
            <div
              className="d-flex flex-column mx-auto"
              style={{ width: "fit-content" }}
            >
              {processedData.filteredBrands.length > 0 ? (
                processedData.filteredBrands.map((dt1: Brand, i1: number) => (
                  <p
                    key={i1}
                    className="cursor-pointer mb-1"
                    onClick={() => {
                      dt1?.brandId === activeBrand
                        ? setActiveBrand("")
                        : setActiveBrand(dt1?.brandId);
                      setActiveCampaign("");
                      setActivePhoneNumber("");
                    }}
                    onMouseEnter={() => setHoveredId(dt1?.brandId)}
                    onMouseLeave={() => setHoveredId("")}
                    data-brand-id={dt1?.brandId}
                  >
                    <span
                      className={`badge fs-xs ${
                        activeBrand === dt1?.brandId ||
                        hoveredId === dt1?.brandId
                          ? "text-secondary"
                          : "text-black"
                      }`}
                      style={{
                        background:
                          activeBrand === dt1?.brandId ||
                          hoveredId === dt1?.brandId
                            ? "#F7F7F7"
                            : "transparent",
                        textWrap: "wrap",
                        lineHeight: "16px",
                        textAlign: "center",
                        padding: "4px 8px",
                        borderRadius: "4px",
                        transition: "all 0.2s ease",
                        border: "1px solid",
                        borderColor:
                          activeBrand === dt1?.brandId ||
                          hoveredId === dt1?.brandId
                            ? "#e6e6e6"
                            : "transparent",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          gap: "4px",
                          flex: 1,
                        }}
                      >
                        <div
                          style={{
                            wordWrap: "break-word",
                            flex: "1",
                            fontSize: "12px",
                          }}
                        >
                          {highlightSearchTerm(dt1?.brandName, searchTerm)}
                        </div>
                        <div
                          style={{
                            color: "#999",
                            fontSize: "11px",
                          }}
                        >
                          -
                        </div>
                        <div
                          style={{
                            whiteSpace: "nowrap",
                            color: "#666",
                            fontSize: "11px",
                          }}
                        >
                          {highlightSearchTerm(dt1?.brandId, searchTerm)}
                        </div>
                      </div>

                      <div
                        className="ms-2"
                        style={{
                          opacity: hoveredId === dt1?.brandId ? 1 : 0,
                          transition: "all 0.2s ease",
                          transform:
                            hoveredId === dt1?.brandId
                              ? "translateX(0)"
                              : "translateX(-5px)",
                          display: "flex",
                          alignItems: "center",
                          padding: "2px",
                        }}
                      >
                        <button
                          className="btn btn-link btn-sm p-0 d-flex align-items-center justify-content-center"
                          style={{
                            width: "20px",
                            height: "20px",
                            minWidth: "20px",
                            color: "#e31cfd",
                            transition: "all 0.2s ease",
                            border:
                              hoveredId === dt1?.brandId
                                ? "1px solid #e6e6e6"
                                : "1px solid transparent",
                            borderRadius: "4px",
                            background:
                              hoveredId === dt1?.brandId
                                ? "#fff"
                                : "transparent",
                          }}
                          onClick={(e) => {
                            e.stopPropagation();
                            router.navigate(
                              `/brands/${dt1?.brandId}?groupId=${groupDetails?.records?.[0]?.group_id}&subGroupId=${subGroupId}&subgroupnames=${subgroupnames}`
                            );
                          }}
                          onMouseEnter={(e) => {
                            e.currentTarget.style.background = "#e31cfd";
                            e.currentTarget.style.color = "#fff";
                            e.currentTarget.style.borderColor = "#e31cfd";
                          }}
                          onMouseLeave={(e) => {
                            e.currentTarget.style.background = "#fff";
                            e.currentTarget.style.color = "#e31cfd";
                            e.currentTarget.style.borderColor = "#e6e6e6";
                          }}
                        >
                          <i
                            className="ri-eye-line"
                            style={{ fontSize: "12px" }}
                          />
                        </button>
                      </div>
                    </span>
                  </p>
                ))
              ) : (
                <p className="text-muted fs-sm">No brands found</p>
              )}
            </div>
          </Col>
          <Col className="custom-col px-4">
            <div
              className="d-flex flex-column mx-auto"
              style={{ width: "fit-content" }}
            >
              {(() => {
                const campaigns = processedData.filteredBrands
                  ?.filter((dt: any) =>
                    activeBrand
                      ? dt?.brandId === activeBrand
                      : dt?.brandId !== ""
                  )
                  .flatMap((dt1: any) => dt1?.campaigns || []);

                return campaigns.length > 0 ? (
                  processedData.filteredBrands
                    ?.filter((dt: any) =>
                      activeBrand
                        ? dt?.brandId === activeBrand
                        : dt?.brandId !== ""
                    )
                    .map((dt1: any) =>
                      dt1?.campaigns?.length > 0
                        ? dt1?.campaigns.map((dt2: any, i2: number) => (
                            <p
                              key={i2}
                              className="cursor-pointer mb-1"
                              onClick={() => {
                                if (dt2?.campaignId === activeCampaign) {
                                  setActiveCampaign("");
                                  setActiveBrand("");
                                  setActivePhoneNumber("");
                                } else {
                                  setActiveCampaign(dt2?.campaignId);
                                  setActiveBrand(dt1?.brandId);
                                  setActivePhoneNumber("");
                                }
                              }}
                              onMouseEnter={() => setHoveredId(dt2?.campaignId)}
                              onMouseLeave={() => setHoveredId("")}
                              data-campaign-id={dt2?.campaignId}
                            >
                              <span
                                className={`badge fs-xs ${
                                  activeCampaign === dt2?.campaignId ||
                                  hoveredId === dt2?.campaignId
                                    ? "text-secondary"
                                    : "text-black"
                                }`}
                                style={{
                                  background:
                                    activeCampaign === dt2?.campaignId ||
                                    hoveredId === dt2?.campaignId
                                      ? "#F7F7F7"
                                      : "transparent",
                                  textWrap: "wrap",
                                  lineHeight: "16px",
                                  textAlign: "center",
                                  padding: "4px 8px",
                                  borderRadius: "4px",
                                  transition: "all 0.2s ease",
                                  border: "1px solid",
                                  borderColor:
                                    activeCampaign === dt2?.campaignId ||
                                    hoveredId === dt2?.campaignId
                                      ? "#e6e6e6"
                                      : "transparent",
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                }}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "center",
                                    gap: "4px",
                                    flex: 1,
                                  }}
                                >
                                  <div
                                    style={{
                                      wordWrap: "break-word",
                                      flex: "1",
                                      fontSize: "12px",
                                    }}
                                  >
                                    {highlightSearchTerm(
                                      dt2?.campaignId,
                                      searchTerm
                                    )}
                                  </div>
                                  <div
                                    style={{
                                      color: "#999",
                                      fontSize: "11px",
                                    }}
                                  >
                                    -
                                  </div>
                                  <div
                                    style={{
                                      whiteSpace: "nowrap",
                                      fontSize: "11px",
                                    }}
                                    className={
                                      dt2?.dcaStatus?.toLowerCase() ===
                                      "pending"
                                        ? "text-muted"
                                        : dt2?.dcaStatus?.toLowerCase() ===
                                          "rejected"
                                        ? "text-primary"
                                        : dt2?.dcaStatus?.toLowerCase() ===
                                          "approved"
                                        ? "text-secondary"
                                        : "text-black"
                                    }
                                  >
                                    {capitalizeString(dt2?.dcaStatus)}
                                  </div>
                                </div>

                                <div
                                  className="ms-2"
                                  style={{
                                    opacity:
                                      hoveredId === dt2?.campaignId ? 1 : 0,
                                    transition: "all 0.2s ease",
                                    transform:
                                      hoveredId === dt2?.campaignId
                                        ? "translateX(0)"
                                        : "translateX(-5px)",
                                    display: "flex",
                                    alignItems: "center",
                                    padding: "2px",
                                  }}
                                >
                                  <button
                                    className="btn btn-link btn-sm p-0 d-flex align-items-center justify-content-center"
                                    style={{
                                      width: "20px",
                                      height: "20px",
                                      minWidth: "20px",
                                      color: "#e31cfd",
                                      transition: "all 0.2s ease",
                                      border:
                                        hoveredId === dt2?.campaignId
                                          ? "1px solid #e6e6e6"
                                          : "1px solid transparent",
                                      borderRadius: "4px",
                                      background:
                                        hoveredId === dt2?.campaignId
                                          ? "#fff"
                                          : "transparent",
                                    }}
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      handleCampaignClick(
                                        dt2?.campaignId,
                                        dt2?.campaignType
                                      );
                                    }}
                                    onMouseEnter={(e) => {
                                      e.currentTarget.style.background =
                                        "#e31cfd";
                                      e.currentTarget.style.color = "#fff";
                                      e.currentTarget.style.borderColor =
                                        "#e31cfd";
                                    }}
                                    onMouseLeave={(e) => {
                                      e.currentTarget.style.background = "#fff";
                                      e.currentTarget.style.color = "#e31cfd";
                                      e.currentTarget.style.borderColor =
                                        "#e6e6e6";
                                    }}
                                  >
                                    <i
                                      className="ri-eye-line"
                                      style={{ fontSize: "12px" }}
                                    />
                                  </button>
                                </div>
                              </span>
                            </p>
                          ))
                        : null
                    )
                ) : (
                  <p className="text-muted fs-sm">No campaigns found</p>
                );
              })()}
            </div>
          </Col>
          <Col className="custom-col px-5">
            <div
              className="d-flex flex-column mx-auto"
              style={{ width: "fit-content" }}
            >
              {(() => {
                const ownNumbers = processedData.filteredBrands
                  ?.filter((dt: any) =>
                    activeBrand
                      ? dt?.brandId === activeBrand
                      : dt?.brandId !== ""
                  )
                  .flatMap((dt1: any) => dt1?.campaigns || [])
                  .flatMap((dt1: any) => dt1?.ownNumbers || []);

                return ownNumbers.length > 0 ? (
                  processedData.filteredBrands
                    ?.filter((dt: any) =>
                      activeBrand
                        ? dt?.brandId === activeBrand
                        : dt?.brandId !== ""
                    )
                    .map((dt1: any) =>
                      dt1?.campaigns?.length > 0
                        ? dt1?.campaigns
                            ?.filter((dt: any) =>
                              activeCampaign
                                ? dt?.campaignId === activeCampaign
                                : dt?.campaignId !== ""
                            )
                            .map((dt2: any) =>
                              dt2?.ownNumbers?.length > 0
                                ? dt2?.ownNumbers?.map(
                                    (dt3: any, i3: number) => (
                                      <p
                                        key={i3}
                                        className="cursor-pointer mb-1"
                                        onClick={() => {
                                          if (
                                            dt3?.phoneNumber ===
                                            activePhoneNumber
                                          ) {
                                            setActivePhoneNumber("");
                                            setActiveCampaign("");
                                            setActiveBrand("");
                                          } else {
                                            setActivePhoneNumber(
                                              dt3?.phoneNumber
                                            );
                                            setActiveCampaign(dt2?.campaignId);
                                            setActiveBrand(dt1?.brandId);
                                          }
                                        }}
                                        onMouseEnter={() =>
                                          setHoveredId(dt3?.phoneNumber)
                                        }
                                        onMouseLeave={() => setHoveredId("")}
                                        data-phone-number={dt3?.phoneNumber}
                                      >
                                        <span
                                          className={`badge fs-xs ${
                                            activePhoneNumber ===
                                              dt3?.phoneNumber ||
                                            hoveredId === dt3?.phoneNumber
                                              ? "text-secondary"
                                              : "text-black"
                                          }`}
                                          style={{
                                            background:
                                              activePhoneNumber ===
                                                dt3?.phoneNumber ||
                                              hoveredId === dt3?.phoneNumber
                                                ? "#F7F7F7"
                                                : "transparent",
                                            textWrap: "wrap",
                                            lineHeight: "16px",
                                            textAlign: "left",
                                            padding: "4px 8px",
                                            borderRadius: "4px",
                                            transition: "all 0.2s ease",
                                            border: "1px solid",
                                            borderColor:
                                              activePhoneNumber ===
                                                dt3?.phoneNumber ||
                                              hoveredId === dt3?.phoneNumber
                                                ? "#e6e6e6"
                                                : "transparent",
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "space-between",
                                          }}
                                        >
                                          <div
                                            style={{
                                              display: "flex",
                                              flexDirection: "row",
                                              alignItems: "center",
                                              gap: "4px",
                                              flex: 1,
                                            }}
                                          >
                                            <div
                                              style={{
                                                wordWrap: "break-word",
                                                flex: "1",
                                                fontSize: "12px",
                                                textAlign: "left",
                                              }}
                                            >
                                              {highlightSearchTerm(
                                                dt3?.phoneNumber,
                                                searchTerm
                                              )}
                                            </div>
                                            <div
                                              style={{
                                                color: "#999",
                                                fontSize: "11px",
                                              }}
                                            >
                                              -
                                            </div>
                                            <div
                                              style={{
                                                whiteSpace: "nowrap",
                                                fontSize: "11px",
                                              }}
                                              className={`${
                                                dt3?.tenDLCWhiteListStatus?.toLowerCase() ===
                                                  "mt" ||
                                                dt3?.tenDLCWhiteListStatus?.toLowerCase() ===
                                                  "false" ||
                                                dt3?.tenDLCWhiteListStatus?.toLowerCase() ===
                                                  "in-progress"
                                                  ? "text-primary"
                                                  : dt3?.tenDLCWhiteListStatus?.toLowerCase() ===
                                                      "ready" ||
                                                    dt3?.tenDLCWhiteListStatus?.toLowerCase() ===
                                                      "active"
                                                  ? "text-secondary"
                                                  : dt3?.tenDLCWhiteListStatus?.toLowerCase() ===
                                                    "pending"
                                                  ? "text-black"
                                                  : "text-muted"
                                              }`}
                                            >
                                              {capitalizeString(
                                                dt3?.tenDLCWhiteListStatus
                                              )}
                                            </div>
                                          </div>

                                          <div
                                            className="ms-2"
                                            style={{
                                              opacity:
                                                hoveredId === dt3?.phoneNumber
                                                  ? 1
                                                  : 0,
                                              transition: "all 0.2s ease",
                                              transform:
                                                hoveredId === dt3?.phoneNumber
                                                  ? "translateX(0)"
                                                  : "translateX(-5px)",
                                              display: "flex",
                                              alignItems: "center",
                                              padding: "2px",
                                            }}
                                          >
                                            <button
                                              className="btn btn-link btn-sm p-0 d-flex align-items-center justify-content-center"
                                              style={{
                                                width: "20px",
                                                height: "20px",
                                                minWidth: "20px",
                                                color: "#e31cfd",
                                                transition: "all 0.2s ease",
                                                border:
                                                  hoveredId === dt3?.phoneNumber
                                                    ? "1px solid #e6e6e6"
                                                    : "1px solid transparent",
                                                borderRadius: "4px",
                                                background:
                                                  hoveredId === dt3?.phoneNumber
                                                    ? "#fff"
                                                    : "transparent",
                                              }}
                                              onClick={(e) => {
                                                e.stopPropagation();
                                                handlePhoneNumberClick(
                                                  dt3?.phoneNumber || "",
                                                  dt2?.brandId || "",
                                                  dt2?.campaignId || ""
                                                );
                                              }}
                                              onMouseEnter={(e) => {
                                                e.currentTarget.style.background =
                                                  "#e31cfd";
                                                e.currentTarget.style.color =
                                                  "#fff";
                                                e.currentTarget.style.borderColor =
                                                  "#e31cfd";
                                              }}
                                              onMouseLeave={(e) => {
                                                e.currentTarget.style.background =
                                                  "#fff";
                                                e.currentTarget.style.color =
                                                  "#e31cfd";
                                                e.currentTarget.style.borderColor =
                                                  "#e6e6e6";
                                              }}
                                            >
                                              <i
                                                className="ri-eye-line"
                                                style={{ fontSize: "12px" }}
                                              />
                                            </button>
                                          </div>
                                        </span>
                                      </p>
                                    )
                                  )
                                : null
                            )
                        : null
                    )
                ) : (
                  <p className="text-muted fs-sm">No numbers found</p>
                );
              })()}
            </div>
          </Col>
          <Col className="custom-col px-4">
            <div
              className="d-flex flex-column mx-auto"
              style={{ width: "fit-content" }}
            >
              {(() => {
                const portingRequestIds = processedData.filteredBrands
                  ?.filter((dt: any) =>
                    activeBrand
                      ? dt?.brandId === activeBrand
                      : dt?.brandId !== ""
                  )
                  .flatMap((dt1: any) => dt1?.campaigns || [])
                  .flatMap((dt1: any) => dt1?.portingRequestIds || []);

                return portingRequestIds.length > 0 ? (
                  processedData.filteredBrands
                    ?.filter((dt: any) =>
                      activeBrand
                        ? dt?.brandId === activeBrand
                        : dt?.brandId !== ""
                    )
                    .map((dt1: any) =>
                      dt1?.campaigns?.length > 0
                        ? dt1?.campaigns
                            ?.filter((dt: any) =>
                              activeCampaign
                                ? dt?.campaignId === activeCampaign
                                : dt?.campaignId !== ""
                            )
                            .map((dt2: any) =>
                              dt2?.portingRequestIds?.length > 0
                                ? dt2?.portingRequestIds?.map(
                                    (dt3: any, i3: number) => (
                                      <p
                                        key={i3}
                                        className="cursor-pointer mb-1"
                                        onClick={() =>
                                          handlePortingClick(
                                            dt3?.portingId || ""
                                          )
                                        }
                                        onMouseEnter={() =>
                                          setHoveredId(dt3?.portingId)
                                        }
                                        onMouseLeave={() => setHoveredId("")}
                                      >
                                        <span
                                          className={`badge fs-xs ${
                                            hoveredId === dt3?.portingId
                                              ? "text-secondary"
                                              : "text-black"
                                          }`}
                                          style={{
                                            background:
                                              hoveredId === dt3?.portingId
                                                ? "#F7F7F7"
                                                : "transparent",
                                            textWrap: "wrap",
                                            lineHeight: "16px",
                                            textAlign: "center",
                                            padding: "4px 8px",
                                            borderRadius: "4px",
                                            transition: "all 0.2s ease",
                                            border: "1px solid",
                                            borderColor:
                                              hoveredId === dt3?.portingId
                                                ? "#e6e6e6"
                                                : "transparent",
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                          }}
                                        >
                                          <div
                                            style={{
                                              display: "flex",
                                              flexDirection: "row",
                                              alignItems: "center",
                                              gap: "4px",
                                              flex: 1,
                                            }}
                                          >
                                            <div
                                              style={{
                                                wordWrap: "break-word",
                                                flex: "1",
                                                fontSize: "12px",
                                              }}
                                            >
                                              {highlightSearchTerm(
                                                dt3?.portingId,
                                                searchTerm
                                              )}
                                            </div>
                                            <div
                                              style={{
                                                color: "#999",
                                                fontSize: "11px",
                                              }}
                                            >
                                              -
                                            </div>
                                            <div
                                              style={{
                                                whiteSpace: "nowrap",
                                                fontSize: "11px",
                                              }}
                                              className={`${
                                                dt3?.status?.toLowerCase() ===
                                                "failed"
                                                  ? "text-primary"
                                                  : dt3?.status?.toLowerCase() ===
                                                    "success"
                                                  ? "text-secondary"
                                                  : dt3?.status?.toLowerCase() ===
                                                    "pending"
                                                  ? "text-muted"
                                                  : "text-black"
                                              }`}
                                            >
                                              {capitalizeString(dt3?.status)}
                                            </div>
                                          </div>

                                          <div
                                            className="ms-2"
                                            style={{
                                              opacity:
                                                hoveredId === dt3?.portingId
                                                  ? 1
                                                  : 0,
                                              transition: "all 0.2s ease",
                                              transform:
                                                hoveredId === dt3?.portingId
                                                  ? "translateX(0)"
                                                  : "translateX(-5px)",
                                              display: "flex",
                                              alignItems: "center",
                                              padding: "2px",
                                            }}
                                          >
                                            <button
                                              className="btn btn-link btn-sm p-0 d-flex align-items-center justify-content-center"
                                              style={{
                                                width: "20px",
                                                height: "20px",
                                                minWidth: "20px",
                                                color: "#e31cfd",
                                                transition: "all 0.2s ease",
                                                border:
                                                  hoveredId === dt3?.portingId
                                                    ? "1px solid #e6e6e6"
                                                    : "1px solid transparent",
                                                borderRadius: "4px",
                                                background:
                                                  hoveredId === dt3?.portingId
                                                    ? "#fff"
                                                    : "transparent",
                                              }}
                                              onClick={(e) => {
                                                e.stopPropagation();
                                                handlePortingClick(
                                                  dt3?.portingId || ""
                                                );
                                              }}
                                              onMouseEnter={(e) => {
                                                e.currentTarget.style.background =
                                                  "#e31cfd";
                                                e.currentTarget.style.color =
                                                  "#fff";
                                                e.currentTarget.style.borderColor =
                                                  "#e31cfd";
                                              }}
                                              onMouseLeave={(e) => {
                                                e.currentTarget.style.background =
                                                  "#fff";
                                                e.currentTarget.style.color =
                                                  "#e31cfd";
                                                e.currentTarget.style.borderColor =
                                                  "#e6e6e6";
                                              }}
                                            >
                                              <i
                                                className="ri-eye-line"
                                                style={{ fontSize: "12px" }}
                                              />
                                            </button>
                                          </div>
                                        </span>
                                      </p>
                                    )
                                  )
                                : null
                            )
                        : null
                    )
                ) : (
                  <p className="text-muted fs-sm">No port requests found</p>
                );
              })()}
            </div>
          </Col>
          <Col className="custom-col ps-4">
            <div
              className="d-flex flex-column mx-auto"
              style={{ width: "fit-content" }}
            >
              {(() => {
                const releasedNumbers = processedData.filteredBrands
                  ?.filter((dt: any) =>
                    activeBrand
                      ? dt?.brandId === activeBrand
                      : dt?.brandId !== ""
                  )
                  .flatMap((dt1: any) => dt1?.campaigns || [])
                  .flatMap((dt1: any) => dt1?.releasedNumbers || []);

                return releasedNumbers.length > 0 ? (
                  processedData.filteredBrands
                    ?.filter((dt: any) =>
                      activeBrand
                        ? dt?.brandId === activeBrand
                        : dt?.brandId !== ""
                    )
                    .map((dt1: any) =>
                      dt1?.campaigns?.length > 0
                        ? dt1?.campaigns
                            ?.filter((dt: any) =>
                              activeCampaign
                                ? dt?.campaignId === activeCampaign
                                : dt?.campaignId !== ""
                            )
                            .map((dt2: any) =>
                              dt2?.releasedNumbers?.length > 0
                                ? dt2?.releasedNumbers?.map(
                                    (dt3: any, i3: number) => (
                                      <p
                                        key={i3}
                                        className="cursor-pointer mb-1"
                                        onClick={() => {
                                          handleReleasedNumberClick(
                                            dt3?.phoneNumber || "",
                                            dt2?.brandId || "",
                                            dt2?.campaignId || ""
                                          );
                                        }}
                                        onMouseEnter={() =>
                                          setHoveredId(dt3?.phoneNumber)
                                        }
                                        onMouseLeave={() => setHoveredId("")}
                                        data-phone-number={dt3?.phoneNumber}
                                      >
                                        <span
                                          className={`badge fs-xs ${
                                            activePhoneNumber ===
                                              dt3?.phoneNumber ||
                                            hoveredId === dt3?.phoneNumber
                                              ? "text-secondary"
                                              : "text-black"
                                          }`}
                                          style={{
                                            background:
                                              activePhoneNumber ===
                                                dt3?.phoneNumber ||
                                              hoveredId === dt3?.phoneNumber
                                                ? "#F7F7F7"
                                                : "transparent",
                                            textWrap: "wrap",
                                            lineHeight: "16px",
                                            textAlign: "left",
                                            padding: "4px 8px",
                                            borderRadius: "4px",
                                            transition: "all 0.2s ease",
                                            border: "1px solid",
                                            borderColor:
                                              activePhoneNumber ===
                                                dt3?.phoneNumber ||
                                              hoveredId === dt3?.phoneNumber
                                                ? "#e6e6e6"
                                                : "transparent",
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "space-between",
                                          }}
                                        >
                                          <div
                                            style={{
                                              display: "flex",
                                              flexDirection: "row",
                                              alignItems: "center",
                                              gap: "4px",
                                              flex: 1,
                                            }}
                                          >
                                            <div
                                              style={{
                                                wordWrap: "break-word",
                                                flex: "1",
                                                fontSize: "12px",
                                                textAlign: "left",
                                              }}
                                            >
                                              {highlightSearchTerm(
                                                dt3?.phoneNumber,
                                                searchTerm
                                              )}
                                            </div>
                                          </div>

                                          <div
                                            className="ms-2"
                                            style={{
                                              opacity:
                                                hoveredId === dt3?.phoneNumber
                                                  ? 1
                                                  : 0,
                                              transition: "all 0.2s ease",
                                              transform:
                                                hoveredId === dt3?.phoneNumber
                                                  ? "translateX(0)"
                                                  : "translateX(-5px)",
                                              display: "flex",
                                              alignItems: "center",
                                              padding: "2px",
                                            }}
                                          >
                                            <button
                                              className="btn btn-link btn-sm p-0 d-flex align-items-center justify-content-center"
                                              style={{
                                                width: "20px",
                                                height: "20px",
                                                minWidth: "20px",
                                                color: "#e31cfd",
                                                transition: "all 0.2s ease",
                                                border:
                                                  hoveredId === dt3?.phoneNumber
                                                    ? "1px solid #e6e6e6"
                                                    : "1px solid transparent",
                                                borderRadius: "4px",
                                                background:
                                                  hoveredId === dt3?.phoneNumber
                                                    ? "#fff"
                                                    : "transparent",
                                              }}
                                              onClick={(e) => {
                                                e.stopPropagation();
                                                handleReleasedNumberClick(
                                                  dt3?.phoneNumber || "",
                                                  dt2?.brandId || "",
                                                  dt2?.campaignId || ""
                                                );
                                              }}
                                              onMouseEnter={(e) => {
                                                e.currentTarget.style.background =
                                                  "#e31cfd";
                                                e.currentTarget.style.color =
                                                  "#fff";
                                                e.currentTarget.style.borderColor =
                                                  "#e31cfd";
                                              }}
                                              onMouseLeave={(e) => {
                                                e.currentTarget.style.background =
                                                  "#fff";
                                                e.currentTarget.style.color =
                                                  "#e31cfd";
                                                e.currentTarget.style.borderColor =
                                                  "#e6e6e6";
                                              }}
                                            >
                                              <i
                                                className="ri-eye-line"
                                                style={{ fontSize: "12px" }}
                                              />
                                            </button>
                                          </div>
                                        </span>
                                      </p>
                                    )
                                  )
                                : null
                            )
                        : null
                    )
                ) : (
                  <p className="text-muted fs-sm">No released numbers found</p>
                );
              })()}
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default withRouter(DLC);
