function Amex({ color = "#000000" }) {
  return (
    <svg
      fill={color}
      height="800px"
      width="800px"
      version="1.1"
      id="Capa_1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 380 380"
    >
      <g>
        <polygon points="70.1,197.327 85.436,197.327 77.72,177.774 	" />
        <path
          d="M362.621,75.298H17.379C7.782,75.298,0,83.078,0,92.677v194.646c0,9.599,7.782,17.379,17.379,17.379h345.242
		c9.596,0,17.379-7.78,17.379-17.379V92.677C380,83.078,372.217,75.298,362.621,75.298z M112.851,225.47
		c-0.65,0.958-1.732,1.53-2.893,1.53H99.524c-1.446,0-2.724-0.87-3.256-2.215l-3.771-9.557H63.124l-3.721,9.544
		c-0.527,1.354-1.807,2.229-3.261,2.229H45.731c-1.156,0-2.236-0.57-2.89-1.525c-0.653-0.956-0.791-2.17-0.371-3.247l26.761-68.666
		c0.526-1.354,1.807-2.229,3.261-2.229h10.372c1.446,0,2.724,0.87,3.256,2.215l27.094,68.667
		C113.64,223.294,113.504,224.511,112.851,225.47z M186.309,222.94c0,1.93-1.57,3.5-3.5,3.5h-9c-1.93,0-3.5-1.57-3.5-3.5v-30.084
		l-15.334,30.305c-0.599,1.183-1.793,1.918-3.118,1.92c-1.327,0-2.522-0.732-3.123-1.91l-15.487-30.393v30.163
		c0,1.93-1.57,3.5-3.5,3.5h-9c-1.93,0-3.5-1.57-3.5-3.5v-67.906c0-1.93,1.57-3.5,3.5-3.5h7.253c1.321,0,2.516,0.731,3.117,1.909
		l20.693,40.535l20.506-40.524c0.599-1.184,1.795-1.92,3.123-1.92h7.37c1.93,0,3.5,1.57,3.5,3.5V222.94z M263.978,223.682
		c0,1.93-1.57,3.5-3.5,3.5h-43c-1.93,0-3.5-1.57-3.5-3.5V155.71c0-1.93,1.57-3.5,3.5-3.5h41.806c1.93,0,3.5,1.57,3.5,3.5v8.284
		c0,1.93-1.57,3.5-3.5,3.5h-30.021v14.56h24.453c1.93,0,3.5,1.57,3.5,3.5v8.284c0,1.93-1.57,3.5-3.5,3.5h-24.453v14.56h31.216
		c1.93,0,3.5,1.57,3.5,3.5V223.682z M329.777,221.068l-7.29,5.277c-0.603,0.435-1.311,0.665-2.054,0.665
		c-0.185,0-0.37-0.015-0.556-0.045c-0.907-0.145-1.739-0.656-2.28-1.404l-17.388-24.022l-17.312,24.011
		c-0.536,0.746-1.367,1.26-2.278,1.408c-0.879,0.151-1.852-0.07-2.608-0.617l-7.298-5.262c-0.759-0.547-1.259-1.356-1.408-2.279
		c-0.149-0.923,0.069-1.849,0.616-2.607l20.413-28.315l-18.714-26.059c-1.125-1.568-0.765-3.759,0.802-4.884l7.309-5.249
		c0.6-0.43,1.305-0.658,2.04-0.658c1.125,0,2.188,0.545,2.845,1.459l15.595,21.715l15.667-21.728
		c0.548-0.758,1.355-1.258,2.278-1.407c0.938-0.152,1.859,0.075,2.608,0.617l7.298,5.262c0.759,0.547,1.259,1.356,1.408,2.279
		c0.149,0.923-0.069,1.849-0.616,2.607l-18.78,26.046l20.484,28.301C331.691,217.744,331.34,219.936,329.777,221.068z"
        />
      </g>
    </svg>
  );
}

export default Amex;
