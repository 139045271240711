import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  loading: false,
  loading2: false,
  authUser: null,
  success: false,
  successMsg: "",
  error: false,
  errorMsg: "",
  walletPricingData: {},
};

const authUserSlice = createSlice({
  name: "account-pricing",
  initialState,
  reducers: {
    apiLoading(state) {
      state.loading = true;
      state.success = false;
      state.successMsg = "";
      state.error = false;
      state.errorMsg = "";
    },
    walletPricingDataSuccessful(state, action) {
      state.loading = false;
      state.walletPricingData = action.payload;
      state.successMsg = "";
      state.error = false;
      state.errorMsg = "";
    },
    walletPricingDataFailed(state, action) {
      state.loading = false;
      state.success = false;
      state.successMsg = "";
      state.error = true;
      state.errorMsg = action.payload.message;
    },
  
  },
});

export const {
  apiLoading,
  walletPricingDataSuccessful,
 walletPricingDataFailed,
} = authUserSlice.actions;

export default authUserSlice.reducer;
