import { createSlice } from "@reduxjs/toolkit";

export const initialState: any = {
  dataObj: {},
  data: [],
  currentPage: 1,
  totalPage: 1,
  pageSize: 10,
  totalNumbers: 1,
  loading: false,
  success: false,
  successMsg: null,
  errorMsg: null,
  error: false,
};

const activePhoneNumberSlice = createSlice({
  name: "ownNumbers",
  initialState,
  reducers: {
    apiLoading(state) {
      state.loading = true;
      state.success = false;
      state.successMsg = "";
      state.error = false;
      state.errorMsg = "";
    },
    apiSuccess(state, action) {
      state.loading = false;
      state.success = true;
      state.successMsg = action.payload.message;
      state.error = false;
      state.errorMsg = "";
      state.dataObj = action.payload;
      state.data = action.payload.data;
      state.currentPage = action.payload.currentPage;
      state.totalPage = action.payload.totalPage;
      state.pageSize = action.payload.pageSize;
      state.totalNumbers = action.payload.totalNumbers;
    },
    apiError(state, action) {
      state.loading = false;
      state.dataObj = {};
      state.success = false;
      state.successMsg = "";
      state.error = true;
      state.errorMsg = action.payload.message;
    },
    releasePhoneNumberSuccessful(state, action) {
      state.loading = false;
      state.success = true;
      state.successMsg = "";
      state.error = false;
      state.errorMsg = "";
    },
    releasePhoneNumberFailed(state, action) {
      state.loading = false;
      state.success = false;
      state.successMsg = "";
      state.error = true;
      state.errorMsg = action.payload.message;
    },
    // Own Numbers Event logs
    getEventLogsSuccessful(state, action) {
      state.loading = false;
      state.EventLogs = action.payload;
      state.success = true;
      state.successMsg = "";
      state.error = false;
      state.errorMsg = "";
    },
    getEventLogsFailed(state, action) {
      state.loading = false;
      state.successMsg = "";
      state.success = false;
      state.successMsg = "";
      state.error = true;
      state.errorMsg = action.payload.message;
    },
    resetEventLogsData(state) {
      state.loading = false;
      state.EventLogs = {};
      state.success = true;
      state.successMsg = "";
      state.error = false;
      state.errorMsg = "";
    },

    // Own Numbers Event logs
    getRegulatoryInformationSuccessful(state, action) {
      state.loading = false;
      state.RegulatoryInformation = action.payload;
      state.success = true;
      state.successMsg = "";
      state.error = false;
      state.errorMsg = "";
    },
    getRegulatoryInformationFailed(state, action) {
      state.loading = false;
      state.successMsg = "";
      state.success = false;
      state.successMsg = "";
      state.error = true;
      state.errorMsg = action.payload.message;
    },
  },
});

export const {
  apiLoading,
  apiSuccess,
  apiError,
  releasePhoneNumberSuccessful,
  releasePhoneNumberFailed,
  getEventLogsSuccessful,
  getEventLogsFailed,
  resetEventLogsData,
  getRegulatoryInformationSuccessful,
  getRegulatoryInformationFailed,
} = activePhoneNumberSlice.actions;

export default activePhoneNumberSlice.reducer;
