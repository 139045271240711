import React, { useEffect, useState } from "react";
import { Container, Card } from "react-bootstrap";
import BreadCrumb from "Common/BreadCrumb";
import { useDispatch, useSelector } from "react-redux";
import { createSelector } from "reselect";
import Datatable from "../../Common/Datatable";
import { dateFormat, humanizeString } from "helpers/common";
import Loader from "assets/images/spinner-dark.svg";
import withRouter from "Common/withRouter";
import { getNotificationsListThunk } from "slices/thunk";
import {
  getNotificationRedirectPath,
  type NotificationItem,
} from "helpers/notification_redirection";

interface NotificationParams {
  phoneNumber?: string;
  signalHouseSid?: string;
  phoneNumberToBePorted?: string;
  portingId?: string;
  brandId?: string;
  brandName?: string;
  campaignId?: string;
  campaignType?: string;
  groupId?: string;
  subGroupId?: string;
  subGroupName?: string;
  emailId?: string;
  amount?: string;
}

interface NotificationRow {
  notificationServices: string;
  params?: NotificationParams;
  notificationMessage: string;
  notificationAlert: "success" | "error";
  notificationId: string;
  createdDate: string;
  webNotificationHistory: Array<{
    _id: string;
    status: "read" | "unread";
    date: string;
  }>;
}

interface NotificationsListProps {
  router: {
    navigate: (path: string) => void;
  };
}

interface NotificationsState {
  loading5: boolean;
  notificationsData: {
    records: NotificationRow[];
    totalRecords: number;
  };
  errors: any;
}

const handleRowClick = (row: NotificationRow, props: any) => {
  const { notificationServices, params = {} } = row;
  const redirectPath = getNotificationRedirectPath(
    notificationServices,
    params
  );
  if (redirectPath) {
    props.router.navigate(redirectPath);
  }
};

const NotificationsList = (props: NotificationsListProps) => {
  document.title = "Signal House Portal Notifications";
  const dispatch = useDispatch<any>();

  const [currPage, setCurrPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const selectProfile = createSelector(
    (state: { Notifications: NotificationsState }) => state.Notifications,
    (notifications) => ({
      loading: notifications.loading5,
      notificationsData: notifications.notificationsData,
      errors: notifications.errors,
    })
  );

  const { loading, notificationsData } = useSelector(selectProfile);

  const columns = [
    {
      name: <span className="font-weight-bold fs-sm">Services</span>,
      minWidth: "180px",
      selector: (row: NotificationRow) => (
        <span
          data-tag="allowRowEvents"
          className="badge bg-body-primary border border-primary text-primary"
        >
          {row.notificationServices
            ? humanizeString(row.notificationServices)
            : "-"}
        </span>
      ),
      sortable: true,
      sortFunction: (rowA: NotificationRow, rowB: NotificationRow) => {
        const serviceA = rowA.notificationServices?.toLowerCase() || "";
        const serviceB = rowB.notificationServices?.toLowerCase() || "";
        return serviceA.localeCompare(serviceB);
      },
    },
    {
      name: <span className="font-weight-bold fs-sm">Message</span>,
      minWidth: "250px",
      grow: 2,
      wrap: true,
      style: { textAlign: "left" },
      selector: (row: NotificationRow) => (
        <span data-tag="allowRowEvents" className="text-truncate-two-lines">
          {row.notificationMessage || "-"}
        </span>
      ),
      sortable: true,
      sortFunction: (rowA: NotificationRow, rowB: NotificationRow) => {
        const messageA = rowA.notificationMessage?.toLowerCase() || "";
        const messageB = rowB.notificationMessage?.toLowerCase() || "";
        return messageA.localeCompare(messageB);
      },
    },
    {
      name: <span className="font-weight-bold fs-sm">Created Date</span>,
      minWidth: "220px",
      selector: (row: NotificationRow) => (
        <span data-tag="allowRowEvents" className="d-flex align-items-center">
          <i
            data-tag="allowRowEvents"
            className="bi bi-calendar3 me-2 fs-lg text-secondary"
          />
          {dateFormat(row.createdDate, "MM/DD/YYYY, hh:mm:ss A")}
        </span>
      ),
      sortable: true,
      sortFunction: (rowA: NotificationRow, rowB: NotificationRow) => {
        const dateA = new Date(rowA.createdDate).getTime();
        const dateB = new Date(rowB.createdDate).getTime();
        return dateA - dateB;
      },
    },
  ];

  const conditionalRowStyles = [
    {
      when: (row: NotificationRow) =>
        row.webNotificationHistory[0]?.status.toLowerCase() !== "read",
      style: {
        backgroundColor: "#f4f4f4",
      },
    },
  ];

  useEffect(() => {
    handleGetData(currPage, rowsPerPage);
  }, []);

  const handleGetData = (page: number, perPage: number, otherParams?: any) => {
    dispatch(getNotificationsListThunk(page, perPage, otherParams || {}));
  };

  const handlePageChange = (page: number) => {
    setCurrPage(page);
    handleGetData(page, rowsPerPage);
  };

  const handleRowsPerPageChange = (rows: number) => {
    setCurrPage(1);
    setRowsPerPage(rows);
    handleGetData(1, rows);
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Notifications" />

          <div className="position-relative">
            <Card className="wrapper">
              <Card.Body className="listing-table">
                {loading ? (
                  <div className={``}>
                    <img
                      src={Loader}
                      className={`position-absolute top-50 start-50 translate-middle`}
                    />
                  </div>
                ) : (
                  <Datatable
                    data={
                      Array.isArray(notificationsData?.records)
                        ? notificationsData?.records
                        : []
                    }
                    columns={columns}
                    handleRowClick={(row: NotificationRow) =>
                      handleRowClick(row, props)
                    }
                    handlePageChange={handlePageChange}
                    handleRowsPerPageChange={handleRowsPerPageChange}
                    currPage={currPage}
                    rowsPerPage={rowsPerPage}
                    totalRecords={notificationsData?.totalRecords}
                    conditionalRowStyles={conditionalRowStyles}
                  />
                )}
              </Card.Body>
            </Card>
          </div>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(NotificationsList);
