import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  loading: false,
  loading2: false,
  loading3: false,
  usageSummaryObj: {},
  usageTransactionsObj: {},
  usageSummaryDashboardObj: {},
  success: false,
  successMsg: "",
  error: false,
  errorMsg: "",
};

const usageSummaryDataSlice = createSlice({
  name: "usageSummary",
  initialState,
  reducers: {
    apiLoading(state) {
      state.loading = true;
      state.success = false;
      state.successMsg = "";
      state.error = false;
      state.errorMsg = "";
    },
    apiLoading2(state) {
      state.loading2 = true;
      state.success = false;
      state.successMsg = "";
      state.error = false;
      state.errorMsg = "";
    },
    apiLoading3(state) {
      state.loading3 = true;
      state.success = false;
      state.successMsg = "";
      state.error = false;
      state.errorMsg = "";
    },
    emptyUsageTransactionsObj(state) {
      state.usageTransactionsObj = {};
    },
    usageSummaryDataSuccessful(state, action) {
      state.loading = false;
      state.usageSummaryObj = action.payload;
      state.success = true;
      state.successMsg = "";
      state.error = false;
      state.errorMsg = "";
    },
    usageSummaryDataFailed(state, action) {
      state.loading = false;
      state.usageSummaryObj = {};
      state.success = false;
      state.successMsg = "";
      state.error = true;
      state.errorMsg = action.payload.message;
    },
    usageSummaryDashboardDataSuccessful(state, action) {
      state.loading3 = false;
      state.usageSummaryDashboardObj = action.payload;
      state.success = true;
      state.successMsg = "";
      state.error = false;
      state.errorMsg = "";
    },
    usageSummaryDashboardDataFailed(state, action) {
      state.loading3 = false;
      state.usageSummaryDashboardObj = {};
      state.success = false;
      state.successMsg = "";
      state.error = true;
      state.errorMsg = action.payload.message;
    },
    usageTransactionsDataSuccessful(state, action) {
      state.loading2 = false;
      state.usageTransactionsObj = action.payload;
      state.success = true;
      state.successMsg = "";
      state.error = false;
      state.errorMsg = "";
    },
    usageTransactionsDataFailed(state, action) {
      state.loading2 = false;
      state.usageTransactionsObj = {};
      state.success = false;
      state.successMsg = "";
      state.error = true;
      state.errorMsg = action.payload.message;
    },
  },
});

export const {
  apiLoading,
  apiLoading2,
  apiLoading3,
  emptyUsageTransactionsObj,
  usageSummaryDataSuccessful,
  usageSummaryDataFailed,
  usageSummaryDashboardDataSuccessful,
  usageSummaryDashboardDataFailed,
  usageTransactionsDataSuccessful,
  usageTransactionsDataFailed,
} = usageSummaryDataSlice.actions;

export default usageSummaryDataSlice.reducer;
