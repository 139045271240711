import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { closeBottomsheet } from "../../slices/bottomsheet/reducer";
import { createSelector } from "reselect";
import { Button, Spinner } from "react-bootstrap";
import { Subject } from "rxjs";
import { Sheet } from "react-modal-sheet";
import "assets/scss/themes.scss";

export const onCloseSubject = new Subject();
export const submitLoadingSubject = new Subject();

const Bottomsheet = () => {
  const dispatch = useDispatch();
  const [bottomsheetState, setModel] = useState({
    isLoaded: false,
    module: "",
    bottomsheetbody: "",
  });
  const [isSubmitDisable, setIsSubmitDisable] = useState(false);
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);
  const [bottomsheetData, setBottomsheetData] = useState({});

  const selectData = createSelector(
    (state: any) => state.Bottomsheet,
    (bottomsheet) => ({
      state: bottomsheet.state,
      data: bottomsheet.data,
      path: bottomsheet.path,
      bottomsheetbody: bottomsheet.bottomsheetbody,
    })
  );

  const {
    state,
    data = {},
    path,
    bottomsheetbody: body,
  } = useSelector(selectData);
  const { module: Component, isLoaded } = bottomsheetState;
  const {
    bottomsheetClass = "",
    headerClass = "",
    closeButton = true,
    title,
    bodyClass = "",
    footer,
    footerClass = "",
    buttonText = {},
    cancelBtn = true,
    onCancel,
    onSubmit,
    submitArg,
    className,
  } = data;

  useEffect(() => {
    onCloseSubject.subscribe(() => onClose());
    submitLoadingSubject.subscribe({
      next: (val: any) => setIsSubmitLoading(val),
    });
  }, []);

  useEffect(() => {
    if (path) {
      import(`./${path}`).then((module) =>
        setModel({
          module: module.default,
          bottomsheetbody: "",
          isLoaded: state ? true : false,
        })
      );
    } else {
      setModel({
        module: "",
        bottomsheetbody: body,
        isLoaded: state ? true : false,
      });
    }
  }, [state, data, path, body]);

  const onClose = () => {
    dispatch(closeBottomsheet({}));
    setBottomsheetData({});
  };

  return (
    <div>
      {isLoaded && (
        <Sheet
          isOpen={state}
          onClose={() => (onCancel ? onCancel({ onClose }) : onClose())}
          disableDrag={true}
          detent={"content-height"}
          // detent={modalType === "filter" ? "content-height" : "full-height"}
        >
          <Sheet.Container>
            <Sheet.Header>
              {title && (
                <div className="modal-header justify-content-between p-3 pb-0">
                  <div className="modal-title h4">{title}</div>
                  <button
                    type="button"
                    className="btn-close"
                    aria-label="Close"
                    onClick={() =>
                      onCancel ? onCancel({ onClose }) : onClose()
                    }
                  />
                </div>
              )}
            </Sheet.Header>
            <Sheet.Content>
              {state && (
                <div className="h-100 d-flex flex-column overflow-hidden">
                  <div
                    className={`h-100 overflow-hidden modal-body p-3 pb-5 ${className}`}
                    id="modal-body"
                  >
                    {isLoaded && Component && (
                      <Component
                        {...data}
                        isOpen={state}
                        onClose={onClose}
                        setIsSubmitDisable={setIsSubmitDisable}
                        bottomsheetData={bottomsheetData}
                        setBottomsheetData={setBottomsheetData}
                        isBottomSheet={true}
                      />
                    )}
                    {isLoaded && body && body}
                  </div>
                  {footer && (
                    <div
                      className="p-3 pt-0 position-sticky bottom-0 flex flex-row justify-content-end modal-footer"
                      id="modal-footer"
                    >
                      {cancelBtn && (
                        <Button
                          className="btn btn-primary-outlined mx-1.5 capitalize"
                          onClick={(e) => {
                            onClose();
                            onCancel && onCancel(e, onClose);
                          }}
                        >
                          {buttonText.cancel || "Cancel"}
                        </Button>
                      )}
                      {onSubmit && (
                        <Button
                          autoFocus
                          onClick={(e) => {
                            onSubmit(e, onClose, bottomsheetData, submitArg);
                          }}
                          className="btn btn-primary mx-1.5 capitalize"
                          disabled={isSubmitDisable}
                        >
                          {/* <Spinner size="sm" animation="border" /> */}
                          {buttonText.submit || "Ok"}
                        </Button>
                      )}
                    </div>
                  )}
                </div>
              )}
            </Sheet.Content>
          </Sheet.Container>
          <Sheet.Backdrop onTap={onClose} />
        </Sheet>
      )}
    </div>
  );
};

export default Bottomsheet;
