import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  loading: false,
  loading2: false,
  loading4: false,
  loading3: false,
  success: false,
  successMsg: "",
  error: false,
  errorMsg: "",
  AllGroups: {},
  groupDetails: {},
  subgroupsData: {},
  subgroupDetails: {},
  subgroupDetailsCount: {},
};

const groupsDataSlice = createSlice({
  name: "groups",
  initialState,
  reducers: {
    apiLoading(state) {
      state.loading = true;
      state.success = false;
      state.successMsg = "";
      state.error = false;
      state.errorMsg = "";
    },
    apiLoadingFalse(state) {
      state.loading = false;
      state.success = false;
      state.successMsg = "";
      state.error = false;
      state.errorMsg = "";
    },
    apiLoading4(state) {
      state.loading4 = true;
      state.success = false;
      state.successMsg = "";
      state.error = false;
      state.errorMsg = "";
    },
    apiLoading3(state) {
      state.loading3 = true;
      state.success = false;
      state.successMsg = "";
      state.error = false;
      state.errorMsg = "";
    },
    getAllGroupsSuccessful(state, action) {
      state.loading = false;
      state.AllGroups = action.payload;
      state.success = true;
      state.successMsg = "";
      state.error = false;
      state.errorMsg = "";
    },
    getAllGroupsFailed(state, action) {
      state.loading = false;
      state.successMsg = "";
      state.success = false;
      state.successMsg = "";
      state.error = true;
      state.errorMsg = action.payload.message;
    },
    createGroupSuccessful(state, action) {
      state.loading = false;
      state.success = true;
      state.successMsg = "";
      state.error = false;
      state.errorMsg = "";
    },
    createGroupFailed(state, action) {
      state.loading = false;
      state.success = false;
      state.successMsg = "";
      state.error = true;
      state.errorMsg = action.payload.message;
    },
    updateGroupSuccessful(state, action) {
      state.loading = false;
      state.loading2 = false;
      state.success = true;
      state.successMsg = "";
      state.error = false;
      state.errorMsg = "";
    },
    updateGroupFailed(state, action) {
      state.loading = false;
      state.loading2 = false;
      state.success = false;
      state.successMsg = "";
      state.error = true;
      state.errorMsg = action.payload.message;
    },
    findGroupDetailsSuccess(state, action) {
      state.loading = false;
      state.success = true;
      state.successMsg = action.payload.message;
      state.error = false;
      state.errorMsg = "";
      state.groupDetails = action.payload;
    },
    findGroupDetailsFailed(state, action) {
      state.loading = false;
      state.success = false;
      state.successMsg = "";
      state.error = true;
      state.errorMsg = action.payload.message;
    },
    deleteGroupSuccess(state, action) {
      state.loading = false;
      state.success = true;
      state.successMsg = action.payload.message;
      state.error = false;
      state.errorMsg = "";
    },
    deleteGroupFailed(state, action) {
      state.loading = false;
      state.success = false;
      state.successMsg = "";
      state.error = true;
      state.errorMsg = action.payload.message;
    },
    subgroupsDataSuccessful(state, action) {
      state.loading4 = false;
      state.subgroupsData = action.payload;
      state.success = true;
      state.successMsg = "";
      state.error = false;
      state.errorMsg = "";
    },
    subgroupsDataFailed(state, action) {
      state.loading4 = false;
      state.subgroupsData = {};
      state.success = false;
      state.successMsg = "";
      state.error = true;
      state.errorMsg = action.payload.message;
    },
    subgroupDetailsSuccessful(state, action) {
      state.loading3 = false;
      state.subgroupDetails = action.payload;
      state.success = true;
      state.successMsg = "";
      state.error = false;
      state.errorMsg = "";
    },
    subgroupDetailsFailed(state, action) {
      state.loading3 = false;
      state.subgroupDetails = {};
      state.success = false;
      state.successMsg = "";
      state.error = true;
      state.errorMsg = action.payload.message;
    },
    subgroupDetailsCountSuccessful(state, action) {
      state.loading3 = false;
      state.subgroupDetailsCount = action.payload;
      state.success = true;
      state.successMsg = "";
      state.error = false;
      state.errorMsg = "";
    },
    subgroupDetailsCountFailed(state, action) {
      state.loading3 = false;
      state.subgroupDetailsCount = {};
      state.success = false;
      state.successMsg = "";
      state.error = true;
      state.errorMsg = action.payload.message;
    },
    subgroupCreateSuccessful(state, action) {
      state.loading4 = false;
      state.success = true;
      state.successMsg = "";
      state.error = false;
      state.errorMsg = "";
    },
    subgroupCreateFailed(state, action) {
      state.loading4 = false;
      state.success = false;
      state.successMsg = "";
      state.error = true;
      state.errorMsg = action.payload.message;
    },
    subgroupUpdateSuccessful(state, action) {
      state.loading4 = false;
      state.success = true;
      state.successMsg = "";
      state.error = false;
      state.errorMsg = "";
    },
    subgroupUpdateFailed(state, action) {
      state.loading4 = false;
      state.success = false;
      state.successMsg = "";
      state.error = true;
      state.errorMsg = action.payload.message;
    },
  },
});

export const {
  apiLoading,
  apiLoading4,
  apiLoading3,
  apiLoadingFalse,
  getAllGroupsSuccessful,
  getAllGroupsFailed,
  createGroupSuccessful,
  createGroupFailed,
  updateGroupSuccessful,
  updateGroupFailed,
  findGroupDetailsSuccess,
  findGroupDetailsFailed,
  deleteGroupSuccess,
  deleteGroupFailed,
  subgroupsDataSuccessful,
  subgroupsDataFailed,
  subgroupDetailsSuccessful,
  subgroupDetailsFailed,
  subgroupDetailsCountSuccessful,
  subgroupDetailsCountFailed,
  subgroupCreateSuccessful,
  subgroupCreateFailed,
  subgroupUpdateSuccessful,
  subgroupUpdateFailed,
} = groupsDataSlice.actions;

export default groupsDataSlice.reducer;
