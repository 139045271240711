import { useEffect } from "react";
import withRouter from "Common/withRouter";

const UserProfile = (props: any) => {
  document.title = "Signal House Portal Settings";
  const path = props.router.location.pathname;

  useEffect(() => {
    if (path === "/settings" || path === "/settings/") {
      props.router.navigate("/settings/general");
    }
  }, [path]);

  return null;
};

export default withRouter(UserProfile);
