import React, { useState, useEffect } from "react";
import { Button, Card, Col, Container, Form, Row } from "react-bootstrap";
import { createSelector } from "reselect";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

interface FormData {
  apiKey: string;
  publicKey: string;
}

const ApiKeys = () => {
  document.title = " Signal House Portal API Keys";

  const [formData, setFormData] = useState<FormData>({
    apiKey: "",
    publicKey: "",
  });

  const selectAccountAndLogin = createSelector(
    (state: any) => state.Login,
    (login) => ({
      loginData: login.loginData,
    })
  );

  const { loginData } = useSelector(selectAccountAndLogin);

  useEffect(() => {
    try {
      const obj = loginData && JSON.parse(loginData);
      obj?.apiKey && setFormData((prev) => ({ ...prev, apiKey: obj.apiKey }));
      obj?.authToken &&
        setFormData((prev) => ({ ...prev, publicKey: obj.authToken }));
    } catch (error) {
      console.error("Error parsing loginData", error);
    }
  }, [loginData]);

  const copyText = (e: React.MouseEvent<HTMLButtonElement>, type: string) => {
    e.preventDefault();
    const textToCopy = document.getElementById(type) as HTMLInputElement;
    if (textToCopy) {
      textToCopy.select();
      textToCopy.setSelectionRange(0, 99999); /* For mobile devices */
      navigator.clipboard.writeText(textToCopy.value);
      toast.success("Text copied");
    }
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col lg={6} xl={5} className="mb-2">
              <Card>
                <Card.Header>
                  <h6 className="card-title mb-2">API Keys</h6>
                </Card.Header>
                <Card.Body className="">
                  <div className="mb-3">
                    <Form.Label htmlFor="apiKey">API Key</Form.Label>
                    <div className="position-relative">
                      <Form.Control
                        type="text"
                        id="apiKey"
                        name="apiKey"
                        className="form-control pe-5 password-input"
                        value={formData.apiKey}
                        disabled
                      />
                      <Button
                        variant="link"
                        className="position-absolute end-0 top-0 text-decoration-none text-muted password-addon"
                        type="button"
                        onClick={(e) => copyText(e, "apiKey")}
                      >
                        <i className="bx bxs-copy align-middle"></i>
                      </Button>
                    </div>
                  </div>
                  <div>
                    <Form.Label htmlFor="publicKey">Public Key</Form.Label>
                    <div className="position-relative">
                      <Form.Control
                        type="password"
                        id="publicKey"
                        name="publicKey"
                        className="form-control pe-5 password-input"
                        value={formData.publicKey}
                        disabled
                      />
                      <Button
                        variant="link"
                        className="position-absolute end-0 top-0 text-decoration-none text-muted password-addon"
                        type="button"
                        onClick={(e) => copyText(e, "publicKey")}
                      >
                        <i className="bx bxs-copy align-middle"></i>
                      </Button>
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default ApiKeys;
