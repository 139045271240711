// action
import { configureNumbersApi } from "helpers/services/numbers/numbers";
import { editConfigurePhoneNumber } from "helpers/apiroute_helper";
import {
  apiLoading,
  getConfigureNumber,
  configureNumbersSuccessful,
  configureNumbersFailed,
} from "./reducer";
import { toast } from "react-toastify";

export const configureNumbers =
  (payload: any, cb?: any) => async (dispatch: any) => {
    dispatch(apiLoading());
    try {
      let response = configureNumbersApi(payload);

      const data: any = await response;
      cb && cb();
      if (data && data.message) {
        dispatch(configureNumbersSuccessful(data));
        toast.success(data?.message);
      } else {
        dispatch(configureNumbersFailed(data));
        toast.error(data?.message);
      }
    } catch (error: any) {
      toast.error(error?.response?.data?.message || "Something went wrong");
    }
  };

export const GetConfiguredNumber =
  (payload: any, cb?: any) => async (dispatch: any) => {
    dispatch(apiLoading());
    try {
      let responce = editConfigurePhoneNumber(payload);

      const data: any = await responce;
      cb && cb();

      if (data || data.data) {
        dispatch(getConfigureNumber(data));
        // toast.success(data?.message);
      } else {
        dispatch(configureNumbersFailed(data));
        // toast.error(data?.message);
      }
    } catch (error: any) {
      // toast.error(error?.response?.data?.message || "Something went wrong");
    }
  };
