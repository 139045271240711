import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  loading: false,
  loading2: false,
  loading3: false,
  loading4: false,
  loading5: false,
  campaignsData: {},
  activeCampaignsData: {},
  campaignDcaElectedStatus: [],
  campaignsPartnerData: {},
  campaignPartnerDcaElectedStatus: [],
  campaignUseCaseData: [],
  campaignTerms: {},
  campaignTnC: "",
  campaignDetails: {},
  campaignMnoMetadata: {},
  campaignMmsData: [],
  campaignOperationStatus: {},
  campaignDcaElected: [],
  cspWebhookEventsData: {},
  currNumProvData: {},
  prevNumProvData: {},
  success: false,
  successMsg: "",
  error: false,
  errorMsg: "",
};

const campaignsDataSlice = createSlice({
  name: "campaigns",
  initialState,
  reducers: {
    apiLoading(state) {
      state.loading = true;
      state.success = false;
      state.successMsg = "";
      state.error = false;
      state.errorMsg = "";
    },
    apiLoading2(state) {
      state.loading2 = true;
      state.success = false;
      state.successMsg = "";
      state.error = false;
      state.errorMsg = "";
    },
    apiLoading3(state) {
      state.loading3 = true;
      state.success = false;
      state.successMsg = "";
      state.error = false;
      state.errorMsg = "";
    },
    apiLoading4(state) {
      state.loading4 = true;
      state.success = false;
      state.successMsg = "";
      state.error = false;
      state.errorMsg = "";
    },
    apiLoading5(state) {
      state.loading5 = true;
      state.success = false;
      state.successMsg = "";
      state.error = false;
      state.errorMsg = "";
    },
    campaignsDataSuccessful(state, action) {
      state.loading = false;
      state.campaignsData = action.payload;
      state.success = true;
      state.successMsg = "";
      state.error = false;
      state.errorMsg = "";
    },
    campaignsDataFailed(state, action) {
      state.loading = false;
      state.campaignsData = {};
      state.success = false;
      state.successMsg = "";
      state.error = true;
      state.errorMsg = action.payload.message;
    },
    activeCampaignsDataSuccessful(state, action) {
      state.loading = false;
      state.activeCampaignsData = action.payload;
      state.success = true;
      state.successMsg = "";
      state.error = false;
      state.errorMsg = "";
    },
    activeCampaignsDataFailed(state, action) {
      state.loading = false;
      state.activeCampaignsData = {};
      state.success = false;
      state.successMsg = "";
      state.error = true;
      state.errorMsg = action.payload.message;
    },
    campaignDcaElectedStatusSuccessful(state, action) {
      state.loading = false;
      state.campaignDcaElectedStatus = action.payload;
      state.success = true;
      state.successMsg = "";
      state.error = false;
      state.errorMsg = "";
    },
    campaignDcaElectedStatusFailed(state, action) {
      state.loading = false;
      state.campaignDcaElectedStatus = [];
      state.success = false;
      state.successMsg = "";
      state.error = true;
      state.errorMsg = action.payload.message;
    },
    campaignsPartnerDataSuccessful(state, action) {
      state.loading2 = false;
      state.campaignsPartnerData = action.payload;
      state.success = true;
      state.successMsg = "";
      state.error = false;
      state.errorMsg = "";
    },
    campaignsPartnerDataFailed(state, action) {
      state.loading2 = false;
      state.campaignsPartnerData = {};
      state.success = false;
      state.successMsg = "";
      state.error = true;
      state.errorMsg = action.payload.message;
    },
    campaignPartnerDcaElectedStatusSuccessful(state, action) {
      state.loading = false;
      state.campaignPartnerDcaElectedStatus = action.payload;
      state.success = true;
      state.successMsg = "";
      state.error = false;
      state.errorMsg = "";
    },
    campaignPartnerDcaElectedStatusFailed(state, action) {
      state.loading = false;
      state.campaignPartnerDcaElectedStatus = [];
      state.success = false;
      state.successMsg = "";
      state.error = true;
      state.errorMsg = action.payload.message;
    },
    campaignsDataReset(state) {
      state.loading = false;
      state.campaignsData = {};
      state.activeCampaignsData = {};
      state.success = false;
      state.successMsg = "";
      state.error = false;
      state.errorMsg = "";
    },
    campaignUseCaseDataSuccessful(state, action) {
      state.loading5 = false;
      state.campaignUseCaseData = action.payload;
      state.success = true;
      state.successMsg = "";
      state.error = false;
      state.errorMsg = "";
    },
    campaignUseCaseDataFailed(state, action) {
      state.loading5 = false;
      state.campaignUseCaseData = [];
      state.success = false;
      state.successMsg = "";
      state.error = true;
      state.errorMsg = action.payload.message;
    },
    campaignTermsSuccessful(state, action) {
      state.loading = false;
      state.campaignTerms = action.payload;
      state.success = true;
      state.successMsg = "";
      state.error = false;
      state.errorMsg = "";
    },
    campaignTermsFailed(state, action) {
      state.loading = false;
      state.campaignTerms = {};
      state.success = false;
      state.successMsg = "";
      state.error = true;
      state.errorMsg = action.payload.message;
    },
    campaignTnCSuccessful(state, action) {
      state.loading = false;
      state.campaignTnC = action.payload;
      state.success = true;
      state.successMsg = "";
      state.error = false;
      state.errorMsg = "";
    },
    campaignTnCFailed(state, action) {
      state.loading = false;
      state.campaignTnC = "";
      state.success = false;
      state.successMsg = "";
      state.error = true;
      state.errorMsg = action.payload.message;
    },
    campaignCreateSuccessful(state, action) {
      state.loading = false;
      state.success = true;
      state.successMsg = "";
      state.error = false;
      state.errorMsg = "";
    },
    campaignCreateFailed(state, action) {
      state.loading = false;
      state.success = false;
      state.successMsg = "";
      state.error = true;
      state.errorMsg = action.payload.message;
    },
    uploadCampaignMmsSuccessful(state, action) {
      state.loading = false;
      state.success = true;
      state.successMsg = "";
      state.error = false;
      state.errorMsg = "";
    },
    uploadCampaignMmsFailed(state, action) {
      state.loading = false;
      state.success = false;
      state.successMsg = "";
      state.error = true;
      state.errorMsg = action.payload.message;
    },
    campaignDetailsSuccessful(state, action) {
      state.loading = false;
      state.campaignDetails = action.payload;
      state.success = true;
      state.successMsg = "";
      state.error = false;
      state.errorMsg = "";
    },
    campaignDetailsFailed(state, action) {
      state.loading = false;
      state.campaignDetails = {};
      state.success = false;
      state.successMsg = "";
      state.error = true;
      state.errorMsg = action.payload.message;
    },
    campaignMnoMetadataSuccessful(state, action) {
      state.loading = false;
      state.campaignMnoMetadata = action.payload;
      state.success = true;
      state.successMsg = "";
      state.error = false;
      state.errorMsg = "";
    },
    campaignMnoMetadataFailed(state, action) {
      state.loading = false;
      state.campaignMnoMetadata = {};
      state.success = false;
      state.successMsg = "";
      state.error = true;
      state.errorMsg = action.payload.message;
    },
    campaignMmsDataSuccessful(state, action) {
      state.loading = false;
      state.campaignMmsData = action.payload;
      state.success = true;
      state.successMsg = "";
      state.error = false;
      state.errorMsg = "";
    },
    campaignMmsDataFailed(state, action) {
      state.loading = false;
      state.campaignMmsData = [];
      state.success = false;
      state.successMsg = "";
      state.error = true;
      state.errorMsg = action.payload.message;
    },
    campaignOperationStatusSuccessful(state, action) {
      state.loading = false;
      state.campaignOperationStatus = action.payload;
      state.success = true;
      state.successMsg = "";
      state.error = false;
      state.errorMsg = "";
    },
    campaignOperationStatusFailed(state, action) {
      state.loading = false;
      state.campaignOperationStatus = {};
      state.success = false;
      state.successMsg = "";
      state.error = true;
      state.errorMsg = action.payload.message;
    },
    campaignDcaElectedSuccessful(state, action) {
      state.loading = false;
      state.campaignDcaElected = action.payload;
      state.success = true;
      state.successMsg = "";
      state.error = false;
      state.errorMsg = "";
    },
    campaignDcaElectedFailed(state, action) {
      state.loading = false;
      state.campaignDcaElected = [];
      state.success = false;
      state.successMsg = "";
      state.error = true;
      state.errorMsg = action.payload.message;
    },
    campaignUpdateSuccessful(state, action) {
      state.loading2 = false;
      state.campaignDetails = action.payload;
      state.success = true;
      state.successMsg = "";
      state.error = false;
      state.errorMsg = "";
    },
    campaignUpdateFailed(state, action) {
      state.loading2 = false;
      state.success = false;
      state.successMsg = "";
      state.error = true;
      state.errorMsg = action.payload.message;
    },
    campaignReviewUpdateSuccessful(state, action) {
      state.loading2 = false;
      state.success = true;
      state.successMsg = "";
      state.error = false;
      state.errorMsg = "";
    },
    campaignReviewUpdateFailed(state, action) {
      state.loading2 = false;
      state.success = false;
      state.successMsg = "";
      state.error = true;
      state.errorMsg = action.payload.message;
    },
    campaignAttachmentDataSuccessful(state, action) {
      state.loading = false;
      state.success = true;
      state.successMsg = "";
      state.error = false;
      state.errorMsg = "";
    },
    campaignAttachmentDataFailed(state, action) {
      state.loading = false;
      state.success = false;
      state.successMsg = "";
      state.error = true;
      state.errorMsg = action.payload.message;
    },
    campaignAttachmentDeleteSuccessful(state, action) {
      state.loading2 = false;
      state.success = true;
      state.successMsg = "";
      state.error = false;
      state.errorMsg = "";
    },
    campaignAttachmentDeleteFailed(state, action) {
      state.loading2 = false;
      state.success = false;
      state.successMsg = "";
      state.error = true;
      state.errorMsg = action.payload.message;
    },
    nudgeCampaignSuccessful(state, action) {
      state.loading2 = false;
      state.success = true;
      state.successMsg = "";
      state.error = false;
      state.errorMsg = "";
    },
    nudgeCampaignFailed(state, action) {
      state.loading2 = false;
      state.success = false;
      state.successMsg = "";
      state.error = true;
      state.errorMsg = action.payload.message;
    },
    nudgeReviewCampaignSuccessful(state, action) {
      state.loading2 = false;
      state.success = true;
      state.successMsg = "";
      state.error = false;
      state.errorMsg = "";
    },
    nudgeReviewCampaignFailed(state, action) {
      state.loading2 = false;
      state.success = false;
      state.successMsg = "";
      state.error = true;
      state.errorMsg = action.payload.message;
    },
    resubmitCampaignSuccessful(state, action) {
      state.loading2 = false;
      state.campaignMnoMetadata = action.payload.mnoMetadata;
      state.success = true;
      state.successMsg = "";
      state.error = false;
      state.errorMsg = "";
    },
    resubmitCampaignFailed(state, action) {
      state.loading2 = false;
      state.success = false;
      state.successMsg = "";
      state.error = true;
      state.errorMsg = action.payload.message;
    },
    deactivateCampaignSuccessful(state, action) {
      state.loading2 = false;
      state.success = true;
      state.successMsg = "";
      state.error = false;
      state.errorMsg = "";
    },
    deactivateCampaignFailed(state, action) {
      state.loading2 = false;
      state.success = false;
      state.successMsg = "";
      state.error = true;
      state.errorMsg = action.payload.message;
    },
    cspWebhookEventsDataSuccessful(state, action) {
      state.loading3 = false;
      state.cspWebhookEventsData = action.payload;
      state.success = true;
      state.successMsg = "";
      state.error = false;
      state.errorMsg = "";
    },
    cspWebhookEventsDataFailed(state, action) {
      state.loading3 = false;
      state.cspWebhookEventsData = {};
      state.success = false;
      state.successMsg = "";
      state.error = true;
      state.errorMsg = action.payload.message;
    },
    currNumProvDataSuccessful(state, action) {
      state.loading4 = false;
      state.currNumProvData = action.payload;
      state.success = true;
      state.successMsg = "";
      state.error = false;
      state.errorMsg = "";
    },
    currNumProvDataFailed(state, action) {
      state.loading4 = false;
      state.currNumProvData = {};
      state.success = false;
      state.successMsg = "";
      state.error = true;
      state.errorMsg = action.payload.message;
    },
    prevNumProvDataSuccessful(state, action) {
      state.loading4 = false;
      state.prevNumProvData = action.payload;
      state.success = true;
      state.successMsg = "";
      state.error = false;
      state.errorMsg = "";
    },
    prevNumProvDataFailed(state, action) {
      state.loading4 = false;
      state.prevNumProvData = {};
      state.success = false;
      state.successMsg = "";
      state.error = true;
      state.errorMsg = action.payload.message;
    },
  },
});

export const {
  apiLoading,
  apiLoading2,
  apiLoading3,
  apiLoading4,
  apiLoading5,
  campaignsDataSuccessful,
  campaignsDataFailed,
  activeCampaignsDataSuccessful,
  activeCampaignsDataFailed,
  campaignDcaElectedStatusSuccessful,
  campaignDcaElectedStatusFailed,
  campaignsPartnerDataSuccessful,
  campaignsPartnerDataFailed,
  campaignPartnerDcaElectedStatusSuccessful,
  campaignPartnerDcaElectedStatusFailed,
  campaignsDataReset,
  campaignUseCaseDataSuccessful,
  campaignUseCaseDataFailed,
  campaignTermsSuccessful,
  campaignTermsFailed,
  campaignTnCSuccessful,
  campaignTnCFailed,
  campaignCreateSuccessful,
  campaignCreateFailed,
  uploadCampaignMmsSuccessful,
  uploadCampaignMmsFailed,
  campaignDetailsSuccessful,
  campaignDetailsFailed,
  campaignMnoMetadataSuccessful,
  campaignMnoMetadataFailed,
  campaignMmsDataSuccessful,
  campaignMmsDataFailed,
  campaignOperationStatusSuccessful,
  campaignOperationStatusFailed,
  campaignDcaElectedSuccessful,
  campaignDcaElectedFailed,
  campaignUpdateSuccessful,
  campaignUpdateFailed,
  campaignReviewUpdateSuccessful,
  campaignReviewUpdateFailed,
  campaignAttachmentDataSuccessful,
  campaignAttachmentDataFailed,
  campaignAttachmentDeleteSuccessful,
  campaignAttachmentDeleteFailed,
  nudgeCampaignSuccessful,
  nudgeCampaignFailed,
  nudgeReviewCampaignSuccessful,
  nudgeReviewCampaignFailed,
  resubmitCampaignSuccessful,
  resubmitCampaignFailed,
  deactivateCampaignSuccessful,
  deactivateCampaignFailed,
  cspWebhookEventsDataSuccessful,
  cspWebhookEventsDataFailed,
  currNumProvDataSuccessful,
  currNumProvDataFailed,
  prevNumProvDataSuccessful,
  prevNumProvDataFailed,
} = campaignsDataSlice.actions;

export default campaignsDataSlice.reducer;
