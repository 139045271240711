import React, { ReactNode, useEffect, useState } from "react";

//import actions
import {
  changeLayout,
  changeSidebarTheme,
  changeLayoutMode,
  changeLayoutWidth,
  changeLayoutPosition,
  changeTopbarTheme,
  changeLeftsidebarSizeType,
  changeLeftsidebarViewType,
  changeSidebarImageType,
  changeBodyImageType,
  changeLayoutTheme,
} from "slices/thunk";

//redux
import { useSelector, useDispatch } from "react-redux";
// import TopBar from './TopBar';

import Header from "./Header";
import Sidebar from "./Sidebar";
import RightSidebar from "./RightSidebar";
import Footer from "./Footer";
import withRouter from "Common/withRouter";
import { createSelector } from "reselect";
import SimpleBar from "simplebar-react";
import { Container } from "react-bootstrap";
import SettingsVerticalLayout from "./SettingsVerticalLayout";

interface LayoutProps {
  children: ReactNode;
  isSubNavbar?: boolean;
  subNavdata?: any;
}

const Layout = (props: LayoutProps) => {
  var windowSize = document.documentElement.clientWidth;
  const dispatch: any = useDispatch();
  const selectLayoutProperties = createSelector(
    (state: any) => state.Layout,
    (layout) => ({
      layoutType: layout.layoutType,
      layoutThemeType: layout.layoutThemeType,
      leftSidebarType: layout.leftSidebarType,
      layoutModeType: layout.layoutModeType,
      layoutWidthType: layout.layoutWidthType,
      layoutPositionType: layout.layoutPositionType,
      topbarThemeType: layout.topbarThemeType,
      leftsidbarSizeType: layout.leftsidbarSizeType,
      leftSidebarViewType: layout.leftSidebarViewType,
      leftSidebarImageType: layout.leftSidebarImageType,
      bodyImageType: layout.bodyImageType,
    })
  );

  const {
    layoutType,
    layoutThemeType,
    leftSidebarType,
    layoutModeType,
    layoutWidthType,
    layoutPositionType,
    topbarThemeType,
    leftsidbarSizeType,
    leftSidebarViewType,
    leftSidebarImageType,
    bodyImageType,
  } = useSelector(selectLayoutProperties);

  /*
    layout settings
    */
  useEffect(() => {
    if (
      layoutType ||
      layoutThemeType ||
      leftSidebarType ||
      layoutModeType ||
      layoutWidthType ||
      layoutPositionType ||
      topbarThemeType ||
      leftsidbarSizeType ||
      leftSidebarViewType ||
      leftSidebarImageType ||
      bodyImageType
    ) {
      window.dispatchEvent(new Event("resize"));
      dispatch(changeLeftsidebarViewType(leftSidebarViewType));
      dispatch(changeLayoutTheme(layoutThemeType));
      dispatch(changeLeftsidebarSizeType(leftsidbarSizeType));
      dispatch(changeSidebarTheme(leftSidebarType));
      dispatch(changeLayoutMode(layoutModeType));
      dispatch(changeLayoutWidth(layoutWidthType));
      dispatch(changeLayoutPosition(layoutPositionType));
      dispatch(changeTopbarTheme(topbarThemeType));
      dispatch(changeLayout(layoutType));
      dispatch(changeSidebarImageType(leftSidebarImageType));
      dispatch(changeBodyImageType(bodyImageType));
    }
  }, [
    layoutType,
    layoutThemeType,
    leftSidebarType,
    layoutModeType,
    layoutWidthType,
    layoutPositionType,
    topbarThemeType,
    leftsidbarSizeType,
    leftSidebarViewType,
    leftSidebarImageType,
    bodyImageType,
    dispatch,
  ]);

  const [isInit, setIsInit] = useState(true);

  useEffect(() => {
    setIsInit(false);
  }, []);

  if (isInit) return null;

  return (
    <React.Fragment>
      <div id="layout-wrapper">
        <Sidebar layoutType={layoutType} />
        <Header
          isSubNavbar={props?.isSubNavbar}
          subNavdata={props?.subNavdata}
        />
        <div className="main-content">
          {props?.isSubNavbar && windowSize > 767 ? (
            <>
              <div className="app-menu2 navbar-menu2">
                <SimpleBar id="scrollbar" className="h-100">
                  <Container fluid>
                    <div id="two-column-menu"></div>
                    <ul className="navbar-nav" id="navbar-nav2">
                      <SettingsVerticalLayout
                        layoutType="vertical"
                        subNavdata={props?.subNavdata}
                      />
                    </ul>
                  </Container>
                </SimpleBar>
                <div className="sidebar-background"></div>
              </div>
              <div className="main-content main-content2">{props.children}</div>
            </>
          ) : (
            props.children
          )}
          <Footer isSubNavbar={props?.isSubNavbar} />
        </div>
      </div>
      <RightSidebar />
    </React.Fragment>
  );
};

export default withRouter(Layout);
