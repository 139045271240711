import React, { useEffect, useState } from "react";
import {
  Container,
  Dropdown,
  Card,
  Row,
  Col,
  Form,
  Button,
  Spinner,
  Nav,
  Tab,
} from "react-bootstrap";
import BreadCrumb from "Common/BreadCrumb";
import { useDispatch, useSelector } from "react-redux";
import { createSelector } from "reselect";
import {
  getCampaignListThunk,
  getCampaignPartnerListThunk,
  getConnectivityPartnersListThunk,
} from "slices/thunk";
import Datatable from "../../Common/Datatable";
import {
  capitalizeString,
  removeEmptyAndNullValues,
  dateFormat,
  customSelectTheme,
  styleObj,
  asyncBrandList,
  asyncSubgroupList,
  asyncCampaignList,
} from "helpers/common";
import Loader from "assets/images/spinner-dark.svg";
import withRouter from "Common/withRouter";
import { useFormik } from "formik";
import { AsyncPaginate } from "react-select-async-paginate";
import moment from "moment";
import Flatpickr from "react-flatpickr";
import Select from "react-select";
import Tooltip from "rc-tooltip";
import { useSearchParams } from "react-router-dom";
import { GroupSelect } from "Common/filter/group-select";
import { BrandSelect } from "Common/filter/brand-select";

const dcaArr = [
  {
    label: "Signal House Review",
    value: "underReview",
  },
  {
    label: "Signal House Rejected",
    value: "rejectedReview",
  },
  // { label: "Signal House Approved", value: "" },
  { label: "Pending", value: "Pending" },
  { label: "Rejected", value: "Rejected" },
  { label: "Approved", value: "Approved" },
];

const CampaignsList = (props: any) => {
  document.title = "Signal House Portal Campaigns";
  const dispatch = useDispatch<any>();
  const [searchParams] = useSearchParams();
  const groupId = searchParams.get("groupId");
  const subGroupId = searchParams.get("subGroupId");
  const subgroupnames = searchParams.get("subgroupnames");
  const brandId = searchParams.get("brandId");
  const campaignId = searchParams.get("campaignId");
  const dcaComplete = searchParams.get("dcaComplete");
  const tcrStatus = searchParams.get("tcrStatus");

  const [currPage1, setCurrPage1] = useState(1);
  const [rowsPerPage1, setRowsPerPage1] = useState(10);
  const [currPage2, setCurrPage2] = useState(1);
  const [rowsPerPage2, setRowsPerPage2] = useState(10);
  const [open, setOpen] = useState(false);
  const [datePickerModal, setDatePickerModal] = useState(false);

  const selectProfile = createSelector(
    (state: any) => state.Campaigns,
    (state: any) => state.Enums,
    (state: any) => state.Groups,
    (campaigns, enums, groups) => ({
      loading: campaigns.loading,
      loading2: campaigns.loading,
      campaignsData: campaigns.campaignsData,
      campaignsPartnerData: campaigns.campaignsPartnerData,
      error: campaigns.error,
      connectivityPartnersObj: enums.connectivityPartnersObj,
      groupDetails: groups?.AllGroups,
    })
  );

  const {
    loading,
    loading2,
    campaignsData,
    campaignsPartnerData,
    connectivityPartnersObj,
    groupDetails,
  } = useSelector(selectProfile);

  const validation: any = useFormik({
    enableReinitialize: true,
    initialValues: {
      subGroupId: [],
      brandId: [],
      brandName: [],
      campaignId: [],
      dcaComplete: "",
      tcrStatus: "",
      createdAt: "",
      startDate: "",
      endDate: "",
    },
    onSubmit: (values) => {
      handleGetData1(currPage1, rowsPerPage1, values);
      handleGetData2(currPage2, rowsPerPage2, values);
    },
  });

  const columns1 = [
    {
      name: <span className="font-weight-bold fs-sm">Registered On</span>,
      minWidth: "220px",
      selector: (row: { createDate: string }) => (
        <span data-tag="allowRowEvents" className="d-flex align-items-center">
          <i
            data-tag="allowRowEvents"
            className="bi bi-calendar3 me-2 fs-lg text-secondary"
          />
          {dateFormat(row.createDate, "MM/DD/YYYY, hh:mm:ss A")}
        </span>
      ),
      sortable: true,
      sortFunction: (a: any, b: any) => {
        return (
          new Date(a.createDate).getTime() - new Date(b.createDate).getTime()
        );
      },
    },
    {
      name: <span className="font-weight-bold fs-sm">Campaign ID</span>,
      minWidth: "160px",
      selector: (row: { campaignId: string; campaignUuid: string }) =>
        row.campaignId.toLowerCase() === "pending" ||
        row.campaignId.toLowerCase() === "rejected" ? (
          <span
            data-tag="allowRowEvents"
            className="badge bg-body-secondary border border-primary text-primary"
          >
            {row.campaignUuid}
          </span>
        ) : (
          <span
            data-tag="allowRowEvents"
            className="badge bg-body-primary border border-primary text-primary"
          >
            <b data-tag="allowRowEvents">{row.campaignId || "-"}</b>
          </span>
        ),
      sortable: true,
      sortFunction: (a: any, b: any) =>
        (a.campaignId || "").localeCompare(b.campaignId || ""),
    },
    {
      name: <span className="font-weight-bold fs-sm">Brand ID</span>,
      minWidth: "110px",
      selector: (row: { brandId: string }) => (
        <span
          data-tag="allowRowEvents"
          className="text-secondary cursor-pointer"
          onClick={(e) => {
            e.stopPropagation();
            props.router.navigate(`/brands/${row.brandId}`);
          }}
        >
          <b data-tag="allowRowEvents">{row.brandId}</b>
        </span>
      ),
      sortable: true,
      sortFunction: (a: any, b: any) =>
        (a.brandId || "").localeCompare(b.brandId || ""),
    },
    {
      name: <span className="font-weight-bold fs-sm">Brand Name</span>,
      minWidth: "150px",
      selector: (row: { brandName: string }) => capitalizeString(row.brandName),
      sortable: true,
      sortFunction: (a: any, b: any) =>
        (a.brandName || "").localeCompare(b.brandName || ""),
    },
    {
      name: <span className="font-weight-bold fs-sm">Use Case</span>,
      minWidth: "200px",
      selector: (row: { usecase: string }) => (
        <span
          data-tag="allowRowEvents"
          className="badge bg-body-secondary border border-secondary text-secondary"
        >
          {row.usecase
            .split("_")
            .map(
              (word) =>
                word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
            )
            .join(" ")}
        </span>
      ),
      sortable: true,
      sortFunction: (a: any, b: any) =>
        (a.usecase || "").localeCompare(b.usecase || ""),
    },
    {
      name: <span className="font-weight-bold fs-sm">TCR Status</span>,
      minWidth: "150px",
      selector: (cell: { status: string }) => {
        switch (cell?.status?.toLowerCase()) {
          case "expired":
            return (
              <span
                data-tag="allowRowEvents"
                className="d-flex align-items-center text-primary"
              >
                <span
                  data-tag="allowRowEvents"
                  className="badge border border-2 border-white rounded-circle bg-primary p-1 me-2"
                >
                  <span></span>
                </span>
                {capitalizeString(cell.status)}
              </span>
            );
          case "deactivated":
            return (
              <span
                data-tag="allowRowEvents"
                className="d-flex align-items-center text-info"
              >
                <span
                  data-tag="allowRowEvents"
                  className="badge border border-2 border-white rounded-circle bg-info p-1 me-2"
                >
                  <span></span>
                </span>
                {capitalizeString(cell.status)}
              </span>
            );
          case "active":
            return (
              <span
                data-tag="allowRowEvents"
                className="d-flex align-items-center text-secondary"
              >
                <span
                  data-tag="allowRowEvents"
                  className="badge border border-2 border-white rounded-circle bg-secondary p-1 me-2"
                >
                  <span></span>
                </span>
                {capitalizeString(cell.status)}
              </span>
            );
          case "rejected":
            return (
              <span
                data-tag="allowRowEvents"
                className="d-flex align-items-center text-danger"
              >
                <span
                  data-tag="allowRowEvents"
                  className="badge border border-2 border-white rounded-circle bg-danger p-1 me-2"
                >
                  <span></span>
                </span>
                {capitalizeString(cell.status)}
              </span>
            );
          case "pending":
            return (
              <span
                data-tag="allowRowEvents"
                className="d-flex align-items-center text-dark"
              >
                <span
                  data-tag="allowRowEvents"
                  className="badge border border-2 border-white rounded-circle bg-dark p-1 me-2"
                >
                  <span></span>
                </span>
                {capitalizeString(cell.status)}
              </span>
            );
          default:
            return (
              <span
                data-tag="allowRowEvents"
                className="d-flex align-items-center text-success"
              >
                <span
                  data-tag="allowRowEvents"
                  className="badge border border-2 border-white rounded-circle bg-success p-1 me-2"
                >
                  <span></span>
                </span>
                {capitalizeString(cell.status)}
              </span>
            );
        }
      },
      sortable: true,
      sortFunction: (a: any, b: any) =>
        (a.status || "").localeCompare(b.status || ""),
    },
    {
      name: <span className="font-weight-bold fs-sm">DCA Election</span>,
      minWidth: "200px",
      selector: (cell: {
        dcaComplete: string;
        dcaStatusDescription?: string;
        rejectionReason?: string;
      }) => {
        switch (cell?.dcaComplete?.toLowerCase()) {
          case "pending":
            return (
              <span
                data-tag="allowRowEvents"
                className="badge border"
                style={{
                  borderColor: "#6c757d",
                  background: "#d3d3d3e0",
                  color: "#6c757d",
                }}
              >
                {capitalizeString(cell?.dcaComplete)}
              </span>
            );
          case "rejected":
            return (
              <>
                <span
                  data-tag="allowRowEvents"
                  className="badge bg-primary border border-primary text-white"
                >
                  {capitalizeString(cell?.dcaComplete)}
                </span>
                <Tooltip
                  placement="bottom"
                  overlay={cell?.dcaStatusDescription || "-"}
                >
                  <i
                    className="ri-information-line text-secondary fs-2xl ms-2 position-absolute"
                    style={{ bottom: 15 }}
                  />
                </Tooltip>
              </>
            );
          case "approved":
            return (
              <span
                data-tag="allowRowEvents"
                className="badge bg-secondary border border-secondary text-white"
              >
                {capitalizeString(cell?.dcaComplete)}
              </span>
            );
          case "signal house review":
            return (
              <span
                data-tag="allowRowEvents"
                className="badge bg-body-secondary border border-black text-black"
              >
                {capitalizeString(cell?.dcaComplete || "-")}
              </span>
            );
          case "signal house rejected":
            return (
              <>
                <span
                  data-tag="allowRowEvents"
                  className="badge bg-body-secondary border border-primary text-primary"
                >
                  {capitalizeString(cell?.dcaComplete || "-")}
                </span>
                <Tooltip
                  placement="bottom"
                  overlay={cell?.rejectionReason || "-"}
                >
                  <i
                    className="ri-information-line text-secondary fs-2xl ms-2 position-absolute"
                    style={{ bottom: 15 }}
                  />
                </Tooltip>
              </>
            );
          case "signal house approved":
            return (
              <>
                <span
                  data-tag="allowRowEvents"
                  className="badge bg-body-secondary border border-secondary text-secondary"
                >
                  {capitalizeString(cell?.dcaComplete || "-")}
                </span>
                <Tooltip
                  placement="bottom"
                  overlay="Add phone number to campaign to finalize your submission"
                >
                  <i
                    className="ri-information-line text-secondary fs-2xl ms-2 position-absolute"
                    style={{ bottom: 15 }}
                  />
                </Tooltip>
              </>
            );
          default:
            return (
              <span
                data-tag="allowRowEvents"
                className="badge bg-body-secondary border border-secondary text-secondary"
              >
                {capitalizeString(cell?.dcaComplete || "-")}
              </span>
            );
        }
      },
      sortable: true,
      sortFunction: (a: any, b: any) => {
        return (a?.dcaComplete || "")
          .toLowerCase()
          .localeCompare((b?.dcaComplete || "").toLowerCase());
      },
    },
  ];

  const columns2 = [
    {
      name: (
        <span className="font-weight-bold fs-sm d-flex align-items-center">
          Shared With Me On
          <Tooltip
            placement="bottomLeft"
            overlay="Date and time the campaign was shared with Signal House as the CNP."
          >
            <i className="ri-information-line text-secondary fs-2xl ms-2" />
          </Tooltip>
        </span>
      ),
      minWidth: "220px",
      selector: (row: { sharedWithMe: string }) => (
        <span data-tag="allowRowEvents" className="d-flex align-items-center">
          <i
            data-tag="allowRowEvents"
            className="bi bi-calendar3 me-2 fs-lg text-secondary"
          />
          {dateFormat(row.sharedWithMe, "MM/DD/YYYY, hh:mm:ss A")}
        </span>
      ),
      sortable: true,
      sortFunction: (a: any, b: any) => {
        return (
          new Date(a.sharedWithMe).getTime() -
          new Date(b.sharedWithMe).getTime()
        );
      },
    },
    {
      name: (
        <span className="font-weight-bold fs-sm d-flex align-items-center">
          Shared to DCA On
          <Tooltip
            placement="bottomLeft"
            overlay="Date and time Signal House shared the campaign with our upstream DCA."
          >
            <i className="ri-information-line text-secondary fs-2xl ms-2" />
          </Tooltip>
        </span>
      ),
      minWidth: "220px",
      selector: (row: { sharedToOn: string }) => (
        <span data-tag="allowRowEvents" className="d-flex align-items-center">
          <i
            data-tag="allowRowEvents"
            className="bi bi-calendar3 me-2 fs-lg text-secondary"
          />
          {dateFormat(row.sharedToOn, "MM/DD/YYYY, hh:mm:ss A")}
        </span>
      ),
      sortable: true,
      sortFunction: (a: any, b: any) => {
        return (
          new Date(a.sharedToOn).getTime() - new Date(b.sharedToOn).getTime()
        );
      },
    },
    {
      name: <span className="font-weight-bold fs-sm">Campaign ID</span>,
      minWidth: "160px",
      selector: (row: { campaignId: string; campaignUuid: string }) =>
        row.campaignId.toLowerCase() === "pending" ||
        row.campaignId.toLowerCase() === "rejected" ? (
          <span
            data-tag="allowRowEvents"
            className="badge bg-body-secondary border border-primary text-primary"
          >
            {row.campaignUuid}
          </span>
        ) : (
          <span data-tag="allowRowEvents" className="text-primary">
            <b data-tag="allowRowEvents">{row.campaignId || "-"}</b>
          </span>
        ),
      sortable: true,
      sortFunction: (a: any, b: any) =>
        (a?.campaignId || "").localeCompare(b?.campaignId || ""),
    },
    {
      name: <span className="font-weight-bold fs-sm">Brand ID</span>,
      minWidth: "110px",
      selector: (row: { brandId: string }) => (
        <span
          data-tag="allowRowEvents"
          className="text-secondary cursor-pointer"
          onClick={(e) => {
            e.stopPropagation();
            props.router.navigate(`/brands/${row.brandId}`);
          }}
        >
          <b data-tag="allowRowEvents">{row.brandId}</b>
        </span>
      ),
      sortable: true,
      sortFunction: (a: any, b: any) => {
        return (a.brandId || "").localeCompare(b.brandId || "");
      },
    },
    {
      name: <span className="font-weight-bold fs-sm">Use Case</span>,
      minWidth: "200px",
      selector: (row: { usecase: string }) => (
        <span
          data-tag="allowRowEvents"
          className="badge bg-body-secondary border border-secondary text-secondary"
        >
          {row.usecase
            .split("_")
            .map(
              (word) =>
                word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
            )
            .join(" ")}
        </span>
      ),
      sortable: true,
      sortFunction: (a: any, b: any) =>
        (a.usecase || "").localeCompare(b.usecase || ""),
    },
    {
      name: <span className="font-weight-bold fs-sm">TCR Status</span>,
      minWidth: "150px",
      selector: (cell: { status: string }) => {
        switch (cell?.status?.toLowerCase()) {
          case "expired":
            return (
              <span
                data-tag="allowRowEvents"
                className="d-flex align-items-center text-primary"
              >
                <span
                  data-tag="allowRowEvents"
                  className="badge border border-2 border-white rounded-circle bg-primary p-1 me-2"
                >
                  <span></span>
                </span>
                {capitalizeString(cell.status)}
              </span>
            );
          case "deactivated":
            return (
              <span
                data-tag="allowRowEvents"
                className="d-flex align-items-center text-info"
              >
                <span
                  data-tag="allowRowEvents"
                  className="badge border border-2 border-white rounded-circle bg-info p-1 me-2"
                >
                  <span></span>
                </span>
                {capitalizeString(cell.status)}
              </span>
            );
          case "active":
            return (
              <span
                data-tag="allowRowEvents"
                className="d-flex align-items-center text-secondary"
              >
                <span
                  data-tag="allowRowEvents"
                  className="badge border border-2 border-white rounded-circle bg-secondary p-1 me-2"
                >
                  <span></span>
                </span>
                {capitalizeString(cell.status)}
              </span>
            );
          case "rejected":
            return (
              <span
                data-tag="allowRowEvents"
                className="d-flex align-items-center text-danger"
              >
                <span
                  data-tag="allowRowEvents"
                  className="badge border border-2 border-white rounded-circle bg-danger p-1 me-2"
                >
                  <span></span>
                </span>
                {capitalizeString(cell.status)}
              </span>
            );
          case "pending":
            return (
              <span
                data-tag="allowRowEvents"
                className="d-flex align-items-center text-dark"
              >
                <span
                  data-tag="allowRowEvents"
                  className="badge border border-2 border-white rounded-circle bg-dark p-1 me-2"
                >
                  <span></span>
                </span>
                {capitalizeString(cell.status)}
              </span>
            );
          default:
            return (
              <span
                data-tag="allowRowEvents"
                className="d-flex align-items-center text-success"
              >
                <span
                  data-tag="allowRowEvents"
                  className="badge border border-2 border-white rounded-circle bg-success p-1 me-2"
                >
                  <span></span>
                </span>
                {capitalizeString(cell.status)}
              </span>
            );
        }
      },
      sortable: true,
      sortFunction: (a: any, b: any) =>
        (a.status || "").localeCompare(b.status || ""),
    },
    {
      name: <span className="font-weight-bold fs-sm">DCA Election</span>,
      minWidth: "200px",
      selector: (cell: {
        dcaComplete: string;
        dcaStatusDescription?: string;
        rejectionReason?: string;
      }) => {
        switch (cell?.dcaComplete?.toLowerCase()) {
          case "pending":
            return (
              <span
                data-tag="allowRowEvents"
                className="badge border"
                style={{
                  borderColor: "#6c757d",
                  background: "#d3d3d3e0",
                  color: "#6c757d",
                }}
              >
                {capitalizeString(cell?.dcaComplete)}
              </span>
            );
          case "rejected":
            return (
              <>
                <span
                  data-tag="allowRowEvents"
                  className="badge bg-primary border border-primary text-white"
                >
                  {capitalizeString(cell?.dcaComplete)}
                </span>
                <Tooltip
                  placement="bottom"
                  overlay={cell?.dcaStatusDescription || "-"}
                >
                  <i
                    className="ri-information-line text-secondary fs-2xl ms-2 position-absolute"
                    style={{ bottom: 15 }}
                  />
                </Tooltip>
              </>
            );
          case "approved":
            return (
              <span
                data-tag="allowRowEvents"
                className="badge bg-secondary border border-secondary text-white"
              >
                {capitalizeString(cell?.dcaComplete)}
              </span>
            );
          case "signal house review":
            return (
              <span
                data-tag="allowRowEvents"
                className="badge bg-body-secondary border border-black text-black"
              >
                {capitalizeString(cell?.dcaComplete || "-")}
              </span>
            );
          case "signal house rejected":
            return (
              <>
                <span
                  data-tag="allowRowEvents"
                  className="badge bg-body-secondary border border-primary text-primary"
                >
                  {capitalizeString(cell?.dcaComplete || "-")}
                </span>
                <Tooltip
                  placement="bottom"
                  overlay={cell?.rejectionReason || "-"}
                >
                  <i
                    className="ri-information-line text-secondary fs-2xl ms-2 position-absolute"
                    style={{ bottom: 15 }}
                  />
                </Tooltip>
              </>
            );
          default:
            return (
              <span
                data-tag="allowRowEvents"
                className="badge bg-body-secondary border border-secondary text-secondary"
              >
                {capitalizeString(cell?.dcaComplete || "-")}
              </span>
            );
        }
      },
      sortable: true,
      sortFunction: (a: any, b: any) => {
        return (a?.dcaComplete || "")
          .toLowerCase()
          .localeCompare((b?.dcaComplete || "").toLowerCase());
      },
    },
  ];

  useEffect(() => {
    handleGetData1(currPage1, rowsPerPage1, {
      ...validation.values,
      subGroupId: subGroupId ? [subGroupId] : [],
      brandId: brandId
        ? [
            {
              subGroupId: subGroupId || "",
              brandId: brandId || "",
            },
          ]
        : [],
      campaignId: campaignId
        ? [
            {
              subGroupId: subGroupId || "",
              brandId: brandId || "",
              campaignId: campaignId || "",
            },
          ]
        : [],
      dcaComplete: dcaComplete || "",
      tcrStatus: tcrStatus || "",
    });
    handleGetData2(currPage2, rowsPerPage2, {
      ...validation.values,
      subGroupId: subGroupId ? [subGroupId] : [],
      brandId: brandId
        ? [
            {
              subGroupId: subGroupId || "",
              brandId: brandId || "",
            },
          ]
        : [],
      campaignId: campaignId
        ? [
            {
              subGroupId: subGroupId || "",
              brandId: brandId || "",
              campaignId: campaignId || "",
            },
          ]
        : [],
      dcaComplete: dcaComplete || "",
      tcrStatus: tcrStatus || "",
    });
    dispatch(getConnectivityPartnersListThunk());
  }, []);

  useEffect(() => {
    subGroupId && validation.setFieldValue("subGroupId", [subGroupId]);
    brandId &&
      validation.setFieldValue("brandId", [
        {
          subGroupId: subGroupId || "",
          brandId: brandId || "",
        },
      ]);
    campaignId &&
      validation.setFieldValue("campaignId", [
        {
          subGroupId: subGroupId || "",
          brandId: brandId || "",
          campaignId: campaignId || "",
        },
      ]);
    dcaComplete && validation.setFieldValue("dcaComplete", dcaComplete);
    tcrStatus && validation.setFieldValue("tcrStatus", tcrStatus);
  }, [subGroupId, brandId, campaignId]);

  const handleGetData1 = (page: number, perPage: number, otherParams?: any) => {
    dispatch(
      getCampaignListThunk(
        page,
        perPage,
        removeEmptyAndNullValues({
          ...otherParams,
          campaignType: "own",
          subGroupId:
            otherParams?.subGroupId.length > 0 ? otherParams?.subGroupId : "",
          brandId:
            otherParams?.brandId.length > 0
              ? otherParams?.brandId?.map((dt: any) => dt.brandId)
              : "",
          brandName:
            otherParams?.brandName.length > 0
              ? otherParams?.brandName?.map((dt: any) => dt.displayName)
              : "",
          campaignId:
            otherParams?.campaignId.length > 0
              ? otherParams?.campaignId?.map((dt: any) => dt.campaignId)
              : "",
          createdAt: "",
          startDate: otherParams?.startDate
            ? moment(otherParams?.startDate, "MM-DD-YYYY")
                .startOf("day")
                .toISOString()
            : "",
          endDate: otherParams?.endDate
            ? moment(otherParams?.endDate, "MM-DD-YYYY")
                .endOf("day")
                .toISOString()
            : "",
        })
      )
    );
  };

  const handleGetData2 = (page: number, perPage: number, otherParams?: any) => {
    dispatch(
      getCampaignPartnerListThunk(
        page,
        perPage,
        removeEmptyAndNullValues({
          ...otherParams,
          campaignType: "partner",
          subGroupId:
            otherParams?.subGroupId.length > 0 ? otherParams?.subGroupId : "",
          brandId:
            otherParams?.brandId.length > 0
              ? otherParams?.brandId?.map((dt: any) => dt.brandId)
              : "",
          brandName:
            otherParams?.brandName.length > 0
              ? otherParams?.brandName?.map((dt: any) => dt.displayName)
              : "",
          campaignId:
            otherParams?.campaignId.length > 0
              ? otherParams?.campaignId?.map((dt: any) => dt.campaignId)
              : "",
          createdAt: "",
          startDate: otherParams?.startDate
            ? moment(otherParams?.startDate, "MM-DD-YYYY")
                .startOf("day")
                .toISOString()
            : "",
          endDate: otherParams?.endDate
            ? moment(otherParams?.endDate, "MM-DD-YYYY")
                .endOf("day")
                .toISOString()
            : "",
        })
      )
    );
  };

  const handleRowClick1 = (row: {
    campaignId: string;
    campaignUuid: string;
  }) => {
    row.campaignId.toLowerCase() === "pending"
      ? props.router.navigate(`/campaigns-review/${row.campaignUuid}`)
      : row.campaignId.toLowerCase() === "rejected"
      ? props.router.navigate(`/campaigns-rejected/${row.campaignUuid}`)
      : props.router.navigate(`/campaigns/${row.campaignId}`);
  };

  const handleRowClick2 = (row: {
    campaignId: string;
    campaignUuid: string;
  }) => {
    row.campaignId.toLowerCase() === "pending"
      ? props.router.navigate(`/campaigns-review/${row.campaignUuid}`)
      : row.campaignId.toLowerCase() === "rejected"
      ? props.router.navigate(`/campaigns-rejected/${row.campaignUuid}`)
      : props.router.navigate(`/campaigns-partner/${row.campaignId}`);
  };

  const handlePageChange1 = (page: number) => {
    setCurrPage1(page);
    handleGetData1(page, rowsPerPage1, validation.values);
  };

  const handlePageChange2 = (page: number) => {
    setCurrPage2(page);
    handleGetData2(page, rowsPerPage2, validation.values);
  };

  const handleRowsPerPageChange1 = (rows: number) => {
    setCurrPage1(1);
    setRowsPerPage1(rows);
    handleGetData1(1, rows, validation.values);
  };

  const handleRowsPerPageChange2 = (rows: number) => {
    setCurrPage2(1);
    setRowsPerPage2(rows);
    handleGetData2(1, rows, validation.values);
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb
            title="Campaigns"
            isBack={Boolean(subGroupId)}
            backClick={() =>
              props.router.navigate(
                subGroupId
                  ? `/groups/${groupId}/subgroups/${subGroupId}/${subgroupnames}`
                  : -1
              )
            }
            isFilter={true}
            filterButton={
              <Dropdown
                align="end"
                className="filterDropDown2"
                show={open}
                onToggle={() => {
                  if (!datePickerModal) {
                    setOpen(!open);
                  }
                }}
              >
                <Dropdown.Toggle variant="secondary">
                  <i className="bx bx-filter-alt me-1"></i>
                </Dropdown.Toggle>

                <Dropdown.Menu className="dropdown-menu-md p-4">
                  <Form
                    onSubmit={(e) => {
                      e.preventDefault();
                      setOpen(!open);
                      validation.handleSubmit();
                    }}
                  >
                    <Row>
                      <Col sm={6} className="mb-3">
                        <Form.Label htmlFor="subGroupId">Sub Group</Form.Label>
                        <GroupSelect
                          isDisabled={!groupDetails?.records?.[0]?.group_id}
                          key={groupDetails?.records?.[0]?.group_id}
                          isMulti={true}
                          isClearable={true}
                          isSearchable={true}
                          styles={styleObj(
                            validation?.touched?.subGroupId &&
                              validation?.errors?.subGroupId
                          )}
                          theme={customSelectTheme}
                          value={
                            validation.values.subGroupId?.length > 0
                              ? validation.values.subGroupId.map(
                                  (dt: string) => ({
                                    sub_group_id: dt,
                                  })
                                )
                              : null
                          }
                          name="subGroupId"
                          loadOptions={asyncSubgroupList(
                            removeEmptyAndNullValues({
                              groupId:
                                groupDetails?.records?.[0]?.group_id || "",
                            }),
                            "subGroupId"
                          )}
                          getOptionValue={(option: any) => option?.sub_group_id}
                          getOptionLabel={(option: any) =>
                            option?.sub_group_name +
                            " - " +
                            option?.sub_group_id
                          }
                          onChange={(option: any) => {
                            if (
                              option &&
                              option.sub_group_id !==
                                validation.values.subGroupId
                            ) {
                              validation.setFieldValue(
                                "subGroupId",
                                option.map((dt: any) => dt.sub_group_id)
                              );
                              validation.setFieldValue("brandId", []);
                              validation.setFieldValue("brandName", []);
                              validation.setFieldValue("campaignId", []);
                            } else if (!option) {
                              validation.setFieldValue("subGroupId", []);
                            }
                          }}
                          additional={{
                            page: 1,
                          }}
                        />
                      </Col>
                      <Col sm={6} className="mb-3">
                        <Form.Label htmlFor="brandId">Brand ID</Form.Label>
                        <BrandSelect
                          key={
                            validation.values.subGroupId.toString() ||
                            groupDetails?.records?.[0]?.group_id ||
                            "brandId"
                          }
                          isMulti={true}
                          isClearable={true}
                          isSearchable={true}
                          styles={styleObj(
                            validation?.touched?.brandId &&
                              validation?.errors?.brandId
                          )}
                          theme={customSelectTheme}
                          value={
                            validation.values.brandId?.length > 0
                              ? validation.values.brandId
                              : null
                          }
                          loadOptions={asyncBrandList(
                            removeEmptyAndNullValues({
                              subGroupId:
                                validation.values.subGroupId.length > 0
                                  ? validation.values.subGroupId
                                  : "",
                            }),
                            "brandId"
                          )}
                          getOptionValue={(option: any) => option?.brandId}
                          getOptionLabel={(option: any) =>
                            option?.displayName + " - " + option?.brandId
                          }
                          onChange={(option: any) => {
                            if (option) {
                              validation.setFieldValue(
                                "subGroupId",
                                option.reduce(
                                  (acc: string[], dt: any) => {
                                    if (
                                      dt?.subGroupId &&
                                      Array.isArray(dt.subGroupId)
                                    ) {
                                      dt.subGroupId.forEach(
                                        (subGroup: string) => {
                                          if (
                                            !acc.includes(subGroup) &&
                                            !validation.values.subGroupId.includes(
                                              subGroup
                                            )
                                          ) {
                                            acc.push(subGroup);
                                          }
                                        }
                                      );
                                    }
                                    return acc;
                                  },
                                  [...validation.values.subGroupId]
                                )
                              );
                              validation.setFieldValue(
                                "brandId",
                                option.map((dt: any) => ({
                                  subGroupId: dt?.subGroupId || [],
                                  brandId: dt?.brandId || "",
                                  displayName: dt?.displayName || "",
                                }))
                              );
                              validation.setFieldValue(
                                "brandName",
                                option.map((dt: any) => ({
                                  subGroupId: dt?.subGroupId || [],
                                  brandId: dt?.brandId || "",
                                  displayName: dt?.displayName || "",
                                }))
                              );
                              validation.setFieldValue("campaignId", []);
                            } else {
                              validation.setFieldValue("brandId", []);
                            }
                          }}
                          additional={{
                            page: 1,
                          }}
                        />
                      </Col>
                      <Col sm={6} className="mb-3">
                        <Form.Label htmlFor="brandName">Brand Name</Form.Label>
                        <AsyncPaginate
                          key={
                            validation.values.brandId
                              ?.map((dt: any) => dt.brandId)
                              .toString() ||
                            validation.values.subGroupId.toString() ||
                            groupDetails?.records?.[0]?.group_id ||
                            "brandName"
                          }
                          isMulti={true}
                          isClearable={true}
                          isSearchable={true}
                          styles={styleObj(
                            validation?.touched?.brandName &&
                              validation?.errors?.brandName
                          )}
                          theme={customSelectTheme}
                          value={
                            validation.values.brandName?.length > 0
                              ? validation.values.brandName
                              : null
                          }
                          loadOptions={asyncBrandList(
                            removeEmptyAndNullValues({
                              subGroupId:
                                validation.values.subGroupId.length > 0
                                  ? validation.values.subGroupId
                                  : "",
                              brandId:
                                validation.values.brandId.length > 0
                                  ? validation.values.brandId?.map(
                                      (dt: any) => dt.brandId
                                    )
                                  : "",
                            }),
                            "brandName"
                          )}
                          getOptionValue={(option: any) => option?.displayName}
                          getOptionLabel={(option: any) => option?.displayName}
                          onChange={(option: any) => {
                            if (option) {
                              validation.setFieldValue(
                                "subGroupId",
                                option.reduce(
                                  (acc: string[], dt: any) => {
                                    if (
                                      dt?.subGroupId &&
                                      Array.isArray(dt.subGroupId)
                                    ) {
                                      dt.subGroupId.forEach(
                                        (subGroup: string) => {
                                          if (
                                            !acc.includes(subGroup) &&
                                            !validation.values.subGroupId.includes(
                                              subGroup
                                            )
                                          ) {
                                            acc.push(subGroup);
                                          }
                                        }
                                      );
                                    }
                                    return acc;
                                  },
                                  [...validation.values.subGroupId]
                                )
                              );
                              validation.setFieldValue(
                                "brandId",
                                option.map((dt: any) => ({
                                  subGroupId: dt?.subGroupId || [],
                                  brandId: dt?.brandId || "",
                                  displayName: dt?.displayName || "",
                                }))
                              );
                              validation.setFieldValue(
                                "brandName",
                                option.map((dt: any) => ({
                                  subGroupId: dt?.subGroupId || [],
                                  brandId: dt?.brandId || "",
                                  displayName: dt?.displayName || "",
                                }))
                              );
                              validation.setFieldValue("campaignId", []);
                            } else {
                              validation.setFieldValue("brandName", []);
                            }
                          }}
                          additional={{
                            page: 1,
                          }}
                        />
                      </Col>
                      <Col sm={6} className="mb-3">
                        <Form.Label htmlFor="campaignId">
                          Campaign ID
                        </Form.Label>
                        <AsyncPaginate
                          key={
                            validation.values.brandId
                              ?.map((dt: any) => dt.brandId)
                              .toString() ||
                            validation.values.subGroupId.toString() ||
                            groupDetails?.records?.[0]?.group_id ||
                            "campaignId"
                          }
                          isMulti={true}
                          isClearable={true}
                          isSearchable={true}
                          styles={styleObj(
                            validation?.touched?.campaignId &&
                              validation?.errors?.campaignId
                          )}
                          theme={customSelectTheme}
                          value={
                            validation.values.campaignId?.length > 0
                              ? validation.values.campaignId
                              : null
                          }
                          loadOptions={asyncCampaignList(
                            removeEmptyAndNullValues({
                              subGroupId:
                                validation.values.subGroupId.length > 0
                                  ? validation.values.subGroupId
                                  : "",
                              brandId:
                                validation.values.brandId.length > 0
                                  ? validation.values.brandId?.map(
                                      (dt: any) => dt.brandId
                                    )
                                  : "",
                            }),
                            "campaignId"
                          )}
                          getOptionValue={(option: any) => option?.campaignId}
                          getOptionLabel={(option: any) => option?.campaignId}
                          onChange={(option: any) => {
                            if (option) {
                              validation.setFieldValue(
                                "subGroupId",
                                option.reduce(
                                  (acc: string[], dt: any) => {
                                    if (
                                      dt?.subGroupId &&
                                      Array.isArray(dt.subGroupId)
                                    ) {
                                      dt.subGroupId.forEach(
                                        (subGroup: string) => {
                                          if (
                                            !acc.includes(subGroup) &&
                                            !validation.values.subGroupId.includes(
                                              subGroup
                                            )
                                          ) {
                                            acc.push(subGroup);
                                          }
                                        }
                                      );
                                    }
                                    return acc;
                                  },
                                  [...validation.values.subGroupId]
                                )
                              );
                              validation.setFieldValue(
                                "brandId",
                                option.reduce(
                                  (acc: any[], dt: any) => {
                                    const existingItem =
                                      validation.values.brandId.find(
                                        (item: any) =>
                                          item.brandId === dt.brandId &&
                                          JSON.stringify(item.subGroupId) ===
                                            JSON.stringify(dt.subGroupId)
                                      );
                                    if (!existingItem) {
                                      acc.push({
                                        subGroupId: dt?.subGroupId || [],
                                        brandId: dt?.brandId || "",
                                        displayName:
                                          dt?.displayName ||
                                          dt?.brandName ||
                                          "",
                                      });
                                    }
                                    return acc;
                                  },
                                  [...validation.values.brandId]
                                )
                              );
                              validation.setFieldValue(
                                "brandName",
                                option.reduce(
                                  (acc: any[], dt: any) => {
                                    const existingItem =
                                      validation.values.brandId.find(
                                        (item: any) =>
                                          item.brandId === dt.brandId &&
                                          JSON.stringify(item.subGroupId) ===
                                            JSON.stringify(dt.subGroupId)
                                      );
                                    if (!existingItem) {
                                      acc.push({
                                        subGroupId: dt?.subGroupId || [],
                                        brandId: dt?.brandId || "",
                                        displayName:
                                          dt?.displayName ||
                                          dt?.brandName ||
                                          "",
                                      });
                                    }
                                    return acc;
                                  },
                                  [...validation.values.brandId]
                                )
                              );

                              validation.setFieldValue(
                                "campaignId",
                                option.map((dt: any) => ({
                                  subGroupId: dt?.subGroupId || [],
                                  brandId: dt?.brandId || "",
                                  displayName:
                                    dt?.displayName || dt?.brandName || "",
                                  campaignId: dt?.campaignId || "",
                                }))
                              );
                            } else {
                              validation.setFieldValue("campaignId", []);
                            }
                          }}
                          additional={{
                            page: 1,
                          }}
                        />
                      </Col>
                      <Col sm={6} className="mb-3">
                        <Form.Label htmlFor="dcaComplete">
                          DCA Election
                        </Form.Label>
                        <Select
                          name="dcaComplete"
                          isClearable={true}
                          styles={styleObj(
                            validation?.touched?.dcaComplete &&
                              validation?.errors?.dcaComplete
                          )}
                          theme={customSelectTheme}
                          options={dcaArr.map((dt) => ({
                            label: dt.label,
                            value: dt.value,
                          }))}
                          onChange={(e: { value: string }) => {
                            if (e) {
                              validation.setFieldValue("dcaComplete", e.value);
                            } else {
                              validation.setFieldValue("dcaComplete", "");
                            }
                          }}
                          value={
                            validation.values.dcaComplete
                              ? {
                                  label: dcaArr.find(
                                    (dt) =>
                                      dt.value === validation.values.dcaComplete
                                  )?.label,
                                  value: validation.values.dcaComplete,
                                }
                              : null
                          }
                        />
                      </Col>
                      <Col sm={6} className="mb-3">
                        <Form.Label htmlFor="tcrStatus">TCR Status</Form.Label>
                        <Select
                          name="tcrStatus"
                          isClearable={true}
                          styles={styleObj(
                            validation?.touched?.tcrStatus &&
                              validation?.errors?.tcrStatus
                          )}
                          theme={customSelectTheme}
                          options={["pending", "ACTIVE"].map((dt) => ({
                            label: capitalizeString(dt),
                            value: dt,
                          }))}
                          onChange={(e: { value: string }) => {
                            if (e) {
                              validation.setFieldValue("tcrStatus", e.value);
                            } else {
                              validation.setFieldValue("tcrStatus", "");
                            }
                          }}
                          value={
                            validation.values.tcrStatus
                              ? {
                                  label: capitalizeString(
                                    validation.values.tcrStatus
                                  ),
                                  value: validation.values.tcrStatus,
                                }
                              : null
                          }
                        />
                      </Col>
                      <Col sm={6} className="mb-3">
                        <Form.Label htmlFor="createdAt">Created At</Form.Label>
                        <Flatpickr
                          className="form-control"
                          name="createdAt"
                          placeholder="Created At"
                          options={{
                            enableTime: false,
                            onOpen: () => {
                              setDatePickerModal(true);
                            },
                            onClose: () => {
                              setDatePickerModal(false);
                            },
                            altFormat: "F j, Y",
                            dateFormat: "m-d-Y",
                            mode: "range",
                            onChange: (
                              value: any,
                              dateStr: string,
                              instance: any
                            ) => {
                              validation.setFieldValue(
                                "createdAt",
                                dateStr || ""
                              );
                              value?.[0] &&
                                validation.setFieldValue(
                                  "startDate",
                                  moment(value?.[0]).format("MM-DD-YYYY")
                                );
                              value?.[1] &&
                                validation.setFieldValue(
                                  "endDate",
                                  moment(value?.[1]).format("MM-DD-YYYY")
                                );
                            },
                          }}
                          value={validation.values.createdAt || ""}
                        />
                      </Col>
                    </Row>
                    <div className="d-flex align-items-center justify-content-end">
                      <Button
                        className="btn btn-secondary me-2"
                        type="button"
                        disabled={loading}
                        onClick={() => {
                          validation.resetForm();
                          if (searchParams.size > 0) {
                            props.router.navigate(
                              props.router.location.pathname,
                              { replace: true }
                            );
                          }
                          setDatePickerModal(false);
                          handleGetData1(currPage1, rowsPerPage1);
                          handleGetData2(currPage2, rowsPerPage2);
                        }}
                      >
                        Clear
                      </Button>
                      <Button
                        className="btn btn-primary"
                        type="submit"
                        disabled={loading}
                      >
                        {loading && <Spinner size="sm" animation="border" />}{" "}
                        Apply
                      </Button>
                    </div>
                  </Form>
                </Dropdown.Menu>
              </Dropdown>
            }
            isCreate={true}
            createLabel="Add new campaign"
            createLink="/campaigns/create"
          />
          <Tab.Container defaultActiveKey="campaign">
            <Nav
              as="ul"
              variant="tabs"
              className="nav-tabs-custom nav-primary mb-3"
            >
              <Nav.Item as="li">
                <Nav.Link eventKey="campaign">Campaigns</Nav.Link>
              </Nav.Item>
              <Nav.Item as="li">
                <Nav.Link eventKey="partner">CNP Campaigns</Nav.Link>
              </Nav.Item>
            </Nav>
            <Tab.Content>
              <Tab.Pane eventKey="campaign">
                <div className="position-relative">
                  <Card className="wrapper2">
                    <Card.Body className="listing-table3">
                      {loading ? (
                        <div className={``}>
                          <img
                            src={Loader}
                            className={`position-absolute top-50 start-50 translate-middle`}
                            alt="loading"
                          />
                        </div>
                      ) : (
                        <Datatable
                          data={
                            Array.isArray(campaignsData?.records)
                              ? campaignsData?.records
                              : []
                          }
                          columns={columns1}
                          handleRowClick={handleRowClick1}
                          handlePageChange={handlePageChange1}
                          handleRowsPerPageChange={handleRowsPerPageChange1}
                          currPage={currPage1}
                          rowsPerPage={rowsPerPage1}
                          totalRecords={campaignsData?.totalRecords}
                        />
                      )}
                    </Card.Body>
                  </Card>
                </div>
              </Tab.Pane>
              <Tab.Pane eventKey="partner">
                <div className="position-relative">
                  <Card className="wrapper2">
                    <Card.Body className="listing-table3">
                      {loading2 ? (
                        <div className={``}>
                          <img
                            src={Loader}
                            className={`position-absolute top-50 start-50 translate-middle`}
                            alt="loading"
                          />
                        </div>
                      ) : (
                        <Datatable
                          data={
                            Array.isArray(campaignsPartnerData?.records)
                              ? campaignsPartnerData?.records
                              : []
                          }
                          columns={columns2}
                          handleRowClick={handleRowClick2}
                          handlePageChange={handlePageChange2}
                          handleRowsPerPageChange={handleRowsPerPageChange2}
                          currPage={currPage2}
                          rowsPerPage={rowsPerPage2}
                          totalRecords={campaignsPartnerData?.totalRecords}
                        />
                      )}
                    </Card.Body>
                  </Card>
                </div>
              </Tab.Pane>
            </Tab.Content>
          </Tab.Container>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(CampaignsList);
