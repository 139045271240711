import { useFormik } from "formik";
import { useEffect } from "react";
import { Button, Form, Spinner } from "react-bootstrap";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { createSelector } from "reselect";
import {
  getNotificationEventsThunk,
  CreateNotificationEventsThunk,
} from "slices/thunk";
import EventSelectorNotification from "./EventSelectorNotification";
import Loader from "assets/images/spinner-dark.svg";
import { Container } from "react-bootstrap";
import BreadCrumb from "../../../../Common/BreadCrumb";

function NotificationEvents() {
  const dispatch = useDispatch<any>();

  const selector = createSelector(
    (state: any) => state.Notifications,
    (notifications: any) => ({
      loading: notifications.loading2,
      loading3: notifications.loading3,
      notificationEventsData: notifications?.notificationEvents,
      error: notifications.error,
    })
  );

  const { loading, loading3, notificationEventsData } = useSelector(selector);

  const validation: any = useFormik({
    enableReinitialize: true,
    initialValues: {
      notificationServices: [],
      notificationType: [],
      notifications: {},
    },
    validationSchema: Yup.object().shape({
      notificationServices: Yup.array()
        .min(1, "At least one event must be selected")
        .required("Events are required"),
    }),
    onSubmit: async (values, { resetForm }) => {
      const notificationArray = [] as any[];
      Object.entries(values.notifications).map((key: any) => {
        if (key[1].length > 0) {
          notificationArray.push({
            notificationService: key[0],
            notificationType: key[1],
          });
        }
      });
      const payload = {
        notificationServices: [...notificationArray],
      };
      const cb = () => {
        resetForm();
        dispatch(getNotificationEventsThunk());
      };
      dispatch(CreateNotificationEventsThunk(payload, cb));
    },
  });

  useEffect(() => {
    dispatch(getNotificationEventsThunk());
    return () => {
      validation.resetForm();
    };
  }, [dispatch]);

  useEffect(() => {
    if (notificationEventsData && notificationEventsData.data) {
      const services = [] as any[];
      const types = [] as any[];
      Object.entries(notificationEventsData?.data).forEach((key: any) => {
        key[1].forEach((data: any) => {
          if (data.enable) {
            services.push(data.notificationService);
            types.push(...data.notificationType);
          }
        });
      });

      validation.setFieldValue("notificationServices", services);
      validation.setFieldValue("notificationType", types);
    }
  }, [notificationEventsData]);

  return (
    <div className="page-content">
      <Container fluid>
        <BreadCrumb title={"Notification Events"} />

        <div className="position-relative wrapper">
          {loading ? (
            <div className={``}>
              <img
                src={Loader}
                className={`position-absolute top-50 start-50 translate-middle`}
                alt="loading"
              />
            </div>
          ) : (
            <Form
              onSubmit={(e) => {
                e.preventDefault();
                validation.handleSubmit();
              }}
            >
              <EventSelectorNotification
                validation={validation}
                formikEvents={validation.values.notificationServices}
                handleEventChange={(data: any, types: any) => {
                  validation.setFieldValue("notificationServices", data);
                  validation.setFieldValue("notifications", types);
                }}
              />
              <div className="hstack gap-2 justify-content-end">
                <Button variant="primary" type="submit" disabled={loading3}>
                  {loading3 && <Spinner size="sm" animation="border" />} Submit
                </Button>
              </div>
            </Form>
          )}
        </div>
      </Container>
    </div>
  );
}

export default NotificationEvents;
