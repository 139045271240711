import { APIClient } from "../../api_helper";

import * as url from "../../url_helper";

const api = new APIClient();

// get Events
export const getCategories = () => {
  return api.get(url.LOGIN, null);
};

export const setLocal = (key: string, data: any) => {
  localStorage.setItem(key, data);
};

export const getLocal = (key: string) => {
  const localData = localStorage.getItem(key);
  return localData;
};

export const removeLocal = (key: string) => {
  localStorage.removeItem(key);
};

export const setLoginData = (data: any) => {
  setLocal("ww:remlog", btoa(JSON.stringify(data)));
};

// get login data
export const getLoginData = () => {
  try {
    const data = getLocal("ww:remlog")?.toString();
    const logData = data && JSON.parse(atob(data));
    return logData;
  } catch (e) {
    return undefined;
  }
};

// clear login data
export const clearLoginData = () => {
  removeLocal("ww:remlog");
};

// logout
export const logout = () => {
  removeLocal("ww:remlog");
  window.location.href = "/";
};
