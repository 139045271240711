import withRouter from "Common/withRouter";
import React from "react";
import { Nav, Tab } from "react-bootstrap";
import PortRequests from "./PortRequests";
import PortOutRequests from "./PortOutRequests";

const PortNumbers = (props: any) => {
  return (
    <React.Fragment>
      <div className="page-content">
        <Tab.Container defaultActiveKey="porting-requests">
          <Nav
            as="ul"
            variant="tabs"
            className="nav-tabs-custom nav-primary mb-3"
          >
            <Nav.Item as="li">
              <Nav.Link eventKey="porting-requests">Porting Requests</Nav.Link>
            </Nav.Item>
            <Nav.Item as="li">
              <Nav.Link eventKey="port-out-requests">
                Port Out Requests
              </Nav.Link>
            </Nav.Item>
          </Nav>
          <Tab.Content className="text-muted">
            <Tab.Pane eventKey="porting-requests">
              <PortRequests />
            </Tab.Pane>
            <Tab.Pane eventKey="port-out-requests">
              <PortOutRequests />
            </Tab.Pane>
          </Tab.Content>
        </Tab.Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(PortNumbers);
