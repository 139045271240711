import React from "react";
import { Col, Container, Row } from "react-bootstrap";

const Footer = ({ isSubNavbar }: any) => {
  return (
    <React.Fragment>
      <footer className={`footer ${isSubNavbar ? "footer2" : ""}`}>
        <Container fluid>
          <Row>
            <Col sm={6}>
              {new Date().getFullYear()} © Signal House LLC. All Rights
              Reserved.
            </Col>
            {/* <Col sm={6}>
              <div className="text-sm-end d-none d-sm-block">
              </div>
            </Col> */}
          </Row>
        </Container>
      </footer>
    </React.Fragment>
  );
};

export default Footer;
