import { AsyncPaginate } from "react-select-async-paginate";
import { useEffect, useState, useRef } from "react";
import { subgroupListApi } from "helpers/apiroute_helper";

export const GroupSelect = (props: any) => {
  const [selectedValue, setSelectedValue] = useState<any>(null);
  const [isLoading, setIsLoading] = useState(false);
  const optionsCacheRef = useRef<Record<string, any>>({});

  const handleChange = (newValue: any) => {
    setSelectedValue(newValue);
    if (newValue) {
      if (Array.isArray(newValue)) {
        newValue.forEach((item: any) => {
          optionsCacheRef.current[item.sub_group_id] = item;
        });
      } else {
        optionsCacheRef.current[newValue.sub_group_id] = newValue;
      }
    }
    props.onChange?.(newValue);
  };

  console.log("selectedValue", optionsCacheRef);
  useEffect(() => {
    const fetchOptionData = async () => {
      if (!props.value) {
        setSelectedValue(null);
        return;
      }

      const checkCache = () => {
        if (Array.isArray(props.value)) {
          const cachedValues = props.value
            .map((val: any) => optionsCacheRef.current[val.sub_group_id])
            .filter(Boolean);
          return cachedValues.length === props.value.length
            ? cachedValues
            : null;
        }
        return props.value?.sub_group_id &&
          optionsCacheRef.current[props.value.sub_group_id]
          ? optionsCacheRef.current[props.value.sub_group_id]
          : null;
      };

      const cachedResult = checkCache();
      if (cachedResult) {
        setSelectedValue(cachedResult);
        return;
      }

      setIsLoading(true);
      try {
        const limit = Array.isArray(props.value) ? props.value.length : 1;
        const response: any = await subgroupListApi(1, limit, {
          subGroupId: Array.isArray(props.value)
            ? props.value?.map((dt: any) => dt.sub_group_id)
            : [props.value?.sub_group_id],
        });

        const records = Array.isArray(props.value)
          ? response?.records || []
          : response?.records?.[0] || null;

        if (Array.isArray(records)) {
          records.forEach((record: any) => {
            optionsCacheRef.current[record.sub_group_id] = record;
          });
        } else if (records?.sub_group_id) {
          optionsCacheRef.current[records.sub_group_id] = records;
        }

        setSelectedValue(records);
      } catch (error) {
        console.error("Error fetching option data:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchOptionData();
  }, [props.value]);

  return (
    <AsyncPaginate
      {...props}
      value={selectedValue}
      isLoading={isLoading}
      onChange={handleChange}
      additional={{
        page: 1,
      }}
    />
  );
};
