import { useEffect, useRef, useState } from "react";
import {
  Container,
  Card,
  Button,
  Form,
  Row,
  Col,
  Spinner,
} from "react-bootstrap";
import BreadCrumb from "Common/BreadCrumb";
import { useDispatch, useSelector } from "react-redux";
import {
  configAutoRechargeThunk,
  // getAllGroupsThunk,
  getAutoRechargeConfigThunk,
  getPaymentHistoryThunk,
  getPaymentMethodThunk,
  getUsageSummaryThunk,
  openModal,
  payWithPaymentMethodThunk,
  updateAuthUserThunk,
  updateAutoRechargeConfigThunk,
  // updateGroupThunk,
} from "slices/thunk";
import Datatable from "../../../Common/Datatable";
import Loader from "assets/images/spinner-dark.svg";
import { createSelector } from "reselect";
import moment from "moment";
import Select from "react-select";
import { Link } from "react-router-dom";
import {
  capitalizeString,
  customSelectTheme,
  dateFormat,
  getChangedValues,
  getCurrentDateTime,
  last12Months,
  removeEmptyAndNullValues,
  removeKeyFromObj,
  styleObj,
} from "helpers/common";
import { onCloseSubject, submitLoadingSubject } from "Common/modals/Modal";
import withRouter from "Common/withRouter";
import { City, Country, State } from "country-state-city";
import * as Yup from "yup";
import { useFormik } from "formik";
// import Radiobox from "Common/Radiobox";

const selectOpts = [...last12Months()];

const Billing = (props: any) => {
  const dispatch = useDispatch<any>();
  const inputRef = useRef<HTMLInputElement>(null);

  const [allCountry, setAllCountry] = useState<any>([]);
  const [allState, setAllState] = useState<any>([]);
  const [allCity, setAllCity] = useState<any>([]);
  // const [allState2, setAllState2] = useState<any>([]);
  // const [allCity2, setAllCity2] = useState<any>([]);
  // const [isChecked, setIsChecked] = useState(false);
  const [summaryObj, setSummaryObj] = useState<any>({});
  const [month, setMonth] = useState(
    `${moment().format("MM")}-${moment().format("YYYY")}`
  );
  const [month2, setMonth2] = useState(
    `${moment().format("MM")}-${moment().format("YYYY")}`
  );

  const selectProfile = createSelector(
    // (state: any) => state.Groups,
    (state: any) => state.AuthUser,
    (state: any) => state.Billing,
    (state: any) => state.UsageSummary,
    (
      // groups,
      authUser,
      billing,
      usageSummary
    ) => ({
      // groupDetails: groups?.AllGroups,
      loading4: authUser.loading,
      loading5: authUser.loading2,
      authUser: authUser?.authUser,
      loading2: billing.loading,
      autoRechargeData: billing.autoRechargeData,
      paymentHistoryData: billing.paymentHistoryData,
      error: billing.error,
      loading3: usageSummary.loading,
      usageSummaryObj: usageSummary.usageSummaryObj,
    })
  );

  const {
    // groupDetails,
    loading4,
    loading5,
    authUser,
    loading2,
    autoRechargeData,
    paymentHistoryData,
    loading3,
    usageSummaryObj,
  } = useSelector(selectProfile);

  const validation: any = useFormik({
    enableReinitialize: true,
    initialValues: {
      profilePictureLink: authUser?.profilePictureLink || "",
      firstName: authUser?.firstName || "",
      middleName: authUser?.middleName || "",
      lastName: authUser?.lastName || "",
      phone: authUser?.phone ? Number(authUser?.phone) : "",
      emailId: authUser?.emailId || "",
      companyName: authUser?.companyName || "",
      cspUid: authUser?.cspUid?.toUpperCase() || "",
      billingAddress: {
        addressLine1: authUser?.billingAddress?.addressLine1 || "",
        addressLine2: authUser?.billingAddress?.addressLine2 || "",
        city: authUser?.billingAddress?.city || "",
        state: authUser?.billingAddress?.state || "",
        country: authUser?.billingAddress?.country || "US",
        postalCode: authUser?.billingAddress?.postalCode || "",
      },
      shippingAddress: {
        addressLine1: authUser?.shippingAddress?.addressLine1 || "",
        addressLine2: authUser?.shippingAddress?.addressLine2 || "",
        city: authUser?.shippingAddress?.city || "",
        state: authUser?.shippingAddress?.state || "",
        country: authUser?.shippingAddress?.country || "US",
        postalCode: authUser?.shippingAddress?.postalCode || "",
      },
      timezone:
        authUser?.timezone ||
        Intl.DateTimeFormat().resolvedOptions().timeZone ||
        "",
      // groupName: authUser?.companyName || "",
      // groupDescription: "",
    },
    validationSchema: Yup.object({
      billingAddress: Yup.object().shape({
        addressLine1: Yup.string().required("Please Enter Adress Line 1"),
        city: Yup.string().required("Please Enter City"),
        state: Yup.string().required("Please Select State"),
        country: Yup.string().required("Please Enter Country"),
        postalCode: Yup.string().max(10).required("Please Enter Postal Code"),
      }),
      // addressLine2: Yup.string().required("Please Enter Adress Line 2"),
      // timezone: Yup.string().required("Please Select Timezone"),
      // groupName: Yup.string().required("Please Enter Group Name"),
    }),
    onSubmit: (values: any) => {
      const userInitValues = { ...validation.initialValues };
      // delete userInitValues["groupName"];
      // delete userInitValues["groupDescription"];

      const userValues = { ...values };
      // delete userValues["groupName"];
      // delete userValues["groupDescription"];

      const userChangedData = getChangedValues(userInitValues, userValues);
      dispatch(updateAuthUserThunk(userChangedData));

      // const grpInitValues = {
      //   groupName: groupDetails?.records?.[0]?.group_name,
      //   groupDescription: groupDetails?.records?.[0]?.group_description,
      //   groupContactPersonFirstName:
      //     groupDetails?.records?.[0]?.group_contact_person_first_name,
      //   groupContactPersonLastName:
      //     groupDetails?.records?.[0]?.group_contact_person_last_name,
      //   groupContactPersonMiddleName:
      //     groupDetails?.records?.[0]?.group_contact_person_middle_name,
      //   groupCompanyName: groupDetails?.records?.[0]?.group_company_name,
      //   addressLine1: groupDetails?.records?.[0]?.address_line1,
      //   addressLine2: groupDetails?.records?.[0]?.address_line2,
      //   city: groupDetails?.records?.[0]?.city,
      //   state: groupDetails?.records?.[0]?.state,
      //   country: groupDetails?.records?.[0]?.country,
      //   postalCode: groupDetails?.records?.[0]?.postal_code,
      //   groupContactPhoneNumber:
      //     groupDetails?.records?.[0]?.group_contact_phone_number,
      // };

      // const grpValues = {
      //   groupName: values.companyName,
      //   groupDescription: values.groupDescription,
      //   groupContactPersonFirstName: values.firstName,
      //   groupContactPersonLastName: values.lastName,
      //   groupContactPersonMiddleName: values.middleName,
      //   groupCompanyName: values.companyName,
      //   addressLine1: values.billingAddress.addressLine1,
      //   addressLine2: values.billingAddress.addressLine2,
      //   city: values.billingAddress.city,
      //   state: values.billingAddress.state,
      //   country: values.billingAddress.country,
      //   postalCode: values.billingAddress.postalCode,
      //   groupContactPhoneNumber: values.phone.toString(),
      // };

      // if (groupDetails?.records?.[0]?.group_id) {
      //   const grpChangedData = removeEmptyAndNullValues(
      //     getChangedValues(grpInitValues, grpValues)
      //   );
      //   dispatch(
      //     updateGroupThunk(
      //       removeEmptyAndNullValues({
      //         ...grpChangedData,
      //         groupContactPersonOrigin: "Web",
      //       }),
      //       groupDetails?.records?.[0]?.group_id,
      //       () => dispatch(getAllGroupsThunk({ page: 1, recordsPerPage: 10 }))
      //     )
      //   );
      // }
    },
  });

  const columns = [
    {
      name: <span className="font-weight-bold fs-sm">Date</span>,
      minWidth: "140px",
      selector: (row: { time?: string }) => row.time || "",
      cell: (row: { time?: string }) => (
        <span className="d-flex align-items-center">
          <i className="bi bi-calendar3 me-2 fs-lg text-secondary" />
          {row.time ? dateFormat(row.time) : "-"}
        </span>
      ),
      sortable: true,
      sortFunction: (a: { time?: string }, b: { time?: string }) =>
        (a.time ? new Date(a.time).getTime() : 0) -
        (b.time ? new Date(b.time).getTime() : 0),
    },
    {
      name: <span className="font-weight-bold fs-sm">Payment Details</span>,
      minWidth: "200px",
      selector: (row: {
        status?: string;
        paymentDetails?: { brand?: string; last4?: string };
      }) =>
        (row.status || "") +
        (row.paymentDetails?.brand || "") +
        (row.paymentDetails?.last4 || ""),
      cell: (row: {
        status?: string;
        paymentDetails?: { brand?: string; last4?: string };
      }) =>
        row?.status === "succeeded" && row.paymentDetails ? (
          <span className="d-flex align-items-center">
            <i
              className={`ri ri-${
                row.paymentDetails.brand || "credit-card"
              }-fill fs-4xl me-2`}
            />
            **** **** **** {row.paymentDetails.last4 || "****"}
          </span>
        ) : (
          "-"
        ),
      sortable: true,
    },
    {
      name: <span className="font-weight-bold fs-sm">Amount</span>,
      selector: (row: { amount?: number | string; currency?: string }) =>
        row.amount !== undefined ? Number(row.amount) : "",
      cell: (row: { amount?: number | string; currency?: string }) =>
        row.amount !== undefined && row.currency
          ? `${row.amount} ${row.currency.toUpperCase()}`
          : "-",
      sortable: true,
    },
  ];

  useEffect(() => {
    setAllCountry(Country.getAllCountries());
    dispatch(getAutoRechargeConfigThunk());
    dispatch(getPaymentMethodThunk());
  }, []);

  useEffect(() => {
    if (validation?.values?.billingAddress?.country) {
      const temp = State.getStatesOfCountry(
        validation?.values?.billingAddress?.country
      );
      setAllState(temp);
    }
  }, [validation?.values?.billingAddress?.country]);

  // useEffect(() => {
  //   if (validation?.values?.shippingAddress?.country) {
  //     const temp = State.getStatesOfCountry(
  //       validation?.values?.shippingAddress?.country
  //     );
  //     setAllState2(temp);
  //   }
  // }, [validation?.values?.shippingAddress?.country]);

  useEffect(() => {
    if (validation?.values?.billingAddress?.state) {
      const temp = City.getCitiesOfState(
        validation?.values?.billingAddress?.country,
        validation?.values?.billingAddress?.state
      );
      setAllCity(temp);
    }
  }, [validation?.values?.billingAddress?.state]);

  // useEffect(() => {
  //   if (validation?.values?.shippingAddress?.state) {
  //     const temp = City.getCitiesOfState(
  //       validation?.values?.shippingAddress?.country,
  //       validation?.values?.shippingAddress?.state
  //     );
  //     setAllCity2(temp);
  //   }
  // }, [validation?.values?.shippingAddress?.state]);

  // useEffect(() => {
  //   if (isChecked) {
  //     validation.setFieldValue(
  //       "shippingAddress",
  //       validation.values.billingAddress
  //     );
  //   } else {
  //     validation.setFieldValue(
  //       "shippingAddress",
  //       validation.initialValues.shippingAddress
  //     );
  //   }
  // }, [isChecked]);

  useEffect(() => {
    validation.setFieldValue(
      "shippingAddress",
      validation.values.billingAddress
    );
  }, [validation.values.billingAddress]);

  useEffect(() => {
    if (month) {
      const monthTemp: any = month?.split("-");
      const startDate = moment([monthTemp?.[1], monthTemp?.[0] - 1]);
      const endDate = moment(startDate).add(1, "month").subtract(1, "day");
      const formattedStartDate = startDate.format("YYYY-MM-DD");
      const formattedEndDate = endDate.format("YYYY-MM-DD");
      dispatch(getUsageSummaryThunk(formattedStartDate, formattedEndDate));
    }
  }, [month]);

  useEffect(() => {
    if (month2) {
      const monthTemp: any = month2?.split("-");
      const startDate = moment([monthTemp?.[1], monthTemp?.[0] - 1]);
      const endDate = moment(startDate).add(1, "month").subtract(1, "day");
      const formattedStartDate = startDate.format("YYYY-MM-DD");
      const formattedEndDate = endDate.format("YYYY-MM-DD");
      dispatch(getPaymentHistoryThunk(1, 5));
    }
  }, [month2]);

  useEffect(() => {
    console.log("********00000", inputRef.current);
    if (inputRef.current) {
      const autocomplete = new google.maps.places.Autocomplete(
        inputRef.current,
        {
          types: ["geocode"], // Restrict the search to geographical location types
          // types: ["address"], // Restrict to address types
          // fields: ["address_components"], // Get only address components
        }
      );

      autocomplete.addListener("place_changed", () => {
        console.log("********11111");
        const place = autocomplete.getPlace();
        if (place.address_components) {
          const addressComponents = place.address_components;
          const addressDetails = {
            street: "",
            area: "",
            city: "",
            state: "",
            country: "",
            zipcode: "",
          };

          addressComponents?.forEach((component: any) => {
            const types = component.types;
            if (types.includes("sublocality_level_1")) {
              addressDetails.area = component.long_name;
            } else if (types.includes("locality")) {
              addressDetails.city = component.long_name;
            } else if (types.includes("administrative_area_level_1")) {
              addressDetails.state = component.short_name;
            } else if (types.includes("country")) {
              addressDetails.country = component.short_name;
            } else if (types.includes("postal_code")) {
              addressDetails.zipcode = component.long_name;
            } else if (
              types.includes("street_number") ||
              types.includes("route")
              // || types.includes("neighborhood")
            ) {
              addressDetails.street += ` ${component.long_name}`;
            }
          });

          validation.setFieldValue(
            "billingAddress.addressLine1",
            addressDetails.street?.trim() || ""
          );
          validation.setFieldValue(
            "billingAddress.addressLine2",
            addressDetails.area || ""
          );
          validation.setFieldValue(
            "billingAddress.city",
            addressDetails.city || ""
          );
          validation.setFieldValue(
            "billingAddress.state",
            addressDetails.state || ""
          );
          validation.setFieldValue(
            "billingAddress.country",
            addressDetails.country || ""
          );
          validation.setFieldValue(
            "billingAddress.postalCode",
            addressDetails.zipcode || ""
          );
        }
      });
    }
  }, [inputRef.current]);

  useEffect(() => {
    if (Object.keys(usageSummaryObj).length > 0) {
      const temp = removeKeyFromObj(usageSummaryObj?.total, [
        "total",
        "transactions",
      ]);
      setSummaryObj(temp);
    }
  }, [usageSummaryObj]);

  const handlePincodeBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    validation.handleBlur(event);

    const postalCode = validation.values.billingAddress.postalCode;

    if (postalCode) {
      const geocoder = new google.maps.Geocoder();
      geocoder.geocode(
        {
          componentRestrictions: {
            postalCode: postalCode,
          },
        },
        (results: any, status) => {
          if (status === google.maps.GeocoderStatus.OK && results[0]) {
            let city = "";
            let country = "";
            let state = "";

            for (let component of results[0].address_components) {
              if (component.types.includes("locality")) {
                city = component.long_name;
              }
              if (component.types.includes("country")) {
                country = component.short_name;
              }
              if (component.types.includes("administrative_area_level_1")) {
                state = component.short_name;
              }
            }

            if (city) validation.setFieldValue("billingAddress.city", city);
            if (country)
              validation.setFieldValue("billingAddress.country", country);
            if (state) validation.setFieldValue("billingAddress.state", state);
          } else {
            console.error(
              "Geocode was not successful for the following reason: " + status
            );
          }
        }
      );
    }
  };

  const handleCancelClick = () => {
    validation.resetForm();
  };

  const addFundsSubmit = ({ values, resetForm }: any) => {
    const cb = () => {
      onCloseSubject.next({});
      resetForm();
    };

    submitLoadingSubject.next(true);
    dispatch(
      payWithPaymentMethodThunk(values?.paymentMethodId, +values?.amount, cb)
    ).then(() => {
      submitLoadingSubject.next(false);
    });
  };

  const autoRechargeSubmit = ({ values, resetForm }: any) => {
    const cb = () => {
      onCloseSubject.next({});
      resetForm();
    };

    submitLoadingSubject.next(true);

    if (
      autoRechargeData?.enabled !== true &&
      autoRechargeData?.enabled !== false
    ) {
      dispatch(configAutoRechargeThunk(values, cb)).then(() => {
        submitLoadingSubject.next(false);
      });
    } else {
      dispatch(updateAutoRechargeConfigThunk(values, cb)).then(() => {
        submitLoadingSubject.next(false);
      });
    }
  };

  return (
    <div className="page-content">
      <Container fluid>
        <BreadCrumb title="Billing" />

        <Card>
          <Card.Header>
            <h6 className="card-title mb-0">Billing Information</h6>
          </Card.Header>
          <Card.Body>
            <div className="d-flex justify-content-between align-items-center mb-3">
              <h6 className="mb-0 me-3">Current Balance</h6>
              <div className="text-end">
                {authUser?.balance ? (
                  <p
                    className={`fs-3xl mb-0 ${
                      Math.sign(authUser?.balance) === -1
                        ? "text-primary"
                        : "text-secondary"
                    }`}
                  >
                    <b>
                      {Math.sign(authUser?.balance) === -1 ? "-" : "+"}
                      {(+authUser?.balance)?.toFixed(2)}
                    </b>
                  </p>
                ) : (
                  <p>-</p>
                )}
              </div>
            </div>
            <div className="d-flex justify-content-between align-items-center mb-3">
              <h6 className="mb-0 me-3">Auto Recharge</h6>
              <div className="d-flex justify-content-end align-items-center">
                <div
                  className="form-check form-switch form-switch-lg"
                  dir="ltr"
                >
                  <Form.Check
                    type="checkbox"
                    role="switch"
                    id="autoRechargeData"
                    disabled={
                      autoRechargeData?.enabled !== true &&
                      autoRechargeData?.enabled !== false
                    }
                    checked={autoRechargeData?.enabled}
                    onChange={() => {
                      dispatch(
                        openModal({
                          modalbody: `Are you sure you want to <b>${
                            autoRechargeData?.enabled ? "disable" : "enable"
                          } Auto Recharge</b>?`,
                          data: {
                            title: "Auto Recharge",
                            footer: true,
                            cancelBtn: true,
                            buttonText: {
                              submit: "Continue",
                            },
                            onSubmit: ({ onClose }: any) => {
                              submitLoadingSubject.next(true);
                              const cb = () => onClose();
                              dispatch(
                                updateAutoRechargeConfigThunk(
                                  {
                                    enabled: !autoRechargeData?.enabled,
                                  },
                                  cb
                                )
                              ).then(() => {
                                submitLoadingSubject.next(false);
                              });
                            },
                          },
                        })
                      );
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="d-flex justify-content-between align-items-center">
              <h6 className="mb-0 me-3">Invoice</h6>
              <div className="d-flex justify-content-end align-items-center">
                <Select
                  styles={styleObj(false)}
                  theme={customSelectTheme}
                  className="me-2"
                  options={[{ label: "Feb 2024", value: "Feb 2024" }]}
                  onChange={(e: any) => {
                    // e.value
                  }}
                  value={{
                    label: "Feb 2024",
                    value: "Feb 2024",
                  }}
                />
                <Select
                  styles={styleObj(false)}
                  theme={customSelectTheme}
                  className="me-2"
                  options={[
                    { label: "PDF", value: "PDF" },
                    { label: "CSV", value: "CSV" },
                  ]}
                  onChange={(e: any) => {
                    // e.value
                  }}
                  value={{
                    label: "PDF",
                    value: "PDF",
                  }}
                />
                <Link to="#" className="d-flex justify-content-end">
                  Download
                </Link>
              </div>
            </div>
          </Card.Body>
        </Card>

        <Card>
          <Card.Body>
            <div className="d-flex justify-content-between align-items-center">
              <div className="me-3">
                <h6 className="mb-1 fs-lg fw-semibold">Add Funds</h6>
                <p className="text-muted mb-0">
                  Upgrade your wallet balance instantly by adding funds.
                </p>
              </div>
              <div className="d-flex justify-content-end align-items-center">
                <Button
                  variant="secondary"
                  className="d-flex align-items-center"
                  onClick={() => {
                    dispatch(
                      openModal({
                        path: "AddFunds",
                        data: {
                          title: "Add Funds",
                          addFundsSubmit,
                          footer: false,
                        },
                      })
                    );
                  }}
                >
                  <i className="bx bx-plus me-1"></i> Add Funds
                </Button>
              </div>
            </div>
          </Card.Body>
        </Card>

        <Card>
          <Card.Body>
            <div className="d-flex justify-content-between align-items-center">
              <div className="me-3">
                <h6 className="mb-1 fs-lg fw-semibold">Add Payment Method</h6>
                <p className="text-muted mb-0">
                  Expand your payment options with ease. Add a new payment
                  method quickly!
                </p>
              </div>
              <div className="d-flex justify-content-end align-items-center">
                <Button
                  variant="secondary"
                  className="d-flex align-items-center"
                  onClick={() => {
                    dispatch(
                      openModal({
                        path: "AddPaymentMethod",
                        data: {
                          title: "Add Payment Method",
                          footer: false,
                        },
                      })
                    );
                  }}
                >
                  <i className="bx bx-plus me-1"></i> Add Payment Method
                </Button>
              </div>
            </div>
          </Card.Body>
        </Card>

        <Card>
          <Card.Body>
            <div className="d-flex justify-content-between align-items-center">
              <div className="me-3">
                <h6 className="mb-1 fs-lg fw-semibold">
                  Auto Recharge Setting
                </h6>
                <p className="text-muted mb-0">
                  Never run out of balance! Set up auto-recharge for seamless
                  top-ups and uninterrupted service.
                </p>
              </div>
              <div className="d-flex justify-content-end align-items-center">
                <Button
                  variant="secondary"
                  className="d-flex align-items-center"
                  onClick={() => {
                    dispatch(
                      openModal({
                        path: "AutoRecharge",
                        data: {
                          title: "Auto Recharge",
                          autoRechargeSubmit,
                          footer: false,
                        },
                      })
                    );
                  }}
                >
                  <i className="bx bx-plus me-1"></i> Auto Recharge
                </Button>
              </div>
            </div>
          </Card.Body>
        </Card>

        <Form
          onSubmit={(e) => {
            e.preventDefault();
            validation.handleSubmit();
          }}
        >
          <Card>
            <Card.Header>
              <h6 className="card-title mb-0">Billing Address</h6>
            </Card.Header>
            <Card.Body>
              <Row className="border bg-light-gray rounded-3 p-3">
                <Col lg={6} className="mb-3">
                  <Form.Label htmlFor="billingAddress.addressLine1">
                    Address Line 1 <span className="text-primary">*</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    autoComplete="off"
                    ref={inputRef}
                    id="billingAddress.addressLine1"
                    name="billingAddress.addressLine1"
                    placeholder="Search for address"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={
                      validation?.values?.billingAddress?.addressLine1 || ""
                    }
                    isInvalid={
                      validation?.touched?.billingAddress?.addressLine1 &&
                      validation?.errors?.billingAddress?.addressLine1
                        ? true
                        : false
                    }
                  />
                  {validation?.touched?.billingAddress?.addressLine1 &&
                  validation?.errors?.billingAddress?.addressLine1 ? (
                    <Form.Control.Feedback type="invalid">
                      {validation?.errors?.billingAddress?.addressLine1}
                    </Form.Control.Feedback>
                  ) : null}
                </Col>
                <Col lg={6} className="mb-3">
                  <Form.Label htmlFor="billingAddress.addressLine2">
                    Address Line 2
                  </Form.Label>
                  <Form.Control
                    type="text"
                    id="billingAddress.addressLine2"
                    name="billingAddress.addressLine2"
                    placeholder="Enter address line2"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={
                      validation?.values?.billingAddress?.addressLine2 || ""
                    }
                    isInvalid={
                      validation?.touched?.billingAddress?.addressLine2 &&
                      validation?.errors?.billingAddress?.addressLine2
                        ? true
                        : false
                    }
                  />
                  {validation?.touched?.billingAddress?.addressLine2 &&
                  validation?.errors?.billingAddress?.addressLine2 ? (
                    <Form.Control.Feedback type="invalid">
                      {validation?.errors?.billingAddress?.addressLine2}
                    </Form.Control.Feedback>
                  ) : null}
                </Col>
                <Col lg={3} className="mb-3">
                  <Form.Label htmlFor="billingAddress.country">
                    Country <span className="text-primary">*</span>
                  </Form.Label>
                  <Select
                    menuPortalTarget={document.body}
                    name="billingAddress.country"
                    styles={styleObj(
                      validation?.touched?.billingAddress?.country &&
                        validation?.errors?.billingAddress?.country
                    )}
                    theme={customSelectTheme}
                    isClearable={true}
                    options={
                      allCountry?.length > 0
                        ? allCountry?.map(
                            (dt: { name: string; isoCode: string }) => ({
                              label: dt?.name,
                              value: dt?.isoCode,
                            })
                          )
                        : []
                    }
                    onChange={(e: { value?: string }) => {
                      if (e) {
                        validation.setFieldValue(
                          "billingAddress.country",
                          e.value ? e.value : ""
                        );
                        validation.setFieldValue("billingAddress.state", "");
                        validation.setFieldValue("billingAddress.city", "");
                        validation.setFieldValue(
                          "billingAddress.postalCode",
                          ""
                        );
                      } else {
                        validation.setFieldValue("billingAddress.country", "");
                      }
                    }}
                    value={{
                      label: allCountry?.find(
                        (dt: { isoCode: string }) =>
                          dt?.isoCode ===
                          validation?.values?.billingAddress?.country
                      )?.name,
                      value: validation?.values?.billingAddress?.country,
                    }}
                  />
                  {validation?.touched?.billingAddress?.country &&
                  validation?.errors?.billingAddress?.country ? (
                    <Form.Control.Feedback type="invalid" className="d-block">
                      {validation?.errors?.billingAddress?.country}
                    </Form.Control.Feedback>
                  ) : null}
                </Col>
                <Col lg={3} className="mb-3">
                  <Form.Label htmlFor="billingAddress.state">
                    State <span className="text-primary">*</span>
                  </Form.Label>
                  <Select
                    menuPortalTarget={document.body}
                    name="billingAddress.state"
                    styles={styleObj(
                      validation?.touched?.billingAddress?.state &&
                        validation?.errors?.billingAddress?.state
                    )}
                    theme={customSelectTheme}
                    // isDisabled={!validation?.values?.billingAddress?.country}
                    isClearable={true}
                    options={
                      allState?.length > 0
                        ? allState?.map(
                            (dt: { name: string; isoCode: string }) => ({
                              label: dt?.name,
                              value: dt?.isoCode,
                            })
                          )
                        : []
                    }
                    onChange={(e: { value?: string }) => {
                      if (e) {
                        validation.setFieldValue(
                          "billingAddress.state",
                          e.value ? e.value : ""
                        );
                        validation.setFieldValue("billingAddress.city", "");
                        validation.setFieldValue(
                          "billingAddress.postalCode",
                          ""
                        );
                      } else {
                        validation.setFieldValue("billingAddress.state", "");
                      }
                    }}
                    value={{
                      label: allState?.find(
                        (dt: { isoCode: string }) =>
                          dt?.isoCode ===
                          validation?.values?.billingAddress?.state
                      )?.name,
                      value: validation?.values?.billingAddress?.state,
                    }}
                  />
                  {validation?.touched?.billingAddress?.state &&
                  validation?.errors?.billingAddress?.state ? (
                    <Form.Control.Feedback type="invalid" className="d-block">
                      {validation?.errors?.billingAddress?.state}
                    </Form.Control.Feedback>
                  ) : null}
                </Col>
                <Col lg={3} className="mb-3">
                  <Form.Label htmlFor="billingAddress.city">
                    City <span className="text-primary">*</span>
                  </Form.Label>
                  <Select
                    menuPortalTarget={document.body}
                    name="billingAddress.city"
                    styles={styleObj(
                      validation?.touched?.billingAddress?.city &&
                        validation?.errors?.billingAddress?.city
                    )}
                    theme={customSelectTheme}
                    // isDisabled={!validation?.values?.billingAddress?.state}
                    isClearable={true}
                    options={
                      allCity?.length > 0
                        ? allCity?.map((dt: { name: string }) => ({
                            label: dt?.name,
                            value: dt?.name,
                          }))
                        : []
                    }
                    onChange={(e: { value?: string }) => {
                      if (e) {
                        validation.setFieldValue(
                          "billingAddress.city",
                          e.value ? e.value : ""
                        );
                        validation.setFieldValue(
                          "billingAddress.postalCode",
                          ""
                        );
                      } else {
                        validation.setFieldValue("billingAddress.city", "");
                      }
                    }}
                    value={{
                      label: validation?.values?.billingAddress?.city,
                      value: validation?.values?.billingAddress?.city,
                    }}
                  />
                  {validation?.touched?.billingAddress?.city &&
                  validation?.errors?.billingAddress?.city ? (
                    <Form.Control.Feedback type="invalid" className="d-block">
                      {validation?.errors?.billingAddress?.city}
                    </Form.Control.Feedback>
                  ) : null}
                </Col>
                <Col lg={3} className="mb-3">
                  <Form.Label htmlFor="billingAddress.postalCode">
                    Zip Code <span className="text-primary">*</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    id="billingAddress.postalCode"
                    name="billingAddress.postalCode"
                    placeholder="Enter zipcode"
                    onChange={validation.handleChange}
                    onBlur={handlePincodeBlur}
                    value={validation?.values?.billingAddress?.postalCode || ""}
                    isInvalid={
                      validation?.touched?.billingAddress?.postalCode &&
                      validation?.errors?.billingAddress?.postalCode
                        ? true
                        : false
                    }
                  />
                  {validation?.touched?.billingAddress?.postalCode &&
                  validation?.errors?.billingAddress?.postalCode ? (
                    <Form.Control.Feedback type="invalid">
                      {validation?.errors?.billingAddress?.postalCode}
                    </Form.Control.Feedback>
                  ) : null}
                </Col>
              </Row>
              <div className="hstack gap-2 justify-content-end mt-4">
                <Button
                  className="btn btn-outline-primary"
                  onClick={handleCancelClick}
                >
                  Cancel
                </Button>
                <Button
                  type="submit"
                  variant="primary"
                  disabled={loading4 || loading5}
                >
                  {loading5 && <Spinner size="sm" animation="border" />} Update
                </Button>
              </div>
              {/* <div className="mt-3 mb-1">
              <h5>Shipping Address</h5>
            </div>
            <div lg={12}>
              <Row className="border bg-light-gray rounded-3 p-3">
                <Col lg={12} className="mb-2">
                  <Form.Label htmlFor="portingRequestType">
                    Same as Billing Address
                  </Form.Label>
                  <div className="d-flex">
                    <div className="me-5">
                      <Radiobox
                        label="Yes"
                        id="badress-yes"
                        name="isChecked"
                        value="True"
                        checked={isChecked}
                        onChange={() => setIsChecked(true)}
                      />
                    </div>
                    <div>
                      <Radiobox
                        label="No"
                        id="badress-no"
                        name="isChecked"
                        value="False"
                        checked={!isChecked}
                        onChange={() => {
                          setIsChecked(false);
                        }}
                      />
                    </div>
                  </div>
                </Col>
                <Col lg={6} className="mb-3">
                  <Form.Label htmlFor="shippingAddress.addressLine1">
                    Address Line 1
                  </Form.Label>
                  <Form.Control
                    type="text"
                    id="shippingAddress.addressLine1"
                    name="shippingAddress.addressLine1"
                    placeholder="Enter address line1"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={
                      validation?.values?.shippingAddress?.addressLine1 || ""
                    }
                    isInvalid={
                      validation?.touched?.shippingAddress?.addressLine1 &&
                      validation?.errors?.shippingAddress?.addressLine1
                        ? true
                        : false
                    }
                  />
                  {validation?.touched?.shippingAddress?.addressLine1 &&
                  validation?.errors?.shippingAddress?.addressLine1 ? (
                    <Form.Control.Feedback type="invalid">
                      {validation?.errors?.shippingAddress?.addressLine1}
                    </Form.Control.Feedback>
                  ) : null}
                </Col>
                <Col lg={6} className="mb-3">
                  <Form.Label htmlFor="shippingAddress.addressLine2">
                    Address Line 2
                  </Form.Label>
                  <Form.Control
                    type="text"
                    id="shippingAddress.addressLine2"
                    name="shippingAddress.addressLine2"
                    placeholder="Enter address line2"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={
                      validation?.values?.shippingAddress?.addressLine2 || ""
                    }
                    isInvalid={
                      validation?.touched?.shippingAddress?.addressLine2 &&
                      validation?.errors?.shippingAddress?.addressLine2
                        ? true
                        : false
                    }
                  />
                  {validation?.touched?.shippingAddress?.addressLine2 &&
                  validation?.errors?.shippingAddress?.addressLine2 ? (
                    <Form.Control.Feedback type="invalid">
                      {validation?.errors?.shippingAddress?.addressLine2}
                    </Form.Control.Feedback>
                  ) : null}
                </Col>
                <Col lg={3} className="mb-3">
                  <Form.Label htmlFor="shippingAddress.city">City</Form.Label>
                  <Select
                    menuPortalTarget={document.body}
                    name="shippingAddress.city"
                    styles={styleObj(validation?.touched?.shippingAddress?.city && validation?.errors?.shippingAddress?.city)}
                    theme={customSelectTheme}
                    isDisabled={!validation?.values?.shippingAddress?.state}
                    options={
                      allCity2?.length > 0
                        ? allCity2?.map((dt: { name: string }) => ({
                            label: dt?.name,
                            value: dt?.name,
                          }))
                        : []
                    }
                    onChange={(e: { value: string }) => {
                      validation.setFieldValue("shippingAddress.city", e.value);
                      validation.setFieldValue(
                        "shippingAddress.postalCode",
                        ""
                      );
                    }}
                    value={{
                      label: validation?.values?.shippingAddress?.city,
                      value: validation?.values?.shippingAddress?.city,
                    }}
                  />
                  {validation?.touched?.shippingAddress?.city &&
                  validation?.errors?.shippingAddress?.city ? (
                    <Form.Control.Feedback type="invalid" className="d-block">
                      {validation?.errors?.shippingAddress?.city}
                    </Form.Control.Feedback>
                  ) : null}
                </Col>
                <Col lg={3} className="mb-3">
                  <Form.Label htmlFor="shippingAddress.state">State</Form.Label>
                  <Select
                    menuPortalTarget={document.body}
                    name="shippingAddress.state"
                    styles={styleObj(validation?.touched?.shippingAddress?.state && validation?.errors?.shippingAddress?.state)}
                    theme={customSelectTheme}
                    isDisabled={!validation?.values?.shippingAddress?.country}
                    options={
                      allState2?.length > 0
                        ? allState2?.map(
                            (dt: { name: string; isoCode: string }) => ({
                              label: dt?.name,
                              value: dt?.isoCode,
                            })
                          )
                        : []
                    }
                    onChange={(e: { value: string }) => {
                      validation.setFieldValue(
                        "shippingAddress.state",
                        e.value
                      );
                      validation.setFieldValue("shippingAddress.city", "");
                      validation.setFieldValue(
                        "shippingAddress.postalCode",
                        ""
                      );
                    }}
                    value={{
                      label: allState2?.find(
                        (dt: { isoCode: string }) =>
                          dt?.isoCode ===
                          validation?.values?.shippingAddress?.state
                      )?.name,
                      value: validation?.values?.shippingAddress?.state,
                    }}
                  />
                  {validation?.touched?.shippingAddress?.state &&
                  validation?.errors?.shippingAddress?.state ? (
                    <Form.Control.Feedback type="invalid" className="d-block">
                      {validation?.errors?.shippingAddress?.state}
                    </Form.Control.Feedback>
                  ) : null}
                </Col>
                <Col lg={3} className="mb-3">
                  <Form.Label htmlFor="shippingAddress.country">
                    Country
                  </Form.Label>
                  <Select
                    menuPortalTarget={document.body}
                    name="shippingAddress.country"
                    styles={styleObj(validation?.touched?.shippingAddress?.country && validation?.errors?.shippingAddress?.country)}
                    theme={customSelectTheme}
                    options={
                      allCountry?.length > 0
                        ? allCountry?.map(
                            (dt: { name: string; isoCode: string }) => ({
                              label: dt?.name,
                              value: dt?.isoCode,
                            })
                          )
                        : []
                    }
                    onChange={(e: { value: string }) => {
                      validation.setFieldValue(
                        "shippingAddress.country",
                        e.value ? e.value : ""
                      );
                      validation.setFieldValue("shippingAddress.state", "");
                      validation.setFieldValue("shippingAddress.city", "");
                      validation.setFieldValue(
                        "shippingAddress.postalCode",
                        ""
                      );
                    }}
                    value={{
                      label: allCountry?.find(
                        (dt: { isoCode: string }) =>
                          dt?.isoCode ===
                          validation?.values?.shippingAddress?.country
                      )?.name,
                      value: validation?.values?.shippingAddress?.country,
                    }}
                  />
                  {validation?.touched?.shippingAddress?.country &&
                  validation?.errors?.shippingAddress?.country ? (
                    <Form.Control.Feedback type="invalid" className="d-block">
                      {validation?.errors?.shippingAddress?.country}
                    </Form.Control.Feedback>
                  ) : null}
                </Col>
                <Col lg={3} className="mb-3">
                  <Form.Label htmlFor="shippingAddress.postalCode">
                    Zip Code
                  </Form.Label>
                  <Form.Control
                    type="text"
                    id="shippingAddress.postalCode"
                    name="shippingAddress.postalCode"
                    placeholder="Enter zipcode"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={
                      validation?.values?.shippingAddress?.postalCode || ""
                    }
                    isInvalid={
                      validation?.touched?.shippingAddress?.postalCode &&
                      validation?.errors?.shippingAddress?.postalCode
                        ? true
                        : false
                    }
                  />
                  {validation?.touched?.shippingAddress?.postalCode &&
                  validation?.errors?.shippingAddress?.postalCode ? (
                    <Form.Control.Feedback type="invalid">
                      {validation?.errors?.shippingAddress?.postalCode}
                    </Form.Control.Feedback>
                  ) : null}
                </Col>
              </Row>
            </div> */}
            </Card.Body>
          </Card>
        </Form>

        <Card>
          <Card.Header className="d-sm-flex align-items-center justify-content-between">
            <h6 className="card-title mb-2">Usage Summary</h6>
            <div className="d-flex align-items-center">
              <p className="mb-0 me-2">As of {getCurrentDateTime()}</p>
              <Select
                styles={styleObj(false)}
                theme={customSelectTheme}
                options={selectOpts}
                onChange={(e: any) => {
                  setMonth(e.value);
                }}
                value={selectOpts?.find((dt) => dt?.value === month)}
              />
            </div>
          </Card.Header>
          <Card.Body className="position-relative">
            {loading3 ? (
              <div style={{ height: 60 }}>
                <img
                  src={Loader}
                  className={`position-absolute top-50 start-50 translate-middle`}
                  alt="loading"
                />
              </div>
            ) : (
              <div className="table-responsive">
                <table className="table table-borderless table-sm align-middle mb-0">
                  <tbody>
                    <tr>
                      <th className="ps-0" scope="row">
                        <b>Product</b>
                      </th>
                      <th className="text-end">
                        <b>Cost</b>
                      </th>
                    </tr>
                    <tr>
                      <th className="ps-0" scope="row">
                        <b>Total</b>
                      </th>
                      <td className="text-muted text-end">
                        <b>
                          $
                          {usageSummaryObj?.total?.total
                            ? (+usageSummaryObj?.total?.total)?.toFixed(4)
                            : 0}
                        </b>
                      </td>
                    </tr>
                    {Object.keys(summaryObj)?.length > 0 ? (
                      Object.keys(summaryObj).map((dt, i) => (
                        <tr key={i}>
                          <td className="ps-0" scope="row">
                            <Link
                              to={`/settings/usage-transactions?type=${dt}`}
                            >
                              {dt === "SMS" || dt === "MMS"
                                ? dt.toUpperCase()
                                : capitalizeString(dt)}
                            </Link>
                          </td>
                          <td className="text-muted text-end">
                            $
                            {usageSummaryObj?.total?.[dt]?.total
                              ? (+usageSummaryObj?.total?.[dt]?.total)?.toFixed(
                                  4
                                )
                              : 0}
                          </td>
                        </tr>
                      ))
                    ) : (
                      <p className="mt-3">No Record Found</p>
                    )}
                  </tbody>
                </table>
              </div>
            )}
            <Link
              to="/settings/billing/usage-summary"
              className="d-flex justify-content-end mt-2"
            >
              View all product usage
            </Link>
          </Card.Body>
        </Card>

        <Card>
          <Card.Header className="d-flex align-items-center justify-content-between">
            <h6 className="card-title mb-0">Payment History</h6>
            <div className="d-flex">
              <Select
                styles={styleObj(false)}
                theme={customSelectTheme}
                options={selectOpts}
                onChange={(e: any) => {
                  setMonth2(e.value);
                }}
                value={selectOpts?.find((dt) => dt?.value === month2)}
              />
            </div>
          </Card.Header>
          <Card.Body className="position-relative">
            {loading2 ? (
              <div style={{ height: 60 }}>
                <img
                  src={Loader}
                  className={`position-absolute top-50 start-50 translate-middle`}
                  alt="loading"
                />
              </div>
            ) : (
              <Datatable
                data={
                  Array.isArray(paymentHistoryData?.records)
                    ? paymentHistoryData?.records
                    : []
                }
                columns={columns}
                pagination={false}
              />
            )}
            <Link
              to="/settings/billing/payment-history"
              className="d-flex justify-content-end mt-2"
            >
              View all payment history and download receipts
            </Link>
          </Card.Body>
        </Card>
      </Container>
    </div>
  );
};

export default withRouter(Billing);
