import {
  registerApi,
  verifyEmailApi,
  registerSubUserApi,
  subUserGroupsAssignApi,
  subUserSubGroupsAssignApi,
  subUsersListApi,
  subUserLoginDetailsApi,
  deleteSubUserLoginSessionApi,
  updateSubUserPassApi,
  registerNotionApi,
  updateSubuserStatusApi,
} from "helpers/apiroute_helper";
import {
  apiLoading,
  apiLoadingFalse,
  apiLoading2,
  apiLoading3,
  registerUserSuccessful,
  registerUserFailed,
  verifyEmailSuccessful,
  verifyEmailFailed,
  registerSubUserSuccessful,
  registerSubUserFailed,
  subUserGroupsAssignSuccessful,
  subUserGroupsAssignFailed,
  subUserSubGroupsAssignSuccessful,
  subUserSubGroupsAssignFailed,
  subUsersDataSuccessful,
  subUsersDataFailed,
  subUsersLoginDataSuccessful,
  subUsersLoginDataFailed,
  deleteSubUserLoginSessionSuccessful,
  deleteSubUserLoginSessionFailed,
  updateSubUserPasswordSuccess,
  updateSubUserPasswordFailed,
} from "./reducer";
import { toast } from "react-toastify";
import { showToastThunk } from "slices/thunk";

const DATABASE_ID = "ddab9f5e-6810-4c15-bbb3-0b2f8a523c04";

export const addDataToNotionThunk =
  (notionObj: any) => async (dispatch: any) => {
    try {
      dispatch(apiLoading());

      const data: any = await registerNotionApi({
        notionUrl: "/pages",
        method: "post",
        queryParams: {},
        payload: {
          parent: { database_id: DATABASE_ID },
          properties: {
            "Full Name": {
              title: [
                {
                  text: {
                    content: `${notionObj.firstName} ${notionObj.lastName}`,
                  },
                },
              ],
            },
            Email: {
              email: notionObj.emailId,
            },
            System: {
              multi_select: [
                ...notionObj.systems
                  .filter((dt: string) => dt !== "Other")
                  ?.map((dt: string) => ({ name: dt })),
                ...(notionObj.otherText
                  ? notionObj.otherText
                      ?.split(",")
                      ?.map((dt: string) => ({ name: dt }))
                  : []),
              ],
            },
            "Campaign Migration?": {
              select: {
                name: notionObj.isCampaignsToMigrate,
              },
            },
            "Approx. Campaign Number": {
              number: notionObj.campaignsNumber,
            },
            "Current CNP": {
              rich_text: [
                {
                  text: {
                    content: notionObj.currentConnectivityPartner,
                  },
                },
              ],
            },
            "Numbers to Port?": {
              select: {
                name: notionObj.isNumbersToPort,
              },
            },
            "Approx Number for Porting": {
              number: notionObj.numbers,
            },
          },
        },
      });

      if (data?.id) {
        // toast.success();
      } else {
        toast.error("Something went wrong");
      }
      dispatch(apiLoadingFalse());
    } catch (error: any) {
      console.log("error", error);
      dispatch(apiLoadingFalse());
      toast.error(error?.response?.data?.message || "Something went wrong");
    }
  };

// Is user register successfull then direct plot user in redux.
export const registerUserThunk =
  (user: any, cb: any, recaptchaRef?: any) => async (dispatch: any) => {
    dispatch(apiLoading());
    try {
      const data: any = await registerApi(
        encodeURIComponent(
          `${
            process.env.REACT_APP_FRONTEND_URL || "https://dev.signalhouse.io"
          }/verifyEmail`
        ),
        { ...user }
      );

      if (data?.code || data?.errorCode) {
        dispatch(registerUserFailed(data));
        toast.error(data?.message);
      } else {
        cb && cb();
        dispatch(registerUserSuccessful(data));
        toast.success(data?.message);
      }
      recaptchaRef && recaptchaRef.current.reset();
    } catch (error: any) {
      dispatch(registerUserFailed(error));
      recaptchaRef && recaptchaRef.current.reset();
      toast.error(error?.response?.data?.message || "Something went wrong");
    }
  };

export const verifyEmailThunk =
  (token: string | null, history: any) => async (dispatch: any) => {
    try {
      const data: any = await verifyEmailApi(token);

      if (data.message) {
        dispatch(verifyEmailSuccessful(data));
        // toast.success(data?.message);
        history("/login");
        dispatch(
          showToastThunk(
            "success",
            "",
            "ri-mail-line",
            "Congratulations on successfully creating your account! Please click here to log in."
          )
        );
        // toast.success(
        //   "Congratulations on successfully creating your account! Please click here to log in."
        // );
      } else {
        dispatch(verifyEmailFailed(data?.message));
        toast.error(data?.message);
      }
    } catch (error: any) {
      dispatch(verifyEmailFailed(error));
      toast.error(error?.response?.data?.message || "Something went wrong");
    }
  };

export const registerSubUserThunk =
  (user: any, cb?: any) => async (dispatch: any) => {
    dispatch(apiLoading());
    try {
      const data: any = await registerSubUserApi(
        encodeURIComponent(
          `${
            process.env.REACT_APP_FRONTEND_URL || "https://dev.signalhouse.io"
          }/verifyEmail`
        ),
        { ...user }
      );

      if (data?.code || data?.errorCode) {
        dispatch(registerSubUserFailed(data));
        toast.error(data?.message || "Something went wrong");
      } else {
        dispatch(registerSubUserSuccessful(data));
        toast.success(data?.message);
        cb && cb(data?.userId);
      }
    } catch (error: any) {
      dispatch(registerSubUserFailed(error));
      toast.error(error?.response?.data?.message || "Something went wrong");
    }
  };

export const subUserGroupsAssignThunk =
  (groupId: string, subUserId: string, grpData: any, cb?: any) =>
  async (dispatch: any) => {
    dispatch(apiLoading());
    try {
      const data: any = await subUserGroupsAssignApi(groupId, subUserId, {
        ...grpData,
      });

      if (data?.code || data?.errorCode) {
        dispatch(subUserGroupsAssignFailed(data));
        toast.error(data?.message || "Something went wrong");
      } else {
        dispatch(subUserGroupsAssignSuccessful(data));
        toast.success(data?.message);
        cb && cb(subUserId);
      }
    } catch (error: any) {
      dispatch(subUserGroupsAssignFailed(error));
      toast.error(error?.response?.data?.message || "Something went wrong");
    }
  };

export const subUserSubGroupsAssignThunk =
  (grpData: any, cb?: any) => async (dispatch: any) => {
    dispatch(apiLoading());
    try {
      const data: any = await subUserSubGroupsAssignApi({ ...grpData });

      if (data?.code || data?.errorCode) {
        dispatch(subUserSubGroupsAssignFailed(data));
        toast.error(data?.message || "Something went wrong");
      } else {
        dispatch(subUserSubGroupsAssignSuccessful(data));
        toast.success(data?.message);
        cb && cb();
      }
    } catch (error: any) {
      dispatch(subUserSubGroupsAssignFailed(error));
      toast.error(error?.response?.data?.message || "Something went wrong");
    }
  };

export const getSubUsersListThunk =
  (page?: number, recordsPerPage?: number, otherParams?: any) =>
  async (dispatch: any) => {
    dispatch(apiLoading2());

    try {
      const data: any = await subUsersListApi(
        page,
        recordsPerPage,
        otherParams
      );

      if (data?.code || data?.errorCode) {
        dispatch(subUsersDataFailed(data));
        toast.error(data?.message || "Something went wrong");
      } else {
        dispatch(subUsersDataSuccessful(data));
      }
    } catch (error: any) {
      dispatch(subUsersDataFailed(error));
      toast.error(error?.response?.data?.message || "Something went wrong");
    }
  };

export const getSubUserLoginDetailsThunk =
  (page?: number, recordsPerPage?: number, otherParams?: any) =>
  async (dispatch: any) => {
    dispatch(apiLoading());

    try {
      const data: any = await subUserLoginDetailsApi(
        page,
        recordsPerPage,
        otherParams
      );

      if (data?.code || data?.errorCode) {
        dispatch(subUsersLoginDataFailed(data));
        toast.error(data?.message || "Something went wrong");
      } else {
        dispatch(subUsersLoginDataSuccessful(data));
      }
    } catch (error: any) {
      dispatch(subUsersLoginDataFailed(error));
      toast.error(error?.response?.data?.message || "Something went wrong");
    }
  };

export const deleteSubUserLoginSessionThunk =
  (emailId: string, cb?: any) => async (dispatch: any) => {
    try {
      dispatch(apiLoading3());

      let data: any = await deleteSubUserLoginSessionApi(emailId);

      if (data?.code || data?.errorCode) {
        dispatch(deleteSubUserLoginSessionFailed(data));
        toast.error(data?.message || "Something went wrong");
      } else {
        dispatch(deleteSubUserLoginSessionSuccessful(data));
        toast.success("Sub user login session deleted successfully");
        cb && cb();
      }
    } catch (error: any) {
      dispatch(deleteSubUserLoginSessionFailed(error));
      toast.error(error?.response?.data?.message || "Something went wrong");
    }
  };

export const updateSubUserPasswordThunk =
  (password: any, emailId: string, cb?: any) => async (dispatch: any) => {
    dispatch(apiLoading());

    try {
      const data: any = await updateSubUserPassApi(password, emailId);

      if (data?.code || data?.errorCode) {
        dispatch(updateSubUserPasswordFailed());
        toast.error(data?.message || "Something went wrong");
      } else {
        dispatch(updateSubUserPasswordSuccess());
        toast.success(
          data?.message || "Sub user password updated successfully"
        );
        cb && cb();
      }
    } catch (error: any) {
      dispatch(updateSubUserPasswordFailed());
      toast.error(error?.response?.data?.message || "Something went wrong");
    }
  };

export const updateSubuserStatusThunk =
  (emailId: string, obj: any, cb?: any) => async (dispatch: any) => {
    dispatch(apiLoading());

    try {
      const data: any = await updateSubuserStatusApi(emailId, obj);

      if (data?.code || data?.errorCode) {
        dispatch(updateSubUserPasswordFailed());
        toast.error(data?.message || "Something went wrong");
      } else {
        dispatch(updateSubUserPasswordSuccess());
        toast.success(data?.message || "Sub user status updated successfully");
        cb && cb();
      }
    } catch (error: any) {
      dispatch(updateSubUserPasswordFailed());
      toast.error(error?.response?.data?.message || "Something went wrong");
    }
  };
