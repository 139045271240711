import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  loading: false,
  loading2: false,
  loading3: false,
  registrationError: null,
  verificationToken: "",
  subUsersData: {},
  subUsersLoginData: {},
  isMailVerified: false,
  mailVerifyError: null,
  success: false,
  successMsg: "",
  error: false,
  errorMsg: "",
};

const registerSlice = createSlice({
  name: "register",
  initialState,
  reducers: {
    apiLoading(state) {
      state.loading = true;
      state.success = false;
      state.error = false;
      state.registrationError = null;
      state.mailVerifyError = null;
    },
    apiLoadingFalse(state) {
      state.loading = false;
    },
    apiLoading2(state) {
      state.loading2 = true;
      state.success = true;
      state.successMsg = "";
      state.error = false;
      state.errorMsg = "";
    },
    apiLoading3(state) {
      state.loading3 = true;
      state.success = true;
      state.successMsg = "";
      state.error = false;
      state.errorMsg = "";
    },
    registerUserSuccessful(state, action) {
      // state.verificationToken = action.payload.verificationToken;
      state.loading = false;
      state.success = true;
    },
    registerUserFailed(state, action) {
      // state.verificationToken = "";
      state.loading = false;
      state.error = true;
      state.registrationError = action.payload;
    },
    verifyEmailSuccessful(state, action) {
      state.isMailVerified = true;
    },
    verifyEmailFailed(state, action) {
      state.isMailVerified = false;
      state.mailVerifyError = action.payload;
    },
    registerSubUserSuccessful(state, action) {
      state.loading = false;
      state.success = true;
    },
    registerSubUserFailed(state, action) {
      state.loading = false;
      state.error = true;
    },
    subUserGroupsAssignSuccessful(state, action) {
      // state.loading = false;
      state.success = true;
    },
    subUserGroupsAssignFailed(state, action) {
      state.loading = false;
      state.error = true;
    },
    subUserSubGroupsAssignSuccessful(state, action) {
      state.loading = false;
      state.success = true;
    },
    subUserSubGroupsAssignFailed(state, action) {
      state.loading = false;
      state.error = true;
    },
    subUsersDataSuccessful(state, action) {
      state.loading2 = false;
      state.subUsersData = action.payload;
      state.success = true;
      state.successMsg = "";
      state.error = false;
      state.errorMsg = "";
    },
    subUsersDataFailed(state, action) {
      state.loading2 = false;
      state.subUsersData = {};
      state.success = false;
      state.successMsg = "";
      state.error = true;
      state.errorMsg = action.payload.message;
    },
    subUsersLoginDataSuccessful(state, action) {
      state.loading = false;
      state.subUsersLoginData = action.payload;
      state.success = true;
      state.successMsg = "";
      state.error = false;
      state.errorMsg = "";
    },
    subUsersLoginDataFailed(state, action) {
      state.loading = false;
      state.subUsersLoginData = {};
      state.success = false;
      state.successMsg = "";
      state.error = true;
      state.errorMsg = action.payload.message;
    },
    deleteSubUserLoginSessionSuccessful(state, action) {
      state.loading3 = false;
      state.success = true;
      state.successMsg = "";
      state.error = false;
      state.errorMsg = "";
    },
    deleteSubUserLoginSessionFailed(state, action) {
      state.loading3 = false;
      state.success = false;
      state.successMsg = "";
      state.error = true;
      state.errorMsg = action.payload.message;
    },
    updateSubUserPasswordSuccess(state) {
      state.loading = false;
    },
    updateSubUserPasswordFailed(state) {
      state.loading = false;
    },
  },
});

export const {
  apiLoading,
  apiLoadingFalse,
  apiLoading2,
  apiLoading3,
  registerUserSuccessful,
  registerUserFailed,
  verifyEmailSuccessful,
  verifyEmailFailed,
  registerSubUserSuccessful,
  registerSubUserFailed,
  subUserGroupsAssignSuccessful,
  subUserGroupsAssignFailed,
  subUserSubGroupsAssignSuccessful,
  subUserSubGroupsAssignFailed,
  subUsersDataSuccessful,
  subUsersDataFailed,
  subUsersLoginDataSuccessful,
  subUsersLoginDataFailed,
  deleteSubUserLoginSessionSuccessful,
  deleteSubUserLoginSessionFailed,
  updateSubUserPasswordSuccess,
  updateSubUserPasswordFailed,
} = registerSlice.actions;

export default registerSlice.reducer;
