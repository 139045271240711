import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  loading: false,
  loading2: false,
  callerArrObj: {},
  createdCaller: {},
  success: false,
  successMsg: "",
  error: false,
  errorMsg: "",
};

const verifiedCallerDataSlice = createSlice({
  name: "verified-caller",
  initialState,
  reducers: {
    apiLoading(state) {
      state.loading = true;
      state.success = false;
      state.successMsg = "";
      state.error = false;
      state.errorMsg = "";
    },
    verifiedCallerDataSuccessful(state, action) {
      state.loading = false;
      state.callerArrObj = action.payload;
      state.success = true;
      state.successMsg = "";
      state.error = false;
      state.errorMsg = "";
    },
    verifiedCallerDataFailed(state, action) {
      state.loading = false;
      state.callerArrObj = {};
      state.success = false;
      state.successMsg = "";
      state.error = true;
      state.errorMsg = action.payload.message;
    },
    apiLoading2(state) {
      state.loading2 = true;
      state.success = false;
      state.successMsg = "";
      state.error = false;
      state.errorMsg = "";
    },
    verifiedCallerCreateSuccessful(state, action) {
      state.loading2 = false;
      state.createdCaller = action.payload;
      state.success = true;
      state.successMsg = "";
      state.error = false;
      state.errorMsg = "";
    },
    verifiedCallerCreateFailed(state, action) {
      state.loading2 = false;
      state.createdCaller = {};
      state.success = false;
      state.successMsg = "";
      state.error = true;
      state.errorMsg = action.payload.message;
    },
    checkVerificationSuccessful(state, action) {
      state.loading2 = false;
      state.success = true;
      state.successMsg = "";
      state.error = false;
      state.errorMsg = "";
    },
    checkVerificationFailed(state, action) {
      state.loading2 = false;
      state.success = false;
      state.successMsg = "";
      state.error = true;
      state.errorMsg = action.payload.message;
    },
  },
});

export const {
  apiLoading,
  verifiedCallerDataSuccessful,
  verifiedCallerDataFailed,
  apiLoading2,
  verifiedCallerCreateSuccessful,
  verifiedCallerCreateFailed,
  checkVerificationSuccessful,
  checkVerificationFailed,
} = verifiedCallerDataSlice.actions;

export default verifiedCallerDataSlice.reducer;
