import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  loading: false,
  successMsg: "",
  errorMsg: "",
  isSuccess: false,
  forgotPassUri: "",
};

const forgotPasswordSlice = createSlice({
  name: "forgotpwd",
  initialState,
  reducers: {
    apiLoading(state) {
      state.loading = true;
      state.successMsg = "";
      state.errorMsg = "";
    },
    userForgetPasswordSuccess(state, action) {
      state.loading = false;
      state.isSuccess = true;
      // state.forgotPassUri = action.payload.forgotPassUri;
    },
    userForgetPasswordFailed(state) {
      state.loading = false;
      state.isSuccess = false;
    },
    updatePasswordSuccess(state) {
      state.loading = false;
    },
    updatePasswordFailed(state) {
      state.loading = false;
    },
  },
});

export const {
  apiLoading,
  userForgetPasswordSuccess,
  userForgetPasswordFailed,
  updatePasswordSuccess,
  updatePasswordFailed,
} = forgotPasswordSlice.actions;

export default forgotPasswordSlice.reducer;
