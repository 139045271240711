import React, { useEffect } from "react";
import { Container, Card, Row, Col } from "react-bootstrap";
import BreadCrumb from "Common/BreadCrumb";
import withRouter from "Common/withRouter";
import { getHelpCenterPagesThunk } from "slices/help-center/thunk";
import { useDispatch, useSelector } from "react-redux";
import { createSelector } from "reselect";
import Loader from "assets/images/spinner-dark.svg";

const HelpCenterList = (props: any) => {
  const helpCenterTopics: { [key: string]: string } = {
    dashboard:
      "Everything you need to know about navigating and extracting the most value out of your dashboard.",
    settings:
      "Complete your billing information, set notifications, and increase your account security.",
    "developer tools":
      "Webhooks, API documentation, and authentication. Build your app with Signal House.",
    numbers: "Purchase, configure, and use your numbers simply and seamlessly.",
    porting:
      "Bring your own number and connect it to fast, reliable messaging.",
    messaging:
      "Leverage the power of 10DLC messaging and broaden the reach of your business.",
    analytics:
      "View the data that matters to you and see the metrics that impact your business.",
  };
  document.title = "Signal House Portal Help Center";

  const dispatch = useDispatch<any>();

  const selectProfile = createSelector(
    (state: any) => state.HelpCenter,
    (helpCenter) => ({
      loading: helpCenter.loading,
      pagesData: helpCenter.pagesData?.results,
      error: helpCenter.error,
    })
  );

  const { loading, pagesData } = useSelector(selectProfile);

  useEffect(() => {
    dispatch(getHelpCenterPagesThunk());
  }, []);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Help Center" />

          <div className="position-relative wrapper">
            <div className="px-3">
              {loading ? (
                <div>
                  <img
                    src={Loader}
                    className="position-absolute top-50 start-50 translate-middle"
                  />
                </div>
              ) : (
                <Row>
                  {pagesData?.filter((dt: any) => dt?.toggle || dt?.child_page)
                    ?.length > 0 ? (
                    pagesData
                      ?.filter(
                        (dt: any) =>
                          dt?.type === "toggle" || dt?.type === "child_page"
                      )
                      ?.map((dt: any, i: number) => (
                        <Col
                          sm={12}
                          md={6}
                          lg={4}
                          xl={3}
                          className="mb-4"
                          key={i}
                          style={{ height: "170px" }}
                        >
                          <Card
                            className="cursor-pointer h-100"
                            onClick={() =>
                              props.router.navigate(
                                dt?.type === "toggle"
                                  ? `/help-center/${dt?.id}`
                                  : `/help-center/${dt?.id}/${dt?.id}?name=${
                                      dt?.type === "toggle"
                                        ? dt?.toggle?.rich_text?.[0]?.text?.content
                                            .split(" ")
                                            .join("-")
                                        : dt?.child_page?.title
                                            .split(" ")
                                            .join("-")
                                    }`
                              )
                            }
                          >
                            <Card.Body className="d-flex flex-column justify-content-between">
                              <h5 className="fw-semibold mb-2">
                                {dt?.type === "toggle"
                                  ? dt?.toggle?.rich_text?.[0]?.text?.content
                                  : `${
                                      dt?.child_page?.title?.toLowerCase() ===
                                      "dashboard"
                                        ? "📊"
                                        : ""
                                    }` + dt?.child_page?.title || ""}
                              </h5>
                              <div>
                                {(() => {
                                  const content =
                                    dt?.toggle?.rich_text?.[0]?.text?.content
                                      ?.replace(/[^\w\s]/gi, "")
                                      ?.trim();

                                  return helpCenterTopics[
                                    (
                                      content ||
                                      dt?.child_page?.title
                                        ?.split(" ")
                                        .join("-")
                                    )?.toLowerCase()
                                  ];
                                })()}
                              </div>
                              <div className="text-primary text-end">
                                View Details
                                <i className="ri-arrow-right-line label-icon align-middle fs-lg ms-2"></i>
                              </div>
                            </Card.Body>
                          </Card>
                        </Col>
                      ))
                  ) : (
                    <p className="m-0">No Record Found</p>
                  )}
                </Row>
              )}
            </div>
          </div>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(HelpCenterList);
