import {
  portRequestListApi,
  portOutRequestListApi,
  createPortNumberRequestApi,
  messageFileUploadApi,
  messaeGetFileApi,
  getPortingStatusLogsApi,
} from "helpers/apiroute_helper";
import {
  apiLoading,
  apiLoadingFalse,
  portRequestsDataSuccessful,
  portRequestsDataFailed,
  portOutDataSuccessful,
  portOutDataFailed,
  createPortNumberRequestSuccessful,
  createPortNumberRequestFailed,
  updateAuthUserFailed,
  fileUploadDone,
  getportingStatusDataSuccessful,
  getportingStatusDataFailed,
  resetPortingStatusData,
} from "./reducer";
import { toast } from "react-toastify";

export const getPortRequestListThunk =
  (page?: number, recordsPerPage?: number, otherParams?: any) =>
  async (dispatch: any) => {
    dispatch(apiLoading());

    try {
      let data: any = await portRequestListApi(
        page,
        recordsPerPage,
        otherParams
      );
      if (data?.code || data?.errorCode) {
        dispatch(portRequestsDataFailed(data));
        toast.error(data?.message || "Something went wrong");
      } else {
        dispatch(portRequestsDataSuccessful(data));
      }
    } catch (error: any) {
      console.log("error", error);
      dispatch(portRequestsDataFailed(error));
      toast.error(error?.response?.data?.message || "Something went wrong");
    }
  };




  export const getPortOutListThunk =
  (page?: number, recordsPerPage?: number, otherParams?: any) =>
  async (dispatch: any) => {
    dispatch(apiLoading());

    try {
      let data: any = await portOutRequestListApi(
        page,
        recordsPerPage,
        otherParams
      );
      if (data?.code || data?.errorCode) {
        dispatch(portOutDataFailed(data));
        toast.error(data?.message || "Something went wrong");
      } else {
        dispatch(portOutDataSuccessful(data));
      }
    } catch (error: any) {
      console.log("error", error);
      dispatch(portOutDataFailed(error));
      toast.error(error?.response?.data?.message || "Something went wrong");
    }
  };










export const createPortNumberRequestThunk =
  (portReq: any, cb?: any) => async (dispatch: any) => {
    dispatch(apiLoading());
    try {
      let data: any = await createPortNumberRequestApi({
        ...portReq,
      });
      if (data?.code || data?.errorCode) {
        dispatch(createPortNumberRequestFailed(data));
        toast.error(data?.message || "Something went wrong");
      } else {
        dispatch(createPortNumberRequestSuccessful(data));
        toast.success("Port Number Request Created successfully");
        cb && cb();
      }
    } catch (error: any) {
      console.log("error", error);
      dispatch(createPortNumberRequestFailed(error));
      toast.error(error?.response?.data?.message || "Something went wrong");
    }
  };

export const fileUploadThunk =
  (file: any, isFormData?: boolean, cb?: any) => async (dispatch: any) => {
    dispatch(apiLoading());
    try {
      const response = await messageFileUploadApi(file, isFormData);
      const data = response.data;

      if (data) {
        dispatch(fileUploadDone(data));
        cb && cb(data);
      } else {
        dispatch(updateAuthUserFailed("Something went wrong"));
      }
    } catch (error) {
      dispatch(updateAuthUserFailed(error));
    } finally {
      dispatch(apiLoadingFalse());
    }
  };

export const getFileThunk = (path: any, cb?: any) => async (dispatch: any) => {
  dispatch(apiLoading());
  try {
    const data: any = await messaeGetFileApi(path);

    if (data) {
      cb && cb(new File([data], `file.${data?.type?.split("/").pop()}`), path);
    } else {
      dispatch(updateAuthUserFailed({ message: "Something went wrong" }));
      toast.error("Something went wrong");
    }
  } catch (error: any) {
    dispatch(updateAuthUserFailed(error));
    toast.error(error?.response?.data?.message || "Something went wrong");
  } finally {
    dispatch(apiLoadingFalse());
  }
};

// Own Numbers Event Logs Thunk
export const getPortingStatusLogsThunk =
  (
    portingId: string,
    pagination: { page: number; recordsPerPage: number },
  ) =>
  async (dispatch: any) => {
    dispatch(apiLoading());

    try {
      // Pass additional parameters to getMessageLogsApi function
      const data: any = await getPortingStatusLogsApi(
        portingId,
        pagination,
      );
      if (data?.code) {
        dispatch(getportingStatusDataFailed(data));
        toast.error(data?.message || "Something went wrong");
      } else {
        dispatch(getportingStatusDataSuccessful(data));
      }
    } catch (error: any) {
      dispatch(getportingStatusDataFailed(error));
      toast.error(error?.response?.data?.message || "Something went wrong");
    }
  };
