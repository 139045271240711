import React, { useEffect, useState } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import Layout from "Layout";
import NonAuthLayout from "Layout/NonAuthLayout";
import TopBarProgress from "react-topbar-progress-indicator";

//routes
import { publicRoutes, authProtectedRoutes } from "./allRoutes";
import { AuthProtected } from "./AuthProtected";

TopBarProgress.config({
  barThickness: 2,
  barColors: {
    "0": "#e41cfd",
    "1.0": "#e41cfd",
  },
  shadowBlur: 1,
  shadowColor: "#e41cfd",
});

const Index = () => {
  const location = useLocation();
  const [progress, setProgress] = useState(false);
  const [prevLoc, setPrevLoc] = useState("");

  useEffect(() => {
    setPrevLoc(location.pathname);
    setProgress(true);
    if (location.pathname === prevLoc) {
      setPrevLoc("");
    }
  }, [location]);

  useEffect(() => {
    setTimeout(() => setProgress(false), 1000);
  }, [prevLoc]);

  return (
    <React.Fragment>
      {progress && <TopBarProgress />}

      <Routes>
        <Route>
          {publicRoutes.map((route: any, idx: number) => (
            <Route
              key={idx}
              path={route.path}
              element={<NonAuthLayout> {route.component} </NonAuthLayout>}
            />
          ))}
        </Route>

        <Route>
          {authProtectedRoutes.map((route: any, idx: number) => (
            <Route
              key={idx}
              path={route.path}
              element={
                <AuthProtected moduleKey={route.moduleKey}>
                  <Layout
                    isSubNavbar={route?.isSubNavbar}
                    subNavdata={route?.subNavdata}
                  >
                    {route.component}
                  </Layout>
                </AuthProtected>
              }
            />
          ))}
        </Route>
      </Routes>
    </React.Fragment>
  );
};

export default Index;
