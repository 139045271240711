import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  loading: false,
  brandsData: {},
  brandDetails: {},
  appeals: [],
  evidence: {},
  evidenceLoading: false,
  success: false,
  successMsg: "",
  error: false,
  errorMsg: "",
  appealLoading: false,
  appealSuccess: false,
  appealError: false,
  appealErrorMsg: "",
  evidenceUploading: false,
  evidenceUploadError: true,
  vettingBrand: [],
  vettingBrandOptions: [],
  vettingBrandList: [],
};

const brandsDataSlice = createSlice({
  name: "brands",
  initialState,
  reducers: {
    apiLoading(state) {
      state.loading = true;
      state.success = false;
      state.successMsg = "";
      state.error = false;
      state.errorMsg = "";
    },
    brandsDataSuccessful(state, action) {
      state.loading = false;
      state.brandsData = action.payload;
      state.success = true;
      state.successMsg = "";
      state.error = false;
      state.errorMsg = "";
    },
    brandsDataFailed(state, action) {
      state.loading = false;
      state.brandsData = {};
      state.success = false;
      state.successMsg = "";
      state.error = true;
      state.errorMsg = action.payload.message;
    },
    brandsDataReset(state) {
      state.loading = false;
      state.brandsData = {};
      state.success = false;
      state.successMsg = "";
      state.error = false;
      state.errorMsg = "";
    },
    brandDetailsSuccessful(state, action) {
      state.loading = false;
      state.brandDetails = action.payload;
      state.success = true;
      state.successMsg = "";
      state.error = false;
      state.errorMsg = "";
    },
    brandDetailsFailed(state, action) {
      state.loading = false;
      state.brandDetails = {};
      state.success = false;
      state.successMsg = "";
      state.error = true;
      state.errorMsg = action.payload.message;
    },
    brandCreateSuccessful(state, action) {
      state.loading = false;
      state.success = true;
      state.successMsg = "";
      state.error = false;
      state.errorMsg = "";
    },
    brandCreateFailed(state, action) {
      state.loading = false;
      state.success = false;
      state.successMsg = "";
      state.error = true;
      state.errorMsg = action.payload.message;
    },
    brandUpdateSuccessful(state, action) {
      state.loading = false;
      state.success = true;
      state.successMsg = "Brand updated successfully";
      state.error = false;
      state.errorMsg = "";
    },
    brandUpdateFailed(state, action) {
      state.loading = false;
      state.success = false;
      state.successMsg = "";
      state.error = true;
      state.errorMsg = action.payload.message;
    },
    brandRevertSuccessful(state, action) {
      state.loading = false;
      state.success = true;
      state.successMsg = "Brand updated successfully";
      state.error = false;
      state.errorMsg = "";
    },
    brandRevertFailed(state, action) {
      state.loading = false;
      state.success = false;
      state.successMsg = "";
      state.error = true;
      state.errorMsg = action.payload.message;
    },

    brandDeleteSuccessful(state, action) {
      state.loading = false;
      state.success = true;
      state.successMsg = "Brand deleted successfully";
      state.error = false;
      state.errorMsg = "";
    },
    brandDeleteFailed(state, action) {
      state.loading = false;
      state.success = false;
      state.successMsg = "";
      state.error = true;
      state.errorMsg = action.payload.message;
    },
    brandAppealPending(state) {
      state.appealLoading = true;
      state.appealSuccess = false;
      state.appealError = false;
      state.appealErrorMsg = "";
    },
    brandAppealSuccessful(state) {
      state.appealLoading = false;
      state.appealSuccess = true;
      state.appealError = false;
      state.appealErrorMsg = "";
    },
    brandAppealFailed(state, action) {
      state.appealLoading = false;
      state.appealSuccess = false;
      state.appealError = true;
      state.appealErrorMsg = action.payload.message;
    },
    appealsInfoPending(state) {
      state.appealLoading = true;
      state.appealSuccess = false;
      state.appealError = false;
      state.appealErrorMsg = "";
    },
    appealsInfoSuccessful(state, action) {
      state.appealLoading = false;
      state.appealSuccess = true;
      state.appealError = false;
      state.appealErrorMsg = "";
      state.appeals = action.payload;
    },
    appealsInfoFailed(state, action) {
      state.appealLoading = false;
      state.appealSuccess = false;
      state.appealError = true;
      state.appeals = [];
      state.appealErrorMsg = action.payload?.message;
    },
    fileUploadPending(state) {
      state.evidenceUploading = true;
      state.success = false;
      state.successMsg = "";
      state.evidenceUploadError = true;
      state.error = false;
      state.errorMsg = "";
    },
    fileUploadSuccess(state, action) {
      state.evidenceUploading = false;
      state.success = true;
      state.evidenceUploadError = false;
      state.successMsg = action.payload.message;
      state.error = false;
      state.errorMsg = "";
    },
    fileUploadFailed(state, action) {
      state.evidenceUploading = false;
      state.success = false;
      state.evidenceUploadError = true;
      state.successMsg = "";
      state.error = true;
      state.errorMsg = action.payload.message;
    },
    getEvidenceFilePending(state) {
      state.evidenceLoading = true;
      state.success = false;
      state.successMsg = "";
      state.error = false;
      state.errorMsg = "";
    },
    getEvidenceFileSuccess(state, action) {
      state.evidenceLoading = false;
      state.success = true;
      state.error = false;
      state.errorMsg = "";
      state.evidence = action.payload;
    },
    getEvidenceFileFailed(state, action) {
      state.evidenceLoading = false;
      state.success = false;
      state.successMsg = "";
      state.error = true;
      state.evidence = [];
      state.errorMsg = action.payload.message;
    },
    brandAppealDelete: (state) => {
      state.loading = true;
      state.success = false;
      state.error = false;
    },
    brandAppealDeleteSuccess: (state) => {
      state.loading = false;
      state.success = true;
      state.error = false;
    },
    brandAppealDeleteFailed: (state, action) => {
      state.loading = false;
      state.success = false;
      state.error = true;
      state.errorMsg = action.payload.message;
    },
    fetchAppealCategoriesRequest(state: any) {
      state.loading = true;
      state.error = null;
    },
    fetchAppealCategoriesSuccess(state: any, action: any) {
      state.loading = false;
      state.appealCategories = action.payload;
    },
    fetchAppealCategoriesFailure(state: any, action: any) {
      state.loading = false;
      state.error = action.payload;
    },
    fetchVettingBrandRequest(state: any) {
      state.loading = true;
      state.error = null;
    },
    fetchVettingBrandSuccess(state: any, action: any) {
      state.loading = false;
      state.vettingBrand = action.payload;
    },
    fetchVettingBrandFailure(state: any, action: any) {
      state.loading = false;
      state.error = action.payload;
    },
    fetchVettingBrandOptionsRequest(state: any) {
      state.loading = true;
      state.error = null;
    },
    fetchVettingBrandOptionsSuccess(state: any, action: any) {
      state.loading = false;
      state.vettingBrandOptions = action.payload;
    },
    fetchVettingBrandOptionsFailure(state: any, action: any) {
      state.loading = false;
      state.error = action.payload;
    },
    externalVettingRequest(state) {
      state.loading = true;
      state.success = false;
      state.successMsg = "";
      state.error = false;
      state.errorMsg = "";
    },
    externalVettingSuccess(state) {
      state.loading = false;
      state.success = true;
      state.successMsg = "Submit vetting request successful";
      state.error = false;
      state.errorMsg = "";
    },
    externalVettingFailure(state, action) {
      state.loading = false;
      state.success = false;
      state.successMsg = "";
      state.error = true;
      state.errorMsg = action.payload.message;
    },
    ImportVettingSuccessful(state, action) {
      state.loading = false;
      state.success = true;
      state.successMsg = "Import vetting updated successfully";
      state.error = false;
      state.errorMsg = "";
    },
    ImportVettingFailed(state, action) {
      state.loading = false;
      state.success = false;
      state.successMsg = "";
      state.error = true;
      state.errorMsg = action.payload.message;
    },
    fetchVettingBrandListRequest(state: any) {
      state.loading = true;
      state.error = null;
    },
    fetchVettingBrandListSuccess(state: any, action: any) {
      state.loading = false;
      state.vettingBrandList = action.payload;
      state.successMsg = "Brand vetting";
      state.error = false;
      state.errorMsg = "";
    },
    fetchVettingBrandListFailure(state: any, action: any) {
      state.loading = false;
      state.success = false;
      state.successMsg = "";
      state.error = true;
      state.errorMsg = action.payload.message;
    },
    downloadVettingAchmentSuccess(state: any, action: any) {
      state.loading = false;
      state.success = false;
      state.successMsg = "Brand vetting";
      state.error = false;
      state.errorMsg = "";
    },
    downloadVettingAchmentFailure(state: any, action: any) {
      state.loading = false;
      state.success = false;
      state.successMsg = "";
      state.error = true;
      state.errorMsg = action.payload.message;
    },
  },
});

export const {
  apiLoading,
  brandsDataSuccessful,
  brandsDataFailed,
  brandsDataReset,
  brandDetailsSuccessful,
  brandDetailsFailed,
  brandCreateSuccessful,
  brandCreateFailed,
  brandUpdateSuccessful,
  brandUpdateFailed,
  brandRevertSuccessful,
  brandRevertFailed,
  brandDeleteSuccessful,
  brandDeleteFailed,
  brandAppealPending,
  brandAppealSuccessful,
  brandAppealFailed,
  appealsInfoPending,
  appealsInfoSuccessful,
  appealsInfoFailed,
  fileUploadPending,
  fileUploadSuccess,
  fileUploadFailed,
  getEvidenceFilePending,
  getEvidenceFileSuccess,
  getEvidenceFileFailed,
  brandAppealDelete,
  brandAppealDeleteSuccess,
  brandAppealDeleteFailed,
  fetchVettingBrandRequest,
  fetchVettingBrandSuccess,
  fetchVettingBrandFailure,
  fetchVettingBrandOptionsRequest,
  fetchVettingBrandOptionsSuccess,
  fetchVettingBrandOptionsFailure,
  externalVettingRequest,
  externalVettingSuccess,
  externalVettingFailure,
  ImportVettingSuccessful,
  ImportVettingFailed,
  fetchVettingBrandListRequest,
  fetchVettingBrandListSuccess,
  fetchVettingBrandListFailure,
  downloadVettingAchmentSuccess,
  downloadVettingAchmentFailure,
} = brandsDataSlice.actions;

export default brandsDataSlice.reducer;
