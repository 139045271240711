import React, { useEffect, useState } from "react";
import { Formik } from "formik";
import {
  Card,
  Button,
  Row,
  Col,
  Form,
  Spinner,
  Container,
  Dropdown,
  Tab,
  Nav,
} from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import { useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import Datatable from "../../Common/Datatable";
import { getEventLogsThunk } from "slices/thunk";
import Tooltip from "rc-tooltip";
import { useDispatch, useSelector } from "react-redux";
import { createSelector } from "reselect";
import Loader from "assets/images/spinner-dark.svg";
import Flatpickr from "react-flatpickr";
import BreadCrumb from "Common/BreadCrumb";
import { dateFormat } from "helpers/common";

const filterInitialState = {
  signalHouseSID: "",
  startDate: "",
  endDate: "",
  from: "",
  to: "",
  selectField: "",
  direction: false,
};

const formatDirection = (direction: string) => {
  if (direction.toLowerCase().includes("inbound")) return "inbound";
  if (direction.toLowerCase().includes("outbound")) return "outbound";
  return direction;
};

const EventsLog = (props: any) => {
  document.title = "Signal House Portal Messaging Logs";
  const [searchParams, setSearchParams] = useSearchParams();
  const dispatch = useDispatch<any>();

  const [loading, setLoading] = useState(false);
  const [currPage, setCurrPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [open, setOpen] = useState(false);
  const [datePickerModal, setDatePickerModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState<any>(null);
  const [showModal, setShowModal] = useState(false);

  const handleClose = () => setShowModal(false);
  const handleShow = () => setShowModal(true);

  const selectProfile = createSelector(
    (state: any) => state.OwnNumbers,
    (OwnNumbers) => ({
      loading: OwnNumbers.loading,
      loading2: OwnNumbers.loading,
    })
  );

  const serializeFormQuery = (params: any) => {
    return Object.keys(params)
      .map(
        (key) => encodeURIComponent(key) + "=" + encodeURIComponent(params[key])
      )
      .join("&");
  };

  const detailData = useSelector((state: any) => state?.OwnNumbers?.EventLogs);

  const { loading2 } = useSelector(selectProfile);

  const copyToClipboard = (data: any) => {
    navigator.clipboard.writeText(JSON.stringify(data, null, 2));
    toast.success("JSON Data copied");
  };

  const eventDetails = (row: any) => {
    setSelectedRow(row);
    handleShow();
  };

  const columns = [
    {
      name: <span className="font-weight-bold fs-sm">Date</span>,
      minWidth: "140px",
      selector: (row: { createdDate: string }) => row.createdDate || "",
      cell: (row: { createdDate: string }) => (
        <span className="d-flex align-items-center">
          <i className="bi bi-calendar3 me-2 fs-lg text-secondary" />
          {dateFormat(row.createdDate || "")}
        </span>
      ),
      sortable: true,
      sortFunction: (a: any, b: any) => {
        return (
          new Date(a.createdDate).getTime() - new Date(b.createdDate).getTime()
        );
      },
    },
    {
      name: <span className="font-weight-bold fs-sm">Web Hook URL</span>,
      selector: (row: { webHookUrl: string }) => row.webHookUrl || "",
      cell: (row: { webHookUrl: string }) => (
        <Tooltip
          placement="top"
          overlay={<p className="mb-0">{row.webHookUrl || ""}</p>}
        >
          <div>{row.webHookUrl || ""}</div>
        </Tooltip>
      ),
      sortable: true,
    },
    {
      name: (
        <span className="font-weight-bold fs-sm">Web Hook Http Response</span>
      ),
      minWidth: "130px",
      selector: (row: { webHookHttpResponse: string }) =>
        row.webHookHttpResponse || "",
      cell: (row: { webHookHttpResponse: string }) => {
        const responseCode = row.webHookHttpResponse || "-";

        let badgeClass =
          "badge bg-body-primary border border-primary text-primary";

        switch (responseCode) {
          case "200":
            badgeClass =
              "badge bg-body-secondary border border-secondary text-secondary";
            break;
          default:
            badgeClass =
              "badge bg-body-primary border border-primary text-primary";
        }

        return (
          <span
            className={`${badgeClass} fs-sm`}
            style={{
              width: "80px",
              display: "inline-block",
              textAlign: "center",
            }}
          >
            {responseCode}
          </span>
        );
      },
      sortable: true,
    },
    {
      name: <span className="font-weight-bold fs-sm">Status Description</span>,
      selector: (row: any) => row.sentDataToWebHook?.statusDescription || "-",
      sortable: true,
    },
  ];

  useEffect(() => {
    setLoading(true);
    handleGetData(currPage, rowsPerPage);
  }, []);

  const handleGetData = async (page: any, perPage: any, values?: any) => {
    setLoading(true);

    const startDate = values
      ? values.startDate
      : searchParams.get("startDate") ?? "";
    const endDate = values ? values.endDate : searchParams.get("endDate") ?? "";

    const allParams = {
      startDate,
      endDate,
    };
    const otherParams = Object.fromEntries(
      Object.entries(allParams).filter(
        ([_, value]) => value !== undefined && value !== ""
      )
    );
    await dispatch(
      getEventLogsThunk(
        { page: page, recordsPerPage: perPage },
        props.phoneNumber,
        otherParams || {}
      )
    );
    setLoading(false);
  };

  const handleRowClick = (row: any) => {
    eventDetails(row);
  };

  const handlePageChange = (page: number) => {
    setCurrPage(page);
    handleGetData(page, rowsPerPage);
  };

  const handleRowsPerPageChange = (rows: number) => {
    setCurrPage(1);
    setRowsPerPage(rows);
    handleGetData(1, rows);
  };

  return (
    <React.Fragment>
      <div className={"mb-4,page-content"}>
        <Container fluid>
          <BreadCrumb
            title={"Events Logs"}
            isFilter={true}
            filterButton={
              <Dropdown
                align="end"
                className="filterDropDown"
                show={open}
                onToggle={() => {
                  if (!datePickerModal) {
                    setOpen(!open);
                  }
                }}
              >
                <Dropdown.Toggle variant="secondary" className="ms-2">
                  <i className="bx bx-filter-alt me-1"></i>
                </Dropdown.Toggle>

                <Dropdown.Menu className="dropdown-menu-md p-4">
                  <Formik
                    initialValues={{
                      startDate: searchParams.get("startDate") ?? "",
                      endDate: searchParams.get("endDate") ?? "",
                    }}
                    enableReinitialize
                    onSubmit={(values) => {
                      const queryParams = serializeFormQuery(values);
                      setSearchParams(queryParams);
                      setOpen(false);
                      handleGetData(1, rowsPerPage, values);
                    }}
                  >
                    {({
                      handleSubmit,
                      getFieldProps,
                      setFieldValue,
                      resetForm,
                      values,
                    }) => (
                      <Form onSubmit={handleSubmit}>
                        <Row>
                          <Col sm={6} className="mb-3">
                            <Form.Label htmlFor="startDate">
                              Start Date & Time
                            </Form.Label>
                            <Flatpickr
                              className="form-control"
                              name="startDate"
                              placeholder="Start Date & Time"
                              options={{
                                enableTime: true,
                                onOpen: () => {
                                  setDatePickerModal(true);
                                },
                                onClose: () => {
                                  setDatePickerModal(false);
                                },
                                maxDate: new Date(values.endDate) || "",
                                altFormat: "F j, Y",
                                dateFormat: "Y-m-d H:i",
                                onChange: (
                                  value: any,
                                  dateStr: string,
                                  instance: any
                                ) => {
                                  const newValue = !!value
                                    ? new Date(value).toISOString()
                                    : value;

                                  setFieldValue("startDate", newValue);
                                  instance.close();
                                },
                              }}
                              value={values.startDate}
                            />
                          </Col>
                          <Col sm={6} className="mb-3">
                            <Form.Label htmlFor="endDate">
                              End Date & Time
                            </Form.Label>
                            <Flatpickr
                              className="form-control"
                              name="endDate"
                              placeholder="End Date & Time"
                              options={{
                                enableTime: true,
                                onOpen: () => {
                                  setDatePickerModal(true);
                                },
                                onClose: () => {
                                  setDatePickerModal(false);
                                },
                                minDate:
                                  new Date(getFieldProps("startDate").value) ||
                                  "",
                                altFormat: "F j, Y",
                                dateFormat: "Y-m-d H:i",
                                onChange: (
                                  value: any,
                                  dateStr: string,
                                  instance: any
                                ) => {
                                  const newValue = !!value
                                    ? new Date(value).toISOString()
                                    : value;
                                  setFieldValue("endDate", newValue);
                                  instance.close();
                                },
                              }}
                              value={values.endDate}
                            />
                          </Col>
                        </Row>
                        <div className="d-flex align-items-center justify-content-end">
                          <Button
                            className="btn btn-secondary me-2"
                            type="button"
                            disabled={loading2}
                            onClick={() => {
                              const queryParams =
                                serializeFormQuery(filterInitialState);
                              setSearchParams(queryParams);
                              resetForm();
                              setFieldValue("startDate", "");
                              setFieldValue("endDate", "");
                              setOpen(false);
                              handleGetData(
                                currPage,
                                rowsPerPage,
                                filterInitialState
                              );
                            }}
                          >
                            Clear
                          </Button>
                          <Button
                            className="btn btn-primary"
                            type="submit"
                            disabled={loading2}
                          >
                            {loading2 && (
                              <Spinner size="sm" animation="border" />
                            )}{" "}
                            Apply
                          </Button>
                        </div>
                      </Form>
                    )}
                  </Formik>
                </Dropdown.Menu>
              </Dropdown>
            }
          />

          <div className="position-relative">
            <Card className="wrapper">
              <Card.Body className="listing-table">
                {loading ? (
                  <div>
                    <img
                      src={Loader}
                      className={`position-absolute top-50 start-50 translate-middle`}
                    />
                  </div>
                ) : (
                  <Datatable
                    data={
                      Array.isArray(detailData?.records)
                        ? detailData?.records
                        : []
                    }
                    columns={columns}
                    handleRowClick={handleRowClick}
                    handlePageChange={handlePageChange}
                    handleRowsPerPageChange={handleRowsPerPageChange}
                    currPage={currPage}
                    rowsPerPage={rowsPerPage}
                    totalRecords={detailData?.totalRecords}
                  />
                )}
              </Card.Body>
            </Card>
          </div>
        </Container>
        {selectedRow && (
          <Modal show={showModal} onHide={handleClose} size="lg">
            <Modal.Header closeButton>
              <Modal.Title>Event Log Details</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Tab.Container defaultActiveKey="rowData">
                <Nav variant="tabs">
                  <Nav.Item>
                    <Nav.Link eventKey="rowData">Row Data</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="jsonData">JSON Data</Nav.Link>
                  </Nav.Item>
                </Nav>
                <Tab.Content>
                  <Tab.Pane eventKey="rowData">
                    <div className="p-2">
                      {Object.keys(selectedRow?.sentDataToWebHook ?? {})
                        .length > 0 ? (
                        Object.entries(selectedRow?.sentDataToWebHook).map(
                          (pair: [string, any], index) => (
                            <div className="d-flex gap-2 mt-2" key={index}>
                              <div className="text-secondary">
                                {pair[0]
                                  .replace(/([A-Z])/g, " $1")
                                  .toLowerCase()
                                  .replace(
                                    /(\w)(\w*)/g,
                                    (g0, g1, g2) =>
                                      g1.toUpperCase() + g2.toLowerCase()
                                  )}{" "}
                                :
                              </div>{" "}
                              <div>
                                {pair[0] === "cost"
                                  ? `$${Number(pair[1]).toFixed(4)} USD` ??
                                    "N/A"
                                  : pair[0] === "timestamp"
                                  ? dateFormat(pair[1])
                                  : pair[0] === "Direction"
                                  ? formatDirection(pair[1])
                                  : pair[1] ?? "N/A"}
                              </div>
                            </div>
                          )
                        )
                      ) : (
                        <div>No details available</div>
                      )}
                    </div>
                  </Tab.Pane>

                  <Tab.Pane eventKey="jsonData">
                    <Card.Body
                      className="text-white position-relative"
                      style={{
                        background: "rgb(18, 28, 45)",
                        borderBottomLeftRadius: 10,
                        borderBottomRightRadius: 10,
                        padding: 20,
                      }}
                    >
                      <div
                        className="position-absolute end-0 me-3 cursor-pointer"
                        onClick={() => copyToClipboard(selectedRow)}
                      >
                        <i className="bx bx-copy align-middle me-2 fs-3xl" />
                      </div>
                      <pre>
                        {JSON.stringify(
                          selectedRow?.sentDataToWebHook ?? {},
                          null,
                          2
                        )}
                      </pre>
                    </Card.Body>
                  </Tab.Pane>
                </Tab.Content>
              </Tab.Container>
            </Modal.Body>
          </Modal>
        )}
      </div>
    </React.Fragment>
  );
};

export default EventsLog;
