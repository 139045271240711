import { Col, Form, Row } from "react-bootstrap";

const Step3 = (props: any) => {
  const { validation } = props;

  return (
    <Row>
      <Col lg={6} className="mb-3">
        <Form.Label htmlFor="email">
          Support Email Address <span className="text-primary">*</span>
        </Form.Label>
        <Form.Control
          type="email"
          name="email"
          onChange={validation.handleChange}
          onBlur={validation.handleBlur}
          value={validation.values.email}
          isInvalid={
            validation.touched.email && validation.errors.email ? true : false
          }
        />
        {validation.touched.email && validation.errors.email ? (
          <Form.Control.Feedback type="invalid">
            {validation.errors.email}
          </Form.Control.Feedback>
        ) : null}
      </Col>
      <Col lg={6} className="mb-3">
        <Form.Label htmlFor="phone">
          Mobile Phone <span className="text-primary">*</span>
        </Form.Label>
        <Form.Control
          type="text"
          name="phone"
          onChange={validation.handleChange}
          onBlur={validation.handleBlur}
          value={validation.values.phone}
          isInvalid={
            validation.touched.phone && validation.errors.phone ? true : false
          }
        />
        {validation.touched.phone && validation.errors.phone ? (
          <Form.Control.Feedback type="invalid">
            {validation.errors.phone}
          </Form.Control.Feedback>
        ) : null}
      </Col>
    </Row>
  );
};

export default Step3;
