import { Form } from "react-bootstrap";

export default function Step2({ validation2, setActiveStep }: any) {
  return (
    <div className="p-5 p-lg-3 p-xxl-5">
      <div className="text-center">
        <p className="text-muted fs-xl mb-3">1/3</p>
        <h5 className="fs-3xl" style={{ maxWidth: 460 }}>
          What system(s) are you integrating with{" "}
          <span className="text-primary">Signal House?</span>
        </h5>
      </div>
      <div className="mt-5">
        <Form
          onSubmit={(e) => {
            e.preventDefault();
            validation2.handleSubmit();
            return false;
          }}
        >
          <div>
            {validation2.touched.systems && validation2.errors.systems ? (
              <p className="text-primary mb-3">{validation2.errors.systems}</p>
            ) : null}
          </div>

          <div className="d-flex flex-column align-items-center">
            <div>
              <Form.Check
                type="checkbox"
                id={"Hubspot"}
                label={"Hubspot"}
                checked={validation2.values.systems?.some(
                  (dt: string) => dt === "Hubspot"
                )}
                onChange={(e: any) => {
                  let temp = [...validation2.values.systems];
                  if (e.target.checked) {
                    temp.push("Hubspot");
                  } else {
                    temp = temp.filter((dt) => dt !== "Hubspot");
                  }
                  validation2.setFieldValue("systems", temp);
                }}
                className="mb-3"
              />

              <Form.Check
                type="checkbox"
                id={"Salesforce"}
                label={"Salesforce"}
                checked={validation2.values.systems?.some(
                  (dt: string) => dt === "Salesforce"
                )}
                onChange={(e: any) => {
                  let temp = [...validation2.values.systems];
                  if (e.target.checked) {
                    temp.push("Salesforce");
                  } else {
                    temp = temp.filter((dt) => dt !== "Salesforce");
                  }
                  validation2.setFieldValue("systems", temp);
                }}
                className="mb-3"
              />

              <Form.Check
                type="checkbox"
                id={"Other"}
                label={"Other"}
                checked={validation2.values.systems?.some(
                  (dt: string) => dt === "Other"
                )}
                onChange={(e: any) => {
                  let temp = [...validation2.values.systems];
                  if (e.target.checked) {
                    temp.push("Other");
                  } else {
                    temp = temp.filter((dt) => dt !== "Other");
                  }
                  validation2.setFieldValue("systems", temp);
                }}
                className="mb-3"
              />
            </div>

            {validation2.values.systems?.some(
              (dt: string) => dt === "Other"
            ) ? (
              <div className="w-100">
                <Form.Control
                  type="text"
                  id="otherText"
                  name="otherText"
                  placeholder="Enter the name of System?"
                  onChange={validation2.handleChange}
                  onBlur={validation2.handleBlur}
                  value={validation2.values.otherText || ""}
                  isInvalid={
                    validation2.touched.otherText &&
                    validation2.errors.otherText
                      ? true
                      : false
                  }
                />
                {validation2.touched.otherText &&
                validation2.errors.otherText ? (
                  <Form.Control.Feedback type="invalid">
                    {validation2.errors.otherText}
                  </Form.Control.Feedback>
                ) : null}
              </div>
            ) : null}
          </div>

          <div className="d-flex align-items-start justify-content-center gap-3 mt-4">
            <button
              type="button"
              className="btn btn-link text-decoration-none btn-label previestab"
              onClick={() => {
                setActiveStep(1);
              }}
            >
              <i className="ri-arrow-left-line label-icon align-middle fs-lg"></i>
              Prev
            </button>
            <button
              type="submit"
              className="btn btn-secondary btn-label right nexttab nexttab"
            >
              <i className="ri-arrow-right-line label-icon align-middle fs-lg ms-2"></i>
              Next
            </button>
          </div>
        </Form>
      </div>
    </div>
  );
}
