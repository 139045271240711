import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  state: false,
  data: {},
  path: "",
  bottomsheetbody: "",
};

const bottomsheetDataSlice = createSlice({
  name: "bottomsheet",
  initialState,
  reducers: {
    openBottomsheet(state, action) {
      state.state = true;
      state.data = action?.payload?.data;
      state.path = action?.payload?.path;
      state.bottomsheetbody = action?.payload?.bottomsheetbody;
    },
    closeBottomsheet(state, action) {
      state.state = false;
      state.data = {};
      state.path = "";
      state.bottomsheetbody = "";
    },
  },
});

export const { openBottomsheet, closeBottomsheet } =
  bottomsheetDataSlice.actions;

export default bottomsheetDataSlice.reducer;
