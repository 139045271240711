import { getAccountPricingApi } from "helpers/apiroute_helper";
import {
  apiLoading,
  walletPricingDataSuccessful,
  walletPricingDataFailed,
} from "./reducer";
import { toast } from "react-toastify";

export const fatchAccountPricingThunk = () => async (dispatch: any) => {
  dispatch(apiLoading());

  try {
    const response = await getAccountPricingApi();
    if (response?.data?.code || response?.data?.message) {
      dispatch(walletPricingDataFailed(response));
      toast.error(response?.data?.message || "Something went wrong");
    } else {
      dispatch(walletPricingDataSuccessful(response));
    }
  } catch (error: any) {
    const errorMessage =
      error.response?.data?.message || "Something went wrong";
    dispatch(walletPricingDataFailed({ message: errorMessage }));
    toast.error(errorMessage);
  }
};
