import React, { useEffect, useState } from "react";
import { Button, Card, Col, Container, Row } from "react-bootstrap";
import CountUp from "react-countup";
import { createSelector } from "reselect";
import { useDispatch, useSelector } from "react-redux";
import {
  getAnalyticsMmsV1Thunk,
  getAnalyticsSmsV1Thunk,
  getStatusLogsThunk,
} from "slices/thunk";
import Loader from "assets/images/spinner-dark.svg";
import {
  dateRangeArr,
  getAnalyticCardData,
  getAnalyticDateGraphData,
  getAnalyticNumberGraphData,
  removeEmptyAndNullValues,
} from "helpers/common";
import moment from "moment";
import { useFormik } from "formik";
import * as Yup from "yup";
import StackedColumn2 from "./StackedColumn2";
import LineChart from "./LineChart";
import SmsAndMmsLogs from "./SmsAndMmsLogs";
import withRouter from "Common/withRouter";
import AnalyticsHeader from "./AnalyticsHeader";
import { useSearchParams } from "react-router-dom";
import { getLocal, setLocal } from "helpers/services/auth/auth";

const selectedTypeArr = ["All", "SMS", "MMS"];
const selectedTypeArr2 = ["All", "Outbound", "Inbound"];

function convertSeconds(milliseconds: number, showString?: boolean) {
  const totalSeconds = Math.floor(milliseconds / 1000);
  const hours = Math.floor(totalSeconds / 3600);
  const minutes = Math.floor((totalSeconds % 3600) / 60);
  const seconds = totalSeconds % 60;

  return showString
    ? hours
      ? `${hours} hour, ${minutes} minutes, and ${seconds} seconds`
      : `${minutes} minutes, and ${seconds} seconds`
    : `${hours}h:${minutes}m:${seconds}s`;
}

const Analytics = (props: any) => {
  const [searchParams] = useSearchParams();
  const type = searchParams.get("type");
  document.title = "Signal House Portal Analytics";

  const dispatch = useDispatch<any>();

  const selectProfile = createSelector(
    (state: any) => state.Analytics,
    (state: any) => state.Groups,
    (analytics, groups) => ({
      loading: analytics.loading,
      loading2: analytics.loading2,
      analyticsAllV1: analytics.analyticsAllV1,
      statusLogs: analytics.statusLogs,
      error: analytics.error,
      groupDetails: groups?.AllGroups,
    })
  );

  const { loading, loading2, analyticsAllV1, statusLogs, groupDetails } =
    useSelector(selectProfile);

  const [selectedType, setSelectedType] = useState(selectedTypeArr[0]);
  const [selectedType2, setSelectedType2] = useState(selectedTypeArr2[1]);
  const [selectedSubType, setSelectedSubType] = useState("totalMessagesSent");
  const [selectedSubTypeLabel, setSelectedSubTypeLabel] = useState(
    "Total messages sent"
  );
  const [open, setOpen] = useState(false);
  const [datePickerModal, setDatePickerModal] = useState(false);
  const [dateRangeTemp, setDateRangeTemp] = useState(dateRangeArr[1]);
  const [cardObj, setCardObj] = useState<any>({});
  const [dateGraphObj, setDateGraphObj] = useState<any>({});
  const [numberGraphObj, setNumberGraphObj] = useState<any>({});
  const [graphType1, setGraphType1] = useState("Column");
  const [graphType2, setGraphType2] = useState("Column");
  const [currPage, setCurrPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const validation: any = useFormik({
    enableReinitialize: true,
    initialValues: {
      groupId: "",
      subGroupId: [],
      brandId: [],
      campaignId: [],
      phoneNumber: [],
      tag: [],
      createdAt: "",
      startDate: moment().subtract(7, "days").startOf("day").toISOString(),
      endDate: moment().endOf("day").format("MM-DD-YYYY"),
    },
    validationSchema: Yup.object({
      groupId: Yup.string().required("Please Select Group"),
    }),
    onSubmit: (values) => {
      const temp = {
        ...values,
        subGroupId: values.subGroupId.length > 0 ? values.subGroupId : "",
        brandId:
          values.brandId.length > 0
            ? values.brandId?.map((dt: any) => dt.brandId)
            : "",
        campaignId:
          values.campaignId.length > 0
            ? values.campaignId?.map((dt: any) => dt.campaignId)
            : "",
        phoneNumber:
          values.phoneNumber.length > 0
            ? values.phoneNumber?.map((dt: any) => dt.phoneNumber)
            : "",
        tag: values.tag.length > 0 ? values.tag?.map((dt: any) => dt.tag) : "",
        startDate: values.startDate
          ? moment(values.startDate).startOf("day").toISOString()
          : "",
        endDate: values.endDate
          ? moment(values.endDate, "MM-DD-YYYY").endOf("day").toISOString()
          : "",
      };
      setOpen(!open);
      handleGetData(temp);

      setLocal("analyticsDateRangeTemp", JSON.stringify(dateRangeTemp));
      setLocal("analyticsStartDate", JSON.stringify(values.startDate));
      setLocal("analyticsEndDate", JSON.stringify(values.endDate));
    },
  });

  useEffect(() => {
    if (groupDetails?.records?.[0]?.group_id) {
      const temp = getLocal("analyticsDateRangeTemp");
      temp
        ? setDateRangeTemp(JSON.parse(temp))
        : setDateRangeTemp(dateRangeArr[1]);

      const temp1 = getLocal("analyticsStartDate");
      const temp2 = getLocal("analyticsEndDate");

      const createdAt = `${
        temp1
          ? moment(JSON.parse(temp1)).format("MM-DD-YYYY")
          : moment().subtract(7, "days").startOf("day").format("MM-DD-YYYY")
      } to ${
        temp2
          ? moment(JSON.parse(temp2)).format("MM-DD-YYYY")
          : moment().endOf("day").format("MM-DD-YYYY")
      }`;
      const startDate = temp1
        ? JSON.parse(temp1)
        : moment().subtract(7, "days").startOf("day").toISOString();
      const endDate = temp2
        ? JSON.parse(temp2)
        : moment().endOf("day").format("MM-DD-YYYY");

      validation.setFieldValue("groupId", groupDetails?.records?.[0]?.group_id);
      validation.setFieldValue("createdAt", createdAt);
      validation.setFieldValue("startDate", startDate);
      validation.setFieldValue("endDate", endDate);

      handleGetData({
        ...validation.values,
        groupId: groupDetails?.records?.[0]?.group_id,
        subGroupId:
          validation.values.subGroupId.length > 0
            ? validation.values.subGroupId
            : "",
        brandId:
          validation.values.brandId.length > 0
            ? validation.values.brandId?.map((dt: any) => dt.brandId)
            : "",
        campaignId:
          validation.values.campaignId.length > 0
            ? validation.values.campaignId?.map((dt: any) => dt.campaignId)
            : "",
        phoneNumber:
          validation.values.phoneNumber.length > 0
            ? validation.values.phoneNumber?.map((dt: any) => dt.phoneNumber)
            : "",
        tag:
          validation.values.tag.length > 0
            ? validation.values.tag?.map((dt: any) => dt.tag)
            : "",
        startDate: startDate
          ? moment(startDate).startOf("day").toISOString()
          : "",
        endDate: endDate
          ? moment(endDate, "MM-DD-YYYY").endOf("day").toISOString()
          : "",
      });
    }
  }, [groupDetails?.records?.[0]?.group_id]);

  useEffect(() => {
    if (groupDetails?.records?.[0]?.group_id) {
      const params = {
        ...validation.values,
        groupId: groupDetails?.records?.[0]?.group_id,
        subGroupId:
          validation.values.subGroupId.length > 0
            ? validation.values.subGroupId
            : "",
        brandId:
          validation.values.brandId.length > 0
            ? validation.values.brandId?.map((dt: any) => dt.brandId)
            : "",
        campaignId:
          validation.values.campaignId.length > 0
            ? validation.values.campaignId?.map((dt: any) => dt.campaignId)
            : "",
        phoneNumber:
          validation.values.phoneNumber.length > 0
            ? validation.values.phoneNumber?.map((dt: any) => dt.phoneNumber)
            : "",
        tag:
          validation.values.tag.length > 0
            ? validation.values.tag?.map((dt: any) => dt.tag)
            : "",
        endDate: moment(validation.values.endDate, "MM-DD-YYYY")
          .endOf("day")
          .toISOString(),
      };
      setCurrPage(1);
      setRowsPerPage(10);
      handleStatusGetData(1, 10, removeEmptyAndNullValues({ ...params }));
    }
  }, [groupDetails?.records?.[0]?.group_id, selectedSubType]);

  useEffect(() => {
    if (Object.keys(analyticsAllV1)?.length) {
      getAnalyticCardData({
        endDate: validation.values?.endDate,
        startDate: validation.values?.startDate,
        analyticsAllV1,
        setCardObj,
      });
      getAnalyticDateGraphData({
        selectedType,
        analyticsAllV1,
        setDateGraphObj,
      });
      getAnalyticNumberGraphData({
        selectedType,
        analyticsAllV1,
        setNumberGraphObj,
      });
    }
  }, [analyticsAllV1]);

  useEffect(() => {
    if (Object.keys(analyticsAllV1)?.length) {
      getAnalyticDateGraphData({
        selectedType,
        analyticsAllV1,
        setDateGraphObj,
      });
      getAnalyticNumberGraphData({
        selectedType,
        analyticsAllV1,
        setNumberGraphObj,
      });
    }
  }, [selectedType]);

  useEffect(() => {
    if (type) {
      const typeMapping: Record<
        string,
        { selectedType: string; selectedType2: string; selectedSubType: string }
      > = {
        all: {
          selectedType: selectedTypeArr[0],
          selectedType2: selectedTypeArr2[0],
          selectedSubType: "totalMessagesSent",
        },
        sms_outbound: {
          selectedType: selectedTypeArr[1],
          selectedType2: selectedTypeArr2[1],
          selectedSubType: "totalMessagesSent",
        },
        sms_inbound: {
          selectedType: selectedTypeArr[1],
          selectedType2: selectedTypeArr2[2],
          selectedSubType: "totalMessagesSent",
        },
        mms_outbound: {
          selectedType: selectedTypeArr[2],
          selectedType2: selectedTypeArr2[1],
          selectedSubType: "totalMessagesSent",
        },
        mms_inbound: {
          selectedType: selectedTypeArr[2],
          selectedType2: selectedTypeArr2[2],
          selectedSubType: "totalMessagesSent",
        },
        "all-d-rate": {
          selectedType: selectedTypeArr[0],
          selectedType2: selectedTypeArr2[1],
          selectedSubType: "deliveryRate",
        },
        "sms_outbound_d-rate": {
          selectedType: selectedTypeArr[1],
          selectedType2: selectedTypeArr2[1],
          selectedSubType: "deliveryRate",
        },
        "mms_outbound_d-rate": {
          selectedType: selectedTypeArr[2],
          selectedType2: selectedTypeArr2[1],
          selectedSubType: "deliveryRate",
        },
        "optout-rate": {
          selectedType: selectedTypeArr[0],
          selectedType2: selectedTypeArr2[1],
          selectedSubType: "optOutRate",
        },
        "sms_optout-rate": {
          selectedType: selectedTypeArr[1],
          selectedType2: selectedTypeArr2[1],
          selectedSubType: "optOutRate",
        },
        "mms_optout-rate": {
          selectedType: selectedTypeArr[2],
          selectedType2: selectedTypeArr2[1],
          selectedSubType: "optOutRate",
        },
        non_optout_rate: {
          selectedType: selectedTypeArr[0],
          selectedType2: selectedTypeArr2[2],
          selectedSubType: "responseRate",
        },
        sms_non_optout_rate: {
          selectedType: selectedTypeArr[1],
          selectedType2: selectedTypeArr2[2],
          selectedSubType: "responseRate",
        },
        mms_non_optout_rate: {
          selectedType: selectedTypeArr[2],
          selectedType2: selectedTypeArr2[2],
          selectedSubType: "responseRate",
        },
      };
      if (type in typeMapping) {
        const { selectedType, selectedType2, selectedSubType } =
          typeMapping[type];
        setSelectedType(selectedType);
        setSelectedType2(selectedType2);
        setSelectedSubType(selectedSubType);
      }
    }
  }, [type]);

  const handleGetData = (params?: any) => {
    const cb = () => {
      dispatch(
        getAnalyticsMmsV1Thunk(
          removeEmptyAndNullValues({ ...params, createdAt: "" })
        )
      );
      setCurrPage(1);
      setRowsPerPage(10);
      handleStatusGetData(1, 10, removeEmptyAndNullValues({ ...params }));
    };

    dispatch(
      getAnalyticsSmsV1Thunk(
        removeEmptyAndNullValues({ ...params, createdAt: "" }),
        cb
      )
    );
  };

  const handleStatusGetData = (
    page: number,
    perPage: number,
    otherParams?: any
  ) => {
    dispatch(
      getStatusLogsThunk(
        page,
        perPage,
        removeEmptyAndNullValues({
          ...otherParams,
          createdAt: "",
          statusFilter:
            selectedSubType === "failed" || selectedSubType === "failedRate"
              ? ["failed"]
              : ["delivered", "sent"],
          direction:
            selectedSubType === "totalInbound" ||
            selectedSubType === "averageMessagesReceived" ||
            selectedSubType === "segmentReceived" ||
            selectedSubType === "averageSegmentReceived" ||
            selectedSubType === "responseRate" ||
            selectedSubType === "averageResponseTime"
              ? "inbound"
              : "outbound",
          hasOptOut:
            selectedSubType === "totalOptOut" ||
            selectedSubType === "optOutRate"
              ? "true"
              : "",
        })
      )
    );
  };

  const handleRowClick = (row: { type: string; signalHouseSID: string }) => {
    row.type === "SMS"
      ? props.router.navigate(`/sms-logs-details/${row.signalHouseSID}`)
      : props.router.navigate(`/mms-logs-details/${row.signalHouseSID}`);
  };

  const handlePageChange = (page: number) => {
    setCurrPage(page);
    handleStatusGetData(
      page,
      rowsPerPage,
      removeEmptyAndNullValues({
        ...validation.values,
        subGroupId:
          validation.values.subGroupId.length > 0
            ? validation.values.subGroupId
            : "",
        brandId:
          validation.values.brandId.length > 0
            ? validation.values.brandId?.map((dt: any) => dt.brandId)
            : "",
        campaignId:
          validation.values.campaignId.length > 0
            ? validation.values.campaignId?.map((dt: any) => dt.campaignId)
            : "",
        phoneNumber:
          validation.values.phoneNumber.length > 0
            ? validation.values.phoneNumber?.map((dt: any) => dt.phoneNumber)
            : "",
        tag:
          validation.values.tag.length > 0
            ? validation.values.tag?.map((dt: any) => dt.tag)
            : "",
      })
    );
  };

  const handleRowsPerPageChange = (rows: number) => {
    setCurrPage(1);
    setRowsPerPage(rows);
    handleStatusGetData(
      1,
      rows,
      removeEmptyAndNullValues({
        ...validation.values,
        subGroupId:
          validation.values.subGroupId.length > 0
            ? validation.values.subGroupId
            : "",
        brandId:
          validation.values.brandId.length > 0
            ? validation.values.brandId?.map((dt: any) => dt.brandId)
            : "",
        campaignId:
          validation.values.campaignId.length > 0
            ? validation.values.campaignId?.map((dt: any) => dt.campaignId)
            : "",
        phoneNumber:
          validation.values.phoneNumber.length > 0
            ? validation.values.phoneNumber?.map((dt: any) => dt.phoneNumber)
            : "",
        tag:
          validation.values.tag.length > 0
            ? validation.values.tag?.map((dt: any) => dt.tag)
            : "",
      })
    );
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <AnalyticsHeader
            selectedType={selectedType}
            setSelectedType={setSelectedType}
            selectedType2={selectedType2}
            setSelectedType2={setSelectedType2}
            selectedSubType={selectedSubType}
            setSelectedSubType={setSelectedSubType}
            setSelectedSubTypeLabel={setSelectedSubTypeLabel}
            validation={validation}
            dateRangeTemp={dateRangeTemp}
            setDateRangeTemp={setDateRangeTemp}
            datePickerModal={datePickerModal}
            setDatePickerModal={setDatePickerModal}
            setOpen={setOpen}
            open={open}
            loading={loading}
            handleGetData={handleGetData}
            groupDetails={groupDetails}
          />
          {loading ? (
            <div className="position-relative wrapper listing-table">
              <img
                src={Loader}
                className="position-absolute top-50 start-50 translate-middle"
              />
            </div>
          ) : (
            <>
              {(selectedType2 == "All" || selectedType2 == "Outbound") && (
                <>
                  <h4 className="mb-3">Outbound</h4>
                  <Card>
                    <Card.Body>
                      <Row className="g-4">
                        <Col
                          xl={3}
                          md={3}
                          sm={6}
                          className={`custom-col border-end-sm analytics-card ${
                            selectedSubType === "deliveryRate" ? "active" : ""
                          }`}
                          onClick={() => {
                            setSelectedSubType("deliveryRate");
                            setSelectedSubTypeLabel("Delivery Rate");
                          }}
                        >
                          <div className="d-flex align-items-center gap-2 mb-4">
                            <div className="avatar-xs flex-shrink-0">
                              <div className="avatar-title bg-body-secondary text-secondary border border-secondary rounded-circle">
                                <i className="bi bi-check-all fs-2xl" />
                              </div>
                            </div>
                            <div className="flex-grow-1">
                              <p className="text-muted mb-0">Delivery Rate</p>
                            </div>
                          </div>
                          <h3>
                            <CountUp
                              suffix="%"
                              decimals={2}
                              start={0}
                              end={(
                                cardObj?.[selectedType]?.deliveryRate || 0
                              ).toFixed(2)}
                            />
                          </h3>
                          {selectedType === "All" ? (
                            Object.keys(cardObj)?.length > 0 ? (
                              Object.keys(cardObj)?.map(
                                (dt, i) =>
                                  dt !== "All" && (
                                    <p className="text-muted mb-1" key={i}>
                                      {dt || ""}:{" "}
                                      {(
                                        cardObj?.[dt]?.deliveryRate || 0
                                      ).toFixed(2)}
                                      %
                                    </p>
                                  )
                              )
                            ) : null
                          ) : (
                            <p className="text-muted mb-1">
                              {selectedType || ""}:{" "}
                              {(
                                cardObj?.[selectedType]?.deliveryRate || 0
                              ).toFixed(2)}
                              %
                            </p>
                          )}
                        </Col>
                        <Col
                          xl={3}
                          md={3}
                          sm={6}
                          className={`border-end-sm-0 custom-col border-end-md analytics-card ${
                            selectedSubType === "totalMessagesSent"
                              ? "active"
                              : ""
                          }`}
                          onClick={() => {
                            setSelectedSubType("totalMessagesSent");
                            setSelectedSubTypeLabel("Total messages sent");
                          }}
                        >
                          <div className="d-flex align-items-center gap-2 mb-4">
                            <div className="avatar-xs flex-shrink-0">
                              <div className="avatar-title bg-body-secondary text-secondary border border-secondary rounded-circle">
                                <i className="bi bi-check fs-3xl" />
                              </div>
                            </div>
                            <div className="flex-grow-1">
                              <p className="text-muted mb-0">
                                Total messages sent
                              </p>
                            </div>
                          </div>
                          <h3>
                            <CountUp
                              start={0}
                              end={
                                cardObj?.[selectedType]?.totalMessagesSent || 0
                              }
                            />
                          </h3>
                          {selectedType === "All" ? (
                            Object.keys(cardObj)?.length > 0 ? (
                              Object.keys(cardObj)?.map(
                                (dt, i) =>
                                  dt !== "All" && (
                                    <p className="text-muted mb-1" key={i}>
                                      {dt || ""}:{" "}
                                      {cardObj?.[dt]?.totalMessagesSent || 0}
                                    </p>
                                  )
                              )
                            ) : null
                          ) : (
                            <p className="text-muted mb-1">
                              {selectedType || ""}:{" "}
                              {cardObj?.[selectedType]?.totalMessagesSent || 0}
                            </p>
                          )}
                        </Col>
                        <Col
                          xl={3}
                          md={3}
                          sm={6}
                          className={`custom-col border-end-sm analytics-card ${
                            selectedSubType === "averageMessagesSent"
                              ? "active"
                              : ""
                          }`}
                          onClick={() => {
                            setSelectedSubType("averageMessagesSent");
                            setSelectedSubTypeLabel("Average messages sent");
                          }}
                        >
                          <div className="d-flex align-items-center gap-2 mb-4">
                            <div className="avatar-xs flex-shrink-0">
                              <div className="avatar-title bg-body-secondary text-secondary border border-secondary rounded-circle">
                                <i className="bi bi-chevron-bar-expand fs-md" />
                              </div>
                            </div>
                            <div className="flex-grow-1">
                              <p className="text-muted mb-0">
                                Average messages sent
                              </p>
                            </div>
                          </div>
                          <h3>
                            <CountUp
                              decimals={2}
                              start={0}
                              end={(
                                cardObj?.[selectedType]?.averageMessagesSent ||
                                0
                              ).toFixed(2)}
                            />
                          </h3>
                          {selectedType === "All" ? (
                            Object.keys(cardObj)?.length > 0 ? (
                              Object.keys(cardObj)?.map(
                                (dt, i) =>
                                  dt !== "All" && (
                                    <p className="text-muted mb-1" key={i}>
                                      {dt || ""}:{" "}
                                      {(
                                        cardObj?.[dt]?.averageMessagesSent || 0
                                      ).toFixed(2)}
                                    </p>
                                  )
                              )
                            ) : null
                          ) : (
                            <p className="text-muted mb-1">
                              {selectedType || ""}:{" "}
                              {(
                                cardObj?.[selectedType]?.averageMessagesSent ||
                                0
                              ).toFixed(2)}
                            </p>
                          )}
                        </Col>
                        <Col
                          xl={3}
                          md={3}
                          sm={6}
                          className={`custom-col border-end-sm analytics-card ${
                            selectedSubType === "segmentSent" ? "active" : ""
                          }`}
                          onClick={() => {
                            setSelectedSubType("segmentSent");
                            setSelectedSubTypeLabel("Total segments sent");
                          }}
                        >
                          <div className="d-flex align-items-center gap-2 mb-4">
                            <div className="avatar-xs flex-shrink-0">
                              <div className="avatar-title bg-body-secondary text-secondary border border-secondary rounded-circle">
                                <i className="bi bi-clipboard2-check fs-md" />
                              </div>
                            </div>
                            <div className="flex-grow-1">
                              <p className="text-muted mb-0">
                                Total segments sent
                              </p>
                            </div>
                          </div>
                          <h3>
                            <CountUp
                              decimals={0}
                              start={0}
                              end={cardObj?.[selectedType]?.segmentSent || 0}
                            />
                          </h3>
                          {selectedType === "All" ? (
                            Object.keys(cardObj)?.length > 0 ? (
                              Object.keys(cardObj)?.map(
                                (dt, i) =>
                                  dt !== "All" && (
                                    <p className="text-muted mb-1" key={i}>
                                      {dt || ""}:{" "}
                                      {cardObj?.[dt]?.segmentSent || 0}
                                    </p>
                                  )
                              )
                            ) : null
                          ) : (
                            <p className="text-muted mb-1">
                              {selectedType || ""}:{" "}
                              {cardObj?.[selectedType]?.segmentSent || 0}
                            </p>
                          )}
                        </Col>
                        <Col
                          xl={3}
                          md={3}
                          sm={6}
                          className={`custom-col border-end-0 analytics-card ${
                            selectedSubType === "averageSegmentsSent"
                              ? "active"
                              : ""
                          }`}
                          onClick={() => {
                            setSelectedSubType("averageSegmentsSent");
                            setSelectedSubTypeLabel("Average segment sent");
                          }}
                        >
                          <div className="d-flex align-items-center gap-2 mb-4">
                            <div className="avatar-xs flex-shrink-0">
                              <div className="avatar-title bg-body-secondary text-secondary border border-secondary rounded-circle">
                                <i className="bi bi-boxes fs-lg" />
                              </div>
                            </div>
                            <div className="flex-grow-1">
                              <p className="text-muted mb-0">
                                Average segment sent
                              </p>
                            </div>
                          </div>
                          <h3>
                            <CountUp
                              decimals={2}
                              start={0}
                              end={(
                                cardObj?.[selectedType]?.averageSegmentsSent ||
                                0
                              ).toFixed(2)}
                            />
                          </h3>
                          {selectedType === "All" ? (
                            Object.keys(cardObj)?.length > 0 ? (
                              Object.keys(cardObj)?.map(
                                (dt, i) =>
                                  dt !== "All" && (
                                    <p className="text-muted mb-1" key={i}>
                                      {dt || ""}:{" "}
                                      {(
                                        cardObj?.[dt]?.averageSegmentsSent || 0
                                      ).toFixed(2)}
                                    </p>
                                  )
                              )
                            ) : null
                          ) : (
                            <p className="text-muted mb-1">
                              {selectedType || ""}:{" "}
                              {(
                                cardObj?.[selectedType]?.averageSegmentsSent ||
                                0
                              ).toFixed(2)}
                            </p>
                          )}
                        </Col>
                      </Row>
                    </Card.Body>
                  </Card>

                  <Card>
                    <Card.Body>
                      <Row>
                        <Col
                          xl={3}
                          md={3}
                          sm={6}
                          className={`border-end-sm analytics-card ${
                            selectedSubType === "failed" ? "active" : ""
                          }`}
                          onClick={() => {
                            setSelectedSubType("failed");
                            setSelectedSubTypeLabel("Failed");
                          }}
                        >
                          <div className="d-flex align-items-center gap-2 mb-4">
                            <div className="avatar-xs flex-shrink-0">
                              <div className="avatar-title bg-body-secondary text-primary border border-primary rounded-circle">
                                <i className="bi bi-x-circle-fill fs-md" />
                              </div>
                            </div>
                            <div className="flex-grow-1">
                              <p className="text-muted mb-0">Failed</p>
                            </div>
                          </div>
                          <h3>
                            <CountUp
                              decimals={0}
                              start={0}
                              end={cardObj?.[selectedType]?.failed || 0}
                            />
                          </h3>
                          {selectedType === "All" ? (
                            Object.keys(cardObj)?.length > 0 ? (
                              Object.keys(cardObj)?.map(
                                (dt, i) =>
                                  dt !== "All" && (
                                    <p className="text-muted mb-1" key={i}>
                                      {dt || ""}: {cardObj?.[dt]?.failed || 0}
                                    </p>
                                  )
                              )
                            ) : null
                          ) : (
                            <p className="text-muted mb-1">
                              {selectedType || ""}:{" "}
                              {cardObj?.[selectedType]?.failed || 0}
                            </p>
                          )}
                        </Col>
                        <Col
                          xl={3}
                          md={3}
                          sm={6}
                          className={`border-end-sm analytics-card ${
                            selectedSubType === "failedRate" ? "active" : ""
                          }`}
                          onClick={() => {
                            setSelectedSubType("failedRate");
                            setSelectedSubTypeLabel("Failure rate");
                          }}
                        >
                          <div className="d-flex align-items-center gap-2 mb-4">
                            <div className="avatar-xs flex-shrink-0">
                              <div className="avatar-title bg-body-secondary text-primary border border-primary rounded-circle">
                                <i className="bi bi-x-circle-fill fs-md" />
                              </div>
                            </div>
                            <div className="flex-grow-1">
                              <p className="text-muted mb-0">Failure rate</p>
                            </div>
                          </div>
                          <h3>
                            <CountUp
                              suffix="%"
                              decimals={2}
                              start={0}
                              end={(
                                cardObj?.[selectedType]?.failedRate || 0
                              ).toFixed(2)}
                            />
                          </h3>
                          {selectedType === "All" ? (
                            Object.keys(cardObj)?.length > 0 ? (
                              Object.keys(cardObj)?.map(
                                (dt, i) =>
                                  dt !== "All" && (
                                    <p className="text-muted mb-1" key={i}>
                                      {dt || ""}:{" "}
                                      {(cardObj?.[dt]?.failedRate || 0).toFixed(
                                        2
                                      )}
                                      %
                                    </p>
                                  )
                              )
                            ) : null
                          ) : (
                            <p className="text-muted mb-1">
                              {selectedType || ""}:{" "}
                              {(
                                cardObj?.[selectedType]?.failedRate || 0
                              ).toFixed(2)}
                              %
                            </p>
                          )}
                        </Col>
                        <Col
                          xl={3}
                          md={3}
                          sm={6}
                          className={`border-end-sm analytics-card ${
                            selectedSubType === "totalOptOut" ? "active" : ""
                          }`}
                          onClick={() => {
                            setSelectedSubType("totalOptOut");
                            setSelectedSubTypeLabel("Total Opt Out");
                          }}
                        >
                          <div className="d-flex align-items-center gap-2 mb-4">
                            <div className="avatar-xs flex-shrink-0">
                              <div className="avatar-title bg-body-secondary text-primary border border-primary rounded-circle">
                                <i className="bi bi-send-fill fs-md" />
                              </div>
                            </div>
                            <div className="flex-grow-1">
                              <p className="text-muted mb-0">Total Opt Out</p>
                            </div>
                          </div>
                          <h3>
                            <CountUp
                              decimals={0}
                              start={0}
                              end={cardObj?.[selectedType]?.totalOptOut || 0}
                            />
                          </h3>
                          {selectedType === "All" ? (
                            Object.keys(cardObj)?.length > 0 ? (
                              Object.keys(cardObj)?.map(
                                (dt, i) =>
                                  dt !== "All" && (
                                    <p className="text-muted mb-1" key={i}>
                                      {dt || ""}:{" "}
                                      {cardObj?.[dt]?.totalOptOut || 0}
                                    </p>
                                  )
                              )
                            ) : null
                          ) : (
                            <p className="text-muted mb-1">
                              {selectedType || ""}:{" "}
                              {cardObj?.[selectedType]?.totalOptOut || 0}
                            </p>
                          )}
                        </Col>
                        <Col
                          xl={3}
                          md={3}
                          sm={6}
                          className={`border-end-0 analytics-card ${
                            selectedSubType === "optOutRate" ? "active" : ""
                          }`}
                          onClick={() => {
                            setSelectedSubType("optOutRate");
                            setSelectedSubTypeLabel("Opt Out rate");
                          }}
                        >
                          <div className="d-flex align-items-center gap-2 mb-4">
                            <div className="avatar-xs flex-shrink-0">
                              <div className="avatar-title bg-body-secondary text-primary border border-primary rounded-circle">
                                <i className="bi bi-send-fill fs-md" />
                              </div>
                            </div>
                            <div className="flex-grow-1">
                              <p className="text-muted mb-0">Opt Out rate</p>
                            </div>
                          </div>
                          <h3>
                            <CountUp
                              suffix="%"
                              decimals={2}
                              start={0}
                              end={(
                                cardObj?.[selectedType]?.optOutRate || 0
                              ).toFixed(2)}
                            />
                          </h3>
                          {selectedType === "All" ? (
                            Object.keys(cardObj)?.length > 0 ? (
                              Object.keys(cardObj)?.map(
                                (dt, i) =>
                                  dt !== "All" && (
                                    <p className="text-muted mb-1" key={i}>
                                      {dt || ""}:{" "}
                                      {(cardObj?.[dt]?.optOutRate || 0).toFixed(
                                        2
                                      )}
                                      %
                                    </p>
                                  )
                              )
                            ) : null
                          ) : (
                            <p className="text-muted mb-1">
                              {selectedType || ""}:{" "}
                              {(
                                cardObj?.[selectedType]?.optOutRate || 0
                              ).toFixed(2)}
                              %
                            </p>
                          )}
                        </Col>
                      </Row>
                    </Card.Body>
                  </Card>
                </>
              )}

              {(selectedType2 == "All" || selectedType2 == "Inbound") && (
                <>
                  <h4
                    className={`${selectedType2 == "All" ? "mt-4" : ""} mb-3`}
                  >
                    Inbound
                  </h4>
                  <Card>
                    <Card.Body>
                      <Row>
                        <Col
                          xl={3}
                          md={3}
                          sm={6}
                          className={`border-end-sm analytics-card ${
                            selectedSubType === "totalInbound" ? "active" : ""
                          }`}
                          onClick={() => {
                            setSelectedSubType("totalInbound");
                            setSelectedSubTypeLabel("Total messages received");
                          }}
                        >
                          <div className="d-flex align-items-center gap-2 mb-4">
                            <div className="avatar-xs flex-shrink-0">
                              <div
                                style={{
                                  transform: "rotate(180deg)",
                                }}
                                className="avatar-title bg-body-secondary text-secondary border border-secondary rounded-circle"
                              >
                                <i className="bi bi-send-fill fs-md" />
                              </div>
                            </div>
                            <div className="flex-grow-1">
                              <p className="text-muted mb-0">
                                Total messages received
                              </p>
                            </div>
                          </div>
                          <h3>
                            <CountUp
                              decimals={0}
                              start={0}
                              end={cardObj?.[selectedType]?.totalInbound || 0}
                            />
                          </h3>
                          {selectedType === "All" ? (
                            Object.keys(cardObj)?.length > 0 ? (
                              Object.keys(cardObj)?.map(
                                (dt, i) =>
                                  dt !== "All" && (
                                    <p className="text-muted mb-1" key={i}>
                                      {dt || ""}:{" "}
                                      {cardObj?.[dt]?.totalInbound || 0}
                                    </p>
                                  )
                              )
                            ) : null
                          ) : (
                            <p className="text-muted mb-1">
                              {selectedType || ""}:{" "}
                              {cardObj?.[selectedType]?.totalInbound || 0}
                            </p>
                          )}
                        </Col>
                        <Col
                          xl={3}
                          md={3}
                          sm={6}
                          className={`border-end-sm-0 border-end-md analytics-card ${
                            selectedSubType === "averageMessagesReceived"
                              ? "active"
                              : ""
                          }`}
                          onClick={() => {
                            setSelectedSubType("averageMessagesReceived");
                            setSelectedSubTypeLabel(
                              "Average messages received"
                            );
                          }}
                        >
                          <div className="d-flex align-items-center gap-2 mb-4">
                            <div className="avatar-xs flex-shrink-0">
                              <div
                                style={{
                                  transform: "rotate(180deg)",
                                }}
                                className="avatar-title bg-body-secondary text-secondary border border-secondary rounded-circle"
                              >
                                <i className="bi bi-send-fill fs-md" />
                              </div>
                            </div>
                            <div className="flex-grow-1">
                              <p className="text-muted mb-0">
                                Average messages received
                              </p>
                            </div>
                          </div>
                          <h3>
                            <CountUp
                              decimals={2}
                              start={0}
                              end={(
                                cardObj?.[selectedType]
                                  ?.averageMessagesReceived || 0
                              ).toFixed(2)}
                            />
                          </h3>
                          {selectedType === "All" ? (
                            Object.keys(cardObj)?.length > 0 ? (
                              Object.keys(cardObj)?.map(
                                (dt, i) =>
                                  dt !== "All" && (
                                    <p className="text-muted mb-1" key={i}>
                                      {dt || ""}:{" "}
                                      {(
                                        cardObj?.[dt]
                                          ?.averageMessagesReceived || 0
                                      ).toFixed(2)}
                                    </p>
                                  )
                              )
                            ) : null
                          ) : (
                            <p className="text-muted mb-1">
                              {selectedType || ""}:{" "}
                              {(
                                cardObj?.[selectedType]
                                  ?.averageMessagesReceived || 0
                              ).toFixed(2)}
                            </p>
                          )}
                        </Col>
                        <Col
                          xl={3}
                          md={3}
                          sm={6}
                          className={`border-end-sm analytics-card ${
                            selectedSubType === "segmentReceived"
                              ? "active"
                              : ""
                          }`}
                          onClick={() => {
                            setSelectedSubType("segmentReceived");
                            setSelectedSubTypeLabel("Total segment received");
                          }}
                        >
                          <div className="d-flex align-items-center gap-2 mb-4">
                            <div className="avatar-xs flex-shrink-0">
                              <div className="avatar-title bg-body-secondary text-secondary border border-secondary rounded-circle">
                                <i className="bi bi-stack fs-md" />
                              </div>
                            </div>
                            <div className="flex-grow-1">
                              <p className="text-muted mb-0">
                                Total segment received
                              </p>
                            </div>
                          </div>
                          <h3>
                            <CountUp
                              decimals={0}
                              start={0}
                              end={
                                cardObj?.[selectedType]?.segmentReceived || 0
                              }
                            />
                          </h3>
                          {selectedType === "All" ? (
                            Object.keys(cardObj)?.length > 0 ? (
                              Object.keys(cardObj)?.map(
                                (dt, i) =>
                                  dt !== "All" && (
                                    <p className="text-muted mb-1" key={i}>
                                      {dt || ""}:{" "}
                                      {cardObj?.[dt]?.segmentReceived || 0}
                                    </p>
                                  )
                              )
                            ) : null
                          ) : (
                            <p className="text-muted mb-1">
                              {selectedType || ""}:{" "}
                              {cardObj?.[selectedType]?.segmentReceived || 0}
                            </p>
                          )}
                        </Col>
                        <Col
                          xl={3}
                          md={3}
                          sm={6}
                          className={`border-end-0 analytics-card ${
                            selectedSubType === "averageSegmentReceived"
                              ? "active"
                              : ""
                          }`}
                          onClick={() => {
                            setSelectedSubType("averageSegmentReceived");
                            setSelectedSubTypeLabel(
                              "Average segments received"
                            );
                          }}
                        >
                          <div className="d-flex align-items-center gap-2 mb-4">
                            <div className="avatar-xs flex-shrink-0">
                              <div className="avatar-title bg-body-secondary text-secondary border border-secondary rounded-circle">
                                <i className="bi bi-stack fs-md" />
                              </div>
                            </div>
                            <div className="flex-grow-1">
                              <p className="text-muted mb-0">
                                Average segments received
                              </p>
                            </div>
                          </div>
                          <h3>
                            <CountUp
                              suffix="%"
                              decimals={2}
                              start={0}
                              end={(
                                cardObj?.[selectedType]
                                  ?.averageSegmentReceived || 0
                              ).toFixed(2)}
                            />
                          </h3>
                          {selectedType === "All" ? (
                            Object.keys(cardObj)?.length > 0 ? (
                              Object.keys(cardObj)?.map(
                                (dt, i) =>
                                  dt !== "All" && (
                                    <p className="text-muted mb-1" key={i}>
                                      {dt || ""}:{" "}
                                      {(
                                        cardObj?.[dt]?.averageSegmentReceived ||
                                        0
                                      ).toFixed(2)}
                                      %
                                    </p>
                                  )
                              )
                            ) : null
                          ) : (
                            <p className="text-muted mb-1">
                              {selectedType || ""}:{" "}
                              {(
                                cardObj?.[selectedType]
                                  ?.averageSegmentReceived || 0
                              ).toFixed(2)}
                              %
                            </p>
                          )}
                        </Col>
                      </Row>
                    </Card.Body>
                  </Card>

                  <Card>
                    <Card.Body>
                      <Row>
                        <Col
                          xl={3}
                          md={3}
                          sm={6}
                          className={`border-end-sm analytics-card ${
                            selectedSubType === "responseRate" ? "active" : ""
                          }`}
                          onClick={() => {
                            setSelectedSubType("responseRate");
                            setSelectedSubTypeLabel("Non-opt out response rate");
                          }}
                        >
                          <div className="d-flex align-items-center gap-2 mb-4">
                            <div className="avatar-xs flex-shrink-0">
                              <div className="avatar-title bg-body-secondary text-secondary border border-secondary rounded-circle">
                                <i className="bi bi-send-fill fs-md" />
                              </div>
                            </div>
                            <div className="flex-grow-1">
                              <p className="text-muted mb-0">
                                Non-opt out Non-opt out response rate
                              </p>
                            </div>
                          </div>
                          <h3>
                            <CountUp
                              suffix="%"
                              decimals={2}
                              start={0}
                              end={(
                                cardObj?.[selectedType]?.responseRate || 0
                              ).toFixed(2)}
                            />
                          </h3>
                          {selectedType === "All" ? (
                            Object.keys(cardObj)?.length > 0 ? (
                              Object.keys(cardObj)?.map(
                                (dt, i) =>
                                  dt !== "All" && (
                                    <p className="text-muted mb-1" key={i}>
                                      {dt || ""}:{" "}
                                      {(
                                        cardObj?.[dt]?.responseRate || 0
                                      ).toFixed(2)}
                                      %
                                    </p>
                                  )
                              )
                            ) : null
                          ) : (
                            <p className="text-muted mb-1">
                              {selectedType || ""}:{" "}
                              {(
                                cardObj?.[selectedType]?.responseRate || 0
                              ).toFixed(2)}
                              %
                            </p>
                          )}
                        </Col>
                        <Col
                          xl={3}
                          md={3}
                          sm={6}
                          className={`border-end-sm-0 border-end-md analytics-card ${
                            selectedSubType === "averageResponseTime"
                              ? "active"
                              : ""
                          }`}
                          onClick={() => {
                            setSelectedSubType("averageResponseTime");
                            setSelectedSubTypeLabel("Average time to response");
                          }}
                        >
                          <div className="d-flex align-items-center gap-2 mb-4">
                            <div className="avatar-xs flex-shrink-0">
                              <div className="avatar-title bg-body-secondary text-secondary border border-secondary rounded-circle">
                                <i className="bi bi-stopwatch-fill fs-lg" />
                              </div>
                            </div>
                            <div className="flex-grow-1">
                              <p className="text-muted mb-0">
                                Average time to response
                              </p>
                            </div>
                          </div>
                          <h3>
                            {convertSeconds(
                              cardObj?.[selectedType]?.averageResponseTime,
                              true
                            )}
                          </h3>
                          {selectedType === "All" ? (
                            Object.keys(cardObj)?.length > 0 ? (
                              Object.keys(cardObj)?.map(
                                (dt, i) =>
                                  dt !== "All" && (
                                    <p className="text-muted mb-1" key={i}>
                                      {dt || ""}:{" "}
                                      {convertSeconds(
                                        cardObj?.[dt]?.averageResponseTime,
                                        true
                                      )}
                                    </p>
                                  )
                              )
                            ) : null
                          ) : (
                            <p className="text-muted mb-1">
                              {selectedType || ""}:{" "}
                              {convertSeconds(
                                cardObj?.[selectedType]?.averageResponseTime,
                                true
                              )}
                            </p>
                          )}
                        </Col>
                      </Row>
                    </Card.Body>
                  </Card>
                </>
              )}

              <Card className="card-height-100">
                <Card.Header className="d-flex align-items-center justify-content-between">
                  <h6 className="card-title mb-2">{selectedSubTypeLabel}</h6>
                  <div>
                    <Button
                      variant={graphType1 === "Column" ? "secondary" : "light"}
                      className="ms-2 fs-2xl p-0"
                      onClick={() => setGraphType1("Column")}
                    >
                      <i className="ph ph-chart-bar m-2" />
                    </Button>
                    <Button
                      variant={graphType1 === "Bar" ? "secondary" : "light"}
                      className="ms-2 fs-2xl p-0"
                      onClick={() => setGraphType1("Bar")}
                    >
                      <i className="ph ph-chart-line m-2" />
                    </Button>
                  </div>
                </Card.Header>
                <Card.Body>
                  <div className="w-100">
                    {graphType1 === "Column" ? (
                      <StackedColumn2
                        graphObj={dateGraphObj}
                        selectedSubType={selectedSubType}
                        convertSeconds={convertSeconds}
                      />
                    ) : (
                      <LineChart
                        graphObj={dateGraphObj}
                        selectedSubType={selectedSubType}
                        convertSeconds={convertSeconds}
                      />
                    )}
                  </div>
                </Card.Body>
              </Card>

              <Card className="card-height-100">
                <Card.Header className="d-flex align-items-center justify-content-between">
                  <h6 className="card-title mb-2">{selectedSubTypeLabel}</h6>
                  <div>
                    <Button
                      variant={graphType2 === "Column" ? "secondary" : "light"}
                      className="ms-2 fs-2xl p-0"
                      onClick={() => setGraphType2("Column")}
                    >
                      <i className="ph ph-chart-bar m-2" />
                    </Button>
                    <Button
                      variant={graphType2 === "Bar" ? "secondary" : "light"}
                      className="ms-2 fs-2xl p-0"
                      onClick={() => setGraphType2("Bar")}
                    >
                      <i className="ph ph-chart-line m-2" />
                    </Button>
                  </div>
                </Card.Header>
                <Card.Body>
                  <div className="w-100">
                    {graphType2 === "Column" ? (
                      <StackedColumn2
                        graphObj={numberGraphObj}
                        selectedSubType={selectedSubType}
                        convertSeconds={convertSeconds}
                      />
                    ) : (
                      <LineChart
                        graphObj={numberGraphObj}
                        selectedSubType={selectedSubType}
                        convertSeconds={convertSeconds}
                      />
                    )}
                  </div>
                </Card.Body>
              </Card>

              <Card className="card-height-100">
                <Card.Header>
                  <h6 className="card-title mb-0">
                    {selectedSubTypeLabel || ""} logs
                  </h6>
                </Card.Header>
                <Card.Body>
                  <SmsAndMmsLogs
                    key={selectedType}
                    loading={loading2}
                    rowsPerPage={rowsPerPage}
                    currPage={currPage}
                    logsData={statusLogs}
                    handleRowClick={handleRowClick}
                    handlePageChange={handlePageChange}
                    handleRowsPerPageChange={handleRowsPerPageChange}
                  />
                </Card.Body>
              </Card>
            </>
          )}
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(Analytics);
