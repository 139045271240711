import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  resonse: {},
  data: {},
  message: null,
  loading: false,
  success: false,
  error: false,
};

const configureNumbersSlice = createSlice({
  name: "configureNumbersSlice",
  initialState,
  reducers: {
    apiLoading(state) {
      state.error = false;
      state.loading = true;
    },
    getConfigureNumber(state, action) {
      state.data = {
        ...action.payload.data,
        ...action?.payload?.messageConfiguration,
      };
      state.loading = false;
      state.success = true;
      state.message = action.payload.message;
    },
    configureNumbersSuccessful(state, action) {
      state.resonse = {
        ...action.payload.data,
        ...action?.payload?.messageConfiguration,
      };

      state.loading = false;
      state.success = true;
      state.message = action.payload.message;
    },
    configureNumbersFailed(state, action) {
      state.error = true;
      state.message = action.payload;
      state.loading = false;
    },
  },
});

export const {
  apiLoading,
  getConfigureNumber,
  configureNumbersSuccessful,
  configureNumbersFailed,
} = configureNumbersSlice.actions;

export default configureNumbersSlice.reducer;
