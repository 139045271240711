// @ts-nocheck
import getChartColorsArray from "Common/ChartsDynamicColor";
import _ from "lodash";
import { useEffect, useMemo, useState } from "react";
import Chart from "react-apexcharts";
import { useSelector } from "react-redux";
import { createSelector } from "reselect";

const StackedColumn2 = ({ graphObj, selectedSubType, convertSeconds }: any) => {
  const [isMounted, setMounted] = useState(false);

  useEffect(() => {
    setMounted(true);
  }, []);

  const dataColors =
    selectedSubType === "averageMessagesSent" ||
    selectedSubType === "segmentSent" ||
    selectedSubType === "averageSegmentsSent" ||
    selectedSubType === "totalInbound" ||
    selectedSubType === "averageMessagesReceived" ||
    selectedSubType === "segmentReceived" ||
    selectedSubType === "averageSegmentReceived" ||
    selectedSubType === "responseRate" ||
    selectedSubType === "averageResponseTime"
      ? '["--tb-secondary"]'
      : selectedSubType === "failed" ||
        selectedSubType === "failedRate" ||
        selectedSubType === "totalOptOut" ||
        selectedSubType === "optOutRate"
      ? '["--tb-primary"]'
      : '["--tb-secondary", "--tb-primary", "--tb-black"]';

  const chartColors = getChartColorsArray(dataColors);

  const series = useMemo(() => {
    if (!graphObj) return [];

    const dates = Object.keys(graphObj);
    const getDataForKey = (key: string) =>
      dates.map((date) => graphObj[date][key] || 0);

    switch (selectedSubType) {
      case "deliveryRate":
        return [
          { name: "Delivered", data: getDataForKey("deliveryRate") },
          { name: "Failed", data: getDataForKey("failedRate") },
          { name: "Sent", data: getDataForKey("sentRate") },
        ];
      case "averageMessagesSent":
        return [
          {
            name: "Average Messages Sent",
            data: getDataForKey("totalMessagesSent"),
          },
        ];
      case "segmentSent":
        return [
          { name: "Total Segment Sent", data: getDataForKey("segmentSent") },
        ];
      case "averageSegmentsSent":
        return [
          {
            name: "Average Segments Sent",
            data: getDataForKey("segmentSent"),
          },
        ];
      case "failed":
        return [{ name: "Failed", data: getDataForKey("failed") }];
      case "failedRate":
        return [
          {
            name: "Failure rate",
            data: getDataForKey("failedRate"),
          },
        ];
      case "totalOptOut":
        return [
          {
            name: "Total Opt Out",
            data: getDataForKey("totalOptOut"),
          },
        ];
      case "optOutRate":
        return [
          {
            name: "Opt Out Rate",
            data: getDataForKey("optOutRate"),
          },
        ];
      case "totalInbound":
        return [
          {
            name: "Total Messages Received",
            data: getDataForKey("totalInbound"),
          },
        ];
      case "averageMessagesReceived":
        return [
          {
            name: "Average Messages Received",
            data: getDataForKey("totalInbound"),
          },
        ];
      case "segmentReceived":
        return [
          {
            name: "Total Segment Received",
            data: getDataForKey("segmentReceived"),
          },
        ];
      case "averageSegmentReceived":
        return [
          {
            name: "Average Segment Received",
            data: getDataForKey("segmentReceived"),
          },
        ];
      case "responseRate":
        return [
          {
            name: "Non-opt out response rate",
            data: getDataForKey("responseRate"),
          },
        ];
      case "averageResponseTime":
        return [
          {
            name: "Average Response Time",
            data: getDataForKey("averageResponseTime"),
          },
        ];
      default:
        return [
          { name: "Delivered", data: getDataForKey("delivered") },
          { name: "Failed", data: getDataForKey("failed") },
          { name: "Sent", data: getDataForKey("sent") },
        ];
    }
  }, [selectedSubType, graphObj]);

  const selectLayoutThemeType = createSelector(
    (state: any) => state.Layout,
    (layoutThemeType) => layoutThemeType.layoutThemeType
  );

  const layoutThemeType = useSelector(selectLayoutThemeType);

  useEffect(() => {
    document.getElementsByClassName("apex-charts");
  }, [layoutThemeType]);

  const options = {
    chart: {
      stacked: true,
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        ...(graphObj &&
          Object.keys(graphObj).length < 7 && {
            columnWidth: "100px",
          }),
        distributed: false,
      },
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          legend: {
            position: "bottom",
            offsetX: -10,
            offsetY: 0,
          },
        },
      },
    ],
    xaxis: {
      categories: graphObj ? Object.keys(graphObj) : [],
    },
    yaxis: {
      show: true,
      max:
        selectedSubType === "deliveryRate" ||
        selectedSubType === "responseRate" ||
        selectedSubType === "optOutRate" ||
        selectedSubType === "failedRate"
          ? 100
          : undefined,
      labels: {
        formatter: (val: any) => {
          if (selectedSubType === "averageResponseTime") {
            return convertSeconds(val, false);
          } else if (
            selectedSubType === "deliveryRate" ||
            selectedSubType === "responseRate" ||
            selectedSubType === "optOutRate" ||
            selectedSubType === "failedRate"
          ) {
            return val.toFixed(2) + "%";
          } else {
            return val.toFixed(2);
          }
        },
      },
    },
    fill: {
      opacity: 1,
    },
    legend: {
      position: "bottom",
      horizontalAlign: "center",
    },
    colors: chartColors,
    dataLabels: {
      enabled: false,
    },
    tooltip: {
      y: {
        formatter: function (val: any) {
          if (selectedSubType === "averageResponseTime") {
            return convertSeconds(val, true);
          } else if (
            selectedSubType === "deliveryRate" ||
            selectedSubType === "responseRate" ||
            selectedSubType === "optOutRate" ||
            selectedSubType === "failedRate"
          ) {
            return val.toFixed(2) + "%";
          } else {
            return val.toFixed(2);
          }
        },
      },
    },
  };

  return isMounted ? (
    <Chart
      dir="ltr"
      className="apex-charts"
      series={series}
      options={options}
      type="bar"
      height={350}
    />
  ) : (
    ""
  );
};

export default StackedColumn2;
