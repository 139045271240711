import React from "react";
import { Container, Card, Row, Col, Button } from "react-bootstrap";
import BreadCrumb from "Common/BreadCrumb";
import withRouter from "Common/withRouter";
import { useDispatch } from "react-redux";
import Hubspot from "assets/images/hubspot.png";
import { openModal } from "slices/thunk";

const MarketPlaceList = (props: any) => {
  document.title = "Signal House Portal Help Center";
  const dispatch = useDispatch<any>();

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Marketplace" />
          <div className="position-relative wrapper">
            <div className="py-3">
              <Row className="row-cols-xl-4 row-cols-sm-2 row-cols-1">
                <Col>
                  <Card className="card-animate text-center bg-secondary-subtle border border-secondary-subtle">
                    <Card.Body className="p-4">
                      <div className="avatar-md mx-auto mb-4">
                        <div className="avatar-title bg-white rounded">
                          <img src={Hubspot} alt="" className="avatar-sm" />
                        </div>
                      </div>
                      <h5 className="text-truncate">HubSpot</h5>
                      <p className="text-muted mb-3">
                        Effortlessly manage campaigns, track analytics, and
                        maximize customer engagement
                      </p>
                      <Button
                        onClick={() => {
                          dispatch(
                            openModal({
                              path: "ConnectHubspot",
                              data: {
                                title: "Connect Hubspot",
                                footer: false,
                              },
                            })
                          );
                        }}
                      >
                        Connect
                      </Button>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
            </div>
          </div>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(MarketPlaceList);
