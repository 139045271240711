import { getHelpCenterPageApi } from "helpers/apiroute_helper";
import {
  apiLoading,
  apiLoading2,
  apiLoading3,
  apiLoading4,
  pagesDataSuccessful,
  pagesDataFailed,
  subPagesDataSuccessful,
  subPagesDataFailed,
  allSubPagesDataSuccessful,
  allSubPagesDataFailed,
  pageDetailsDataSuccessful,
  pageDetailsDataFailed,
} from "./reducer";
import { toast } from "react-toastify";

const DATABASE_ID = "b363548f71624bdfb784bdc4475c3ef3";

export const getHelpCenterPagesThunk = () => async (dispatch: any) => {
  try {
    dispatch(apiLoading());

    const data: any = await getHelpCenterPageApi({
      notionUrl: "/blocks/a523b9fb-f8f7-4dcb-a964-07c03f53d530/children",
      method: "get",
      queryParams: {},
    });

    if (data?.request_id) {
      dispatch(pagesDataSuccessful(data));
    } else {
      dispatch(pagesDataFailed(data));
      toast.error(data?.message || "Something went wrong");
    }
  } catch (error: any) {
    console.log("error", error);
    dispatch(pagesDataFailed(error));
    toast.error(error?.response?.data?.message || "Something went wrong");
  }
};

export const getHelpCenterSubPagesThunk =
  (childId: string) => async (dispatch: any) => {
    try {
      dispatch(apiLoading2());

      const data: any = await getHelpCenterPageApi({
        notionUrl: `/blocks/${childId}/children`,
        method: "get",
        queryParams: {},
      });

      if (data?.request_id) {
        dispatch(subPagesDataSuccessful(data));
      } else {
        dispatch(subPagesDataFailed(data));
        toast.error(data?.message || "Something went wrong");
      }
    } catch (error: any) {
      console.log("error", error);
      dispatch(subPagesDataFailed(error));
      toast.error(error?.response?.data?.message || "Something went wrong");
    }
  };

export const getHelpCenterAllSubPagesThunk = () => async (dispatch: any) => {
  try {
    dispatch(apiLoading3());

    const data: any = await getHelpCenterPageApi({
      notionUrl: `/databases/${DATABASE_ID}/query`,
      method: "post",
      queryParams: {},
    });

    if (data?.request_id) {
      dispatch(allSubPagesDataSuccessful(data));
    } else {
      dispatch(allSubPagesDataFailed(data));
      toast.error(data?.message || "Something went wrong");
    }
  } catch (error: any) {
    console.log("error", error);
    dispatch(allSubPagesDataFailed(error));
    toast.error(error?.response?.data?.message || "Something went wrong");
  }
};

export const getHelpCenterPageDetailsThunk =
  (childId: string) => async (dispatch: any) => {
    try {
      dispatch(apiLoading4());

      const fetchChildrenRecursively = async (
        blockId: string
      ): Promise<any> => {
        const response: any = await getHelpCenterPageApi({
          notionUrl: `/blocks/${blockId}/children`,
          method: "get",
          queryParams: {},
        });

        if (response?.results) {
          const processedResults = await Promise.all(
            response.results.map(async (block: any) => {
              if (block.has_children) {
                const childrenData = await fetchChildrenRecursively(block.id);

                return { ...block, children: childrenData };
              }

              return block;
            })
          );

          return processedResults;
        }
        return [];
      };

      const data: any = await fetchChildrenRecursively(childId);
      if (data.length > 0) {
        dispatch(
          pageDetailsDataSuccessful({
            results: data,
            request_id: Date.now().toString(),
          })
        );
      } else {
        dispatch(pageDetailsDataFailed({ message: "No data found" }));
        toast.error("No data found");
      }
    } catch (error: any) {
      console.log("error", error);
      dispatch(pageDetailsDataFailed(error));
      toast.error(error?.response?.data?.message || "Something went wrong");
    }
  };
