import { createSlice } from "@reduxjs/toolkit";
import { getLocal } from "helpers/services/auth/auth";

export const initialState = {
  loginData: getLocal("loginData") || "{}",
  error: "", // for error message
  loading: false,
  isUserLogout: false,
  errorMsg: false, // for error
};

const loginSlice = createSlice({
  name: "login",
  initialState,
  reducers: {
    apiLoading(state) {
      state.error = "";
      state.loading = true;
      state.errorMsg = false;
    },
    apiLoadingFalse(state) {
      state.loading = false;
    },
    loginSuccess(state, action) {
      state.loginData = JSON.stringify(action.payload);
      state.loading = false;
      state.isUserLogout = false;
    },
    loginFailed(state, action) {
      state.error = action.payload.data;
      state.loading = false;
      state.isUserLogout = false;
      state.errorMsg = true;
    },
    logoutUserSuccess(state) {
      state.loginData = "{}";
      state.isUserLogout = true;
    },
  },
});

export const {
  apiLoading,
  apiLoadingFalse,
  loginFailed,
  loginSuccess,
  logoutUserSuccess,
} = loginSlice.actions;

export default loginSlice.reducer;
