import { useEffect, useState } from "react";
import withRouter from "Common/withRouter";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import {
  getAuthUserThunk,
  getLocationThunk,
  loginUserThunk,
} from "slices/thunk";
import {
  osName,
  browserVersion,
  browserName,
  mobileVendor,
  mobileModel,
  deviceType,
} from "react-device-detect";
import { createSelector } from "reselect";
import { setAuthorization } from "helpers/api_helper";

const VerifyAuth = (props: any) => {
  document.title = "Signal House Portal Verify Auth";

  const dispatch = useDispatch<any>();
  const [searchParams] = useSearchParams();
  const [error, setError] = useState("");

  const selectAccountAndLogin = createSelector(
    (state: any) => state.Login,
    (login) => ({
      loginData: login.loginData,
      isUserLogout: login.isUserLogout,
    })
  );

  const { loginData, isUserLogout } = useSelector(selectAccountAndLogin);

  useEffect(() => {
    setError("");
    if (searchParams.get("at")) {
      const cb = (data: {
        city?: string;
        region_name?: string;
        country_name?: string;
        zip?: string;
        ip?: string;
      }) => {
        const initialValues = {
          location: `${data?.city || ""}, ${data?.region_name || ""}, ${
            data?.country_name || ""
          }, ${data?.zip || ""}`,
          device: `${osName || ""} ${browserName || ""}`,
          deviceInfo: {
            osName,
            browserVersion,
            browserName,
            mobileVendor,
            mobileModel,
            deviceType,
          },
          ip: data?.ip,
        };

        const errorCb = (msg: any) => setError(msg);

        dispatch(
          loginUserThunk(
            {
              ...initialValues,
              refreshToken: searchParams.get("at"),
            },
            props.router.navigate,
            null,
            errorCb
          )
        );
      };

      dispatch(getLocationThunk(cb));
    }
  }, [searchParams]);

  useEffect(() => {
    const obj = loginData && JSON.parse(loginData);
    if (obj.apiKey && obj.authToken && !isUserLogout) {
      setAuthorization(obj.apiKey, obj.authToken);

      const cb = (user: any) => {
        if (user?.companyName && user?.phone) {
          props.router.navigate("/dashboard");
        } else {
          props.router.navigate("/settings/general");
        }
      };
      dispatch(getAuthUserThunk(cb));
    }
  }, [loginData, isUserLogout]);

  return error ? (
    <div className="position-fixed top-0 end-0 bottom-0 start-0">
      <div className="col-lg-5 col-xl-6 d-flex justify-content-center align-items-center w-100 h-100">
        <div className="container py-5">
          <div className="card bg-primary-subtle border-0 registration-inner mb-0 box-center shadow-lg mb-0 position-relative">
            <div className="card-body text-center p-5">
              <h5 className="fs-3xl mb-0 text-primary">{error}</h5>
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : null;
};
export default withRouter(VerifyAuth);
