import { useEffect, useState } from "react";
import Img1 from "../../Common/svg/amex";
import Img2 from "../../Common/svg/diners-club";
import Img3 from "../../Common/svg/discover";
import Img4 from "../../Common/svg/jcb";
import Img5 from "../../Common/svg/mastercard";
import Img6 from "../../Common/svg/visa";
import { Form } from "react-bootstrap";

const accountId = "acct_X5NArkvpk2jpPekNwIjt4";
const publishableKey =
  "pk_wGl2UhSYUckz1sgtWSTek5A3oKJhUbwUm3gv5FDDag7gMnvM5SqxOWUNpCXnZuMsXHkOzSFy2Mq5Q0rpRbsSn15bXEIXm3CAiADy";

function CardElement({ tilled, setIsDisable }: any) {
  const [isInit, setIsInit] = useState(true);
  const [cardType, setCardType] = useState("");

  const fieldOptions = {
    styles: {
      base: {
        fontFamily: "Helvetica Neue, Arial, sans-serif",
        color: "#757575",
        fontWeight: "400",
        fontSize: "16px",
      },
      invalid: {
        ":hover": {
          textDecoration: "underline dotted #EA4628",
          color: "#9e2146",
        },
        ":focus": {
          textDecoration: "underline dotted #EA4628",
        },
      },
      valid: {
        color: "#59C288",
      },
    },
  };

  useEffect(() => {
    setIsDisable(true);
    isInit && defineTiiled();
  }, []);

  const defineTiiled = async () => {
    tilled.current = new Tilled(publishableKey, accountId, {
      sandbox: true,
      log_level: 0,
    });

    const cardForm = await tilled.current.form({
      payment_method_type: "card",
    });

    const cardNumberElement = {
      ...fieldOptions,
      placeholder: "Card Number",
    };

    const cardCvvElement = {
      ...fieldOptions,
      placeholder: "CVV",
    };
    cardForm
      .createField("cardNumber", cardNumberElement)
      .inject("#card-number-element");
    cardForm
      .createField("cardExpiry", fieldOptions)
      .inject("#card-expiration-element");
    cardForm.createField("cardCvv", cardCvvElement).inject("#card-cvv-element");

    await cardForm.build();

    cardForm.on("validation", () => setIsDisable(cardForm?.invalid));

    cardForm.fields.cardNumber.on("change", (evt: any) =>
      setCardType(evt?.brand)
    );

    setIsInit(false);
  };

  return (
    <>
      <div className="mb-3 position-relative">
        <Form.Label htmlFor="card-number-element">
          Card number <span className="text-primary">*</span>
        </Form.Label>
        <div className="form-control" id="card-number-element"></div>
        <div
          className={`position-absolute credit-card-icons ${
            !cardType || cardType === "unknown" ? "d-none" : ""
          }`}
          style={{ top: 33, right: 15 }}
        >
          {cardType === "amex" ? (
            <Img1 color="#59C288" />
          ) : cardType === "diners" ? (
            <Img2 color="#59C288" />
          ) : cardType === "discover" ? (
            <Img3 color="#59C288" />
          ) : cardType === "jcb" ? (
            <Img4 color="#59C288" />
          ) : cardType === "mastercard" ? (
            <Img5 color="#59C288" />
          ) : cardType === "visa" ? (
            <Img6 color="#59C288" />
          ) : null}
        </div>
      </div>
      <div className="d-flex justify-content-center mb-3">
        <div style={{ width: "50%", marginRight: 20 }}>
          <Form.Label htmlFor="card-expiration-element">
            Expiration <span className="text-primary">*</span>
          </Form.Label>
          <div className="form-control" id="card-expiration-element"></div>
        </div>
        <div style={{ width: "50%" }}>
          <Form.Label htmlFor="card-cvv-element">
            CVV <span className="text-primary">*</span>
          </Form.Label>
          <div className="form-control" id="card-cvv-element"></div>
        </div>
      </div>
    </>
  );
}

export default CardElement;
