import React, { useEffect } from "react";
import PropTypes from "prop-types";
import withRouter from "Common/withRouter";
import { Collapse } from "react-bootstrap";
import { withTranslation } from "react-i18next";
import navdata from "../LayoutMenuData";
import { Link } from "react-router-dom";
import { createSelector } from "reselect";
import { useSelector } from "react-redux";

const VerticalLayout = (props: any) => {
  const path = props.router.location.pathname;
  const navData = navdata().props.children;

  const selectAccountAndLogin = createSelector(
    (state: any) => state.AuthUser,
    (authUser) => ({
      authUserPermissions: authUser.authUserPermissions,
    })
  );
  const { authUserPermissions } = useSelector(selectAccountAndLogin);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    const initMenu = () => {
      const pathName = process.env.PUBLIC_URL + path;
      const ul: any = document.getElementById("navbar-nav");
      const items = ul.getElementsByTagName("a");
      let itemsArray = [...items]; // converts NodeList to Array
      removeActivation(itemsArray);
      let matchingMenuItem =
        pathName === "/"
          ? itemsArray.find((x) => {
              return x.pathname === "/home";
            })
          : itemsArray.find((x) => {
              return x.pathname !== "/"
                ? pathName.startsWith(x.pathname)
                : x.pathname === pathName;
            });
      if (matchingMenuItem) {
        activateParentDropdown(matchingMenuItem);
      }
    };
    if (props.layoutType === "vertical") {
      initMenu();
    }
  }, [path, props.layoutType]);

  function activateParentDropdown(item: any) {
    item.classList.add("active");
    let parentCollapseDiv = item.closest(".collapse.menu-dropdown");

    if (parentCollapseDiv) {
      // to set aria expand true remaining
      parentCollapseDiv.classList.add("show");
      parentCollapseDiv.parentElement.children[0].classList.add("active");
      parentCollapseDiv.parentElement.children[0].setAttribute(
        "aria-expanded",
        "true"
      );
      if (parentCollapseDiv.parentElement.closest(".collapse.menu-dropdown")) {
        parentCollapseDiv.parentElement
          .closest(".collapse")
          .classList.add("show");
        if (
          parentCollapseDiv.parentElement.closest(".collapse")
            .previousElementSibling
        )
          parentCollapseDiv.parentElement
            .closest(".collapse")
            .previousElementSibling.classList.add("active");
      }
      return false;
    }
    return false;
  }

  const removeActivation = (items: any) => {
    let actiItems = items.filter((x: any) => x.classList.contains("active"));

    actiItems.forEach((item: any) => {
      if (item.classList.contains("menu-link")) {
        if (!item.classList.contains("active")) {
          item.setAttribute("aria-expanded", false);
        }
        if (item.nextElementSibling) {
          item.nextElementSibling.classList.remove("show");
        }
      }
      if (item.classList.contains("nav-link")) {
        if (item.nextElementSibling) {
          item.nextElementSibling.classList.remove("show");
        }
        item.setAttribute("aria-expanded", false);
      }
      item.classList.remove("active");
    });
  };

  const hasPermission = (uid: string) => {
    if (!authUserPermissions || !uid) return false;

    const permission = authUserPermissions[uid];
    return permission && permission.read === true;
  };

  const renderMenuItem = (item: any) => {
    if (item.uid && !hasPermission(item.uid)) {
      return null;
    }

    if (item.subItems) {
      const visibleSubItems = item.subItems.filter(
        (subItem: any) => !subItem.uid || hasPermission(subItem.uid)
      );

      if (visibleSubItems.length === 0) {
        return null;
      }

      return (
        <li className="nav-item" id={item.id}>
          <Link
            to={item.link ? item.link : "/"}
            target={item.target ? item.target : "_self"}
            onClick={item.click}
            className="nav-link menu-link border-bottom-right-radius-0"
            data-bs-toggle="collapse"
            id={item.id}
          >
            <i className={item.icon} id={item.id}></i>
            <span data-key="t-apps" id={item.id}>
              {props.t(item.label)}
            </span>
            {item.badgeName ? (
              <span
                className={"badge badge-pill bg-soft-" + item.badgeColor}
                data-key="t-new"
                id={item.id}
              >
                {item.badgeName}
              </span>
            ) : null}
          </Link>
          <Collapse
            className="menu-dropdown"
            // className="menu-dropdown"
            in={item.stateVariables}
          >
            <div id="example-collapse-text">
              <ul className="nav nav-sm flex-column">
                {/* subItms  */}
                {visibleSubItems &&
                  visibleSubItems.map((subItem: any, key2: number) => (
                    <React.Fragment key={key2}>
                      {!subItem.isChildItem ? (
                        <li className="nav-item">
                          <Link
                            to={subItem.link ? subItem.link : "/"}
                            className="nav-link"
                            target={subItem.target ? subItem.target : "_self"}
                          >
                            {props.t(subItem.label)}
                            {subItem.badgeName ? (
                              <span
                                className={
                                  "badge badge-pill bg-soft-" +
                                  subItem.badgeColor
                                }
                                data-key="t-new"
                              >
                                {subItem.badgeName}
                              </span>
                            ) : null}
                          </Link>
                        </li>
                      ) : (
                        <li className="nav-item">
                          <Link
                            to={process.env.PUBLIC_URL + "/"}
                            onClick={subItem.click}
                            className="nav-link"
                            target={subItem.target ? subItem.target : "_self"}
                            data-bs-toggle="collapse"
                          >
                            {props.t(subItem.label)}
                          </Link>
                          <Collapse
                            className="menu-dropdown"
                            in={subItem.stateVariables}
                          >
                            <div>
                              <ul className="nav nav-sm flex-column">
                                {/* child subItms  */}
                                {subItem.subItems &&
                                  (subItem.subItems || []).map(
                                    (childItem: any, key3: number) => (
                                      <React.Fragment key={key3}>
                                        {!childItem.subItems ? (
                                          <li className="nav-item">
                                            <Link
                                              to={
                                                childItem.link
                                                  ? childItem.link
                                                  : "/"
                                              }
                                              className="nav-link"
                                              target={
                                                childItem.target
                                                  ? childItem.target
                                                  : "_self"
                                              }
                                            >
                                              {props.t(childItem.label)}
                                            </Link>
                                          </li>
                                        ) : (
                                          <li className="nav-item">
                                            <Link
                                              to={process.env.PUBLIC_URL + "/"}
                                              onClick={childItem.click}
                                              className="nav-link"
                                              target={
                                                childItem.target
                                                  ? childItem.target
                                                  : "_self"
                                              }
                                              data-bs-toggle="collapse"
                                            >
                                              {props.t(childItem.label)}
                                            </Link>
                                            <Collapse
                                              className="menu-dropdown"
                                              in={childItem.stateVariables}
                                            >
                                              <div>
                                                <ul className="nav nav-sm flex-column">
                                                  {childItem.subItems.map(
                                                    (
                                                      subChildItem: any,
                                                      key4: number
                                                    ) => (
                                                      <li
                                                        className="nav-item"
                                                        key={key4}
                                                      >
                                                        <Link
                                                          to={subChildItem.link}
                                                          className="nav-link"
                                                          target={
                                                            subChildItem.target
                                                              ? subChildItem.target
                                                              : "_self"
                                                          }
                                                        >
                                                          {props.t(
                                                            subChildItem.label
                                                          )}
                                                        </Link>
                                                      </li>
                                                    )
                                                  )}
                                                </ul>
                                              </div>
                                            </Collapse>
                                          </li>
                                        )}
                                      </React.Fragment>
                                    )
                                  )}
                              </ul>
                            </div>
                          </Collapse>
                        </li>
                      )}
                    </React.Fragment>
                  ))}
              </ul>
            </div>
          </Collapse>
        </li>
      );
    }

    return (
      <li className="nav-item">
        <Link
          to={item.link ? item.link : "/"}
          target={item.target ? item.target : "_self"}
          className="nav-link menu-link"
        >
          {item.icon && <i className={item.icon}></i>}
          <span>{props.t(item.label)}</span>
          {item.badgeName ? (
            <span
              className={"badge badge-pill badge-soft-" + item.badgeColor}
              data-key="t-new"
            >
              {item.badgeName}
            </span>
          ) : null}
        </Link>
      </li>
    );
  };

  return (
    <React.Fragment>
      {/* menu Items */}
      {(navData || []).map((item: any, key: number) => {
        return (
          <React.Fragment key={key}>
            {/* Main Header */}
            {item["isHeader"] ? (
              <li className="menu-title">
                <i className="ri-more-fill" aria-expanded="false"></i>
                <span data-key="t-menu">{props.t(item.label)} </span>
              </li>
            ) : (
              renderMenuItem(item)
            )}
          </React.Fragment>
        );
      })}
    </React.Fragment>
  );
};

VerticalLayout.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
};

export default withRouter(withTranslation()(VerticalLayout));
