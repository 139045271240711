import { useEffect, useState } from "react";
import { Form, InputGroup, Card } from "react-bootstrap";
import { useSelector } from "react-redux";
import { createSelector } from "reselect";
import { humanizeString } from "helpers/common";
import Datatable from "Common/Datatable";

const transformEventsData = (dataArray: any) => {
  const transformedData: any = {};
  for (const category in dataArray) {
    transformedData[category] = dataArray[category].reduce(
      (acc: any, event: any) => {
        acc[event.notificationService] = {
          ...event,
          // notificationType: event.notificationType ||
          notificationType: ["web", "SMS", "email"], // Default to all types if not provided
        };
        return acc;
      },
      {}
    );
  }
  return transformedData;
};

const EventSelectorNotification = ({
  webhookId,
  validation,
  formikEvents,
  handleEventChange,
}: any) => {
  const selector = createSelector(
    (state: any) => state.Notifications,
    (notifications: any) => ({
      notificationEventsData: notifications.notificationEvents,
      error: notifications.error,
    })
  );

  const { notificationEventsData } = useSelector(selector);
  const eventsData: any = transformEventsData(notificationEventsData.data);

  const [isInit, setIsInit] = useState(true);
  const [search, setSearch] = useState("");
  const [selectedEvents, setSelectedEvents] = useState(new Set(formikEvents));
  const [notificationType, setNotificationTypes] = useState<{
    [key: string]: string[];
  }>({});

  useEffect(() => {
    handleEventChange(Array.from(selectedEvents), notificationType);
  }, [selectedEvents]);

  useEffect(() => {
    Object.entries(notificationType).map((key) => {
      const eventName = key[0];
      if (
        selectedEvents.has(eventName) &&
        notificationType[eventName].length < 1
      ) {
        handleEventSelect(eventName);
      } else if (
        !selectedEvents.has(eventName) &&
        !!notificationType[eventName].length
      ) {
        handleEventSelect(eventName);
      }
    });
  }, [notificationType]);

  useEffect(() => {
    if (
      webhookId &&
      Array.from(selectedEvents)?.length !== formikEvents?.length &&
      isInit
    ) {
      setIsInit(false);
      formikEvents?.length > 0 && setSelectedEvents(new Set(formikEvents));
    }
  }, [formikEvents]);

  useEffect(() => {
    if (notificationEventsData.data) {
      setSelectedEvents(() => {
        const newSelectedEvents = new Set();
        Object.entries(notificationEventsData?.data).forEach(
          ([key, events]: any) => {
            events.forEach((data: any) => {
              if (data.enable) {
                newSelectedEvents.add(data.notificationService);
                setNotificationTypes((prev: any) => ({
                  ...prev,
                  [data.notificationService]: data.notificationType || [
                    "web",
                    "SMS",
                    // "voice",
                    "email",
                  ],
                }));
              }
            });
          }
        );
        return newSelectedEvents;
      });
    }
  }, [notificationEventsData]);

  const handleEventSelect = (eventName: any) => {
    setSelectedEvents((prevSelectedEvents) => {
      const newSelectedEvents = new Set(prevSelectedEvents);
      if (newSelectedEvents.has(eventName)) {
        newSelectedEvents.delete(eventName);
      } else {
        newSelectedEvents.add(eventName);
      }
      return newSelectedEvents;
    });
  };

  const handleNotificationTypeChange = (eventName: string, type: string) => {
    setNotificationTypes((prev) => ({
      ...prev,
      [eventName]: prev[eventName]?.includes(type)
        ? prev[eventName].filter((t) => t !== type)
        : [...(prev[eventName] || []), type],
    }));
  };

  const filteredEvents: any = Object.keys(eventsData).reduce(
    (acc: any, category) => {
      const events = Object.keys(eventsData[category]).filter((event) =>
        event.toLowerCase().includes(search.toLowerCase())
      );
      if (events.length) {
        acc[category] = events;
      }
      return acc;
    },
    {}
  );

  const allEvents: any = new Set();
  Object.keys(filteredEvents)?.forEach((dt) => {
    filteredEvents[dt]?.forEach((dt2: string) => allEvents.add(dt2));
  });
  const allEvents2: any = Array.from(allEvents);

  const isCheckDisable = (category: string, eventName: string) => {
    return false;
  };

  const columns = [
    {
      name: <span className="font-weight-bold fs-sm">Service</span>,
      selector: (row: { eventName: string }) => humanizeString(row.eventName),
    },
    {
      name: <span className="font-weight-bold fs-sm">All</span>,
      minWidth: "100px",
      cell: (row: { category: string; eventName: string }) => (
        <div className="form-check form-switch form-switch-md" dir="ltr">
          <Form.Check
            type="checkbox"
            role="switch"
            id={row.eventName}
            checked={
              (notificationType[row.eventName]?.includes("web") &&
                notificationType[row.eventName]?.includes("SMS") &&
                // notificationType[row.eventName]?.includes("voice") &&
                notificationType[row.eventName]?.includes("email")) ||
              isCheckDisable(row.category, row.eventName)
            }
            onChange={(e) => {
              if (!e.target.checked) {
                setNotificationTypes((pre: any) => {
                  return {
                    ...pre,
                    [row.eventName]: [],
                  };
                });
              } else {
                setNotificationTypes((pre: any) => {
                  return {
                    ...pre,
                    [row.eventName]: [
                      "web",
                      "SMS",
                      //  "voice",
                      "email",
                    ],
                  };
                });
              }
            }}
          />
        </div>
      ),
    },
    {
      name: <span className="font-weight-bold fs-sm">Web</span>,
      minWidth: "100px",
      cell: (row: { category: string; eventName: string }) => (
        <div className="form-check form-switch form-switch-md" dir="ltr">
          <Form.Check
            key="web"
            type="checkbox"
            role="switch"
            id={`${row.eventName}-web`}
            checked={notificationType[row.eventName]?.includes("web")}
            onChange={() => handleNotificationTypeChange(row.eventName, "web")}
            disabled={isCheckDisable(row.category, row.eventName)}
          />
        </div>
      ),
    },
    {
      name: <span className="font-weight-bold fs-sm">SMS</span>,
      minWidth: "100px",
      cell: (row: { category: string; eventName: string }) => (
        <div className="form-check form-switch form-switch-md" dir="ltr">
          <Form.Check
            key="SMS"
            type="checkbox"
            role="switch"
            id={`${row.eventName}-SMS`}
            checked={notificationType[row.eventName]?.includes("SMS")}
            onChange={() => handleNotificationTypeChange(row.eventName, "SMS")}
            disabled={isCheckDisable(row.category, row.eventName)}
          />
        </div>
      ),
    },
    // {
    //   name: <span className="font-weight-bold fs-sm">Voice</span>,
    //   minWidth: "100px",
    //   cell: (row: { category: string; eventName: string }) => (
    //     <div className="form-check form-switch form-switch-md" dir="ltr">
    //       <Form.Check
    //         key="voice"
    //         type="checkbox"
    //         role="switch"
    //         id={`${row.eventName}-voice`}
    //         checked={notificationType[row.eventName]?.includes("voice")}
    //         onChange={() =>
    //           handleNotificationTypeChange(row.eventName, "voice")
    //         }
    //         disabled={isCheckDisable(row.category, row.eventName)}
    //       />
    //     </div>
    //   ),
    // },
    {
      name: <span className="font-weight-bold fs-sm">Email</span>,
      minWidth: "100px",
      cell: (row: { category: string; eventName: string }) => (
        <div className="form-check form-switch form-switch-md" dir="ltr">
          <Form.Check
            key="email"
            type="checkbox"
            role="switch"
            id={`${row.eventName}-email`}
            checked={notificationType[row.eventName]?.includes("email")}
            onChange={() =>
              handleNotificationTypeChange(row.eventName, "email")
            }
            disabled={isCheckDisable(row.category, row.eventName)}
          />
        </div>
      ),
    },
  ];

  const conditionalRowStyles = [
    {
      when: (row: { category: string; eventName: string }) =>
        isCheckDisable(row.category, row.eventName),
      style: {
        color: "var(--tb-secondary-color)",
        backgroundColor: "#f4f4f4",
        cursor: "not-allowed",

        "&:hover": {
          color: "var(--tb-secondary-color)",
        },
      },
    },
  ];

  return (
    <>
      <Card>
        <Card.Body>
          <Form.Label htmlFor="notificationServices">
            Events
            <span className="text-primary">*</span>
          </Form.Label>
          {validation.touched.notificationServices &&
          validation.errors.notificationServices ? (
            <Form.Control.Feedback type="invalid" className="d-block mb-2">
              {validation.errors.notificationServices}
            </Form.Control.Feedback>
          ) : null}
          {validation.values.notificationServices?.length > 0 && (
            <div className="d-flex flex-wrap mb-3">
              {validation.values.notificationServices?.map((tag: any) => (
                <span key={tag} className="details-tag">
                  {tag}
                </span>
              ))}
            </div>
          )}
          <InputGroup className="mb-3">
            <span className="input-group-text">
              <i className="ph-magnifying-glass" />
            </span>
            <Form.Control
              type="text"
              placeholder="Search events..."
              value={search}
              onChange={(e: any) => {
                setSearch(e.target.value);
              }}
            />
          </InputGroup>
          <div className="d-flex align-items-center">
            <div className="form-check form-switch form-switch-md" dir="ltr">
              <Form.Check
                type="checkbox"
                role="switch"
                id={"all-events"}
                checked={
                  allEvents2.length &&
                  selectedEvents.size &&
                  allEvents2.every((dt?: string) => selectedEvents.has(dt))
                }
                onChange={(e) => {
                  if (e.target.checked) {
                    const newSelectedEvents = new Set();
                    let newNotifications = {};
                    Object.keys(filteredEvents)?.forEach((dt) => {
                      filteredEvents[dt]?.forEach((dt2: string) => {
                        !isCheckDisable(dt, dt2) && newSelectedEvents.add(dt2);
                        newNotifications = {
                          ...newNotifications,
                          [dt2]: [
                            "web",
                            "SMS",
                            //  "voice",
                            "email",
                          ],
                        };
                      });
                    });
                    setSelectedEvents(newSelectedEvents);
                    setNotificationTypes({ ...newNotifications });
                  } else {
                    setSelectedEvents(new Set());
                    setNotificationTypes({});
                  }
                }}
              />
            </div>
            <p className="mb-0 text-black text-bold">Select all events</p>
          </div>
        </Card.Body>
      </Card>

      {Object.keys(filteredEvents).map((category, i) => (
        <Card key={i}>
          <Card.Header className="d-sm-flex align-items-center justify-content-between">
            <h6 className="card-title mb-2">{category}</h6>
            <div className="d-flex align-items-center">
              <div className="ms-4">
                <div
                  className="form-check form-switch form-switch-md"
                  dir="ltr"
                >
                  <Form.Check
                    type="checkbox"
                    role="switch"
                    id={`all-${category.toLowerCase()}`}
                    checked={filteredEvents[category].every(
                      (dt: string) =>
                        notificationType[dt]?.includes("web") &&
                        notificationType[dt]?.includes("SMS") &&
                        // notificationType[dt]?.includes("voice") &&
                        notificationType[dt]?.includes("email")
                    )}
                    onChange={(e) => {
                      const newSelectedEvents = new Set(selectedEvents);
                      let newNotifications = { ...notificationType };

                      if (e.target.checked) {
                        filteredEvents[category].forEach((dt: string) => {
                          !isCheckDisable(category, dt) &&
                            newSelectedEvents.add(dt);
                          newNotifications = {
                            ...newNotifications,
                            [dt]: ["web", "SMS", "voice", "email"],
                          };
                        });
                      } else {
                        filteredEvents[category].forEach((dt: string) => {
                          newSelectedEvents.delete(dt);
                          newNotifications = {
                            ...newNotifications,
                            [dt]: [],
                          };
                        });
                      }
                      setSelectedEvents(newSelectedEvents);
                      setNotificationTypes({ ...newNotifications });
                    }}
                  />
                </div>
              </div>
              <p className="mb-0 text-black text-bold">{`Select all ${category.toLowerCase()} events`}</p>
            </div>
          </Card.Header>
          <Card.Body>
            <Datatable
              data={
                Array.isArray(filteredEvents[category])
                  ? filteredEvents[category].map((dt: string) => ({
                      category: category,
                      eventName: dt,
                    }))
                  : []
              }
              columns={columns}
              pagination={false}
              conditionalRowStyles={conditionalRowStyles}
            />
          </Card.Body>
        </Card>
      ))}
    </>
  );
};

export default EventSelectorNotification;
