import { showToast, hideToast } from "./reducer";

export const showToastThunk =
  (toastVariant?: any, toastClass?: any, toastIcon?: any, toastMsg?: any) =>
  async (dispatch: any) => {
    dispatch(showToast({ toastVariant, toastClass, toastIcon, toastMsg }));
    setTimeout(() => dispatch(hideToastThunk()), 3000); // Hides the alert after 3 seconds (3000 milliseconds)
  };

export const hideToastThunk = () => async (dispatch: any) => {
  dispatch(hideToast());
};
