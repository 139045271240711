import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  loading: false,
  loading2: false,
  loading3: false,
  loading4: false,
  loading5: false,
  success: false,
  successMsg: "",
  error: null,
  errors: false,
  errorMsg: "",
  unreadCount: 0,
  notifications: {},
  notificationEvents: {},
  webNotificationsData: {},
  notificationsData: {},
};

const notificationsSlice = createSlice({
  name: "notifications",
  initialState,
  reducers: {
    apiLoading(state) {
      state.loading = true;
      state.success = false;
      state.successMsg = "";
      state.errors = false;
      state.errorMsg = "";
    },
    apiLoading2(state) {
      state.loading2 = true;
      state.success = false;
      state.successMsg = "";
      state.errors = false;
      state.errorMsg = "";
    },
    apiLoading3(state) {
      state.loading3 = true;
      state.success = false;
      state.successMsg = "";
      state.errors = false;
      state.errorMsg = "";
    },
    apiLoading4(state) {
      state.loading4 = true;
      state.success = false;
      state.successMsg = "";
      state.errors = false;
      state.errorMsg = "";
    },
    apiLoading5(state) {
      state.loading5 = true;
      state.success = false;
      state.successMsg = "";
      state.errors = false;
      state.errorMsg = "";
    },
    fetchNotificationsStart(state) {
      state.loading = true;
      state.error = null;
    },
    fetchNotificationsSuccess(state, action: any) {
      state.notifications = action.payload;
      state.loading = false;
    },
    fetchNotificationsFailure(state, action: any) {
      state.loading = false;
      state.error = action.payload;
    },
    getNotificationEventsSuccessful(state, action) {
      state.loading2 = false;
      state.notificationEvents = action.payload;
      state.success = true;
      state.successMsg = "";
      state.errors = false;
      state.errorMsg = "";
    },
    getNotificationEventsFailed(state, action) {
      state.loading2 = false;
      state.notificationEvents = {};
      state.success = false;
      state.successMsg = "";
      state.errors = true;
      state.errorMsg = action.payload.message;
    },
    createNotificationEventsSuccessful(state, action) {
      state.loading3 = false;
      state.success = true;
      state.successMsg = "";
      state.errors = false;
      state.errorMsg = "";
    },
    createNotificationEventsFailed(state, action) {
      state.loading3 = false;
      state.success = false;
      state.successMsg = "";
      state.errors = true;
      state.errorMsg = action.payload.message;
    },
    webNotificationsDataSuccessful(state, action) {
      state.webNotificationsData = action.payload;
      state.success = true;
      state.successMsg = "";
      state.errors = false;
      state.errorMsg = "";
    },
    webNotificationsDataFailed(state, action) {
      state.webNotificationsData = {};
      state.success = false;
      state.successMsg = "";
      state.errors = true;
      state.errorMsg = action.payload.message;
    },
    notificationsDataSuccessful(state, action) {
      state.loading5 = false;
      state.notificationsData = action.payload;
      state.success = true;
      state.successMsg = "";
      state.errors = false;
      state.errorMsg = "";
    },
    notificationsDataFailed(state, action) {
      state.loading5 = false;
      state.notificationsData = {};
      state.success = false;
      state.successMsg = "";
      state.errors = true;
      state.errorMsg = action.payload.message;
    },
  },
});

export const {
  apiLoading,
  apiLoading2,
  apiLoading3,
  apiLoading4,
  apiLoading5,
  fetchNotificationsStart,
  fetchNotificationsSuccess,
  fetchNotificationsFailure,
  createNotificationEventsSuccessful,
  createNotificationEventsFailed,
  getNotificationEventsSuccessful,
  getNotificationEventsFailed,
  webNotificationsDataSuccessful,
  webNotificationsDataFailed,
  notificationsDataSuccessful,
  notificationsDataFailed,
} = notificationsSlice.actions;

export default notificationsSlice.reducer;
