import withRouter from "Common/withRouter";
import {
  asyncBrandList,
  asyncCampaignList,
  customSelectTheme,
  removeEmptyAndNullValues,
  styleObj,
} from "helpers/common";
import { Button, Col, Form, Row } from "react-bootstrap";
import { AsyncPaginate } from "react-select-async-paginate";
import { getSubgroupDetailsThunk } from "slices/thunk";
import { useDispatch } from "react-redux";
import { UnknownAction } from "@reduxjs/toolkit";

const DLC = ({
  dlcFormik,
  formik,
  isSubmit,
  setIsSubgrpCalling,
  brandTemp,
  setBrandTemp,
}: any) => {
  const dispatch = useDispatch();

  return (
    <Row className="border bg-light-gray rounded-3 p-3">
      <div className="d-flex align-items-center mb-2">
        <h6 className="mb-0 me-2">CNP Campaign Provisioning</h6>
        <div className="form-check form-switch form-switch-md" dir="ltr">
          <Form.Check
            type="checkbox"
            role="switch"
            checked={dlcFormik.values.isCnpCampaign}
            onChange={async () => {
              dlcFormik.setFieldValue(
                "isCnpCampaign",
                !dlcFormik.values.isCnpCampaign
              );

              dlcFormik.setTouched({
                brandId: true,
                campaignId: true,
              });
              setBrandTemp({});
              dlcFormik.setFieldValue("brandId", "");
              dlcFormik.setFieldValue("campaignId", "");
              await dlcFormik.validateForm();
            }}
          />
        </div>
      </div>
      {!dlcFormik.values.isCnpCampaign && (
        <Col sm={6} lg={4} xl={3}>
          <Form.Label htmlFor="brandId">Brand</Form.Label>
          <AsyncPaginate
            key={`${formik.values.subGroupId?.[0]?.sub_group_id}`}
            isClearable={true}
            styles={styleObj(
              (dlcFormik?.touched?.brandId && dlcFormik?.errors?.brandId) ||
                (formik?.touched?.brandId && formik?.errors?.brandId)
            )}
            theme={customSelectTheme}
            value={dlcFormik.values.brandId ? brandTemp : null}
            loadOptions={asyncBrandList(
              removeEmptyAndNullValues({
                subGroupId: formik.values.subGroupId?.[0]?.sub_group_id || "",
              }),
              "brandId"
            )}
            getOptionValue={(option: any) => option?.brandId}
            getOptionLabel={(option: any) =>
              `${option?.brandId} - ${option?.displayName}`
            }
            onChange={async (option: any) => {
              if (option && option.brandId === dlcFormik.values.brandId) {
                return;
              }
              if (option) {
                if (option.subGroupId?.[0]) {
                  setIsSubgrpCalling(true);
                  dispatch(
                    getSubgroupDetailsThunk(
                      option.subGroupId?.[0]
                    ) as unknown as UnknownAction
                  );
                }
                setBrandTemp(option);
                dlcFormik.setFieldValue("brandId", option.brandId);
                dlcFormik.setFieldValue("campaignId", "");
                dlcFormik.setFieldTouched("brandId", true);
                await dlcFormik.validateForm();
              } else {
                setBrandTemp({});
                dlcFormik.setFieldValue("brandId", "");
              }
            }}
            isSearchable={true}
            additional={{
              page: 1,
            }}
          />
          {dlcFormik?.touched?.brandId && dlcFormik?.errors?.brandId && (
            <Form.Control.Feedback type="invalid" className="d-block">
              {dlcFormik?.errors?.brandId}
            </Form.Control.Feedback>
          )}
        </Col>
      )}
      <Col sm={6} lg={4} xl={3}>
        <Form.Label htmlFor="campaignId">Campaign</Form.Label>
        <AsyncPaginate
          key={`${dlcFormik.values.isCnpCampaign}-${dlcFormik.values.brandId}`}
          isClearable={true}
          styles={styleObj(
            (dlcFormik?.touched?.campaignId && dlcFormik?.errors?.campaignId) ||
              (formik?.touched?.campaignId && formik?.errors?.campaignId)
          )}
          theme={customSelectTheme}
          value={
            dlcFormik.values.campaignId
              ? { campaignId: dlcFormik.values.campaignId }
              : null
          }
          loadOptions={asyncCampaignList(
            removeEmptyAndNullValues({
              subGroupId: formik.values.subGroupId?.[0]?.sub_group_id || "",
              brandId: dlcFormik.values.brandId || "",
              campaignType:
                dlcFormik.values.brandId && !dlcFormik.values.isCnpCampaign
                  ? "own"
                  : dlcFormik.values.isCnpCampaign
                  ? "partner"
                  : "",
              dcaComplete: "Approved",
            }),
            "campaignId"
          )}
          getOptionValue={(option: any) => option?.campaignId}
          getOptionLabel={(option: any) => option?.campaignId}
          onChange={async (option: any) => {
            if (option && option.campaignId === dlcFormik.values.campaignId) {
              return;
            }
            if (option) {
              if (option.subGroupId?.[0]) {
                setIsSubgrpCalling(true);
                dispatch(
                  getSubgroupDetailsThunk(
                    option.subGroupId?.[0]
                  ) as unknown as UnknownAction
                );
              }
              setBrandTemp({ ...option, displayName: option.brandName });
              dlcFormik.setFieldValue("brandId", option.brandId);
              dlcFormik.setFieldValue("campaignId", option.campaignId);
              dlcFormik.setFieldValue("usecase", option.usecase);
              dlcFormik.setFieldValue("description", option.description);
              dlcFormik.setFieldTouched("campaignId", true);
              await dlcFormik.validateForm();
            } else {
              dlcFormik.setFieldValue("campaignId", "");
              dlcFormik.setFieldValue("usecase", "");
              dlcFormik.setFieldValue("description", "");
              dlcFormik.setFieldTouched("campaignId", true);
              await dlcFormik.validateForm();
            }
          }}
          isSearchable={true}
          additional={{
            page: 1,
          }}
        />
        {dlcFormik?.touched?.campaignId && dlcFormik?.errors?.campaignId && (
          <Form.Control.Feedback type="invalid" className="d-block">
            {dlcFormik?.errors?.campaignId}
          </Form.Control.Feedback>
        )}
      </Col>
      <Col lsm={6} lg={4} xl={3} style={{ marginTop: "28px" }}>
        <div className="hstack gap-2">
          <Button
            className="btn btn-primary"
            form="Form2"
            type="button"
            disabled={isSubmit}
            onClick={async (e) => {
              e.preventDefault();
              await dlcFormik.setTouched({
                brandId: true,
                campaignId: true,
              });
              await dlcFormik.validateForm();
              if (dlcFormik.isValid) {
                dlcFormik.handleSubmit();
              }
            }}
          >
            Add
          </Button>
          <Button
            className="btn btn-secondary"
            type="button"
            onClick={() => {
              dlcFormik.resetForm();
            }}
          >
            Reset
          </Button>
        </div>
      </Col>
    </Row>
  );
};

export default withRouter(DLC);
