import BreadCrumb from "Common/BreadCrumb";
import withRouter from "Common/withRouter";
import Loader from "assets/images/spinner-dark.svg";
import { useEffect, useState } from "react";
import Tooltip from "rc-tooltip";
import { Card, Container } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { createSelector } from "reselect";
import { getAllGroupsThunk } from "slices/thunk";
import Datatable from "../../../../Common/Datatable";
import { capitalizeString, dateFormat } from "helpers/common";

const AllGroups = (props: any) => {
  document.title = "Signal House Portal All Groups";
  const dispatch = useDispatch<any>();

  const [currPage, setCurrPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const selector = createSelector(
    (state: any) => state.Groups,
    (groups) => ({
      loading: groups.loading,
      groupDetails: groups.AllGroups,
      error: groups.error,
    })
  );

  const { loading, groupDetails } = useSelector(selector);

  const columns = [
    {
      name: (
        <span data-tag="allowRowEvents" className="font-weight-bold fs-sm">
          Name
        </span>
      ),
      selector: (row: { group_name: string }) => row.group_name,
      sortable: true,
      sortFunction: (a: any, b: any) =>
        a.group_name.localeCompare(b.group_name),
    },
    {
      name: (
        <span data-tag="allowRowEvents" className="font-weight-bold fs-sm">
          Company Name
        </span>
      ),
      selector: (row: { group_company_name: string }) => row.group_company_name,
      sortable: true,
      sortFunction: (a: any, b: any) =>
        a.group_company_name.localeCompare(b.group_company_name),
    },
    {
      name: <span className="font-weight-bold fs-sm">Status</span>,
      selector: (row: { status: string }) => {
        switch (row.status) {
          case "Active":
            return (
              <span
                data-tag="allowRowEvents"
                className="badge bg-body-secondary border border-success text-success"
                style={{
                  width: "80px",
                  display: "inline-block",
                  textAlign: "center",
                }}
              >
                Active
              </span>
            );
          default:
            return (
              <span
                data-tag="allowRowEvents"
                className="badge bg-body-secondary border border-primary text-primary"
                style={{
                  width: "80px",
                  display: "inline-block",
                  textAlign: "center",
                }}
              >
                {capitalizeString(String(row.status) || "Inactive")}
              </span>
            );
        }
      },
      sortable: true,
      sortFunction: (a: any, b: any) => a.status.localeCompare(b.status),
    },
    {
      name: <span className="font-weight-bold fs-sm">Created Date</span>,
      selector: (row: { created_datetime: string }) => (
        <span data-tag="allowRowEvents" className="d-flex align-items-center">
          <i
            data-tag="allowRowEvents"
            className="bi bi-calendar3 me-2 fs-lg text-secondary"
          />
          {dateFormat(row.created_datetime)}
        </span>
      ),
      sortable: true,
      sortFunction: (a: any, b: any) =>
        new Date(a.created_datetime).getTime() -
        new Date(b.created_datetime).getTime(),
    },
    {
      name: <span className="font-weight-bold fs-sm">Action</span>,
      cell: (cell: { group_id: string }) => {
        return (
          <>
            <i
              className="ri-eye-fill align-middle me-3 text-muted fs-xl"
              onClick={() =>
                props.router.navigate(`/settings/groups/${cell.group_id}`)
              }
            />
            <i
              className="ri-pencil-fill align-middle me-3 text-muted fs-xl"
              onClick={() =>
                props.router.navigate(`/settings/groups/${cell.group_id}/edit`)
              }
            />
            {/* <i className="ri-delete-bin-fill align-middle text-muted fs-xl" /> */}
            <Tooltip
              placement="bottomRight"
              overlay={<p className="mb-0">Add Sub group</p>}
            >
              <i
                className="ri-add-circle-fill align-middle text-muted fs-xl"
                onClick={() =>
                  props.router.navigate(
                    `/settings/groups/${cell?.group_id}/subgroups`
                  )
                }
              />
            </Tooltip>
          </>
        );
      },
    },
  ];

  useEffect(() => {
    handleGetData(currPage, rowsPerPage);
  }, []);

  const handleGetData = (page: number, perPage: number, otherParams?: any) => {
    dispatch(getAllGroupsThunk({ page: page, recordsPerPage: perPage }));
  };

  const handlePageChange = (page: number) => {
    setCurrPage(page);
    handleGetData(page, rowsPerPage);
  };

  const handleRowsPerPageChange = (rows: number) => {
    setCurrPage(1);
    setRowsPerPage(rows);
    handleGetData(1, rows);
  };

  return (
    <div className="page-content">
      <Container fluid>
        <BreadCrumb
          title="Groups"
          isCreate={true}
          createLabel="Create Group"
          createLink="/settings/groups/create"
        />

        <div className="position-relative">
          <Card className="wrapper">
            <Card.Body className="listing-table">
              {loading ? (
                <div className={``}>
                  <img
                    src={Loader}
                    className={`position-absolute top-50 start-50 translate-middle`}
                    alt="loading"
                  />
                </div>
              ) : (
                <Datatable
                  data={
                    Array.isArray(groupDetails?.records)
                      ? groupDetails?.records
                      : []
                  }
                  columns={columns}
                  handlePageChange={handlePageChange}
                  handleRowsPerPageChange={handleRowsPerPageChange}
                  currPage={currPage}
                  rowsPerPage={rowsPerPage}
                  totalRecords={groupDetails?.totalRecords}
                />
              )}
            </Card.Body>
          </Card>
        </div>
      </Container>
    </div>
  );
};

export default withRouter(AllGroups);
