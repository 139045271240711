import { useEffect, useState } from "react";
import {
  Button,
  Card,
  Container,
  Form,
  Spinner,
  Row,
  Col,
  Modal,
} from "react-bootstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  createNewGroupThunk,
  updateGroupThunk,
  findGroupDetailThunk,
  deleteGroupThunk,
  openModal,
} from "slices/thunk";
import { useDispatch, useSelector } from "react-redux";
import { createSelector } from "reselect";
import {
  customSelectTheme,
  removeEmptyAndNullValues,
  styleObj,
} from "helpers/common";
import { City, Country, State } from "country-state-city";
import Select from "react-select";
import BreadCrumb from "Common/BreadCrumb";
import withRouter from "Common/withRouter";
import { FocusError } from "focus-formik-error";

const CreateGroup = (props: any) => {
  const { groupId } = props?.router?.params;
  const dispatch = useDispatch<any>();

  document.title = `Signal House Portal ${groupId ? "Update" : "Create"} Group`;

  const [allCountry, setAllCountry] = useState<any>([]);
  const [allState, setAllState] = useState<any>([]);
  const [allCity, setAllCity] = useState<any>([]);
  const [modal2, setModal2] = useState<boolean>(false);

  const selectAccount = createSelector(
    (state: any) => state.Groups,
    (groups) => ({
      loading: groups.loading,
      loading2: groups.loading2,
      groupDetails: groups?.groupDetails?.data?.[0],
    })
  );
  const { loading, loading2, groupDetails } = useSelector(selectAccount);

  const validationSchema = Yup.object({
    groupName: Yup.string().required("Please Enter Group Name"),
    groupDescription: Yup.string().required("Please Enter Group Description"),
    groupContactPersonFirstName: Yup.string()
      .required("Please Enter First Name")
      .matches(/^\S*$/, "Spaces are not allowed in First Name"),
    groupContactPersonMiddleName: Yup.string().matches(
      /^\S*$/,
      "Spaces are not allowed in Middle Name"
    ),
    groupContactPersonLastName: Yup.string()
      .required("Please Enter last Name")
      .matches(/^\S*$/, "Spaces are not allowed in Last Name"),
    groupContactPhoneNumber: Yup.string()
      .required("Please Enter Phone number")
      .matches(
        /^[1-9]\d{1,3}[1-9]\d{6,14}$/,
        "Phone number must start with 1-4 digit country code followed by 7-15 digit phone number, numbers only"
      ),
    groupCompanyName: Yup.string().required("Please Enter Company Name"),
    addressLine1: Yup.string().required("Please Enter Adress Line 1"),
    city: Yup.string().required("Please Enter City"),
    state: Yup.string().required("Please Select State"),
    country: Yup.string().required("Please Enter Country"),
    postalCode: Yup.string().required("Please Enter Postal Code"),
  });

  const validation: any = useFormik({
    enableReinitialize: true,
    initialValues: groupId
      ? {
          groupName: groupDetails?.group_name || "",
          groupDescription: groupDetails?.group_description || "",
          groupContactPersonFirstName:
            groupDetails?.group_contact_person_first_name || "",
          groupContactPersonLastName:
            groupDetails?.group_contact_person_last_name || "",
          groupContactPersonMiddleName:
            groupDetails?.group_contact_person_middle_name || "",
          groupCompanyName: groupDetails?.group_company_name || "",
          addressLine1: groupDetails?.address_line1 || "",
          addressLine2: groupDetails?.address_line2 || "",
          city: groupDetails?.city || "",
          state: groupDetails?.state || "",
          country: groupDetails?.country || "",
          postalCode: groupDetails?.postal_code || "",
          groupContactPersonOrigin: groupDetails?.origin || "",
          groupContactPhoneNumber:
            groupDetails?.group_contact_phone_number || "",
          status: groupDetails?.status || "",
        }
      : {
          groupName: "",
          groupDescription: "",
          groupContactPersonFirstName: "",
          groupContactPersonLastName: "",
          groupContactPersonMiddleName: "",
          groupCompanyName: "",
          addressLine1: "",
          addressLine2: "",
          city: "",
          state: "",
          country: "",
          postalCode: "",
          groupContactPersonOrigin: "",
          groupContactPhoneNumber: "",
        },
    validationSchema: validationSchema,
    onSubmit: (values: any) => {
      values.groupContactPhoneNumber =
        values.groupContactPhoneNumber.replaceAll("-");
      const filteredValues = removeEmptyAndNullValues(values);

      if (groupId) {
        dispatch(
          updateGroupThunk(filteredValues, groupId, () => {
            props.router?.navigate("/settings/groups");
          })
        );
      } else {
        dispatch(
          createNewGroupThunk(filteredValues, () => {
            props.router?.navigate("/settings/groups");
          })
        );
      }
    },
  });

  const handleDelete = () => {
    const groupId2 = `groupId=${groupId}`;
    dispatch(deleteGroupThunk(groupId2)).then(() =>
      props.router?.navigate("/settings/groups")
    );
  };

  useEffect(() => {
    if (groupId) {
      dispatch(findGroupDetailThunk({ groupId }));
    }
  }, []);

  useEffect(() => {
    setAllCountry(Country.getAllCountries());
  }, []);

  useEffect(() => {
    if (validation?.values?.country) {
      const temp = State.getStatesOfCountry(validation?.values?.country);
      setAllState(temp);
    }
  }, [validation?.values?.country]);

  useEffect(() => {
    if (validation?.values?.state) {
      const temp = City.getCitiesOfState(
        validation?.values?.country,
        validation?.values?.state
      );
      setAllCity(temp);
    }
  }, [validation?.values?.state]);

  const handleCancelClick = () => {
    validation.resetForm();
  };

  return (
    <div className="page-content">
      <Container fluid>
        <BreadCrumb
          title={groupId ? "Edit Group Details" : "Create New Group"}
          isBack={true}
          backClick={() => props.router?.navigate("/settings/groups")}
        />
        <Form
          onSubmit={(e) => {
            e.preventDefault();
            validation.handleSubmit();
          }}
        >
          <FocusError formik={validation} />
          <Card>
            <Card.Body>
              <Row>
                <div className="d-flex align-items-center justify-content-between">
                  <div />
                  {groupId && (
                    <div className="d-flex flex-column align-items-end">
                      <Form.Label htmlFor="webhookUrl" className="">
                        Status
                      </Form.Label>
                      <div className="d-flex align-items-center">
                        <p className="mb-0 me-2">Inactive</p>
                        <div
                          className="form-check form-switch form-switch-md"
                          dir="ltr"
                        >
                          <Form.Check
                            type="checkbox"
                            role="switch"
                            id="flexSwitchCheckChecked"
                            checked={validation.values.status === "Active"}
                            onChange={() => {
                              dispatch(
                                openModal({
                                  modalbody:
                                    "Are you sure you want to change the status?",
                                  data: {
                                    title: "Change Status",
                                    footer: true,
                                    cancelBtn: true,
                                    buttonText: {
                                      submit: "Yes",
                                    },
                                    onSubmit: ({ onClose }: any) => {
                                      validation.setFieldValue(
                                        "status",
                                        validation.values.status === "Active"
                                          ? "Inactive"
                                          : "Active"
                                      );
                                      onClose();
                                    },
                                  },
                                })
                              );
                            }}
                          />
                        </div>
                        <p className="mb-0 ms-1">Active</p>
                      </div>
                    </div>
                  )}
                </div>
                <Col lg={4} className="mb-3">
                  <Form.Label htmlFor="groupName">
                    Group Name <span className="text-primary">*</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    id="groupName"
                    name="groupName"
                    placeholder="Enter group name"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.groupName || ""}
                    isInvalid={
                      validation.touched.groupName &&
                      validation.errors.groupName
                        ? true
                        : false
                    }
                  />
                  {validation.touched.groupName &&
                  validation.errors.groupName ? (
                    <Form.Control.Feedback type="invalid">
                      {validation.errors.groupName}
                    </Form.Control.Feedback>
                  ) : null}
                </Col>
                <Col lg={8} className="mb-3">
                  <Form.Label htmlFor="groupDescription">
                    Group Description <span className="text-primary">*</span>
                  </Form.Label>
                  <textarea
                    id="groupDescription"
                    name="groupDescription"
                    placeholder="Enter group description"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.groupDescription || ""}
                    className={`form-control ${
                      validation.touched.groupDescription &&
                      validation.errors.groupDescription
                        ? "is-invalid"
                        : ""
                    }`}
                    rows={3}
                  />
                  {validation.touched.groupDescription &&
                  validation.errors.groupDescription ? (
                    <Form.Control.Feedback type="invalid" className="d-block">
                      {validation.errors.groupDescription}
                    </Form.Control.Feedback>
                  ) : null}
                </Col>
                <div className="mt-3 mb-1">
                  <h5>Group Contact Person Details</h5>
                </div>
                <Col lg={12} className="mb-4">
                  <Row className="border bg-light-gray rounded-3 p-3">
                    <Col lg={4} className="mb-3">
                      <Form.Label htmlFor="groupContactPersonFirstName">
                        First Name <span className="text-primary">*</span>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        id="groupContactPersonFirstName"
                        name="groupContactPersonFirstName"
                        placeholder="Enter first name"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={
                          validation.values.groupContactPersonFirstName || ""
                        }
                        isInvalid={
                          validation.touched.groupContactPersonFirstName &&
                          validation.errors.groupContactPersonFirstName
                            ? true
                            : false
                        }
                      />
                      {validation.touched.groupContactPersonFirstName &&
                      validation.errors.groupContactPersonFirstName ? (
                        <Form.Control.Feedback type="invalid">
                          {validation.errors.groupContactPersonFirstName}
                        </Form.Control.Feedback>
                      ) : null}
                    </Col>
                    <Col lg={4} className="mb-3">
                      <Form.Label htmlFor="groupContactPersonMiddleName">
                        Middle Name
                      </Form.Label>
                      <Form.Control
                        type="text"
                        id="groupContactPersonMiddleName"
                        name="groupContactPersonMiddleName"
                        placeholder="Enter middle name"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={
                          validation.values.groupContactPersonMiddleName || ""
                        }
                        isInvalid={
                          validation.touched.groupContactPersonMiddleName &&
                          validation.errors.groupContactPersonMiddleName
                            ? true
                            : false
                        }
                      />
                      {validation.touched.groupContactPersonMiddleName &&
                      validation.errors.groupContactPersonMiddleName ? (
                        <Form.Control.Feedback type="invalid">
                          {validation.errors.groupContactPersonMiddleName}
                        </Form.Control.Feedback>
                      ) : null}
                    </Col>
                    <Col lg={4} className="mb-3">
                      <Form.Label htmlFor="groupContactPersonLastName">
                        Last Name <span className="text-primary">*</span>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        id="groupContactPersonLastName"
                        name="groupContactPersonLastName"
                        placeholder="Enter last name"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={
                          validation.values.groupContactPersonLastName || ""
                        }
                        isInvalid={
                          validation.touched.groupContactPersonLastName &&
                          validation.errors.groupContactPersonLastName
                            ? true
                            : false
                        }
                      />
                      {validation.touched.groupContactPersonLastName &&
                      validation.errors.groupContactPersonLastName ? (
                        <Form.Control.Feedback type="invalid">
                          {validation.errors.groupContactPersonLastName}
                        </Form.Control.Feedback>
                      ) : null}
                    </Col>
                    <Col lg={4} className="mb-3">
                      <Form.Label htmlFor="groupContactPhoneNumber">
                        Phone Number <span className="text-primary">*</span>
                      </Form.Label>
                      <Form.Control
                        type="number"
                        id="groupContactPhoneNumber"
                        name="groupContactPhoneNumber"
                        placeholder="Enter phone number"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.groupContactPhoneNumber || ""}
                        isInvalid={
                          validation.touched.groupContactPhoneNumber &&
                          validation.errors.groupContactPhoneNumber
                            ? true
                            : false
                        }
                      />
                      {validation.touched.groupContactPhoneNumber &&
                      validation.errors.groupContactPhoneNumber ? (
                        <Form.Control.Feedback type="invalid">
                          {validation.errors.groupContactPhoneNumber}
                        </Form.Control.Feedback>
                      ) : null}
                    </Col>
                    <Col lg={8} className="mb-3">
                      <Form.Label htmlFor="groupCompanyName">
                        Company Name <span className="text-primary">*</span>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        id="groupCompanyName"
                        name="groupCompanyName"
                        placeholder="Enter company name"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.groupCompanyName || ""}
                        isInvalid={
                          validation.touched.groupCompanyName &&
                          validation.errors.groupCompanyName
                            ? true
                            : false
                        }
                      />
                      {validation.touched.groupCompanyName &&
                      validation.errors.groupCompanyName ? (
                        <Form.Control.Feedback type="invalid">
                          {validation.errors.groupCompanyName}
                        </Form.Control.Feedback>
                      ) : null}
                    </Col>
                  </Row>
                </Col>

                <div className="mt-3 mb-1">
                  <h5>Address Info</h5>
                </div>
                <Col lg={12}>
                  <Row className="border bg-light-gray rounded-3 p-3">
                    <Col lg={6} className="mb-3">
                      <Form.Label htmlFor="addressLine1">
                        Address Line 1 <span className="text-primary">*</span>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        id="addressLine1"
                        name="addressLine1"
                        placeholder="Enter address line1"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation?.values?.addressLine1 || ""}
                        isInvalid={
                          validation?.touched?.addressLine1 &&
                          validation?.errors?.addressLine1
                            ? true
                            : false
                        }
                      />
                      {validation?.touched?.addressLine1 &&
                      validation?.errors?.addressLine1 ? (
                        <Form.Control.Feedback type="invalid">
                          {validation?.errors?.addressLine1}
                        </Form.Control.Feedback>
                      ) : null}
                    </Col>
                    <Col lg={6} className="mb-3">
                      <Form.Label htmlFor="addressLine2">
                        Address Line 2
                      </Form.Label>
                      <Form.Control
                        type="text"
                        id="addressLine2"
                        name="addressLine2"
                        placeholder="Enter address line2"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation?.values?.addressLine2 || ""}
                        isInvalid={
                          validation?.touched?.addressLine2 &&
                          validation?.errors?.addressLine2
                            ? true
                            : false
                        }
                      />
                      {validation?.touched?.addressLine2 &&
                      validation?.errors?.addressLine2 ? (
                        <Form.Control.Feedback type="invalid">
                          {validation?.errors?.addressLine2}
                        </Form.Control.Feedback>
                      ) : null}
                    </Col>
                    <Col lg={4} className="mb-3">
                      <Form.Label htmlFor="country">
                        Country <span className="text-primary">*</span>
                      </Form.Label>
                      <Select
                        menuPortalTarget={document.body}
                        name="country"
                        styles={styleObj(
                          validation?.touched?.country &&
                            validation?.errors?.country
                        )}
                        theme={customSelectTheme}
                        options={
                          allCountry?.length > 0
                            ? allCountry?.map(
                                (dt: { name: string; isoCode: string }) => ({
                                  label: dt?.name,
                                  value: dt?.isoCode,
                                })
                              )
                            : []
                        }
                        onChange={(e: { value: string }) => {
                          validation.setFieldValue(
                            "country",
                            e.value ? e.value : ""
                          );
                          validation.setFieldValue("state", "");
                          validation.setFieldValue("city", "");
                          validation.setFieldValue("postalCode", "");
                        }}
                        value={{
                          label: allCountry?.find(
                            (dt: { isoCode: string }) =>
                              dt?.isoCode === validation?.values?.country
                          )?.name,
                          value: validation?.values?.country,
                        }}
                      />
                      {validation?.touched?.country &&
                      validation?.errors?.country ? (
                        <Form.Control.Feedback
                          type="invalid"
                          className="d-block"
                        >
                          {validation?.errors?.country}
                        </Form.Control.Feedback>
                      ) : null}
                    </Col>

                    <Col lg={4} className="mb-3">
                      <Form.Label htmlFor="state">
                        State <span className="text-primary">*</span>
                      </Form.Label>
                      <Select
                        menuPortalTarget={document.body}
                        name="state"
                        styles={styleObj(
                          validation?.touched?.state &&
                            validation?.errors?.state
                        )}
                        theme={customSelectTheme}
                        isDisabled={!validation?.values?.country}
                        options={
                          allState?.length > 0
                            ? allState?.map(
                                (dt: { name: string; isoCode: string }) => ({
                                  label: dt?.name,
                                  value: dt?.isoCode,
                                })
                              )
                            : []
                        }
                        onChange={(e: { value: string }) => {
                          validation.setFieldValue("state", e.value);
                          validation.setFieldValue("city", "");
                          validation.setFieldValue("postalCode", "");
                        }}
                        value={{
                          label: allState?.find(
                            (dt: { isoCode: string }) =>
                              dt?.isoCode === validation?.values?.state
                          )?.name,
                          value: validation?.values?.state,
                        }}
                      />
                      {validation?.touched?.state &&
                      validation?.errors?.state ? (
                        <Form.Control.Feedback
                          type="invalid"
                          className="d-block"
                        >
                          {validation?.errors?.state}
                        </Form.Control.Feedback>
                      ) : null}
                    </Col>
                    <Col lg={4} className="mb-3">
                      <Form.Label htmlFor="city">
                        City <span className="text-primary">*</span>
                      </Form.Label>
                      <Select
                        menuPortalTarget={document.body}
                        name="city"
                        styles={styleObj(
                          validation?.touched?.city && validation?.errors?.city
                        )}
                        theme={customSelectTheme}
                        isDisabled={!validation?.values?.state}
                        options={
                          allCity?.length > 0
                            ? allCity?.map((dt: { name: string }) => ({
                                label: dt?.name,
                                value: dt?.name,
                              }))
                            : []
                        }
                        onChange={(e: { value: string }) => {
                          validation.setFieldValue("city", e.value);
                          validation.setFieldValue("postalCode", "");
                        }}
                        value={{
                          label: validation?.values?.city,
                          value: validation?.values?.city,
                        }}
                      />
                      {validation?.touched?.city && validation?.errors?.city ? (
                        <Form.Control.Feedback
                          type="invalid"
                          className="d-block"
                        >
                          {validation?.errors?.city}
                        </Form.Control.Feedback>
                      ) : null}
                    </Col>
                    <Col lg={4} className="mb-3">
                      <Form.Label htmlFor="postalCode">
                        Zip Code <span className="text-primary">*</span>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        id="postalCode"
                        name="postalCode"
                        placeholder="Enter zipcode"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation?.values?.postalCode || ""}
                        isInvalid={
                          validation?.touched?.postalCode &&
                          validation?.errors?.postalCode
                            ? true
                            : false
                        }
                      />
                      {validation?.touched?.postalCode &&
                      validation?.errors?.postalCode ? (
                        <Form.Control.Feedback type="invalid">
                          {validation?.errors?.postalCode}
                        </Form.Control.Feedback>
                      ) : null}
                    </Col>
                    <Col lg={4} className="mb-3">
                      <Form.Label htmlFor="groupContactPersonOrigin">
                        Origin
                      </Form.Label>
                      <Form.Control
                        type="text"
                        id="groupContactPersonOrigin"
                        name="groupContactPersonOrigin"
                        placeholder="Enter groupContactPersonOrigin"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.groupContactPersonOrigin || ""}
                        isInvalid={
                          validation.touched.groupContactPersonOrigin &&
                          validation.errors.groupContactPersonOrigin
                            ? true
                            : false
                        }
                      />
                      {validation.touched.groupContactPersonOrigin &&
                      validation.errors.groupContactPersonOrigin ? (
                        <Form.Control.Feedback type="invalid">
                          {validation.errors.groupContactPersonOrigin}
                        </Form.Control.Feedback>
                      ) : null}
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Card.Body>
          </Card>

          <div className="hstack gap-2 justify-content-end mb-4">
            <Button
              type="submit"
              variant="primary"
              disabled={
                !validation.isValid || !validation?.dirty || loading || loading2
              }
            >
              {loading2 && <Spinner size="sm" animation="border" />} Submit
            </Button>
            {!groupId && (
              <Button
                className="btn btn-outline-primary"
                onClick={handleCancelClick}
              >
                Reset
              </Button>
            )}
            {groupId && (
              <Button
                className="btn btn-subtle-primary"
                onClick={() => setModal2(true)}
              >
                Delete Group
              </Button>
            )}
          </div>

          <Modal show={modal2} onHide={() => setModal2(false)}>
            <Modal.Header closeButton>
              <Modal.Title>Delete this group</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <p>
                Are you sure you want to delete this group, this step is not
                recoverable.
              </p>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={() => setModal2(false)}>
                Close
              </Button>
              <Button variant="primary" onClick={handleDelete}>
                Delete Group
              </Button>
            </Modal.Footer>
          </Modal>
        </Form>
      </Container>
    </div>
  );
};
export default withRouter(CreateGroup);
