// @ts-nocheck
import React, { useEffect, useState, useMemo, useRef } from "react";
import { Card, Col, Row } from "react-bootstrap";
import ReactApexChart from "react-apexcharts";
import { createSelector } from "reselect";
import { useDispatch, useSelector } from "react-redux";
import getChartColorsArray from "Common/ChartsDynamicColor";
import Loader from "assets/images/spinner-dark.svg";
import { capitalizeString, removeKeyFromObj } from "helpers/common";
import * as d3 from "d3";

const SummaryChart = ({ data, labels, chartId = "default" }) => {
  const svgRef = useRef(null);
  const tooltipRef = useRef(null);
  const [currentState, setCurrentState] = useState("total");
  const [showPercentage, setShowPercentage] = useState(false);
  const [showPopover, setShowPopover] = useState(false);
  const togger = useRef(false);
  const [activeLegends, setActiveLegends] = useState(new Set());
  const [tooltipVisible, setTooltipVisible] = useState(false);
  const [tooltipContent, setTooltipContent] = useState("");
  const [tooltipPosition, setTooltipPosition] = useState({ x: 0, y: 0 });

  // const { authUser = {} } = useSelector(authData);

  // console.log("authUser", authUser);
  useEffect(() => {
    if (!svgRef.current) return;

    const total = data.reduce((a, b) => a + b, 0);
    const [deliveryCount, failedCount] = data;

    // Calculate percentages, defaulting to 1 if total is 0
    const percentages =
      total === 0 ? data.map(() => 1) : data.map((value) => value / total);

    // Set up dimensions
    const width = 150;
    const height = 150;
    const radius = Math.min(width, height) / 2;
    const ringWidth = 9;
    const ringGap = 7;

    const svg = d3.select(svgRef.current);
    const tooltip = d3.select(tooltipRef.current);

    // Clear previous content
    svg.selectAll("*").remove();

    // Create chart group
    const g = svg
      .attr("width", width)
      .attr("height", height)
      .append("g")
      .attr("transform", `translate(${width / 2},${height / 2})`);

    // Set up pie generator
    const pie = d3.pie().sort(null);

    // Set up arc generators with increased space between circles
    const arcOuter = d3
      .arc()
      .innerRadius(radius - ringWidth)
      .outerRadius(radius);

    const arcInner = d3
      .arc()
      .innerRadius(radius - ringWidth - ringGap - ringWidth)
      .outerRadius(radius - ringWidth - ringGap);

    // Color scales
    const color = d3
      .scaleOrdinal()
      .domain([0, 1])
      .range(["#6868ab", "#E5E5E5"]);
    const color2 = d3
      .scaleOrdinal()
      .domain([0, 1])
      .range(["#e41cfd", "#E5E5E5"]);

    // Define legend items with unique IDs using chartId
    const legendItems = [
      {
        id: `delivery-legend-${chartId}`,
        label: labels[0],
        color: color(0),
        state: "delivery",
        ringClass: "outerArc",
        ringId: `delivery-ring-${chartId}`,
      },
      ...(data.length > 1
        ? [
            {
              id: `failed-legend-${chartId}`,
              label: labels[1],
              color: color2(0),
              state: "failed",
              ringClass: "innerArc",
              ringId: `failed-ring-${chartId}`,
            },
          ]
        : []),
    ];

    // Function to update center text
    const updateCenterText = (state, showPercentage = false) => {
      if (state === "delivery") {
        labelTspan.text(labels[0]);
        valueTspan.text(
          showPercentage
            ? getPercentage(deliveryCount)
            : deliveryCount.toFixed(0)
        );
        togger.current = showPercentage;
      } else if (state === "failed") {
        labelTspan.text(labels[1]);
        valueTspan.text(
          showPercentage ? getPercentage(failedCount) : failedCount.toFixed(0)
        );
        togger.current = showPercentage;
      } else {
        resetCenterText();
      }
    };

    const resetCenterText = () => {
      labelTspan.text("Total");
      labelTspanv.text("Total").style("display", "none");
      labelTspan1.text("Total").style("display", "none");
      labelTspanv2.text("Total").style("display", "none");
      valueTspan.text(total.toFixed(0));
      togger.current = false;
    };

    // Function to calculate and format percentage
    const getPercentage = (count) => {
      if (total === 0) return "0%";
      return `${((count / total) * 100).toFixed(1)}%`;
    };

    // Update ring highlight function to use scoped IDs
    const updateRingHighlight = (state) => {
      svg.selectAll(`.arc-${chartId}`).style("opacity", 1);
      d3.selectAll(`[data-legend-id*="${chartId}"]`).classed("active", false);

      const activeLegend = legendItems.find((item) => item.state === state);
      if (activeLegend) {
        svg
          .selectAll(`.${activeLegend.ringClass}-${chartId}`)
          .style("opacity", 0.3);
        d3.select(`[data-legend-id="${activeLegend.id}"]`).classed(
          "active",
          true
        );
      }
    };

    // Update chart state function
    const updateChartState = (state) => {
      setCurrentState(state);
      setShowPercentage(false);

      // Update active legends based on state
      if (state === "total") {
        setActiveLegends(new Set());
      } else {
        const activeLegend = legendItems.find((item) => item.state === state);
        if (activeLegend) {
          setActiveLegends(new Set([activeLegend.id]));
        }
      }

      updateRingHighlight(state);
      updateCenterText(state);
    };

    // Consolidate tooltip handling
    const showTooltip = (event, d, content) => {
      event.stopPropagation();
      const [x, y] = d3.pointer(event, svg.node());
      setTooltipPosition({ x: x + 120, y: y + 40 });
      setTooltipContent(content);
      setTooltipVisible(true);
    };

    const hideTooltip = () => {
      setTooltipVisible(false);
    };

    // Update center text group handling
    const centerTextGroup = g
      .append("g")
      .attr("class", "center-text-group")
      .style("cursor", "pointer");

    // Simplified center text click handler
    centerTextGroup.on("click", (event) => {
      event.stopPropagation();
      if (currentState === "total") {
        const newToggleState = !togger.current;
        togger.current = newToggleState;

        if (newToggleState) {
          // Show percentages view
          if (labels[0]) {
            labelTspan.text(labels[0]);
            labelTspanv
              .text(getPercentage(deliveryCount))
              .style("display", "block");
          }
          if (labels[1]) {
            labelTspan1.text(labels[1]).style("display", "block");
            labelTspanv2
              .text(getPercentage(failedCount))
              .style("display", "block");
          }
          valueTspan.text("");
        } else {
          resetCenterText();
        }
      } else {
        const newShowPercentage = !showPercentage;
        setShowPercentage(newShowPercentage);
        updateCenterText(currentState, newShowPercentage);
      }
    });

    // Simplified hover handling
    centerTextGroup
      .on("mouseover", (event) => {
        const tooltipText =
          currentState === "total"
            ? togger.current
              ? "Click to see counts"
              : "Click to see percentages"
            : showPercentage
            ? "Click to see count"
            : "Click to see percentage";

        showTooltip(event, null, tooltipText);
      })
      .on("mouseout", hideTooltip);

    // Update ring hover events
    const updateRingTooltip = (event, d, isOuter) => {
      const count = isOuter ? deliveryCount : failedCount;
      const label = isOuter ? labels[0] : labels[1];
      const tooltipText =
        total === 0
          ? `${label}: 0 (0%)`
          : `${label}: ${count} (${getPercentage(count)})`;

      showTooltip(event, d, tooltipText);
    };

    // Update outer ring events
    g.selectAll(`.outerArc-${chartId}`)
      .data(pie(total === 0 ? [1] : [percentages[0], 1 - percentages[0]]))
      .enter()
      .append("path")
      .attr("class", `arc arc-${chartId} outerArc outerArc-${chartId}`)
      .attr("data-ring-id", legendItems[0].ringId)
      .attr("d", arcOuter)
      .attr("fill", (d, i) => (total === 0 ? color(1) : color(i)))
      .on("click", (event) => {
        event.stopPropagation();
        const newState = currentState === "delivery" ? "total" : "delivery";
        updateChartState(newState);
      })
      .on("mouseover", (event, d) => updateRingTooltip(event, d, true))
      .on("mouseout", hideTooltip);

    // Draw inner circle only if there are multiple values
    if (data.length > 1) {
      g.selectAll(`.innerArc-${chartId}`)
        .data(pie(total === 0 ? [1] : [percentages[1], 1 - percentages[1]]))
        .enter()
        .append("path")
        .attr("class", `arc arc-${chartId} innerArc innerArc-${chartId}`)
        .attr("data-ring-id", legendItems[1].ringId)
        .attr("d", arcInner)
        .attr("fill", (d, i) => (total === 0 ? color2(1) : color2(i)))
        .on("click", (event) => {
          event.stopPropagation();
          const newState = currentState === "failed" ? "total" : "failed";
          updateChartState(newState);
        })
        .on("mouseover", (event, d) => updateRingTooltip(event, d, false))
        .on("mouseout", hideTooltip);
    }

    // Add center text group with hover area
    const centerText = centerTextGroup
      .append("text")
      .attr("class", "center-text")
      .attr("text-anchor", "middle")
      .attr("dominant-baseline", "central")
      .attr("y", -20)
      .style("font-size", "14px");

    // Add hover tooltip
    const centerTooltip = d3
      .select(svgRef.current.parentNode)
      .append("div")
      .attr("class", "center-tooltip")
      .style("opacity", 0)
      .style("max-width", "150px")
      .style("white-space", "normal")
      .style("word-wrap", "break-word");

    // Add label tspan
    const labelTspan = centerText
      .append("tspan")
      .attr("x", 0)
      .attr("class", "label-text")
      .text("Total");
    const labelTspanv = centerText
      .append("tspan")
      .attr("x", 0)
      .attr("dy", "1.2em")
      .attr("class", "label-text")
      .text("Total")
      .style("font-weight", "bold")
      .style("display", "none")
      .style("font-size", "14px");
    const labelTspan1 = centerText
      .append("tspan")
      .attr("x", 0)
      .attr("dy", "1.2em")
      .attr("class", "label-text")
      .text("Total")
      .style("display", "none");
    const labelTspanv2 = centerText
      .append("tspan")
      .attr("x", 0)
      .attr("dy", "1.2em")
      .attr("class", "label-text")
      .text("Total")
      .style("display", "none")
      .style("font-weight", "bold")
      .style("font-size", "14px");
    // Add value tspan
    const valueTspan = centerText
      .append("tspan")
      .attr("x", 0)
      .attr("dy", "1.2em")
      .text(`${total.toFixed(0)}`)
      .style("font-size", "18px")
      .style("font-weight", "bold");

    // Add click event listener to reset when clicking outside the chart
    // d3.select("body").on("click", () => {
    //   updateChartState("total");
    // });

    // Remove existing legend before adding a new one
    d3.select(svgRef.current.parentNode).select(".legend").remove();

    // Add legend
    const legend = d3
      .select(svgRef.current.parentNode)
      .append("div")
      .attr("class", "legend");

    // Update legend creation with scoped IDs
    legend
      .selectAll(`.legend-item-${chartId}`)
      .data(legendItems)
      .enter()
      .append("div")
      .attr("class", `legend-item legend-item-${chartId}`)
      .attr("data-legend-id", (d) => d.id)
      .html(
        (d) => `
        <div class="legend-color" style="background-color: ${d.color};"></div>
        <div>${d.label}</div>
      `
      )
      .on("click", (event, d) => {
        event.stopPropagation();
        const newState = currentState === d.state ? "total" : d.state;
        updateChartState(newState);
      });

    // Initial update
    updateRingHighlight(currentState);
    if (currentState !== "total") {
      updateCenterText(currentState, showPercentage);
    }
  }, [
    currentState,
    showPercentage,
    data,
    labels,
    showPopover,
    activeLegends,
    chartId,
  ]);

  return (
    <div
      className="d-flex align-items-center flex-column position-relative"
      id={`chart-container-${chartId}`}
    >
      <svg ref={svgRef}></svg>
      <div
        ref={tooltipRef}
        className="tooltip"
        style={{
          opacity: tooltipVisible ? 1 : 0,
          left: `${tooltipPosition.x}px`,
          top: `${tooltipPosition.y}px`,
        }}
      >
        {tooltipContent}
      </div>
      <style jsx>{`
        .tooltip {
          position: absolute;
          background-color: rgba(0, 0, 0, 0.8);
          color: #fff;
          padding: 8px 12px;
          border-radius: 5px;
          font-size: 12px;
          pointer-events: none;
          opacity: 0;
          transition: opacity 0.2s ease;
          box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
          transform: translate(-50%, -50%);
          z-index: 1000;
          min-width: 140px;
        }
        .percentage-list {
          display: flex;
          flex-direction: column;
          gap: 6px;
        }
        .percentage-item {
          display: flex;
          align-items: center;
          gap: 8px;
          white-space: nowrap;
        }
        .dot {
          width: 8px;
          height: 8px;
          border-radius: 50%;
          display: inline-block;
        }
        .legend {
          display: flex;
          justify-content: center;
          margin-top: 20px;
        }
        .legend-item {
          display: flex;
          align-items: center;
          margin: 0 10px;
          cursor: pointer;
          padding: 5px 10px;
          border-radius: 20px;
          transition: all 0.3s ease;
        }
        .legend-item:hover,
        .legend-item.active {
          background-color: rgba(224, 224, 224, 0.8);
        }
        .legend-color {
          width: 12px;
          height: 12px;
          margin-right: 8px;
          border-radius: 50%;
        }
        .center-tooltip {
          position: absolute;
          background-color: rgba(0, 0, 0, 0.8);
          color: #fff;
          padding: 8px 12px;
          border-radius: 5px;
          font-size: 12px;
          pointer-events: none;
          opacity: 0;
          transition: opacity 0.2s ease;
          box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
          transform: translate(-50%, -50%);
          z-index: 1000;
          white-space: nowrap;
        }
      `}</style>
    </div>
  );
};

const UsageSummary = ({ cardObj }: any) => {
  const dispatch = useDispatch<any>();

  const selectProfile = createSelector(
    (state: any) => state.UsageSummary,
    (state: any) => state.Analytics,
    (state: any) => state.Groups,
    (usageSummary, analytics, groups) => ({
      loading: analytics.loading,
      usageSummaryObj: usageSummary.usageSummaryObj,
      error: usageSummary.error,
      groupDetails: groups?.AllGroups,
    })
  );

  const { loading, usageSummaryObj } = useSelector(selectProfile);

  const [summaryObj, setSummaryObj] = useState<any>({});

  const orderedKeys = ["Total", "SMS", "MMS", "Total Opt-Out"];

  useEffect(() => {
    if (Object.keys(usageSummaryObj).length > 0) {
      const temp = removeKeyFromObj(usageSummaryObj?.total, [
        "total",
        "transactions",
      ]);
      const reorderedObj: any = {};
      orderedKeys.forEach((key) => {
        if (temp[key]) {
          reorderedObj[key] = temp[key];
        }
      });
      setSummaryObj(reorderedObj);
    }
  }, [usageSummaryObj]);

  return (
    <Card className="card card-height-100 overflow-hidden mb-0 h-100">
      <Card.Header className="align-items-center d-flex pb-3">
        <h4 className="card-title mb-0 flex-grow-1">Messaging Insights</h4>
      </Card.Header>
      {loading ? (
        <div style={{ height: 60 }}>
          <img
            src={Loader}
            className={`position-absolute top-50 start-50 translate-middle`}
          />
        </div>
      ) : (
        <Card.Body className="p-0 dashsummary">
          <Row className="g-0">
            <Col md={4}>
              <Card className={`shadow-none rounded-0 mb-0 border h-100`}>
                <Card.Header className="pt-2">
                  <h6 className="mb-0">Messaging at a Glance</h6>
                </Card.Header>
                <Card.Body className="p-2 pt-4">
                  <div id="summary-total123" dir="ltr">
                    <SummaryChart
                      chartId="messaging-glance"
                      data={[
                        cardObj?.All?.delivered || 0,
                        cardObj?.All?.failed || 0,
                      ]}
                      key="sms"
                      // colors='["--tb-secondary", "--tb-primary"]'
                      labels={["Delivered", "Failed"]}
                    />
                  </div>
                </Card.Body>
              </Card>
            </Col>

            <Col md={4}>
              <Card className={`shadow-none rounded-0 mb-0 border h-100`}>
                <Card.Header className="pt-2">
                  <h6 className="mb-0">SMS at a Glance</h6>
                </Card.Header>
                <Card.Body className="p-2 pt-4">
                  <div id="summary-total-1" dir="ltr">
                    <SummaryChart
                      chartId="sms-glance"
                      key="smsd"
                      data={[
                        cardObj?.SMS?.delivered || 0,
                        cardObj?.SMS?.failed || 0,
                      ]}
                      dataColors='["--tb-secondary", "--tb-primary"]'
                      labels={["Delivered", "Failed"]}
                      trackBg={["#6868ab", "#e41cfd"]}
                    />
                  </div>
                </Card.Body>
              </Card>
            </Col>

            <Col md={4}>
              <Card className={`shadow-none rounded-0 mb-0 border h-100`}>
                <Card.Header className="pt-2">
                  <h6 className="mb-0">MMS at a Glance</h6>
                </Card.Header>
                <Card.Body className="p-2 pt-4">
                  <div id="summary-total-3" dir="ltr">
                    <SummaryChart
                      chartId="mms-glance"
                      key="mms"
                      data={[
                        cardObj?.MMS?.delivered || 0,
                        cardObj?.MMS?.failed || 0,
                      ]}
                      dataColors='["--tb-secondary", "--tb-primary"]'
                      labels={["Delivered", "Failed"]}
                      trackBg={["#6868ab", "#e41cfd"]}
                    />
                  </div>
                </Card.Body>
              </Card>
            </Col>

            {Object.keys(summaryObj)?.length > 0 &&
              Object.keys(summaryObj).map(
                (dt1, i1) =>
                  typeof summaryObj?.[dt1] === "object" &&
                  !Array.isArray(summaryObj?.[dt1]) &&
                  summaryObj?.[dt1] !== null && (
                    <Col md={4} key={dt1}>
                      <Card className={`shadow-none rounded-0 mb-0 border`}>
                        <Card.Header className="pt-2">
                          <h6 className="mb-0">Total {dt1}</h6>
                        </Card.Header>
                        <Card.Body className="p-2 pt-4">
                          <div id={`summary-[${dt1}]`} dir="ltr">
                            <SummaryChart
                              key={`mmsd_${i1}`}
                              data={
                                Object.keys(summaryObj?.[dt1])?.length > 0
                                  ? Object.keys(summaryObj?.[dt1]).map(
                                      (dt2) =>
                                        usageSummaryObj?.total?.[dt1]?.[dt2]
                                          ?.transactions || 0
                                    )
                                  : [0]
                              }
                              labels={
                                Object.keys(summaryObj?.[dt1])?.length > 0
                                  ? Object.keys(summaryObj?.[dt1]).map((dt2) =>
                                      capitalizeString(dt2)
                                    )
                                  : ["No Data"]
                              }
                              trackBg={["#6868ab", "#e41cfd"]}
                              dataColors='["--tb-secondary", "--tb-primary"]'
                              chartId={"total-messaging-glance" + i1}
                            />
                          </div>
                        </Card.Body>
                      </Card>
                    </Col>
                  )
              )}

            <Col md={4}>
              <Card className={`shadow-none rounded-0 mb-0 border h-100`}>
                <Card.Header className="pt-2">
                  <h6 className="mb-0">Opt-Outs</h6>
                </Card.Header>
                <Card.Body className="p-2 pt-4">
                  <div id="summary-total" dir="ltr">
                    <SummaryChart
                      key="opt-out"
                      data={[cardObj?.All?.optOutRate || 0]}
                      dataColors='["--tb-secondary"]'
                      labels={["Opt-Out"]}
                      trackBg={["#6868ab"]}
                      chartId="total-messaging-glance"
                    />
                  </div>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Card.Body>
      )}
    </Card>
  );
};

export default UsageSummary;
