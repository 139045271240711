import { APIClient } from "../../api_helper";
import * as url from "../../url_helper";

const api = new APIClient();

export const getNumbers = (payload: any) => {
  return api.create(url.GET_PHONE_NUMBER, payload);
};

export const configureNumbersApi = (payload: any) => {
  return api.create(url.Configure_Phone_Number, payload);
};

