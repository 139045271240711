import { useEffect, useState } from "react";
import { Card, Container } from "react-bootstrap";
import { getAllMsgShortLinkThunk, openModal } from "slices/thunk";
import { useDispatch, useSelector } from "react-redux";
import { createSelector } from "reselect";
import BreadCrumb from "Common/BreadCrumb";
import { dateFormat, removeEmptyAndNullValues } from "helpers/common";
import Datatable from "Common/Datatable";
import Loader from "assets/images/spinner-dark.svg";
import { toast } from "react-toastify";
import Tooltip from "rc-tooltip";

const ShortURL = () => {
  document.title = "Signal House Portal Short URL";
  const dispatch = useDispatch<any>();

  const selectAccount = createSelector(
    (state: any) => state.Messages,
    (messages) => ({
      loading: messages.loading3,
      shortLinkData: messages.shortLinkData,
    })
  );

  const { loading, shortLinkData } = useSelector(selectAccount);

  const [currPage, setCurrPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const columns = [
    {
      name: <span className="font-weight-bold fs-sm">Short URL</span>,
      minWidth: "300px",
      selector: (row: { shortUrl: string }) => row.shortUrl,
      cell: (row: { shortUrl: string }) => (
        <span data-tag="allowRowEvents" className="text-primary">
          <span
            data-tag="allowRowEvents"
            className="fw-semibold"
            id={row.shortUrl}
          >
            {row.shortUrl || "-"}
          </span>
          <i
            className="bx bxs-copy align-middle ms-2"
            onClick={() => copyText(row.shortUrl)}
          ></i>
        </span>
      ),
      sortable: true,
      sortFunction: (a: any, b: any) => a.shortUrl.localeCompare(b.shortUrl),
    },
    {
      name: <span className="font-weight-bold fs-sm">Original URL</span>,
      minWidth: "350px",
      selector: (row: { originalUrl: string }) => row.originalUrl,
      cell: (row: { originalUrl: string }) => (
        <Tooltip
          placement="topLeft"
          overlay={
            <p className="mb-0" style={{ wordBreak: "break-word" }}>
              {row.originalUrl || "-"}
            </p>
          }
        >
          <div className="text-secondary text-truncate-two-lines">
            <b data-tag="allowRowEvents">{row.originalUrl || "-"}</b>
          </div>
        </Tooltip>
      ),
      sortable: true,
      sortFunction: (a: any, b: any) =>
        a.originalUrl.localeCompare(b.originalUrl),
    },
    {
      name: <span className="font-weight-bold fs-sm">Friendly Name</span>,
      minWidth: "150px",
      selector: (row: { friendlyName: string }) => row.friendlyName,
      cell: (row: { friendlyName: string }) => (
        <span data-tag="allowRowEvents" className="text-truncate-two-lines">
          {row.friendlyName || "-"}
        </span>
      ),
      sortable: true,
      sortFunction: (a: any, b: any) =>
        a.friendlyName.localeCompare(b.friendlyName),
    },
    {
      name: <span className="font-weight-bold fs-sm">Email ID</span>,
      minWidth: "350px",
      selector: (row: { emailId: string }) => row.emailId,
      cell: (row: { emailId: string }) => (
        <span data-tag="allowRowEvents" className="line-clamp-1">
          {row.emailId || "-"}
        </span>
      ),
      sortable: true,
      sortFunction: (a: any, b: any) => a.emailId.localeCompare(b.emailId),
    },
    {
      name: <span className="font-weight-bold fs-sm">Visits</span>,
      width: "100px",
      selector: (row: { visits: string }) => row.visits,
      cell: (row: { visits: number }) => row.visits || 0,
      sortable: true,
      sortFunction: (a: any, b: any) => {
        return (a.visits || 0) - (b.visits || 0);
      },
    },
    {
      name: <span className="font-weight-bold fs-sm">Create Date</span>,
      minWidth: "220px",
      selector: (row: { createdAt: string }) => row.createdAt,
      cell: (row: { createdAt: string }) => (
        <span data-tag="allowRowEvents" className="d-flex align-items-center">
          <i
            data-tag="allowRowEvents"
            className="bi bi-calendar3 me-2 fs-lg text-secondary"
          />
          {dateFormat(row.createdAt, "MM/DD/YYYY, hh:mm:ss A")}
        </span>
      ),
      sortable: true,
      sortFunction: (a: any, b: any) =>
        new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime(),
    },
  ];

  useEffect(() => {
    handleGetData(currPage, rowsPerPage);
  }, []);

  const handleGetData = (page: number, perPage: number, otherParams?: any) => {
    dispatch(
      getAllMsgShortLinkThunk(
        page,
        perPage,
        removeEmptyAndNullValues({
          ...otherParams,
        })
      )
    );
  };

  const handlePageChange = (page: number) => {
    setCurrPage(page);
    handleGetData(page, rowsPerPage);
  };

  const handleRowsPerPageChange = (rows: number) => {
    setCurrPage(1);
    setRowsPerPage(rows);
    handleGetData(1, rows);
  };

  const copyText = (type: string) => {
    var text = document.getElementById(type)?.innerText;
    var elem = document.createElement("textarea");
    document.body.appendChild(elem);
    if (elem && text) {
      elem.value = text;
      elem.select();
      toast.success("Link copied");
    }
    document.execCommand("copy");
    document.body.removeChild(elem);
  };

  return (
    <div className="page-content">
      <Container fluid>
        <BreadCrumb
          title="Shorten URL"
          // isCreate={true}
          // createLabel="Add URL"
          // createClick={() => {
          //   dispatch(
          //     openModal({
          //       path: "AddShortenURL",
          //       data: {
          //         title: "Add URL",
          //         footer: false,
          //         getLinkData: () => handleGetData(currPage, rowsPerPage),
          //       },
          //     })
          //   );
          // }}
        />

        <div className="position-relative">
          <Card className="wrapper">
            <Card.Body className="listing-table">
              {loading ? (
                <div>
                  <img
                    src={Loader}
                    className={`position-absolute top-50 start-50 translate-middle`}
                  />
                </div>
              ) : (
                <Datatable
                  data={
                    Array.isArray(shortLinkData?.records)
                      ? shortLinkData?.records
                      : []
                  }
                  columns={columns}
                  handlePageChange={handlePageChange}
                  handleRowsPerPageChange={handleRowsPerPageChange}
                  currPage={currPage}
                  rowsPerPage={rowsPerPage}
                  totalRecords={shortLinkData?.totalRecords}
                />
              )}
            </Card.Body>
          </Card>
        </div>
      </Container>
    </div>
  );
};
export default ShortURL;
