import React from "react";
import { Container, Row, Col, Card, Image } from "react-bootstrap";

// Images
import avatar1 from "assets/images/login/call.png";
import avatar2 from "assets/images/login/chat.png";
import avatar3 from "assets/images/login/mail.png";
import avatar4 from "assets/images/login/network.png";
import avatar5 from "assets/images/login/sms.png";
import avatar6 from "assets/images/login/wifi.png";
import withRouter from "Common/withRouter";
import logoLight from "assets/images/logo-light.png";

const ParticlesAuth = ({ children }: any) => {
  return (
    <React.Fragment>
      <div className="auth-page-wrapper py-5 position-relative d-flex align-items-center justify-content-center min-vh-100">
        <Container>
          <Row className="justify-content-center">
            <Col lg={11}>
              <Card className="mb-0">
                <Row className="g-0 align-items-center">
                  <Col lg={7} xl={6} xxl={5}>
                    <Card className="auth-card bg-secondary h-100 border-0 shadow-none d-none d-sm-block mb-0">
                      <Card.Body className="py-5 d-flex justify-content-between flex-column">
                        <div className="text-center">
                          <h3 className="text-white">
                            Start your journey with us.
                          </h3>
                          <p className="text-white opacity-75 fs-base">
                            Accelerate your business growth with seamless
                            messaging solutions powered by Signal House.
                          </p>
                        </div>

                        <div className="auth-effect-main my-5 position-relative rounded-circle d-flex align-items-center justify-content-center mx-auto">
                          <div className="effect-circle-1 position-relative mx-auto rounded-circle d-flex align-items-center justify-content-center">
                            <div className="effect-circle-2 position-relative mx-auto rounded-circle d-flex align-items-center justify-content-center">
                              <div className="effect-circle-3 mx-auto rounded-circle position-relative text-white fs-3xl d-flex align-items-center justify-content-center">
                                <img src={logoLight} alt="" height="80" />
                              </div>
                            </div>
                          </div>
                          <ul className="auth-user-list list-unstyled">
                            <li>
                              <div className="avatar-sm d-inline-block">
                                <div className="avatar-title shadow-lg overflow-hidden rounded-circle">
                                  <Image
                                    src={avatar1}
                                    alt=""
                                    className="img-fluid"
                                  />
                                </div>
                              </div>
                            </li>
                            <li>
                              <div className="avatar-sm d-inline-block">
                                <div className="avatar-title shadow-lg overflow-hidden rounded-circle">
                                  <Image
                                    src={avatar2}
                                    alt=""
                                    className="img-fluid"
                                  />
                                </div>
                              </div>
                            </li>
                            <li>
                              <div className="avatar-sm d-inline-block">
                                <div className="avatar-title shadow-lg overflow-hidden rounded-circle">
                                  <Image
                                    src={avatar3}
                                    alt=""
                                    className="img-fluid"
                                  />
                                </div>
                              </div>
                            </li>
                            <li>
                              <div className="avatar-sm d-inline-block">
                                <div className="avatar-title shadow-lg overflow-hidden rounded-circle">
                                  <Image
                                    src={avatar4}
                                    alt=""
                                    className="img-fluid"
                                  />
                                </div>
                              </div>
                            </li>
                            <li>
                              <div className="avatar-sm d-inline-block">
                                <div className="avatar-title shadow-lg overflow-hidden rounded-circle">
                                  <Image
                                    src={avatar5}
                                    alt=""
                                    className="img-fluid"
                                  />
                                </div>
                              </div>
                            </li>
                            <li>
                              <div className="avatar-sm d-inline-block">
                                <div className="avatar-title shadow-lg overflow-hidden rounded-circle">
                                  <Image
                                    src={avatar6}
                                    alt=""
                                    className="img-fluid"
                                  />
                                </div>
                              </div>
                            </li>
                          </ul>
                        </div>

                        <div className="text-center">
                          <p className="text-white opacity-75 mb-0 mt-3">
                            &copy; {new Date().getFullYear()} Signal House.
                          </p>
                        </div>
                      </Card.Body>
                    </Card>
                  </Col>
                  {/* Pass Children */}
                  {children}
                </Row>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(ParticlesAuth);
