import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  loading: false,
  verticalObj: {},
  stockExchangeArr: [],
  countryObj: {},
  stateObj: {},
  brandAppealCategoryArr: [],
  entityTypeArr: [],
  brandRelationshipArr: [],
  usecaseObj: {},
  connectivityPartnersObj: {},
  success: false,
  successMsg: "",
  error: false,
  errorMsg: "",
  BrandSuspendedHead: [],
};

const enumsDataSlice = createSlice({
  name: "enums",
  initialState,
  reducers: {
    apiLoading(state) {
      state.loading = true;
      state.success = false;
      state.successMsg = "";
      state.error = false;
      state.errorMsg = "";
    },
    verticalDataSuccessful(state, action) {
      state.loading = false;
      state.verticalObj = action.payload;
      state.success = true;
      state.successMsg = "";
      state.error = false;
      state.errorMsg = "";
    },
    verticalDataFailed(state, action) {
      state.loading = false;
      state.verticalObj = {};
      state.success = false;
      state.successMsg = "";
      state.error = true;
      state.errorMsg = action.payload.message;
    },
    stockExchangeDataSuccessful(state, action) {
      state.loading = false;
      state.stockExchangeArr = action.payload;
      state.success = true;
      state.successMsg = "";
      state.error = false;
      state.errorMsg = "";
    },
    stockExchangeDataFailed(state, action) {
      state.loading = false;
      state.stockExchangeArr = [];
      state.success = false;
      state.successMsg = "";
      state.error = true;
      state.errorMsg = action.payload.message;
    },
    countryDataSuccessful(state, action) {
      state.loading = false;
      state.countryObj = action.payload;
      state.success = true;
      state.successMsg = "";
      state.error = false;
      state.errorMsg = "";
    },
    countryDataFailed(state, action) {
      state.loading = false;
      state.countryObj = {};
      state.success = false;
      state.successMsg = "";
      state.error = true;
      state.errorMsg = action.payload.message;
    },
    stateDataSuccessful(state, action) {
      state.loading = false;
      state.stateObj = action.payload;
      state.success = true;
      state.successMsg = "";
      state.error = false;
      state.errorMsg = "";
    },
    stateDataFailed(state, action) {
      state.loading = false;
      state.stateObj = {};
      state.success = false;
      state.successMsg = "";
      state.error = true;
      state.errorMsg = action.payload.message;
    },
    brandAppealCategoryDataSuccessful(state, action) {
      state.loading = false;
      state.brandAppealCategoryArr = action.payload;
      state.success = true;
      state.successMsg = "";
      state.error = false;
      state.errorMsg = "";
    },
    brandAppealCategoryDataFailed(state, action) {
      state.loading = false;
      state.brandAppealCategoryArr = [];
      state.success = false;
      state.successMsg = "";
      state.error = true;
      state.errorMsg = action.payload.message;
    },
    entityTypeDataSuccessful(state, action) {
      state.loading = false;
      state.entityTypeArr = action.payload;
      state.success = true;
      state.successMsg = "";
      state.error = false;
      state.errorMsg = "";
    },
    entityTypeDataFailed(state, action) {
      state.loading = false;
      state.entityTypeArr = [];
      state.success = false;
      state.successMsg = "";
      state.error = true;
      state.errorMsg = action.payload.message;
    },
    brandRelationshipDataSuccessful(state, action) {
      state.loading = false;
      state.brandRelationshipArr = action.payload;
      state.success = true;
      state.successMsg = "";
      state.error = false;
      state.errorMsg = "";
    },
    brandRelationshipDataFailed(state, action) {
      state.loading = false;
      state.brandRelationshipArr = [];
      state.success = false;
      state.successMsg = "";
      state.error = true;
      state.errorMsg = action.payload.message;
    },
    usecaseDataSuccessful(state, action) {
      state.loading = false;
      state.usecaseObj = action.payload;
      state.success = true;
      state.successMsg = "";
      state.error = false;
      state.errorMsg = "";
    },
    usecaseDataFailed(state, action) {
      state.loading = false;
      state.usecaseObj = {};
      state.success = false;
      state.successMsg = "";
      state.error = true;
      state.errorMsg = action.payload.message;
    },
    connectivityPartnersDataSuccessful(state, action) {
      state.loading = false;
      state.connectivityPartnersObj = action.payload;
      state.success = true;
      state.successMsg = "";
      state.error = false;
      state.errorMsg = "";
    },
    connectivityPartnersDataFailed(state, action) {
      state.loading = false;
      state.connectivityPartnersObj = {};
      state.success = false;
      state.successMsg = "";
      state.error = true;
      state.errorMsg = action.payload.message;
    },
    //Brand Suspended data
    BrandSuspendedSuccessful(state, action) {
      state.loading = false;
      state.BrandSuspendedHead = action.payload;
      state.success = true;
      state.successMsg = "";
      state.error = false;
      state.errorMsg = "";
    },
    BrandSuspendedFailed(state, action) {
      state.loading = false;
      state.successMsg = "";
      state.success = false;
      state.successMsg = "";
      state.error = true;
      state.errorMsg = action.payload.message;
    },
  },
});

export const {
  apiLoading,
  verticalDataSuccessful,
  verticalDataFailed,
  stockExchangeDataSuccessful,
  stockExchangeDataFailed,
  countryDataSuccessful,
  countryDataFailed,
  stateDataSuccessful,
  stateDataFailed,
  brandAppealCategoryDataSuccessful,
  brandAppealCategoryDataFailed,
  entityTypeDataSuccessful,
  entityTypeDataFailed,
  brandRelationshipDataSuccessful,
  brandRelationshipDataFailed,
  usecaseDataSuccessful,
  usecaseDataFailed,
  connectivityPartnersDataSuccessful,
  connectivityPartnersDataFailed,
  BrandSuspendedSuccessful,
  BrandSuspendedFailed,
} = enumsDataSlice.actions;

export default enumsDataSlice.reducer;
