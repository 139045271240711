import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  loading: false,
  resellersArr: [],
  success: false,
  successMsg: "",
  error: false,
  errorMsg: "",
};

const resellersDataSlice = createSlice({
  name: "resellers",
  initialState,
  reducers: {
    apiLoading(state) {
      state.loading = true;
      state.success = false;
      state.successMsg = "";
      state.error = false;
      state.errorMsg = "";
    },
    resellersDataSuccessful(state, action) {
      state.loading = false;
      state.resellersArr = action.payload;
      state.success = true;
      state.successMsg = "";
      state.error = false;
      state.errorMsg = "";
    },
    resellersDataFailed(state, action) {
      state.loading = false;
      state.resellersArr = [];
      state.success = false;
      state.successMsg = "";
      state.error = true;
      state.errorMsg = action.payload.message;
    },
    resellerCreateSuccessful(state, action) {
      state.loading = false;
      state.success = true;
      state.successMsg = "";
      state.error = false;
      state.errorMsg = "";
    },
    resellerCreateFailed(state, action) {
      state.loading = false;
      state.success = false;
      state.successMsg = "";
      state.error = true;
      state.errorMsg = action.payload.message;
    },
  },
});

export const {
  apiLoading,
  resellersDataSuccessful,
  resellersDataFailed,
  resellerCreateSuccessful,
  resellerCreateFailed,
} = resellersDataSlice.actions;

export default resellersDataSlice.reducer;
