import { getOtpAPi, numVerify, verifyNum } from "helpers/apiroute_helper";
import {
  initiateVerificationFailure,
  initiateVerificationSuccess,
} from "./reducer";
import { toast } from "react-toastify";
import { getAuthUserThunk } from "slices/thunk";

export const initiateNumberVerification =
  (cb: any) => async (dispatch: any) => {
    try {
      const response: any = await numVerify();

      if (response?.code || response?.errorCode) {
        toast.error(response?.message || "Failed to initiate verification");
      } else {
        dispatch(initiateVerificationSuccess());
        dispatch(getAuthUserThunk());
        // toast.success(
        //   response?.message || "Verification initiated successfully."
        // );
        cb();
      }
    } catch (error: any) {
      console.error("error", error);
      dispatch(initiateVerificationFailure(error));
      toast.error(error?.response?.data?.message || "Something went wrong");
    }
  };

export const verifyOTP = (otp: any, cb?: any) => async (dispatch: any) => {
  try {
    const response: any = await verifyNum({ otp });

    if (response?.code || response?.errorCode) {
      toast.error(response?.message || "OTP verification failed");
    } else {
      dispatch(getAuthUserThunk());
      // toast.success(response?.message || "OTP verified successfully.");
      cb && cb();
    }
  } catch (error: any) {
    console.error("error", error);
    toast.error(error?.response?.data?.message || "Something went wrong");
  }
};

export const resendOTP = () => async () => {
  try {
    const response: any = await getOtpAPi();

    if (response?.code || response?.errorCode) {
      toast.error(response?.message || "Failed to resend OTP");
    } else {
      toast.success(response?.message || "OTP resent successfully.");
    }
  } catch (error: any) {
    console.error("error", error);
    toast.error(error?.response?.data?.message || "Something went wrong");
  }
};
