import { createSlice } from "@reduxjs/toolkit";
import { fileUploadSuccess } from "slices/brands/reducer";

export const initialState = {
  loading: false,
  portRequestsArr: [],
  portOutRequestsArr: [],
  success: false,
  successMsg: "",
  error: false,
  errorMsg: "",
  uploadedFile: [],
  historyRecords: {},
  portingStatusData: {},
};

const portRequestsDataSlice = createSlice({
  name: "ports",
  initialState,
  reducers: {
    apiLoading(state) {
      state.loading = true;
      state.success = false;
      state.successMsg = "";
      state.error = false;
      state.errorMsg = "";
    },
    apiLoadingFalse(state) {
      state.loading = false;
      state.success = false;
      state.successMsg = "";
      state.error = false;
      state.errorMsg = "";
    },
    portRequestsDataSuccessful(state, action) {
      state.loading = false;
      state.portRequestsArr = action.payload;
      state.success = true;
      state.successMsg = "";
      state.error = false;
      state.errorMsg = "";
    },
    portRequestsDataFailed(state, action) {
      state.loading = false;
      state.portRequestsArr = [];
      state.success = false;
      state.successMsg = "";
      state.error = true;
      state.errorMsg = action.payload.message;
    },
    portOutDataSuccessful(state, action) {
      state.loading = false;
      state.portOutRequestsArr = action.payload;
      state.success = true;
      state.successMsg = "";
      state.error = false;
      state.errorMsg = "";
    },

    portOutDataFailed(state, action) {
      state.loading = false;
      state.portRequestsArr = [];
      state.success = false;
      state.successMsg = "";
      state.error = true;
      state.errorMsg = action.payload.message;
    },
    createPortNumberRequestSuccessful(state, action) {
      state.loading = false;
      state.success = true;
      state.successMsg = "";
      state.error = false;
      state.errorMsg = "";
    },
    createPortNumberRequestFailed(state, action) {
      state.loading = false;
      state.success = false;
      state.successMsg = "";
      state.error = true;
      state.errorMsg = action.payload.message;
    },
    fileUploadDone(state, action) {
      state.loading = false;
      state.success = true;
      state.uploadedFile = [];
      state.error = false;
    },
    updateAuthUserFailed(state, action) {
      state.loading = false;
      state.success = false;
      state.successMsg = "";
      state.error = true;
      state.errorMsg = action.payload.message;
    },
    //Ptporting Status Data
    getportingStatusDataSuccessful(state, action) {
      state.loading = false;
      state.portingStatusData = action.payload;
      state.success = true;
      state.successMsg = "";
      state.error = false;
      state.errorMsg = "";
    },
    getportingStatusDataFailed(state, action) {
      state.loading = false;
      state.successMsg = "";
      state.success = false;
      state.successMsg = "";
      state.error = true;
      state.errorMsg = action.payload.message;
    },
    resetPortingStatusData(state) {
      state.loading = false;
      state.portingStatusData = {};
      state.success = true;
      state.successMsg = "";
      state.error = false;
      state.errorMsg = "";
    },
  },
});

export const {
  apiLoading,
  apiLoadingFalse,
  portRequestsDataSuccessful,
  portRequestsDataFailed,
  portOutDataSuccessful,
  portOutDataFailed,
  createPortNumberRequestSuccessful,
  createPortNumberRequestFailed,
  fileUploadDone,
  updateAuthUserFailed,
  getportingStatusDataSuccessful,
  getportingStatusDataFailed,
  resetPortingStatusData,
} = portRequestsDataSlice.actions;

export default portRequestsDataSlice.reducer;
