import React, { useEffect, useState } from "react";
import { Card, Container, Form } from "react-bootstrap";
import {
  getSubUsersListThunk,
  openModal,
  updateSubuserStatusThunk,
} from "slices/thunk";
import { useDispatch, useSelector } from "react-redux";
import { createSelector } from "reselect";
import BreadCrumb from "Common/BreadCrumb";
import Datatable from "Common/Datatable";
import Loader from "assets/images/spinner-dark.svg";
import Tooltip from "rc-tooltip";
import { capitalizeString, dateFormat } from "helpers/common";
import { submitLoadingSubject } from "Common/modals/Modal";

const Users = () => {
  const dispatch = useDispatch<any>();

  const selectAccount = createSelector(
    (state: any) => state.Account,
    (state: any) => state.AuthUser,
    (account, authUser) => ({
      loading4: account.loading2,
      subUsersData: account.subUsersData,
      authUser: authUser.authUser,
      error: account.error,
    })
  );

  const { loading4, subUsersData, authUser } = useSelector(selectAccount);

  const [currPage, setCurrPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const columns = [
    {
      name: <span className="font-weight-bold fs-sm">Name</span>,
      minWidth: "250px",
      selector: (row: { firstName: string; lastName: string }) =>
        `${row?.firstName || ""} ${row?.lastName || ""}`,
      cell: (row: { firstName: string; lastName: string }) => (
        <span>
          {row?.firstName || ""} {row?.lastName || ""}
        </span>
      ),
      sortable: true,
      sortFunction: (a: any, b: any) =>
        `${a.firstName || ""} ${a.lastName || ""}`.localeCompare(
          `${b.firstName || ""} ${b.lastName || ""}`
        ),
    },
    {
      name: <span className="font-weight-bold fs-sm">Email ID</span>,
      id: "email",
      minWidth: "270px",
      selector: (row: { emailId: string }) => row.emailId || "",
      cell: (row: { emailId: string }) => (
        <span className="d-flex align-items-center">
          <i className="bi bi-envelope me-2 fs-xl text-secondary" />
          <span className="line-clamp-1">{row.emailId || ""}</span>
        </span>
      ),
      sortable: true,
      sortFunction: (a: any, b: any) =>
        (a.emailId || "").localeCompare(b.emailId || ""),
    },
    {
      name: <span className="font-weight-bold fs-sm">Role</span>,
      width: "150px",
      selector: (cell: { roles: string; emailId: string }) => (
        <span>
          <span className="badge bg-body-secondary border border-secondary text-secondary">
            {capitalizeString(cell.roles.replace("company_", ""))}
          </span>
          {authUser?.roles?.[0] === "company_admin" && (
            <i
              className="bi bi-pencil fs-md cursor-pointer text-secondary ms-2"
              style={{
                WebkitTextStroke: "0.5px",
              }}
              onClick={() => {
                dispatch(
                  openModal({
                    path: "UpdateSubuserRole",
                    data: {
                      title: "Update User Role",
                      detailsRoles: cell.roles || "",
                      emailId: cell.emailId || "",
                      getSubUsersData: () =>
                        getSubUsersData(currPage, rowsPerPage),
                      footer: false,
                    },
                  })
                );
              }}
            />
          )}
        </span>
      ),
      sortable: true,
      sortFunction: (a: any, b: any) =>
        (a.roles || "")
          .toLowerCase()
          .localeCompare((b.roles || "").toLowerCase()),
    },
    {
      name: <span className="font-weight-bold fs-sm">Last Login</span>,
      width: "220px",
      selector: (row: { lastLogin: { created: string } }) =>
        row.lastLogin ? (
          <span data-tag="allowRowEvents" className="d-flex align-items-center">
            <i
              data-tag="allowRowEvents"
              className="bi bi-calendar3 me-2 fs-lg text-secondary"
            />
            {dateFormat(row.lastLogin?.created, "MM/DD/YYYY, hh:mm:ss A")}
          </span>
        ) : (
          "-"
        ),
      sortable: true,
      sortFunction: (a: any, b: any) => {
        return (
          new Date(a.lastLogin?.created).getTime() -
          new Date(b.lastLogin?.created).getTime()
        );
      },
    },
    {
      name: <span className="font-weight-bold fs-sm">Create Date</span>,
      width: "220px",
      selector: (row: { createDatetime: string }) => (
        <span data-tag="allowRowEvents" className="d-flex align-items-center">
          <i
            data-tag="allowRowEvents"
            className="bi bi-calendar3 me-2 fs-lg text-secondary"
          />
          {dateFormat(row.createDatetime, "MM/DD/YYYY, hh:mm:ss A")}
        </span>
      ),
      sortable: true,
      sortFunction: (a: any, b: any) => {
        return (
          new Date(a.createDatetime).getTime() -
          new Date(b.createDatetime).getTime()
        );
      },
    },
    {
      name: <span className="font-weight-bold fs-sm">Active</span>,
      width: "130px",
      selector: (row: { suspend: boolean }) => row.suspend,
      cell: (row: { suspend: boolean; firstName: string; emailId: string }) => (
        <div className="form-check form-switch form-switch-md" dir="ltr">
          <Form.Check
            type="checkbox"
            role="switch"
            disabled={authUser?.roles?.[0] !== "company_admin"}
            checked={!row.suspend}
            onChange={() => {
              dispatch(
                openModal({
                  modalbody: `Are you sure you want to ${
                    !row.suspend ? "suspend" : "activate"
                  } this user, ${row.firstName || ""}?`,
                  data: {
                    title: `${!row.suspend ? "Suspend" : "Active"} User`,
                    footer: true,
                    cancelBtn: true,
                    buttonText: {
                      submit: "Continue",
                    },
                    onSubmit: ({ onClose }: any) => {
                      const cb = () => {
                        onClose();
                        getSubUsersData(currPage, rowsPerPage);
                      };
                      submitLoadingSubject.next(true);
                      dispatch(
                        updateSubuserStatusThunk(
                          row.emailId,
                          { suspend: !row.suspend },
                          cb
                        )
                      ).then(() => {
                        submitLoadingSubject.next(false);
                      });
                    },
                  },
                })
              );
            }}
          />
        </div>
      ),
      sortable: true,
      sortFunction: (a: any, b: any) => a.suspend.localeCompare(b.suspend),
    },
    {
      name: <span className="font-weight-bold fs-sm">Action</span>,
      width: "150px",
      cell: (row: { firstName: string; emailId: string }) => {
        return (
          <div className="d-flex align-items-center">
            <Tooltip
              placement="bottom"
              overlay={<p className="mb-0">Login Details</p>}
            >
              <i
                className="ri-eye-fill align-middle me-3 text-muted fs-xl"
                onClick={() => {
                  dispatch(
                    openModal({
                      path: "SubUserDetails",
                      data: {
                        modalClass: "campResubmitModal",
                        title: `${row.firstName}'s Details`,
                        footer: true,
                        cancelBtn: true,
                        details: row,
                        getSubUsersData: () =>
                          getSubUsersData(currPage, rowsPerPage),
                      },
                    })
                  );
                }}
              />
            </Tooltip>
            <Tooltip
              placement="bottom"
              overlay={<p className="mb-0">Change Password</p>}
            >
              <i
                className="ri-lock-2-fill align-middle text-muted fs-xl"
                onClick={() => {
                  dispatch(
                    openModal({
                      path: "ChangeSubUserPassword",
                      data: {
                        title: `Change Password for ${row.firstName}`,
                        footer: false,
                        userEmailId: row.emailId,
                      },
                    })
                  );
                }}
              />
            </Tooltip>
          </div>
        );
      },
      sortable: false,
    },
  ];

  useEffect(() => {
    getSubUsersData(currPage, rowsPerPage);
  }, []);

  const getSubUsersData = (
    page: number,
    perPage: number,
    otherParams?: any
  ) => {
    dispatch(getSubUsersListThunk(page, perPage, otherParams || {}));
  };

  const handlePageChange = (page: number) => {
    setCurrPage(page);
    getSubUsersData(page, rowsPerPage);
  };

  const handleRowsPerPageChange = (rows: number) => {
    setCurrPage(1);
    setRowsPerPage(rows);
    getSubUsersData(1, rows);
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb
            title="Users"
            isCreate={true}
            createLabel="Add User"
            createClick={() => {
              dispatch(
                openModal({
                  path: "AddSubUser",
                  data: {
                    title: "Add User",
                    footer: false,
                    getSubUsersData: () =>
                      getSubUsersData(currPage, rowsPerPage),
                  },
                })
              );
            }}
          />
          <div className="position-relative">
            <Card className="wrapper">
              <Card.Body className="listing-table">
                {loading4 ? (
                  <div className={``}>
                    <img
                      src={Loader}
                      className={`position-absolute top-50 start-50 translate-middle`}
                    />
                  </div>
                ) : (
                  <Datatable
                    data={
                      Array.isArray(subUsersData?.records)
                        ? subUsersData?.records
                        : []
                    }
                    columns={columns}
                    className="subUserTable"
                    handlePageChange={handlePageChange}
                    handleRowsPerPageChange={handleRowsPerPageChange}
                    currPage={currPage}
                    rowsPerPage={rowsPerPage}
                    totalRecords={subUsersData?.totalRecords}
                  />
                )}
              </Card.Body>
            </Card>
          </div>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Users;
