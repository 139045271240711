import { createSlice } from "@reduxjs/toolkit";
export const initialState = {
  twoFAEnabled: false,
  verificationLoading: false,
  verificationSuccess: false,
  verificationError: false,
  verificationErrorMessage: "",
  otpSent: false,
  otpVerified: false,
  numberVerified: false,
};
const verificationSlice = createSlice({
  name: "verification",
  initialState:initialState,
  reducers: {
    enable2FAStart(state) {
      state.verificationLoading = true;
      state.verificationError = false;
      state.verificationErrorMessage = "";
    },
    enable2FASuccess(state, action) {
      state.verificationLoading = false;
      state.twoFAEnabled = true;
    },
    enable2FAFailure(state, action) {
      state.verificationLoading = false;
      state.verificationError = true;
      state.verificationErrorMessage = action.payload.message;
    },
    initiateVerificationStart(state) {
      state.verificationLoading = true;
      state.verificationError = false;
      state.verificationErrorMessage = "";
    },
    initiateVerificationSuccess(state) {
      state.verificationLoading = false;
      state.otpSent = true;
    },
    initiateVerificationFailure(state, action) {
      state.verificationLoading = false;
      state.verificationError = true;
      state.verificationErrorMessage = action.payload.message;
    },
    resendOTPStart(state) {
      state.verificationLoading = true;
      state.verificationError = false;
      state.verificationErrorMessage = "";
    },
    resendOTPSuccess(state) {
      state.verificationLoading = false;
      state.otpSent = true;
    },
    resendOTPFailure(state, action) {
      state.verificationLoading = false;
      state.verificationError = true;
      state.verificationErrorMessage = action.payload.message;
    },
    verifyOTPStart(state) {
      state.verificationLoading = true;
      state.verificationError = false;
      state.verificationErrorMessage = "";
    },
    verifyOTPSuccess(state) {
      state.verificationLoading = false;
      state.otpVerified = true;
    },
    verifyOTPFailure(state, action) {
      state.verificationLoading = false;
      state.verificationError = true;
      state.verificationErrorMessage = action.payload.message;
    },
    checkVerificationStatusStart(state) {
      state.verificationLoading = true;
      state.verificationError = false;
      state.verificationErrorMessage = "";
    },
    checkVerificationStatusSuccess(state, action) {
      state.verificationLoading = false;
      state.numberVerified = action.payload.numberVerified;
    },
    checkVerificationStatusFailure(state, action) {
      state.verificationLoading = false;
      state.verificationError = true;
      state.verificationErrorMessage = action.payload.message;
    },
  },
});

export const {
  enable2FAStart,
  enable2FASuccess,
  enable2FAFailure,
  initiateVerificationStart,
  initiateVerificationSuccess,
  initiateVerificationFailure,
  resendOTPStart,
  resendOTPSuccess,
  resendOTPFailure,
  verifyOTPStart,
  verifyOTPSuccess,
  verifyOTPFailure,
  checkVerificationStatusStart,
  checkVerificationStatusSuccess,
  checkVerificationStatusFailure,
} = verificationSlice.actions;

export default verificationSlice.reducer;
