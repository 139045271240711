import { forgotPassApi, updatePassApi } from "helpers/apiroute_helper";
import {
  apiLoading,
  userForgetPasswordSuccess,
  userForgetPasswordFailed,
  updatePasswordSuccess,
  updatePasswordFailed,
} from "./reducer";
import { toast } from "react-toastify";

export const userForgetPasswordThunk =
  (emailId: string, history: any) => async (dispatch: any) => {
    dispatch(apiLoading());

    try {
      const data: any = await forgotPassApi(emailId);

      if (data?.exist) {
        dispatch(userForgetPasswordSuccess(data));
        toast.success(
          "Password reset link sent successfully! Please check your inbox to reset your password."
        );
        // var pathArray = data?.forgotPassUri?.split("/");
        // const tokenUrl = pathArray[3];
        // tokenUrl && history(`/${tokenUrl}`);
      } else {
        dispatch(userForgetPasswordFailed());
        toast.error(
          data?.message || data?.exist
            ? "Something went wrong"
            : "User not exist"
        );
      }
    } catch (error: any) {
      console.log("error", error);
      dispatch(userForgetPasswordFailed());
      toast.error(error?.response?.data?.message || "Something went wrong");
    }
  };

export const updatePasswordThunk =
  (password: any, token?: string | null, email?: any, history?: any) =>
  async (dispatch: any) => {
    dispatch(apiLoading());

    try {
      const data: any = token
        ? await updatePassApi({ password }, token, email)
        : await updatePassApi(password);

      if (data?.message) {
        dispatch(updatePasswordSuccess());
        toast.success(data?.message);
        history && history("/login");
      } else {
        dispatch(updatePasswordFailed());
        toast.error("Something went wrong");
      }
    } catch (error: any) {
      dispatch(updatePasswordFailed());
      toast.error(error?.response?.data?.message || "Something went wrong");
    }
  };
