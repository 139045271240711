import React from "react";
import { Container, Row, Col } from "react-bootstrap";
// import Skeleton from "react-loading-skeleton";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const DashboardSkeleton = () => {
  return (
    <div className="page-content">
      <Container fluid>
        {/* Header */}
        <div className="page-title-box d-flex align-items-center justify-content-between">
          <h4 className="mb-0 me-3">
            <Skeleton width={200} />
          </h4>
          <div className="d-flex align-items-center" style={{ flex: "1 1 0%" }}>
            <div className="flex-grow-1">
              <Skeleton width={200} />
            </div>
            <div className="ms-2">
              <Skeleton width={40} height={40} />
            </div>
          </div>
        </div>

        {/* Widgets Section */}
        <Row className="mb-4">
          {[1, 2, 3, 4].map((i) => (
            <Col key={i} xl={3} md={6}>
              <div className="card">
                <div className="card-body p-4">
                  <Skeleton height={20} width={100} className="mb-2" />
                  <Skeleton height={30} width={150} className="mb-2" />
                  <Skeleton height={30} width={150} className="mb-2" />
                  <Skeleton height={30} width={150} className="mb-2" />
                </div>
              </div>
            </Col>
          ))}
        </Row>

        {/* Usage Summary and Payment History */}
        <Row className="mb-4">
          <Col xl={8}>
            <div className="card">
              <div className="card-body">
                <Skeleton height={20} width={150} className="mb-4" />
                <Skeleton height={200} />
              </div>
            </div>
          </Col>
          <Col xl={4}>
            <div className="card">
              <div className="card-body">
                <Skeleton height={20} width={150} className="mb-4" />
                <Skeleton height={200} />
              </div>
            </div>
          </Col>
        </Row>

        {/* Last Days and Delivery Rate */}
        <Row>
          <Col xl={8}>
            <div className="card">
              <div className="card-body">
                <Skeleton height={20} width={150} className="mb-4" />
                <Skeleton height={300} />
              </div>
            </div>
          </Col>
          <Col xl={4}>
            <div className="card">
              <div className="card-body">
                <Skeleton height={20} width={150} className="mb-4" />
                <Skeleton height={300} />
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default DashboardSkeleton;
