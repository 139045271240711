import { useEffect } from "react";
import { Col, Form, Row, Tab } from "react-bootstrap";
import Loader from "assets/images/spinner-dark.svg";

const Step2 = (props: any) => {
  const { loading2, validation2, campaignTerms, setactiveTab } = props;

  useEffect(() => {
    const qualifyData =
      campaignTerms?.mnoMetadata &&
      Object.fromEntries(
        Object.entries(campaignTerms?.mnoMetadata).filter(
          ([key, value]: any) => value?.qualify
        )
      );
    validation2.setFieldValue(
      "mnoIds",
      qualifyData && Object.keys(qualifyData)?.length > 0
        ? Object.keys(qualifyData)
        : []
    );
  }, [campaignTerms]);

  return (
    <Tab.Pane
      eventKey="1"
      id="pills-info-desc"
      role="tabpanel"
      aria-labelledby="pills-info-desc-tab"
    >
      {loading2 ? (
        <div className={``}>
          <img
            src={Loader}
            className={`position-absolute top-50 start-50 translate-middle`}
            alt="loading"
          />
        </div>
      ) : (
        <Form
          action="#"
          onSubmit={(e) => {
            e.preventDefault();
            validation2.handleSubmit();
          }}
        >
          <div>
            <div className="mb-4">
              <div>
                <div>
                  <h5 className="mb-1">Carrier Terms Preview</h5>
                  <p className="text-muted">
                    The below list shows campaign qualification results and
                    terms for each MNO.
                  </p>
                </div>
              </div>
            </div>
            <div className="">
              {campaignTerms?.mnoMetadata &&
              Object.keys(campaignTerms?.mnoMetadata).length > 0
                ? Object.keys(campaignTerms?.mnoMetadata).map((dt, i) => (
                    <Row className="mb-3 p-2" key={i}>
                      <div className="d-flex align-items-center justify-content-center w-auto">
                        {campaignTerms?.mnoMetadata?.[dt]?.qualify ? (
                          <i className="ri-checkbox-circle-line display-6 text-primary"></i>
                        ) : (
                          <i className="ri-checkbox-blank-circle-line display-6 text-muted"></i>
                        )}
                      </div>
                      <Col className="bg-secondary text-white d-flex align-items-center justify-content-center">
                        {campaignTerms?.mnoMetadata?.[dt]?.mno}
                      </Col>
                      <Col className="p-0 text-center">
                        <div className="bg-primary-subtle p-2">Qualify</div>
                        <div className="p-2 bg-body">
                          {campaignTerms?.mnoMetadata?.[dt]?.qualify
                            ? "Yes"
                            : "No"}
                        </div>
                      </Col>
                      <Col className="p-0 text-center">
                        <div className="bg-primary-subtle p-2">MNO Review</div>
                        <div className="p-2 bg-body">
                          {campaignTerms?.mnoMetadata?.[dt]?.mnoReview
                            ? "Yes"
                            : "No"}
                        </div>
                      </Col>
                      <Col className="p-0 text-center">
                        <div className="bg-primary-subtle p-2">
                          {campaignTerms?.mnoMetadata?.[dt]?.mno === "T-Mobile"
                            ? "Brand Tier"
                            : "TPM Scope"}
                        </div>
                        <div className="p-2 bg-body">
                          {campaignTerms?.mnoMetadata?.[dt]?.mno === "T-Mobile"
                            ? campaignTerms?.mnoMetadata?.[dt]?.brandTier ||
                              "N/A"
                            : campaignTerms?.mnoMetadata?.[dt]?.tpmScope ||
                              "N/A"}
                        </div>
                      </Col>
                      <Col className="p-0 text-center">
                        <div className="bg-primary-subtle p-2">
                          {campaignTerms?.mnoMetadata?.[dt]?.mno === "T-Mobile"
                            ? "Brand Daily Cap"
                            : "SMS TPM"}
                        </div>
                        <div className="p-2 bg-body">
                          {campaignTerms?.mnoMetadata?.[dt]?.mno === "T-Mobile"
                            ? campaignTerms?.mnoMetadata?.[dt]?.brandDailyCap
                              ? "Yes"
                              : "No"
                            : campaignTerms?.mnoMetadata?.[dt]?.tpm || "N/A"}
                        </div>
                      </Col>
                      <Col className="p-0 text-center">
                        <div className="bg-primary-subtle p-2">MMS TPM</div>
                        <div className="p-2 bg-body">
                          {campaignTerms?.mnoMetadata?.[dt]?.mmsTpm || "N/A"}
                        </div>
                      </Col>
                      <Col className="p-0 text-center">
                        <div className="bg-primary-subtle p-2">
                          Message Class
                        </div>
                        <div className="p-2 bg-body">
                          {campaignTerms?.mnoMetadata?.[dt]?.msgClass || "N/A"}
                        </div>
                      </Col>
                    </Row>
                  ))
                : null}
            </div>
          </div>
          <div className="d-flex align-items-start justify-content-center gap-3 mt-4">
            <button
              type="button"
              className="btn btn-link text-decoration-none btn-label previestab"
              onClick={() => setactiveTab(0)}
            >
              <i className="ri-arrow-left-line label-icon align-middle fs-lg"></i>
              Back to Selection
            </button>
            <button
              type="submit"
              className="btn btn-secondary btn-label right nexttab nexttab"
            >
              <i className="ri-arrow-right-line label-icon align-middle fs-lg ms-2"></i>
              Next
            </button>
          </div>
        </Form>
      )}
    </Tab.Pane>
  );
};

export default Step2;
