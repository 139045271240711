import React, { useState, useEffect, useRef } from "react";
import "swagger-ui-react/swagger-ui.css";
import {
  Card,
  Container,
  Form,
  Navbar,
  Image,
  InputGroup,
  Badge,
  Button,
} from "react-bootstrap";
import Logo from "../../assets/images/logo-light.png";
// @ts-ignore
import SwaggerUI from "swagger-ui-react";
import { FaSearch } from "react-icons/fa";
import { useLocation } from "react-router-dom";

const ApiDocs: React.FC = () => {
  const [apiSpec, setApiSpec] = useState<any>(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [error, setError] = useState<string | null>(null);
  const location = useLocation();
  const swaggerRef = useRef<any>(null);

  useEffect(() => {
    const fetchApiData = async () => {
      try {
        const response = await fetch("https://devapi.signalhouse.io/openApi/", {
          headers: {
            "Content-Type": "application/json",
          },
        });
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();
        setApiSpec(data);
        setError(null);
      } catch (error) {
        console.error("Error fetching API data:", error);
        setError("Failed to load API documentation. Please try again later.");
      }
    };

    fetchApiData();
  }, []);

  useEffect(() => {
    if (apiSpec) {
      const hash = location.hash;
      if (hash) {
        // Wait for SwaggerUI to fully render
        setTimeout(() => {
          const decodedHash = decodeURIComponent(hash.replace(/^#/, ""));
          // Find the element with the matching hash
          const element1 = document.querySelector(
            `[href="${hash}"], [href="#${hash.slice(1)}"]`
          );

          if (element1) {
            // If found, scroll to the element with offset
            scrollToElementWithOffset(element1);
          } else {
            // If not found, try to find an element with matching data-item-id
            const dataItemElement = document.querySelector(
              `[data-item-id="${decodedHash}"]`
            );
            if (dataItemElement) {
              scrollToElementWithOffset(dataItemElement);
            }
          }
          console.log("Qwfewfdwq", hash);
          const element = document.querySelector(
            `[data-item-id="${decodedHash}"]`
          );

          console.log("efewf", element);
          if (element) {
            scrollToElementWithOffset(element);
          }
        }, 1000); // Adjust the delay if needed
      }
    }
  }, [apiSpec, location.hash]);

  const scrollToElementWithOffset = (element: Element) => {
    const headerOffset = 135; // Adjust this value based on your fixed header height
    const elementPosition = element.getBoundingClientRect().top;
    const offsetPosition = elementPosition + window.pageYOffset - headerOffset;

    window.scrollTo({
      top: offsetPosition,
      behavior: "smooth",
    });
  };

  const filterSpec = (spec: any) => {
    if (!searchTerm) return spec;

    const filteredPaths: any = {};
    Object.entries(spec.paths).forEach(([path, methods]: [string, any]) => {
      if (path.toLowerCase().includes(searchTerm.toLowerCase())) {
        filteredPaths[path] = methods;
      } else {
        const filteredMethods: any = {};
        Object.entries(methods).forEach(([method, details]: [string, any]) => {
          if (
            details.summary?.toLowerCase().includes(searchTerm.toLowerCase()) ||
            details.description
              ?.toLowerCase()
              .includes(searchTerm.toLowerCase())
          ) {
            filteredMethods[method] = details;
          }
        });
        if (Object.keys(filteredMethods).length > 0) {
          filteredPaths[path] = filteredMethods;
        }
      }
    });

    return { ...spec, paths: filteredPaths };
  };

  const [sideNavVisible, setSideNavVisible] = useState(false);

  const toggleSideNav = () => {
    setSideNavVisible(!sideNavVisible);
  };

  return (
    <div className="swagger-ui d-flex flex-column vh-100">
      <Navbar
        bg="secondary"
        expand="lg"
        className="mb-3 position-fixed w-100"
        style={{ zIndex: 1030 }}
      >
        <Container fluid className="wrapper">
          <Button
            variant="outline-light"
            className="d-lg-none me-2"
            onClick={toggleSideNav}
          >
            <i className="fas fa-bars"></i>
          </Button>
          <Navbar.Brand className="d-flex align-items-center">
            <Image
              src={Logo}
              alt="SignalHouse Logo"
              height="70"
              className="me-3"
            />
            <span
              style={{
                fontSize: "24px",
                fontWeight: "bold",
                color: "white",
                textShadow: "1px 1px 2px rgba(0,0,0,0.1)",
              }}
            >
              SignalHouse APIs <Badge bg="primary">1.0.0</Badge>
            </span>
          </Navbar.Brand>
          <Form className="d-flex flex-grow-1">
            <InputGroup className="w-100 align-items-center">
              <Form.Control
                type="search"
                placeholder="Search endpoints..."
                aria-label="Search"
                value={searchTerm}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setSearchTerm(e.target.value)
                }
                className="border-end-0"
                style={{
                  boxShadow: "none",
                  borderColor: "#ced4da",
                  borderRadius: "0.25rem 0 0 0.25rem",
                  height: "38px",
                }}
              />
              <InputGroup.Text
                className="bg-white border-start-0"
                style={{
                  height: "38px",
                  display: "flex",
                  alignItems: "center",
                  borderRadius: "0 0.25rem 0.25rem 0",
                }}
              >
                <FaSearch className="text-muted" style={{ fontSize: "14px" }} />
              </InputGroup.Text>
            </InputGroup>
          </Form>
        </Container>
      </Navbar>
      <div
        className="d-flex flex-grow-1 position-relative"
        style={{ marginTop: "100px" }}
      >
        <div
          className={`side-nav position-fixed h-100 bg-light ${
            sideNavVisible ? "d-block" : "d-none"
          } d-lg-block`}
          style={{
            width: "250px",
            borderRight: "1px solid #e0e0e0",
            overflowY: "auto",
            zIndex: 1000,
            top: "135px",
          }}
        >
          <nav className="nav flex-column">
            {apiSpec &&
              apiSpec.tags &&
              apiSpec.tags.map((tag: any) => (
                <a
                  key={tag.name}
                  className="nav-link"
                  href={`#tag/${tag.name}`}
                  onClick={(e) => {
                    e.preventDefault();
                    const element = document.querySelector(
                      `[data-tag="${tag.name}"]`
                    );
                    if (element) {
                      scrollToElementWithOffset(element);
                    }
                    if (window.innerWidth < 992) {
                      setSideNavVisible(false);
                    }
                  }}
                >
                  {tag.name}
                </a>
              ))}
          </nav>
        </div>
        <div className="flex-grow-1" style={{ marginLeft: "250px" }}>
          <Container fluid>
            <Card>
              <Card.Body>
                {error ? (
                  <div>{error}</div>
                ) : apiSpec ? (
                  <SwaggerUI
                    ref={swaggerRef}
                    spec={filterSpec(apiSpec)}
                    filter={true}
                    deepLinking={true}
                    docExpansion="list"
                    defaultModelsExpandDepth={-1}
                  />
                ) : (
                  <div></div>
                )}
              </Card.Body>
            </Card>
          </Container>
        </div>
      </div>
    </div>
  );
};

export default ApiDocs;
