import { useEffect } from "react";
import withRouter from "Common/withRouter";
import { verifyEmailThunk } from "slices/thunk";
import { useDispatch } from "react-redux";
import { useSearchParams } from "react-router-dom";

const VerifyEmail = (props: any) => {
  document.title = "Signal House Portal Verify Email";

  const dispatch = useDispatch<any>();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    if (searchParams.get("token"))
      dispatch(
        verifyEmailThunk(searchParams.get("token"), props.router.navigate)
      );
  }, [searchParams]);

  return null;
};
export default withRouter(VerifyEmail);
