import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  loading: false,
  loading2: false,
  loading3: false,
  loading4: false,
  success: false,
  successMsg: "",
  error: false,
  errorMsg: "",
  pagesData: {},
  allSubPagesData: {},
  subPagesData: {},
  pageDetailsData: {},
};

const helpCenterSlice = createSlice({
  name: "help-center",
  initialState,
  reducers: {
    apiLoading(state) {
      state.loading = true;
      state.success = false;
      state.error = false;
    },
    apiLoading2(state) {
      state.loading2 = true;
      state.success = false;
      state.error = false;
    },
    apiLoading3(state) {
      state.loading3 = true;
      state.success = false;
      state.error = false;
    },
    apiLoading4(state) {
      state.loading4 = true;
      state.success = false;
      state.error = false;
    },
    pagesDataSuccessful(state, action) {
      state.loading = false;
      state.pagesData = action.payload;
      state.success = true;
      state.successMsg = "";
      state.error = false;
      state.errorMsg = "";
    },
    pagesDataFailed(state, action) {
      state.loading = false;
      state.pagesData = {};
      state.success = false;
      state.successMsg = "";
      state.error = true;
      state.errorMsg = action.payload.message;
    },
    subPagesDataSuccessful(state, action) {
      state.loading2 = false;
      state.subPagesData = action.payload;
      state.success = true;
      state.successMsg = "";
      state.error = false;
      state.errorMsg = "";
    },
    subPagesDataFailed(state, action) {
      state.loading2 = false;
      state.subPagesData = {};
      state.success = false;
      state.successMsg = "";
      state.error = true;
      state.errorMsg = action.payload.message;
    },
    allSubPagesDataSuccessful(state, action) {
      state.loading3 = false;
      state.allSubPagesData = action.payload;
      state.success = true;
      state.successMsg = "";
      state.error = false;
      state.errorMsg = "";
    },
    allSubPagesDataFailed(state, action) {
      state.loading3 = false;
      state.allSubPagesData = {};
      state.success = false;
      state.successMsg = "";
      state.error = true;
      state.errorMsg = action.payload.message;
    },
    pageDetailsDataSuccessful(state, action) {
      state.loading4 = false;
      state.pageDetailsData = action.payload;
      state.success = true;
      state.successMsg = "";
      state.error = false;
      state.errorMsg = "";
    },
    pageDetailsDataFailed(state, action) {
      state.loading4 = false;
      state.pageDetailsData = {};
      state.success = false;
      state.successMsg = "";
      state.error = true;
      state.errorMsg = action.payload.message;
    },
  },
});

export const {
  apiLoading,
  apiLoading2,
  apiLoading3,
  apiLoading4,
  pagesDataSuccessful,
  pagesDataFailed,
  subPagesDataSuccessful,
  subPagesDataFailed,
  allSubPagesDataSuccessful,
  allSubPagesDataFailed,
  pageDetailsDataSuccessful,
  pageDetailsDataFailed,
} = helpCenterSlice.actions;

export default helpCenterSlice.reducer;
