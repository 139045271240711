import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  loading: false,
  loading2: false,
  paymentHistoryData: {},
  paymentMethods: [],
  autoRechargeData: {},
  success: false,
  successMsg: "",
  error: false,
  errorMsg: "",
};

const campaignsDataSlice = createSlice({
  name: "billing",
  initialState,
  reducers: {
    apiLoading(state) {
      state.loading = true;
      state.success = false;
      state.successMsg = "";
      state.error = false;
      state.errorMsg = "";
    },
    apiLoading2(state) {
      state.loading2 = true;
      state.success = false;
      state.successMsg = "";
      state.error = false;
      state.errorMsg = "";
    },
    paymentHistoryDataSuccessful(state, action) {
      state.loading = false;
      state.paymentHistoryData = action.payload;
      state.success = true;
      state.successMsg = "";
      state.error = false;
      state.errorMsg = "";
    },
    paymentHistoryDataFailed(state, action) {
      state.loading = false;
      state.paymentHistoryData = {};
      state.success = false;
      state.successMsg = "";
      state.error = true;
      state.errorMsg = action.payload.message;
    },
    createPaymentIntentSuccessful(state) {
      state.loading = false;
      state.success = true;
      state.successMsg = "";
      state.error = false;
      state.errorMsg = "";
    },
    createPaymentIntentFailed(state, action) {
      state.loading = false;
      state.success = false;
      state.successMsg = "";
      state.error = true;
      state.errorMsg = action.payload.message;
    },
    paymentMethodDataSuccessful(state, action) {
      state.loading2 = false;
      state.paymentMethods = action.payload;
      state.success = true;
      state.successMsg = "";
      state.error = false;
      state.errorMsg = "";
    },
    paymentMethodDataFailed(state, action) {
      state.loading2 = false;
      state.paymentMethods = [];
      state.success = false;
      state.successMsg = "";
      state.error = true;
      state.errorMsg = action.payload.message;
    },
    attachPaymentMethodIdSuccessful(state) {
      state.loading = false;
      state.success = true;
      state.successMsg = "";
      state.error = false;
      state.errorMsg = "";
    },
    attachPaymentMethodIdFailed(state, action) {
      state.loading = false;
      state.success = false;
      state.successMsg = "";
      state.error = true;
      state.errorMsg = action.payload.message;
    },
    deletePaymentMethodIdSuccessful(state, action) {
      state.loading2 = false;
      state.success = true;
      state.successMsg = "";
      state.error = false;
      state.errorMsg = "";
    },
    deletePaymentMethodIdFailed(state, action) {
      state.loading2 = false;
      state.success = false;
      state.successMsg = "";
      state.error = true;
      state.errorMsg = action.payload.message;
    },
    createCustomerSuccessful(state) {
      // state.loading = false;
      state.success = true;
      state.successMsg = "";
      state.error = false;
      state.errorMsg = "";
    },
    createCustomerFailed(state, action) {
      // state.loading = false;
      state.success = false;
      state.successMsg = "";
      state.error = true;
      state.errorMsg = action.payload.message;
    },
    payWithPaymentMethodSuccessful(state) {
      state.loading = false;
      state.success = true;
      state.successMsg = "";
      state.error = false;
      state.errorMsg = "";
    },
    payWithPaymentMethodFailed(state, action) {
      state.loading = false;
      state.success = false;
      state.successMsg = "";
      state.error = true;
      state.errorMsg = action.payload.message;
    },
    autoRechargeDataSuccessful(state, action) {
      state.loading2 = false;
      state.autoRechargeData = action.payload;
      state.success = true;
      state.successMsg = "";
      state.error = false;
      state.errorMsg = "";
    },
    autoRechargeDataFailed(state, action) {
      state.loading2 = false;
      state.autoRechargeData = {};
      state.success = false;
      state.successMsg = "";
      state.error = true;
      state.errorMsg = action.payload.message;
    },
    configAutoRechargeSuccessful(state) {
      state.loading2 = false;
      state.success = true;
      state.successMsg = "";
      state.error = false;
      state.errorMsg = "";
    },
    configAutoRechargeFailed(state, action) {
      state.loading2 = false;
      state.success = false;
      state.successMsg = "";
      state.error = true;
      state.errorMsg = action.payload.message;
    },
    updateAutoRechargeConfigSuccessful(state, action) {
      state.loading2 = false;
      state.success = true;
      state.successMsg = "";
      state.error = false;
      state.errorMsg = "";
    },
    updateAutoRechargeConfigFailed(state, action) {
      state.loading2 = false;
      state.success = false;
      state.successMsg = "";
      state.error = true;
      state.errorMsg = action.payload.message;
    },
  },
});

export const {
  apiLoading,
  apiLoading2,
  paymentHistoryDataSuccessful,
  paymentHistoryDataFailed,
  createPaymentIntentSuccessful,
  createPaymentIntentFailed,
  paymentMethodDataSuccessful,
  paymentMethodDataFailed,
  attachPaymentMethodIdSuccessful,
  attachPaymentMethodIdFailed,
  deletePaymentMethodIdSuccessful,
  deletePaymentMethodIdFailed,
  createCustomerSuccessful,
  createCustomerFailed,
  payWithPaymentMethodSuccessful,
  payWithPaymentMethodFailed,
  autoRechargeDataSuccessful,
  autoRechargeDataFailed,
  configAutoRechargeSuccessful,
  configAutoRechargeFailed,
  updateAutoRechargeConfigSuccessful,
  updateAutoRechargeConfigFailed,
} = campaignsDataSlice.actions;

export default campaignsDataSlice.reducer;
