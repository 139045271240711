import React, { useEffect, useState } from "react";
import { Container, Card, Row, Col, Form } from "react-bootstrap";
import BreadCrumb from "Common/BreadCrumb";
import withRouter from "Common/withRouter";
import {
  getHelpCenterSubPagesThunk,
  getHelpCenterAllSubPagesThunk,
  getHelpCenterPagesThunk,
} from "slices/help-center/thunk";
import { useDispatch, useSelector } from "react-redux";
import { createSelector } from "reselect";
import Loader from "assets/images/spinner-dark.svg";

const HelpCenterSubPages = (props: any) => {
  document.title = "Signal House Portal Help Center Details";

  const dispatch = useDispatch<any>();
  const { pageId } = props?.router?.params;

  const selectProfile = createSelector(
    (state: any) => state.HelpCenter,
    (helpCenter) => ({
      loading: helpCenter.loading2,
      pagesData: helpCenter.pagesData?.results,
      subPagesData: helpCenter.subPagesData?.results,
      allSubPagesData: helpCenter.allSubPagesData?.results,
      error: helpCenter.error,
    })
  );

  const { loading, pagesData, subPagesData, allSubPagesData } =
    useSelector(selectProfile);

  const [selectedPage, setSelectedPage] = useState<any>({});
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredSubPages, setFilteredSubPages] = useState<any[]>([]);

  useEffect(() => {
    dispatch(getHelpCenterPagesThunk());
  }, []);

  useEffect(() => {
    if (pageId) {
      dispatch(getHelpCenterSubPagesThunk(pageId));
      dispatch(getHelpCenterAllSubPagesThunk());
    }
  }, [pageId]);

  useEffect(() => {
    if (pagesData?.filter((dt: any) => dt?.id === pageId)?.length > 0) {
      setSelectedPage(pagesData?.filter((dt: any) => dt?.id === pageId)[0]);
    }
  }, [pagesData]);

  useEffect(() => {
    if (subPagesData) {
      setFilteredSubPages(subPagesData);
    }
  }, [subPagesData]);

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const term = e.target.value.toLowerCase();
    setSearchTerm(term);

    if (term === "") {
      setFilteredSubPages(subPagesData);
    } else {
      const filtered = subPagesData?.filter((page: any) => {
        if (page.type === "toggle") {
          return page.toggle?.rich_text?.[0]?.text?.content
            .toLowerCase()
            .includes(term);
        } else if (page.type === "child_page") {
          const childPage = allSubPagesData?.find(
            (subPage: any) => subPage.id === page.id
          );
          return childPage?.properties?.Page?.title?.[0]?.text?.content
            .toLowerCase()
            .includes(term);
        }
        return false;
      });
      setFilteredSubPages(filtered);
    }
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb
            title={`Signal House - ${
              selectedPage?.type === "toggle"
                ? selectedPage?.toggle?.rich_text?.[0]?.text?.content
                : selectedPage?.child_page?.title || "-"
            }`}
            isFilter={true}
            filterButton={
              <Form.Group className="" style={{ width: "250px" }}>
                <Form.Control
                  className="w-100"
                  type="text"
                  placeholder="Search articles..."
                  value={searchTerm}
                  onChange={handleSearch}
                />
              </Form.Group>
            }
            isBack={true}
            backClick={() => props.router.navigate("/help-center")}
          />

          <div className="position-relative wrapper">
            {loading ? (
              <div>
                <img
                  src={Loader}
                  className="position-absolute top-50 start-50 translate-middle"
                />
              </div>
            ) : (
              <>
                <Card>
                  <Card.Body>
                    {filteredSubPages?.filter(
                      (dt: any) =>
                        dt?.type === "toggle" || dt?.type === "child_page"
                    )?.length > 0 ? (
                      filteredSubPages
                        ?.filter(
                          (dt: any) =>
                            dt?.type === "toggle" || dt?.type === "child_page"
                        )
                        ?.map((dt: any, i: number) =>
                          dt?.type === "toggle" ? (
                            <Col lg={12} className="mb-4 px-2 py-1" key={i}>
                              <div className="mb-3">
                                <h4 className="mb-0">
                                  {dt?.toggle?.rich_text?.[0]?.text?.content}
                                </h4>
                              </div>
                              <Row className="border bg-light-gray rounded-3 p-3">
                                {allSubPagesData?.filter(
                                  (dt2: any) => dt2?.parent?.block_id === dt?.id
                                )?.length > 0 ? (
                                  allSubPagesData
                                    ?.filter(
                                      (dt2: any) =>
                                        dt2?.parent?.block_id === dt?.id
                                    )
                                    ?.map((dt2: any, i2: number) => (
                                      <div
                                        className="accordion px-0 cursor-pointer border py-1 my-2"
                                        style={{ borderRadius: 8 }}
                                        key={i2}
                                        onClick={() =>
                                          props.router.navigate(
                                            `/help-center/${pageId}/${
                                              dt2?.id
                                            }?name=${dt2?.properties?.Page?.title?.[0]?.text?.content
                                              .split(" ")
                                              .join("-")}`
                                          )
                                        }
                                      >
                                        <div
                                          className="accordion-body"
                                          style={{
                                            color: "var(--tb-card-color)",
                                          }}
                                        >
                                          <div className="d-flex align-items-center justify-content-between">
                                            <h6
                                              className="card-title mb-0"
                                              key={i2}
                                            >
                                              {dt2?.icon?.type === "emoji"
                                                ? dt2?.icon?.emoji
                                                : null}
                                              <span className="ms-2">
                                                {
                                                  dt2?.properties?.Page
                                                    ?.title?.[0]?.text?.content
                                                }
                                              </span>
                                            </h6>
                                            <div className="text-primary text-end">
                                              View Details
                                              <i className="ri-arrow-right-line label-icon align-middle fs-lg ms-2"></i>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    ))
                                ) : (
                                  <p className="m-0">No Record Found</p>
                                )}
                              </Row>
                            </Col>
                          ) : dt?.type === "child_page" ? (
                            allSubPagesData
                              ?.filter((dt2: any) => dt2?.id === dt?.id)
                              ?.map((dt2: any, i2: number) => (
                                <div
                                  className="accordion px-0 cursor-pointer border py-1 my-2"
                                  style={{ borderRadius: 8 }}
                                  key={i}
                                  onClick={() =>
                                    props.router.navigate(
                                      `/help-center/${pageId}/${
                                        dt?.id
                                      }?name=${dt2?.properties?.Page?.title?.[0]?.text?.content
                                        .split(" ")
                                        .join("-")}`
                                    )
                                  }
                                >
                                  <div
                                    className="accordion-body"
                                    style={{ color: "var(--tb-card-color)" }}
                                  >
                                    <div className="d-flex align-items-center justify-content-between">
                                      <h6 className="card-title mb-0" key={i2}>
                                        {dt2?.icon?.type === "emoji"
                                          ? dt2?.icon?.emoji
                                          : null}
                                        <span className="ms-2">
                                          {
                                            dt2?.properties?.Page?.title?.[0]
                                              ?.text?.content
                                          }
                                        </span>
                                      </h6>
                                      <div className="text-primary text-end">
                                        View Details
                                        <i className="ri-arrow-right-line label-icon align-middle fs-lg ms-2"></i>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              ))
                          ) : null
                        )
                    ) : (
                      <p className="m-0">No Record Found</p>
                    )}
                  </Card.Body>
                </Card>
              </>
            )}
          </div>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(HelpCenterSubPages);
