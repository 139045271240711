import { useEffect } from "react";
import { Card, Col, Button } from "react-bootstrap";
import { createSelector } from "reselect";
import { useDispatch, useSelector } from "react-redux";
import { getPaymentHistoryThunk } from "slices/thunk";
import Loader from "assets/images/spinner-dark.svg";
import { BudgetChart } from "./charts";
import { Link } from "react-router-dom";
import { dateFormat } from "helpers/common";

const PaymentHistory = () => {
  const dispatch = useDispatch<any>();

  const selectProfile = createSelector(
    (state: any) => state.AuthUser,
    (state: any) => state.Billing,
    (user, billing) => ({
      authUser: user?.authUser,
      loading: billing.loading,
      paymentHistoryData: billing.paymentHistoryData,
    })
  );

  const { authUser, loading, paymentHistoryData } = useSelector(selectProfile);

  useEffect(() => {
    dispatch(getPaymentHistoryThunk(1, 20));
  }, []);

  return (
    <Col xl={3} className="mb-4">
      <Card className="overflow-hidden mb-0 h-100">
        <Card.Body>
          <div className="h-100 d-flex flex-column justify-content-between">
            <div>
              <p className="text-muted mb-2">Balance (USD)</p>
              <h4>
                {authUser?.balance
                  ? `$${(+authUser?.balance)?.toFixed(2)}`
                  : "-"}{" "}
              </h4>
              <p className="text-muted text-truncate">Current Balance</p>
            </div>
            <div>
              <div className="mx-3" style={{ height: 100 }}>
                {loading ? (
                  <div className="position-relative h-100">
                    <img
                      src={Loader}
                      className={`position-absolute top-50 start-50 translate-middle`}
                    />
                  </div>
                ) : (
                  <BudgetChart
                    periodData={
                      paymentHistoryData?.records?.length > 0
                        ? [
                            {
                              data: paymentHistoryData?.records
                                ?.filter(
                                  (dt: { status?: string }) =>
                                    dt?.status === "succeeded"
                                )
                                ?.slice(0, 10)
                                ?.map((dt: any) => dt?.amount),
                            },
                          ]
                        : []
                    }
                    dates={
                      paymentHistoryData?.records?.length > 0
                        ? paymentHistoryData?.records
                            ?.filter(
                              (dt: { status?: string }) =>
                                dt?.status === "succeeded"
                            )
                            ?.slice(0, 10)
                            ?.map((dt: any) =>
                              dateFormat(dt?.time, "MM/DD/YYYY, hh:mm:ss A")
                            )
                        : []
                    }
                    dataColors='["--tb-primary"]'
                  />
                )}
              </div>
              <div className="mt-4">
                <Link to={"/dashboard/add-funds"}>
                  <Button
                    variant="primary"
                    className="w-100"
                    data-bs-toggle="modal"
                    data-bs-target="#addAmount"
                  >
                    Add Funds
                  </Button>
                </Link>
              </div>
            </div>
          </div>
        </Card.Body>
      </Card>
    </Col>
  );
};

export default PaymentHistory;
