import React, { useEffect, useState, useRef } from "react";
import { Card, Container, Row, Col, Nav } from "react-bootstrap";
import BreadCrumb from "Common/BreadCrumb";
import withRouter from "Common/withRouter";
import { getHelpCenterPageDetailsThunk } from "slices/help-center/thunk";
import { useDispatch, useSelector } from "react-redux";
import { createSelector } from "reselect";
import Loader from "assets/images/spinner-dark.svg";
import { useSearchParams } from "react-router-dom";
import { helpCenterObj } from "helpers/common";

const HelpCenterDetails = (props: any) => {
  document.title = "Signal House Portal Help Center Details";

  const dispatch = useDispatch<any>();
  const [searchParams] = useSearchParams();
  const { pageId, subpageId } = props?.router?.params;
  const [headings, setHeadings] = useState<
    { id: string; text: string; level: number; blockId: string }[]
  >([]);
  const contentRef = useRef<HTMLDivElement>(null);
  // const [activeHeading, setActiveHeading] = useState<string | null>(null);

  const selectProfile = createSelector(
    (state: any) => state.HelpCenter,
    (helpCenter) => ({
      loading: helpCenter?.loading4,
      pageDetailsData: helpCenter?.pageDetailsData?.results,
      error: helpCenter?.error,
    })
  );

  const { loading, pageDetailsData } = useSelector(selectProfile);

  useEffect(() => {
    if (subpageId) {
      dispatch(getHelpCenterPageDetailsThunk(subpageId));
    }
  }, [subpageId]);

  useEffect(() => {
    if (pageDetailsData) {
      const extractedHeadings = pageDetailsData
        .filter((block: any) => ["heading_2", "heading_3"].includes(block.type))
        .map((block: any, index: number) => ({
          id: `heading-${index}`,
          blockId: block.id,
          text: block?.[block.type]?.rich_text?.[0]?.plain_text,
          level: block.type === "heading_2" ? 2 : 3,
        }));
      setHeadings(extractedHeadings);
    }
  }, [pageDetailsData]);

  useEffect(() => {
    const handleScroll = () => {
      if (contentRef.current) {
        const scrollPosition = contentRef.current.scrollTop;
        const containerHeight = contentRef.current.clientHeight;
        const headingElements = headings.map((heading: any) =>
          document.getElementById(`heading-${heading.blockId}`)
        );

        let activeHeadingFound = false;

        for (let i = 0; i < headingElements.length; i++) {
          const element = headingElements[i];
          if (element) {
            const elementTop = element.offsetTop - scrollPosition;
            const elementBottom = elementTop + element.offsetHeight;

            if (elementTop <= containerHeight / 2 && elementBottom >= 0) {
              // setActiveHeading(`heading-${headings[i].blockId}`);
              activeHeadingFound = true;
              break;
            }
          }
        }

        if (!activeHeadingFound && headingElements.length > 0) {
          const lastElement = headingElements[headingElements.length - 1];
          if (
            lastElement &&
            lastElement.offsetTop <= scrollPosition + containerHeight
          ) {
            // setActiveHeading(
            //   `heading-${headings[headings.length - 1].blockId}`
            // );
          }
        }
      }
    };

    const contentElement = contentRef.current;
    if (contentElement) {
      contentElement.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (contentElement) {
        contentElement.removeEventListener("scroll", handleScroll);
      }
    };
  }, [headings]);

  const scrollToHeading = (id: string) => {
    const element = document.getElementById(id);
    if (element && contentRef.current) {
      const elementPosition = element.offsetTop;
      contentRef.current.scrollTo({
        top: elementPosition,
        behavior: "smooth",
      });
    }
  };

  const renderBlock = (block: any, index: any): JSX.Element | null => {
    switch (block?.type) {
      case "heading_1":
        return (
          <h1 key={index} className="fs-4xl text-primary mb-3">
            {block?.heading_1?.rich_text?.[0]?.plain_text}
          </h1>
        );
      case "heading_2":
        return (
          <h2 key={index} id={`heading-${block.id}`} className="h2 mb-3">
            {block?.heading_2?.rich_text?.[0]?.plain_text}
          </h2>
        );
      case "heading_3":
        return (
          <h3 key={index} id={`heading-${block.id}`} className="h3 mb-3">
            {block?.heading_3?.rich_text?.[0]?.plain_text}
          </h3>
        );
      case "paragraph":
        return (
          <p key={index} className="mb-3">
            {block?.paragraph?.rich_text?.map((text: any, i: number) => (
              <span
                key={i}
                className={`${text?.annotations?.italic ? "fst-italic" : ""} ${
                  text?.annotations?.bold ? "fw-bold" : ""
                }`}
              >
                {text?.href ? (
                  <a
                    href={helpCenterObj[text.href] || text.href}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {text?.text?.content}
                  </a>
                ) : (
                  text?.text?.content
                )}
              </span>
            ))}
          </p>
        );
      case "image":
        return (
          <div key={index} className="mb-4">
            <img
              src={block?.image?.file?.url || block?.image?.external?.url}
              alt="image"
              className="img-fluid rounded shadow d-flex mx-auto"
            />
          </div>
        );
      case "bulleted_list_item":
      case "numbered_list_item":
        return renderListItem(block, index?.toString());
      case "to_do":
        return (
          <div key={index} className="form-check mb-2">
            <input
              className="form-check-input"
              type="checkbox"
              checked={block?.to_do?.checked}
              readOnly
            />
            <label className="form-check-label">
              {block?.to_do?.rich_text?.[0]?.plain_text}
            </label>
          </div>
        );
      case "toggle":
        return (
          <div key={index} className="mb-3">
            <button
              className="btn btn-outline-primary"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target={`#toggle-${index}`}
            >
              {block?.toggle?.rich_text?.[0]?.plain_text}
            </button>
            <div className="collapse mt-2" id={`toggle-${index}`}>
              {block.children && renderBlocks(block.children)}
            </div>
          </div>
        );
      case "code":
        return (
          <pre key={index} className="bg-light-gray border rounded p-3 mb-3">
            <code>{block?.code?.rich_text?.[0]?.plain_text}</code>
          </pre>
        );
      case "quote":
        return (
          <blockquote
            key={index}
            className="blockquote border-start border-primary ps-3 mb-3"
          >
            <p className="mb-0">{block?.quote?.rich_text?.[0]?.plain_text}</p>
          </blockquote>
        );
      case "divider":
        return <hr key={index} className="my-4" />;
      case "table":
        return (
          <div key={index} className="table-responsive mb-3">
            <table className="table table-bordered">
              <tbody>
                {block.children.map((row: any, rowIndex: number) =>
                  renderBlock(row, `${index}-${rowIndex}`)
                )}
              </tbody>
            </table>
          </div>
        );
      case "table_row":
        return (
          <tr key={index}>
            {block?.table_row?.cells?.map((cell: any, cellIndex: number) => (
              <td key={cellIndex}>
                {cell?.map((text: any, textIndex: number) => (
                  <span
                    key={textIndex}
                    className={`${
                      text?.annotations?.italic ? "fst-italic" : ""
                    } ${text?.annotations?.bold ? "fw-bold" : ""}`}
                  >
                    {text?.href ? (
                      <a
                        href={helpCenterObj[text.href] || text.href}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {text?.plain_text}
                      </a>
                    ) : (
                      text?.plain_text
                    )}
                  </span>
                ))}
              </td>
            ))}
          </tr>
        );
      default:
        return null;
    }
  };

  const renderListItem = (block: any, index: string): JSX.Element => {
    const ListTag = block.type === "bulleted_list_item" ? "ul" : "ol";
    const listItemContent = block[block.type]?.rich_text?.map((dt: any) =>
      dt?.href ? (
        <a
          key={dt.id}
          href={helpCenterObj[dt.href] || dt.href}
          target="_blank"
          rel="noopener noreferrer"
        >
          {dt?.text?.content}
        </a>
      ) : (
        dt?.text?.content
      )
    );

    return (
      <li key={index} className="mb-1">
        {listItemContent}
        {block.children && (
          <ListTag className="mt-2">
            {block.children.map((child: any, childIndex: number) =>
              renderListItem(child, `${index}-${childIndex}`)
            )}
          </ListTag>
        )}
      </li>
    );
  };

  const renderBlocks = (blocks: any[]): JSX.Element[] => {
    let currentList: JSX.Element | null = null;
    const renderedBlocks: JSX.Element[] = [];

    blocks.forEach((block: any, index: number) => {
      if (
        block.type === "bulleted_list_item" ||
        block.type === "numbered_list_item"
      ) {
        if (
          !currentList ||
          currentList.type !==
            (block.type === "bulleted_list_item" ? "ul" : "ol")
        ) {
          if (currentList) {
            renderedBlocks.push(currentList);
          }
          currentList = React.createElement(
            block.type === "bulleted_list_item" ? "ul" : "ol",
            { key: `list-${index}`, className: "mb-3" },
            []
          );
        }
        (currentList.props.children as JSX.Element[]).push(
          renderListItem(block, index.toString())
        );
      } else {
        if (currentList) {
          renderedBlocks.push(currentList);
          currentList = null;
        }
        const renderedBlock = renderBlock(block, index);
        if (renderedBlock) {
          renderedBlocks.push(renderedBlock);
        }
      }
    });

    if (currentList) {
      renderedBlocks.push(currentList);
    }

    return renderedBlocks;
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb
            title={
              pageDetailsData?.find((block: any) => block.type === "heading_1")
                ?.heading_1?.rich_text?.[0]?.plain_text ||
              searchParams.get("name")?.split("-").join(" ")
            }
            isBack={true}
            backClick={() =>
              pageId === subpageId
                ? props.router?.navigate(`/help-center`)
                : props.router?.navigate(`/help-center/${pageId}`)
            }
          />

          <div className="position-relative wrapper" ref={contentRef}>
            {loading ? (
              <div>
                <img
                  src={Loader}
                  className="position-absolute top-50 start-50 translate-middle"
                />
              </div>
            ) : (
              <>
                {pageDetailsData?.length > 0 ? (
                  <Row className="mx-0">
                    {headings.length > 0 && (
                      <Col md={2} className="ps-0">
                        <Nav
                          className="flex-column sticky-top"
                          style={{ top: "20px" }}
                        >
                          {headings.map((heading: any) => (
                            <Nav.Link
                              key={heading.id}
                              onClick={() =>
                                scrollToHeading("heading-" + heading?.blockId)
                              }
                              className={`${
                                // activeHeading === "heading-" + heading?.blockId
                                //   ? "text-primary"
                                //   : "text-muted"
                                "text-muted"
                              } ps-0`}
                              // ${heading.level === 3 ? "ps-3" : ""}
                            >
                              {heading.text}
                            </Nav.Link>
                          ))}
                        </Nav>
                      </Col>
                    )}
                    <Col
                      md={10}
                      className={`${
                        headings.length > 0 ? "" : "mx-auto d-flex"
                      }`}
                    >
                      <Card className="w-100">
                        <Card.Body className="p-4">
                          <div
                            className="border p-3 fs-md"
                            style={{ borderRadius: 8 }}
                          >
                            {renderBlocks(pageDetailsData)}
                          </div>
                        </Card.Body>
                      </Card>
                    </Col>
                  </Row>
                ) : (
                  <p className="m-0">No Record Found</p>
                )}
              </>
            )}
          </div>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(HelpCenterDetails);
