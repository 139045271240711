import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  loading: false,
  loading2: false,
  loading3: false,
  loading4: false,
  success: false,
  successMsg: "",
  error: false,
  errorMsg: "",
  webhooksList: {},
  webhookDetails: {},
  webhookLogs: {},
  webhookEvents: {},
};

const webhookSlice = createSlice({
  name: "webhook",
  initialState,
  reducers: {
    apiLoading(state) {
      state.loading = true;
      state.success = false;
      state.successMsg = "";
      state.error = false;
      state.errorMsg = "";
    },
    apiLoading2(state) {
      state.loading2 = true;
      state.success = false;
      state.successMsg = "";
      state.error = false;
      state.errorMsg = "";
    },
    apiLoading3(state) {
      state.loading3 = true;
      state.success = false;
      state.successMsg = "";
      state.error = false;
      state.errorMsg = "";
    },
    apiLoading4(state) {
      state.loading4 = true;
      state.success = false;
      state.successMsg = "";
      state.error = false;
      state.errorMsg = "";
    },
    webhooksListSuccessful(state, action) {
      state.loading = false;
      state.webhooksList = action.payload;
      state.success = true;
      state.successMsg = "";
      state.error = false;
      state.errorMsg = "";
    },
    webhooksListFailed(state, action) {
      state.loading = false;
      state.webhooksList = {};
      state.success = false;
      state.successMsg = "";
      state.error = true;
      state.errorMsg = action.payload.message;
    },
    webhookDetailsSuccessful(state, action) {
      state.loading4 = false;
      state.webhookDetails = action.payload;
      state.success = true;
      state.successMsg = "";
      state.error = false;
      state.errorMsg = "";
    },
    webhookDetailsFailed(state, action) {
      state.loading4 = false;
      state.webhookDetails = {};
      state.success = false;
      state.successMsg = "";
      state.error = true;
      state.errorMsg = action.payload.message;
    },
    resetWebhookDetailsByIdData(state, action) {
      state.loading = false;
      state.webhookDetails = {};
      state.success = true;
      state.successMsg = "";
      state.error = false;
      state.errorMsg = "";
    },
    webhookLogsSuccessful(state, action) {
      state.loading2 = false;
      state.webhookLogs = action.payload;
      state.success = true;
      state.successMsg = "";
      state.error = false;
      state.errorMsg = "";
    },
    webhookLogsFailed(state, action) {
      state.loading2 = false;
      state.webhookLogs = {};
      state.success = false;
      state.successMsg = "";
      state.error = true;
      state.errorMsg = action.payload.message;
    },
    webhookEventsSuccessful(state, action) {
      state.loading3 = false;
      state.webhookEvents = action.payload;
      state.success = true;
      state.successMsg = "";
      state.error = false;
      state.errorMsg = "";
    },
    webhookEventsFailed(state, action) {
      state.loading3 = false;
      state.webhookEvents = {};
      state.success = false;
      state.successMsg = "";
      state.error = true;
      state.errorMsg = action.payload.message;
    },
    createWebhookSuccessful(state, action) {
      state.loading2 = false;
      state.success = true;
      state.successMsg = "";
      state.error = false;
      state.errorMsg = "";
    },
    createWebhookFailed(state, action) {
      state.loading2 = false;
      state.success = false;
      state.successMsg = "";
      state.error = true;
      state.errorMsg = action.payload.message;
    },
    editWebhookSuccessful(state, action) {
      state.loading2 = false;
      state.success = true;
      state.successMsg = "";
      state.error = false;
      state.errorMsg = "";
    },
    editWebhookFailed(state, action) {
      state.loading2 = false;
      state.success = false;
      state.successMsg = "";
      state.error = true;
      state.errorMsg = action.payload.message;
    },
    deleteWebhookSuccessful(state, action) {
      state.loading2 = false;
      state.success = true;
      state.successMsg = action.payload.message;
      state.error = false;
      state.errorMsg = "";
    },
    deleteWebhookFailed(state, action) {
      state.loading2 = false;
      state.success = false;
      state.successMsg = "";
      state.error = true;
      state.errorMsg = action.payload.message;
    },
  },
});

export const {
  apiLoading,
  apiLoading2,
  apiLoading3,
  apiLoading4,
  webhooksListSuccessful,
  webhooksListFailed,
  webhookDetailsSuccessful,
  webhookDetailsFailed,
  webhookLogsSuccessful,
  webhookLogsFailed,
  webhookEventsSuccessful,
  webhookEventsFailed,
  createWebhookSuccessful,
  createWebhookFailed,
  editWebhookSuccessful,
  editWebhookFailed,
  deleteWebhookSuccessful,
  deleteWebhookFailed,
  resetWebhookDetailsByIdData
} = webhookSlice.actions;

export default webhookSlice.reducer;
