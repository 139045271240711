interface NotificationParams {
  phoneNumber?: string;
  signalHouseSid?: string;
  phoneNumberToBePorted?: string;
  portingId?: string;
  brandId?: string;
  brandName?: string;
  campaignId?: string;
  campaignType?: string;
  groupId?: string;
  subGroupId?: string;
  subGroupName?: string;
  emailId?: string;
  amount?: string;
  campaignStatus?: string;
}

interface NotificationItem {
  notificationServices: string;
  params?: NotificationParams;
  notificationMessage: string;
  notificationAlert: "success" | "error" | "warning" | "primary";
  notificationId: string;
  createdDate: string;
}

// Helper function to determine campaign route based on type
const getCampaignRoute = (
  campaignId: string,
  campaignType?: string,
  campaignStatus?: string
): string => {
  if (campaignType === "own") {
    return campaignId.toLowerCase().startsWith("c")
      ? `/campaigns/${campaignId}`
      : campaignStatus?.toLowerCase() === "rejected"
      ? `/campaigns-rejected/${campaignId}`
      : `/campaigns-review/${campaignId}`;
  } else {
    return `/campaigns-partner/${campaignId}`;
  }
};

// Main function to get redirect path
export const getNotificationRedirectPath = (
  service: string,
  params: NotificationParams
): string => {
  const routeMap: { [key: string]: string } = {
    // Number related routes
    NUMBER_UPDATED:
      params.phoneNumber && params.signalHouseSid
        ? `/mynumbers/${params.phoneNumber}/${params.signalHouseSid}`
        : "",
    NUMBER_PURCHASED:
      params.phoneNumber && params.signalHouseSid
        ? `/mynumbers/${params.phoneNumber}/${params.signalHouseSid}`
        : "",
    NUMBER_RELEASED:
      params.phoneNumber && params.signalHouseSid
        ? `/mynumbers/${params.phoneNumber}/${params.signalHouseSid}`
        : "",
    NUMBER_RENEWAL:
      params.phoneNumber && params.signalHouseSid
        ? `/mynumbers/${params.phoneNumber}/${params.signalHouseSid}`
        : "",
    NUMBER_PORTING: params.portingId
      ? `/porting-status/${params.portingId}`
      : "",

    // Brand routes
    BRAND_UPDATES: params.brandId ? `/brands/${params.brandId}` : "",

    // Campaign routes
    CAMPAIGN_UPDATES: params.campaignId
      ? getCampaignRoute(params.campaignId, params.campaignType)
      : "",
    CAMPAIGN_UPDATES_BY_ADMIN: params.campaignId
      ? getCampaignRoute(
          params.campaignId,
          params.campaignType,
          params.campaignStatus
        )
      : "",

    // Sub group routes
    SUB_GROUP:
      params.groupId && params.subGroupId && params.subGroupName
        ? `/groups/${params.groupId}/subgroups/${params.subGroupId}/${params.subGroupName}`
        : "",

    // Settings routes
    PAYMENT_METHOD: "/settings/billing",
    FUND_ADDED: "/settings/billing/payment-history",
    FUND_RECHARGE: "/settings/billing/payment-history",
    SUB_USER_UPDATES: "/settings/users",
    PASSWORD_UPDATES: "/settings/security",
    "2FA_UPDATES": "/settings/security",
  };

  return routeMap[service] || "";
};

export type { NotificationParams, NotificationItem };
