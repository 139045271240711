import React, { useState } from "react";
import { Card, Form, Button, Spinner } from "react-bootstrap";
import { Link } from "react-router-dom";
import ParticlesAuth from "../AuthenticationInner/ParticlesAuth";
import { useSelector, useDispatch } from "react-redux";
import withRouter from "Common/withRouter";

// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";

import { userForgetPasswordThunk } from "slices/thunk";
import { createSelector } from "reselect";
import { BsCheckCircle } from "react-icons/bs";

const ForgotPassword = (props: any) => {
  document.title = "Signal House Portal Reset Password";

  const dispatch = useDispatch<any>();
  const validation: any = useFormik({
    enableReinitialize: true,
    initialValues: {
      emailId: "",
    },
    validationSchema: Yup.object({
      emailId: Yup.string().required("Please Enter Your Email"),
    }),
    onSubmit: (values) => {
      dispatch(userForgetPasswordThunk(values?.emailId, props.router.navigate));
    },
  });

  const selectForgetPassword = createSelector(
    (state: any) => state.ForgetPassword,
    (forgetPassword) => ({
      loading: forgetPassword.loading,
      isSuccess: forgetPassword.isSuccess,
    })
  );

  const { loading, isSuccess } = useSelector(selectForgetPassword);

  return (
    <ParticlesAuth>
      <React.Fragment>
        <div className="col-lg-5 col-xl-6 mx-auto">
          <Card className="card mb-0 border-0 shadow-none mb-0">
            <Card.Body className="p-sm-5 m-lg-4">
              {!isSuccess ? (
                <>
                  <div className="text-center mt-2">
                    <h5 className="fs-3xl">Forgot Password?</h5>
                    <p className="text-muted mb-4">
                      Reset password with Signal House
                    </p>
                  </div>
                  <div className="p-2">
                    <Form
                      onSubmit={(e) => {
                        e.preventDefault();
                        validation.handleSubmit();
                        return false;
                      }}
                    >
                      <div className="mb-4">
                        <Form.Label className="form-label">Email</Form.Label>
                        <Form.Control
                          type="email"
                          className="form-control password-input"
                          id="emailId"
                          placeholder="Enter Email"
                          required
                          name="emailId"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.emailId || ""}
                          isInvalid={
                            validation.touched.emailId &&
                            validation.errors.emailId
                              ? true
                              : false
                          }
                        />
                        {validation.touched.emailId &&
                        validation.errors.emailId ? (
                          <Form.Control.Feedback type="invalid">
                            <div>{validation.errors.emailId}</div>
                          </Form.Control.Feedback>
                        ) : null}
                      </div>

                      <div className="text-center mt-4">
                        <Button
                          className="btn btn-primary w-100"
                          type="submit"
                          disabled={loading}
                        >
                          {loading && <Spinner size="sm" />} Send Reset Link
                        </Button>
                      </div>
                    </Form>
                  </div>
                  <div className="mt-4 text-center">
                    <p className="mb-0">
                      Wait, I remember my password...{"  "}
                      <Link
                        to={process.env.PUBLIC_URL + "/login"}
                        className="fw-semibold text-primary text-decoration-underline"
                      >
                        Click here
                      </Link>
                    </p>
                  </div>
                </>
              ) : (
                <div className="text-center p-5">
                  <BsCheckCircle color="green" size={80} />
                  <h5 className="fs-3xl mt-4 mb-4">
                    Password reset link sent successfully! Please check your
                    inbox to reset your password.
                  </h5>
                  <Link
                    to={"/login"}
                    className="fs-lg fw-medium text-primary text-decoration-underline"
                  >
                    Proceed to Login
                  </Link>
                </div>
              )}
            </Card.Body>
          </Card>
        </div>
      </React.Fragment>
    </ParticlesAuth>
  );
};

export default withRouter(ForgotPassword);
