import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  show: false,
  toastVariant: "primary",
  toastClass: "",
  toastMsg: "",
  toastIcon: "ri-error-warning-line",
};

const toastDataSlice = createSlice({
  name: "toast",
  initialState,
  reducers: {
    showToast(state, action) {
      state.show = true;
      state.toastVariant = action.payload.toastVariant;
      state.toastClass = action.payload.toastClass;
      state.toastMsg = action.payload.toastMsg;
      state.toastIcon = action.payload.toastIcon;
    },
    hideToast(state) {
      state.show = false;
      state.toastVariant = "";
      state.toastClass = "";
      state.toastMsg = "";
      state.toastIcon = "";
    },
  },
});

export const { showToast, hideToast } = toastDataSlice.actions;

export default toastDataSlice.reducer;
