import React, { useState } from "react";
import { Form, Button, Spinner, Card, Col } from "react-bootstrap";
import { Link, useSearchParams } from "react-router-dom";
import ParticlesAuth from "./ParticlesAuth";

// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";

import { updatePasswordThunk } from "slices/thunk";

//redux
import { useSelector, useDispatch } from "react-redux";
import { createSelector } from "reselect";
import withRouter from "Common/withRouter";

const PasswordCreate = (props: any) => {
  document.title = "Signal House Portal Create New Password";

  const dispatch = useDispatch<any>();
  const [param] = useSearchParams();
  const [passwordShow1, setPasswordShow1] = useState<any>(false);
  const [passwordShow2, setPasswordShow2] = useState<any>(false);

  const validation: any = useFormik({
    enableReinitialize: true,
    initialValues: {
      password: "",
      confPassword: "",
    },
    validationSchema: Yup.object({
      password: Yup.string()
        .required("Please Enter Password")
        .min(8)
        .max(128)
        .matches(
          /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}/,
          "Password Must Include at Least One Uppercase Letter, One Lowercase Letter, One Digit, and One Special Character"
        ),
      confPassword: Yup.string()
        .required("Please Enter Confirm Password")
        .min(8)
        .max(128)
        .matches(
          /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}/,
          "Password Must Include at Least One Uppercase Letter, One Lowercase Letter, One Digit, and One Special Character"
        )
        .oneOf([Yup.ref("password"), ""], "Passwords must match"),
    }),
    onSubmit: (values) => {
      dispatch(
        updatePasswordThunk(
          values?.password,
          param.get("token"),
          param.get("email"),
          props.router.navigate
        )
      );
    },
  });

  const selectAccount = createSelector(
    (state: any) => state.ForgetPassword,
    (forgetPassword) => ({
      loading: forgetPassword.loading,
    })
  );
  const { loading } = useSelector(selectAccount);

  return (
    <ParticlesAuth>
      <React.Fragment>
        <Col lg={5} xl={6} className="mx-auto">
          <Card className=" mb-0 border-0 shadow-none mb-0">
            <Card.Body className=" p-sm-5 m-lg-4">
              <div className="text-center">
                <h5 className="fs-3xl">Create new password</h5>
                <p className="text-muted mb-5">
                  Your new password must be different from previous used
                  password.
                </p>
              </div>

              <div className="p-2">
                <Form
                  onSubmit={(e) => {
                    e.preventDefault();
                    validation.handleSubmit();
                    return false;
                  }}
                >
                  <div className="mb-3">
                    <Form.Label htmlFor="password-input">
                      Password <span className="text-primary">*</span>
                    </Form.Label>
                    <div className="position-relative auth-pass-inputgroup">
                      <Form.Control
                        className="pe-5 password-input"
                        placeholder="Enter password"
                        id="password-input"
                        type={!passwordShow1 ? "password" : "text"}
                        name="password"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.password || ""}
                        isInvalid={
                          validation.touched.password &&
                          validation.errors.password
                            ? true
                            : false
                        }
                      />
                      <Button
                        variant="link"
                        className="position-absolute end-0 top-0 text-decoration-none text-muted password-addon"
                        type="button"
                        id="password-addon"
                        onClick={() => setPasswordShow1(!passwordShow1)}
                      >
                        <i className="ri-eye-fill align-middle"></i>
                      </Button>
                      {validation.touched.password &&
                      validation.errors.password ? (
                        <Form.Control.Feedback type="invalid">
                          <div>{validation.errors.password}</div>
                        </Form.Control.Feedback>
                      ) : null}
                    </div>
                  </div>

                  <div className="mb-3">
                    <Form.Label htmlFor="confirm-password-input">
                      Confirm Password <span className="text-primary">*</span>
                    </Form.Label>
                    <div className="position-relative auth-pass-inputgroup">
                      <Form.Control
                        className="pe-5 password-input"
                        placeholder="Confirm password"
                        id="confirm-password-input"
                        type={!passwordShow2 ? "password" : "text"}
                        name="confPassword"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.confPassword || ""}
                        isInvalid={
                          validation.touched.confPassword &&
                          validation.errors.confPassword
                            ? true
                            : false
                        }
                      />
                      <Button
                        variant="link"
                        className="position-absolute end-0 top-0 text-decoration-none text-muted password-addon"
                        type="button"
                        id="password-addon"
                        onClick={() => setPasswordShow2(!passwordShow2)}
                      >
                        <i className="ri-eye-fill align-middle"></i>
                      </Button>
                      {validation.touched.confPassword &&
                      validation.errors.confPassword ? (
                        <Form.Control.Feedback type="invalid">
                          <div>{validation.errors.confPassword}</div>
                        </Form.Control.Feedback>
                      ) : null}
                    </div>
                  </div>

                  <div className="mt-4">
                    <Button
                      className="btn btn-primary w-100"
                      type="submit"
                      disabled={loading}
                    >
                      {loading && <Spinner size="sm" animation="border" />}{" "}
                      Reset Password
                    </Button>
                  </div>
                </Form>
              </div>
              <div className="mt-4 text-center">
                <p className="mb-0">
                  Wait, I remember my password...{"  "}
                  <Link
                    to={process.env.PUBLIC_URL + "/login"}
                    className="fw-semibold text-primary text-decoration-underline"
                  >
                    Click here
                  </Link>
                </p>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </React.Fragment>
    </ParticlesAuth>
  );
};

export default withRouter(PasswordCreate);
