import React, { useRef, useState } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import { addDataToNotionThunk, registerUserThunk } from "slices/thunk";
import { useSelector, useDispatch } from "react-redux";
import { createSelector } from "reselect";
import ReCAPTCHA from "react-google-recaptcha";
import SignupWrapper from "pages/AuthenticationInner/SignupWrapper";
import { Link } from "react-router-dom";
import Step1 from "./Step1";
import Step2 from "./Step2";
import Step3 from "./Step3";
import Step4 from "./Step4";

const ProgressDiv = ({ activeStep }: any) => {
  const getStyle = (step: number) => ({
    height: 6,
    background: activeStep > step ? "var(--tb-primary)" : "rgba(0, 0, 0, 0.18)",
    borderRadius: 5,
  });

  return (
    <div
      className="position-absolute top-0 start-0 end-0"
      style={{
        display: "grid",
        columnGap: 5,
        gridTemplateColumns: "auto auto auto auto",
      }}
    >
      <div style={getStyle(1)} />
      <div style={getStyle(2)} />
      <div style={getStyle(3)} />
      <div style={getStyle(4)} />
    </div>
  );
};

const Register = () => {
  document.title = "Signal House Portal Register";

  const dispatch = useDispatch<any>();
  const recaptchaRef = useRef<any>();

  const selectAccount = createSelector(
    (state: any) => state.Account,
    (account) => ({
      loading: account.loading,
      success: account.success,
    })
  );
  const { loading, success } = useSelector(selectAccount);

  const [activeStep, setActiveStep] = useState(1);

  // const passwordRegex = new RegExp(
  //   "^(?=.*[A-Z])" +
  //     "(?=.*[a-z])" +
  //     "(?=.*\\d)" +
  //     "(?=.[@$!%?&])" +
  //     "[A-Za-z\\d@$!%*?&]+$"
  // );
  const passwordRegex =
    /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@$!%?&])[A-Za-z\d@$!%*?&]{8,}$/;

  const validation1: any = useFormik({
    enableReinitialize: true,
    initialValues: {
      firstName: "",
      lastName: "",
      emailId: "",
      password: "",
      confPassword: "",
    },
    validationSchema: Yup.object({
      firstName: Yup.string().required("Please Enter Firstname"),
      lastName: Yup.string().required("Please Enter Lastname"),
      emailId: Yup.string().required("Please Enter Email"),
      password: Yup.string()
        .required("Please Enter Password")
        .min(8)
        .max(128)
        .matches(
          passwordRegex,
          "Password Must Include at Least One Uppercase Letter, One Lowercase Letter, One Digit, and One Special Character (@, $, !, %, ?, or &)"
        ),
      confPassword: Yup.string()
        .required("Please Enter Confirm Password")
        .min(8)
        .max(128)
        .matches(
          passwordRegex,
          "Password Must Include at Least One Uppercase Letter, One Lowercase Letter, One Digit, and One Special Character (@, $, !, %, ?, or &)"
        )
        .oneOf([Yup.ref("password"), ""], "Passwords must match"),
    }),
    onSubmit: () => {
      setActiveStep(2);
    },
  });

  const validation2: any = useFormik({
    enableReinitialize: true,
    initialValues: {
      systems: [],
      otherText: "",
    },
    validationSchema: Yup.object({
      systems: Yup.array().min(1, "Select any one").required("Select any one"),
      otherText: Yup.string().when("systems", ([systems]) => {
        return systems?.some((dt: string) => dt === "Other")
          ? Yup.string().required("System Name is Required")
          : Yup.string().notRequired();
      }),
    }),
    onSubmit: () => {
      setActiveStep(3);
    },
  });

  const validation3: any = useFormik({
    enableReinitialize: true,
    initialValues: {
      isCampaignsToMigrate: "",
      campaignsNumber: 0,
      currentConnectivityPartner: "",
    },
    validationSchema: Yup.object({
      isCampaignsToMigrate: Yup.string().required("Select any one"),
      campaignsNumber: Yup.number().when(
        "isCampaignsToMigrate",
        ([isCampaignsToMigrate]) => {
          return isCampaignsToMigrate === "Yes"
            ? Yup.number()
                .min(1, "The Campaign Number must be a valid number")
                .required("Campaigns Number is Required")
            : Yup.number().notRequired();
        }
      ),
      currentConnectivityPartner: Yup.string().when(
        "isCampaignsToMigrate",
        ([isCampaignsToMigrate]) => {
          return isCampaignsToMigrate === "Yes"
            ? Yup.string().required("Connectivity Partner is Required")
            : Yup.string().notRequired();
        }
      ),
    }),
    onSubmit: () => {
      setActiveStep(4);
    },
  });

  const validation4: any = useFormik({
    enableReinitialize: true,
    initialValues: {
      isNumbersToPort: "",
      numbers: 0,
    },
    validationSchema: Yup.object({
      isNumbersToPort: Yup.string().required("Select any one"),
      numbers: Yup.number().when("isNumbersToPort", ([isNumbersToPort]) => {
        return isNumbersToPort === "Yes"
          ? Yup.number()
              .min(1, "Enter Valid number")
              .required("Number is Required")
          : Yup.number().notRequired();
      }),
    }),
    onSubmit: () => {
      setActiveStep(5);
      recaptchaRef.current.execute();
    },
  });

  const onChange = async (value: any) => {
    if (value) {
      const cb = () =>
        dispatch(
          addDataToNotionThunk({
            ...validation1.values,
            ...validation2.values,
            ...validation3.values,
            ...validation4.values,
          })
        );

      dispatch(
        registerUserThunk(
          {
            emailId: validation1.values.emailId,
            firstName: validation1.values.firstName,
            lastName: validation1.values.lastName,
            password: validation1.values.password,
          },
          cb
        )
      );
    }
  };

  return (
    <SignupWrapper>
      <React.Fragment>
        <div className="col-lg-5 col-xl-6 d-flex justify-content-center align-items-center w-100 h-100">
          <div className="container py-5">
            <div className="card bg-gray registration-inner mb-0 box-center shadow-lg mb-0 position-relative">
              <ProgressDiv activeStep={activeStep} />

              <div className="card-body">
                <ReCAPTCHA
                  onChange={onChange}
                  ref={recaptchaRef}
                  size="invisible"
                  sitekey="6LfhWLUpAAAAABRtUNaQvSlm_mNws1zmJYEoIY3A"
                />
                {success ? (
                  <div className="text-center p-5">
                    <h5 className="fs-3xl mb-4">
                      Thank you for choosing to sign with us. Please take a
                      moment to check your email for the verification process.
                    </h5>
                    <Link
                      to={"/login"}
                      className="fs-lg fw-medium text-primary text-decoration-underline"
                    >
                      Proceed to Login
                    </Link>
                  </div>
                ) : activeStep === 1 ? (
                  <Step1 validation1={validation1} />
                ) : activeStep === 2 ? (
                  <Step2
                    validation2={validation2}
                    setActiveStep={setActiveStep}
                  />
                ) : activeStep === 3 ? (
                  <Step3
                    validation3={validation3}
                    setActiveStep={setActiveStep}
                  />
                ) : activeStep === 4 || activeStep === 5 ? (
                  <Step4
                    validation4={validation4}
                    setActiveStep={setActiveStep}
                    loading={loading}
                  />
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    </SignupWrapper>
  );
};
export default Register;
