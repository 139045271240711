import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  state: false,
  data: {},
  path: "",
  modalbody: "",
};

const modalDataSlice = createSlice({
  name: "modal",
  initialState,
  reducers: {
    openModal(state, action) {
      state.state = true;
      state.data = action?.payload?.data;
      state.path = action?.payload?.path;
      state.modalbody = action?.payload?.modalbody;
    },
    closeModal(state, action) {
      state.state = false;
      state.data = {};
      state.path = "";
      state.modalbody = "";
    },
  },
});

export const { openModal, closeModal } = modalDataSlice.actions;

export default modalDataSlice.reducer;
