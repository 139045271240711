import React, { useEffect, useState } from "react";
import { Container, Card, Row, Col, Accordion } from "react-bootstrap";
import BreadCrumb from "Common/BreadCrumb";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import {
  capitalizeString,
  customSelectTheme,
  getCurrentDateTime,
  last12Months,
  removeKeyFromObj,
  styleObj,
} from "helpers/common";
import Loader from "assets/images/spinner-dark.svg";
import withRouter from "Common/withRouter";
import { createSelector } from "reselect";
import Select from "react-select";
// import Tooltip from "rc-tooltip";
import { getUsageSummaryThunk } from "slices/thunk";
import { Link } from "react-router-dom";

const selectOpts = [...last12Months()];

const Summary = (props: any) => {
  document.title = "Signal House Portal Usage Summary";
  const dispatch = useDispatch<any>();

  const [month, setMonth] = useState(
    `${moment().format("MM")}-${moment().format("YYYY")}`
  );
  const [summaryObj, setSummaryObj] = useState<any>({});
  const [activeAcc, setActiveAcc] = useState<any>("");

  const selectProfile = createSelector(
    (state: any) => state.UsageSummary,
    (usageSummary) => ({
      loading: usageSummary.loading,
      usageSummaryObj: usageSummary.usageSummaryObj,
      error: usageSummary.error,
    })
  );

  const { loading, usageSummaryObj } = useSelector(selectProfile);

  useEffect(() => {
    if (month) {
      const monthTemp: any = month?.split("-");
      const startDate = moment([monthTemp?.[1], monthTemp?.[0] - 1]);
      const endDate = moment(startDate).add(1, "month").subtract(1, "day");
      const formattedStartDate = startDate.format("MM-DD-YYYY");
      const formattedEndDate = endDate.format("MM-DD-YYYY");
      dispatch(
        getUsageSummaryThunk(
          moment(formattedStartDate).toISOString(),
          moment(formattedEndDate).toISOString()
        )
      );
    }
  }, [month]);

  useEffect(() => {
    if (Object.keys(usageSummaryObj).length > 0) {
      const temp = removeKeyFromObj(usageSummaryObj?.total, [
        "total",
        "transactions",
      ]);
      setSummaryObj(temp);
    }
  }, [usageSummaryObj]);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb
            title="All Product Usage"
            isBack={true}
            backClick={() => props.router.navigate("/settings/billing")}
          />
          <div className="wrapper">
            <Card>
              <Card.Body className="">
                <p className="mb-1 d-flex align-items-center">
                  Cost listed here may exclude taxes, fees, support fees, expert
                  services costs and certain other products.
                  {/* <Tooltip placement="bottomRight" overlay=""> */}
                  <i className="ri-information-line text-secondary fs-3xl ms-2"></i>
                  {/* </Tooltip> */}
                </p>
                <p>
                  You have some products that have recurring costs.{" "}
                  <a href="#">View your recurring items.</a>
                </p>
                <div className="mb-4 d-flex flex-row justify-content-between">
                  <div>
                    <Select
                      styles={styleObj(false)}
                      theme={customSelectTheme}
                      options={selectOpts}
                      onChange={(e: { value: string }) => setMonth(e.value)}
                      value={selectOpts?.find((dt) => dt?.value === month)}
                    />
                  </div>
                  <div>
                    <p className="mb-0">As of {getCurrentDateTime()}</p>
                  </div>
                </div>
                {loading ? (
                  <div style={{ height: 60 }} className="position-relative">
                    <img
                      src={Loader}
                      className={`position-absolute top-50 start-50 translate-middle`}
                      alt="loading"
                    />
                  </div>
                ) : (
                  <>
                    <Row
                      style={{
                        borderBottom: "1px solid rgba(0,0,0,.12)",
                        margin: 0,
                      }}
                    >
                      <Col className="mb-2 fs-md fw-semibold">Product</Col>
                      <Col
                        xs={2}
                        sm={2}
                        md={1}
                        className="mb-2 fs-md fw-semibold text-end"
                      >
                        Units
                      </Col>
                      <Col
                        xs={2}
                        sm={2}
                        md={1}
                        className="mb-2 fs-md fw-semibold text-end"
                      >
                        Quantity
                      </Col>
                      <Col
                        xs={2}
                        sm={2}
                        md={1}
                        className="mb-2 fs-md fw-semibold text-end"
                      >
                        Cost
                      </Col>
                    </Row>
                    {Object.keys(summaryObj)?.length > 0 ? (
                      Object.keys(summaryObj).map((dt1, i1) =>
                        typeof summaryObj?.[dt1] === "object" &&
                        !Array.isArray(summaryObj?.[dt1]) &&
                        summaryObj?.[dt1] !== null ? (
                          <Accordion
                            alwaysOpen={false}
                            activeKey={activeAcc}
                            className="accordion-flush summary-accordian"
                            key={i1}
                          >
                            <Accordion.Item eventKey={dt1}>
                              <Accordion.Header
                                id={dt1}
                                style={{
                                  borderBottom: "1px solid rgba(0,0,0,.12)",
                                }}
                                className="lefticon-accordion"
                                onClick={() => {
                                  if (activeAcc === dt1) {
                                    setActiveAcc("");
                                  } else {
                                    setActiveAcc(dt1);
                                  }
                                }}
                              >
                                <Row>
                                  <Col>
                                    <div>
                                      <Link
                                        to={`/settings/usage-transactions?type=${dt1}`}
                                      >
                                        {dt1 === "SMS" || dt1 === "MMS"
                                          ? dt1.toUpperCase()
                                          : capitalizeString(dt1)}
                                      </Link>
                                    </div>
                                  </Col>
                                  <Col
                                    xs={2}
                                    sm={2}
                                    md={1}
                                    className="text-end"
                                  ></Col>
                                  <Col
                                    xs={2}
                                    sm={2}
                                    md={1}
                                    className="text-end"
                                  >
                                    {
                                      usageSummaryObj?.total?.[dt1]
                                        ?.transactions
                                    }
                                  </Col>
                                  <Col
                                    xs={2}
                                    sm={2}
                                    md={1}
                                    className="text-end"
                                  >
                                    $
                                    {usageSummaryObj?.total?.[dt1]?.total
                                      ? (+usageSummaryObj?.total?.[dt1]
                                          ?.total)?.toFixed(2)
                                      : 0}
                                  </Col>
                                </Row>
                              </Accordion.Header>
                              <Accordion.Body className="p-0">
                                {Object.keys(summaryObj?.[dt1])?.length > 0
                                  ? Object.keys(summaryObj?.[dt1]).map(
                                      (dt2, i2) => (
                                        <Row
                                          key={i2}
                                          className="p-3 pe-0 me-0 ms-4"
                                          style={{
                                            borderBottom:
                                              "1px solid rgba(0,0,0,.12)",
                                          }}
                                        >
                                          <Col>{capitalizeString(dt2)}</Col>
                                          <Col
                                            xs={2}
                                            sm={2}
                                            md={1}
                                            className="text-end"
                                          ></Col>
                                          <Col
                                            xs={2}
                                            sm={2}
                                            md={1}
                                            className="text-end"
                                          >
                                            {
                                              usageSummaryObj?.total?.[dt1]?.[
                                                dt2
                                              ]?.transactions
                                            }
                                          </Col>
                                          <Col
                                            xs={2}
                                            sm={2}
                                            md={1}
                                            className="text-end"
                                          >
                                            $
                                            {usageSummaryObj?.total?.[dt1]?.[
                                              dt2
                                            ]?.total
                                              ? (+usageSummaryObj?.total?.[
                                                  dt1
                                                ]?.[dt2]?.total)?.toFixed(2)
                                              : 0}
                                          </Col>
                                        </Row>
                                      )
                                    )
                                  : null}
                              </Accordion.Body>
                            </Accordion.Item>
                          </Accordion>
                        ) : (
                          <div>{dt1}</div>
                        )
                      )
                    ) : (
                      <p className="mt-3">No Record Found</p>
                    )}
                  </>
                )}
              </Card.Body>
            </Card>
          </div>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(Summary);
